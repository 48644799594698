<template>
  <div>
    <div>
      <v-container class="pb-0">
        <v-row class="">
          <AppBreadcrumb :items="breadcrumb_items" />
        </v-row>
        <v-row class="mb-4">
          <v-col cols="4" md="3" class="text-center">
            <v-avatar
              size="240"
              style="border-radius: 50%"
            >
              <v-img
                :src="
                  user.profilePicture
                    ? user.profilePicture
                    : require('@/assets/unknown.jpeg')
                "
              ></v-img>
            </v-avatar>
            <div class="text-h5 mt-5" style="font-weight: 500;">{{ user.surname }} {{ user.name[0] }}. {{ user.middleName[0] }}.</div>
            <div class="d-flex align-center mt-2">
              <v-spacer></v-spacer>
              <div v-for="(item, index) in valid_contact_list" :key="index">
              <a
                class="reset-link"
                :href="item.link"
                target="_blank"
              >
                <v-btn
                  elevation="0"
                  x-small
                  color="transparent"
                  height="40px"
                  class="mx-1"
                >
                  <v-icon color="black"> {{ item.icon }}</v-icon>
                </v-btn>
              </a>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-col>
          <v-col cols="8" md="9">
            <div class="mb-16">
              <div class="white" style="border-radius: 12px;">
                <v-container>
                  <div class="mb-4 text-h5">
                    Персональные данные
                  </div>
                  <v-row class="mb-4">
                    <v-col cols="6" class="mb-2" v-show="user.birthDate">
                      <div class="body-1 mb-2 grey--text">Дата рождения</div>
                      <div class="">
                        {{ rightDate(user.birthDate) }}
                      </div>
                    </v-col>
                    <v-col cols="6" class="mb-2" v-show="user.gender">
                      <div class="body-1 mb-2 grey--text">Пол</div>
                      <div class="">{{ user.gender }}</div>
                    </v-col>
                    <v-col cols="6" class="mb-2" v-show="user.phone">
                      <div class="body-1 mb-2 grey--text">Номер телефона</div>
                      <div class="">
                        {{ user.phone }}
                      </div>
                    </v-col>
                    <v-col cols="6" class="mb-2" v-show="user.mail">
                      <div class="body-1 mb-2 grey--text">Электронная почта</div>
                      <div class="blue--text">
                        {{ user.mail }}
                      </div>
                    </v-col>
                    <v-col cols="6" class="mb-2" v-show="user.address">
                      <div class="body-1 mb-2 grey--text">Адрес</div>
                      <div class="">
                        {{ user.address }}
                      </div>
                    </v-col>
                  </v-row>
                  <div class="mb-4 text-h5">
                    Роли пользователя
                  </div>
                  <div v-if="rolelist.length != 0">
                    <v-tabs
                    style="border-radius: 12px 12px 0px 0px; border: 1px solid #379AD3;"
                    v-model="tab"
                    background-color="#EBF5FB"
                    color="primary"
                    centered
                    >
                      <v-tab
                      v-for="(role, i) in rolelist"
                      :key="i"
                      @change="changeRoleId(role.id)"
                      >
                        {{ role.name }}
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items style="border: 1px solid #379AD3; border-top: 0px; border-radius: 0px 0px 12px 12px;" v-model="tab">
                      <v-tab-item
                      v-for="(role, i) in rolelist"
                      :key="i">

                        <user-information :isLK="false" :userId="playerId" :roleId="componentKey" :key="componentKey"  />

                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                  <div class="mb-4 mt-4 text-h5" v-if="advertisements.length != 0">
                    Активные объявления
                  </div>
                  <v-row dense class="mt-5">
                    <v-col cols="12" md="6" v-for="item in advertisements" :key="item.id" @click="viewForum(item)">
                      <AdminForumCard :forum="item"></AdminForumCard>
                    </v-col>
                  </v-row>
                  <div class="mb-1 mt-4 text-h5" v-if="teams.length != 0">
                    Команды
                  </div>
                  <v-row class="mt-2">
                    <v-col cols="6">
                      <div v-for="team in teams" :key="team.id">
                        <router-link
                        :to="{ name: 'team-name', params: { teamId: team.teamId } }"
                        class="undo-link-default"
                        >
                          <v-card  class="rounded-xl" elevation="0">
                            <div class="d-flex flex-no-wrap">
                              <v-avatar class="ma-3 rounded-lg" size="125" tile>
                                <v-img
                                  :src="
                                    team.teamLogo != null
                                      ? team.teamLogo
                                      : require('@/assets/team_room_1.jpg')
                                  "
                                ></v-img>
                              </v-avatar>
                              <v-card-text>
                                <div
                                  class="body-1 blue--text mb-2"
                                  style="text-decoration: none"
                                >
                                  {{ team.teamCity }}
                                </div>
                                <div class="text-h5 mb-2">{{ team.teamTitle }}</div>
                              </v-card-text>
                            </div>
                          </v-card>
                        </router-link>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>   
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import moment from "moment";
import AdminForumCard from '../../../components/Admin/Forum/AdminForumCard.vue';
import UserInformation from '../../Admin/User/View/UserInformation.vue';

export default {
  components: { AdminForumCard, UserInformation },
  created() {
    this.getUserRoles()
    this.getAllAdminTeams()
    this.$store.dispatch("user/getUser", this.playerId).then(() => {
      console.log(this.user)
      this.mail = this.user.mail;
      const user = this.user;
      this.breadcrumb_items = [
        {
          text: "Раздевалка",
          disabled: false,
          exact: true,
          to: { name: "room" },
        },
        {
          text: user.name + " " + user.surname,
          disabled: true,
          to: "",
        },
      ],
      this.contact_list = [
        {
          icon: "mdi-whatsapp",
          link: `${user.whatsApp ? user.whatsApp : ""}`,
        },
        {
          icon: "mdi-instagram",
          link: `${user.instagram ? user.instagram : ""}`,
        },
        {
          icon: "mdi-alpha-k-box",
          link: `${user.vk ? user.vk : ""}`,
        },
        {
          icon: "mdi-web",
          link: `${user.website ? user.website : ""}`,
        },
        {
          icon: "mdi-music-note-outline",
          link: `${user.tiktok ? user.tiktok : ""}`,
        },
        {
          icon: "mdi-twitter",
          link: `${user.twitter ? user.twitter : ""}`,
        },
        {
          icon: "mdi-youtube",
          link: `${user.youtube ? user.youtube : ""}`,
        },
        {
          icon: "mdi-facebook",
          link: `${user.facebook ? user.facebook : ""}`,
        },
      ];
    })
    this.$store.dispatch("team-player/getPlayerInformation", this.playerId);
    //const playerId = this.playerId;
    
    
    this.getUserAdvertisements();
  },
  mounted(){
  },
  computed: {
    playerId() {
      return this.$route.params.playerId;
    },
    ...mapState("user", ["user"]),
    valid_contact_list() {
      return this.contact_list.filter((x) => {
        if (x.link !== "null") {
          if (x.link) return x.link;
        }
      });
    },
    roleCategory() {
      if (this.trainerRole.name === "TRAINER") {
        return this.trainerRole.category
          .map((cat) => {
            if (cat == "ADULT") return "Взрослый";
            if (cat == "KID") return "Детский";
            if (cat == "YOUTH") return "Юношеский";
            if (cat == "FEMALE") return "Женский";
          })
          .join(", ");
      } else return "";
    },
  },
  data() {
    return {
      breadcrumb_items: null,
      sidebar_items: [],
      sidebar_tab: 0,
      enterMail: true,
      contact_list: [],
      mail: "",
      advertisements: [],
      // playerRole: "",
      // trainerRole: "",
      // userRole: "",
      teams: [],
      rolelist: [],
      componentKey: "",
      displayRoles: [],
      tab: null,
    };
  },
  methods: {
    async getUserAdvertisements() {
      const playerId = this.playerId;
      await Axios.get(`${GET_USER_BY_ID}${playerId}/forums`)
      .then((res) => {
        this.advertisements = res.data
      })
      .catch((error) => {
        console.error(error);
      });
    },
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.playerId}/roles`)
      .then( (res) => {
        this.displayRoles = res.data.map((role) => {
          if (role.name === "PLAYER") return { ...role, name: "Игрок" };
          if (role.name === "TRAINER") return { ...role, name: "Тренер" };
          if (role.name === "SELLER") return { ...role, name: "Продавец" };
          if (role.name === "ARENA_ADMIN") return { ...role, name: "Администратор арены" };
          if (role.name === "TEAM_ADMIN") return { ...role, name: "Администратор команды" };
          if (role.name === "TOURNAMENT_ORGANIZER") return { ...role, name: "Организатор турнира"};
          if (role.name === "string")
            return { ...role, name: "недопустимая роль" };
        })

        this.displayRoles.forEach(elem => {
          let newObject = {
            name: elem.name,
            id: elem.id,
          }
          this.rolelist.push(newObject)
        })
        this.componentKey = this.rolelist[0].id
      })
      .catch((error) => {
        console.error(error);
      })
    },

    changeRoleId(id){
      this.componentKey = id
    },

    async getAllAdminTeams() {
      await Axios.get(`${GET_USER_BY_ID}${this.playerId}/team/titles`)
      .then( (res) => {
        this.teams = res.data.filter((x) => x.approved === "accepted")
      })
      .catch((error) => {
        console.error(error);
      })
    },

    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("D MMMM YYYY");
    },

    viewForum(forum) {
      this.forum = forum
      const id = forum.id
      this.$router.push({
        name: "advertisement",
        params: { advertisementId: id },
      });
    },
  },
};
</script>

<style scoped></style>
