<template>
  <div>
    <div class="text-h6">Команды</div>
    <v-row class="mt-2 mb-0 pb-0">
      <v-col cols="4" md="3" lg="2" xl="1" class="pb-0 mt-0">
        <v-select
            :items="seasons"
            value="Сезон"
            v-model="season"
            placeholder="Сезон"
            item-text="title"
            solo
            flat
            return-object
            hide-details="auto"
            @change="fetchTournaments"
        ></v-select>
      </v-col>
      <v-col cols="4" md="3" lg="2" xl="1" class="pb-0 mt-0">
        <v-select
            :items="tournaments"
            placeholder="Турнир"
            v-model="tournament"
            :item-text="getTitle"
            solo
            flat
            return-object
            hide-details="auto"
            @change="fetchDivisions"
        ></v-select>
      </v-col>
      <v-col cols="4" md="3" lg="2" xl="1" class="pb-0 mt-0">
        <v-select
            :items="divisions"
            placeholder="Дивизион"
            v-model="division"
            item-text="title"
            solo
            flat
            hide-details="auto"
            return-object
            @change="getTeams"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mt-2 mb-0 pb-0" v-if="teamsFetching">
      <v-col cols="8" md="9" lg="6" xl="3" sm="12" xs="12" class="pb-0 mt-0">
        <div>
          <app-loader/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="">
        <div class="mt-4 my-auto" v-for="(item, i) in teams" :key="i">
          <span class="mr-4 ">{{ item.team.title }}</span>
          <v-icon @click="removeTeam(item.team.id)">
            mdi-close
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="admins_dialog" max-width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mr-2 mt-6"
            color="primary"
            large
            elevation="0"
            v-bind="attrs"
            v-on="on"
            @click="openAdminDialog"
        >
          Добавить команду
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить команду</div>
          <div class="mb-4">
            <v-icon @click.stop="admins_dialog = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-col class="text-h6 mb-n4">Сезон</v-col>
          <v-row cols="12" align="center">
            <v-col cols="12">
              <v-autocomplete
                  v-model="selected_season"
                  :items="dialog_seasons"
                  :loading="is_searching"
                  :search-input.sync="season_search_text"
                  color="white"
                  solo
                  flat
                  single-line
                  hide-selected
                  hide-no-data
                  item-text="title"
                  item-value="id"
                  label="Поиск сезона"
                  placeholder="Поиск сезона"
                  prepend-inner-icon="mdi-magnify"
                  return-object
                  clearable
                  hide-details="auto"
                  class="rounded-lg"
                  @change="fetchDialogTournaments"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <div v-if="season_search_text !== null">
            <v-col cols="12" class="text-h6 mb-n4">Турнир</v-col>
            <v-row cols="12" align="center">
              <v-col cols="10" class="pr-0">
                <v-autocomplete
                    v-model="selected_tournament"
                    :items="dialog_tournaments"
                    :loading="is_searching"
                    :search-input.sync="tournament_search_text"
                    color="white"
                    solo
                    flat
                    single-line
                    hide-selected
                    hide-no-data
                    item-text="title"
                    item-value="id"
                    label="Поиск турнира"
                    placeholder="Поиск турнира"
                    prepend-inner-icon="mdi-magnify"
                    return-object
                    clearable
                    hide-details="auto"
                    class="rounded-lg"
                    @change="fetchDialogDivisions"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1" class="ma-0">
                <v-dialog v-model="tournament_dialog" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="body-2 pa-3"
                        v-bind="attrs"
                        v-on="on"
                    >Добавить
                    </v-btn>
                  </template>
                  <v-card :loading="adding_tournaments" class="py-3">
                    <v-card-title class="justify-space-between">
                      <div class="text-h5 black--text">Добавить турнир</div>
                      <div class="mb-4">
                        <v-icon @click.stop="tournament_dialog = false">mdi-close</v-icon>
                      </div>
                    </v-card-title>
                    <v-card-text class="mb-4">
                      <v-col cols="12" class="text-h6 mb-n4">Название турнира</v-col>
                      <v-text-field
                          class="ml-3 mr-3"
                          placeholder="Название турнира"
                          v-model="tournamentTitle"
                          :rules="tournamentTitleRules"
                      />
                    </v-card-text>
                    <v-card-actions class="mt-3">
                      <v-btn
                          class="body-2"
                          @click="tournament_dialog = false"
                          elevation="0"
                      >
                        Назад
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                          elevation="0"
                          color="primary"
                          class="body-2"
                          @click="addTournament"
                      >
                        Добавить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

            </v-row>
          </div>

          <div v-if="tournament_search_text !== null">
            <v-col cols="12" class="text-h6 mb-n4">Дивизион</v-col>
            <v-row cols="12" align="center">
              <v-col cols="10" class="pr-0">
                <v-autocomplete
                    v-model="selected_division"
                    :items="dialog_divisions"
                    :loading="is_searching"
                    :search-input.sync="division_search_text"
                    color="white"
                    solo
                    flat
                    single-line
                    hide-selected
                    hide-no-data
                    item-text="title"
                    item-value="id"
                    label="Поиск дивизиона"
                    placeholder="Поиск дивизиона"
                    prepend-inner-icon="mdi-magnify"
                    return-object
                    clearable
                    hide-details="auto"
                    class="rounded-lg"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1" class="ma-0">
                <v-dialog v-model="division_dialog" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="body-2 pa-3"
                        v-bind="attrs"
                        v-on="on"
                    >Добавить
                    </v-btn>
                  </template>
                  <v-card :loading="adding_tournaments" class="py-3">
                    <v-card-title class="justify-space-between">
                      <div class="text-h5 black--text">Добавить дивизион</div>
                      <div class="mb-4">
                        <v-icon @click.stop="division_dialog = false">mdi-close</v-icon>
                      </div>
                    </v-card-title>
                    <v-card-text class="mb-4">
                      <v-col cols="12" class="text-h6 mb-n4">Название дивизиона</v-col>
                      <v-text-field
                          class="ml-3 mr-3"
                          placeholder="Название дивизиона"
                          v-model="divisionTitle"
                          :rules="divisionTitleRules"
                      />
                    </v-card-text>
                    <v-card-actions class="mt-3">
                      <v-btn
                          class="body-2"
                          @click="tournament_dialog = false"
                          elevation="0"
                      >
                        Назад
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                          elevation="0"
                          color="primary"
                          class="body-2"
                          @click="addDivision"
                      >
                        Добавить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

            </v-row>
          </div>

          <div v-if="division_search_text !== null">
            <v-col cols="12" class="text-h6 mb-n4">Команда</v-col>
            <v-autocomplete
                v-model="selected_team"
                :items="dialog_teams"
                :loading="is_searching"
                :search-input.sync="team_search_text"
                color="white"
                solo
                flat
                single-line
                hide-selected
                hide-no-data
                item-text="title"
                item-value="id"
                label="Поиск команды"
                placeholder="Поиск команды"
                prepend-inner-icon="mdi-magnify"
                return-object
                clearable
                hide-details="auto"
                class="rounded-lg"
            ></v-autocomplete>
          </div>

        </v-card-text>

        <v-card-actions class="mt-3">
          <v-btn
              class="body-2"
              @click="closeAdminDialog"
              elevation="0"
          >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="body-2"
              @click="addTeam"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Axios from "axios";
import {
  ADD_TEAM_TO_DIVISION,
  GET_SEASONS,
  GET_TEAMS,
  GET_TOURNAMENT_DIVISIONS
} from "@/api";
import AppLoader from "@/components/AppUnit/AppLoader";
import {apiLeagues} from "@/service";

export default {
  props: {
    leagueId: {
      type: String,
      required: true,
    },
  },
  components: {
    AppLoader
  },
  data() {
    return {
      selected_user: null,
      search_text: "",
      division_search_text: "",
      season_search_text: "",
      tournament_search_text: "",
      season_dialog_search_text: "",
      tournament_dialog_search_text: "",
      division_dialog_search_text: "",
      team_search_text: "",
      is_searching: false,
      admins_dialog: false,
      adding_tournaments: false,
      adding_seasons: false,
      adding_divisions: false,
      tournament_dialog: false,
      season_dialog: false,
      division_dialog: false,
      tournamentTitle: "",
      divisionTitle: "",
      adding: false,
      users: [],
      userOrganizerId: "",
      otherOrganizers: [],
      season: {},
      tournament: {},
      division: {},
      selected_season: {},
      selected_tournament: {},
      selected_division: {},
      selected_team: {},
      selected_teams: [],
      tournamentTitleRules: [
        v => !!v || "Введите название турнира"
      ],
      divisionTitleRules: [
        v => !!v || "Введите название дивизиона"
      ],
      teamsFetching: false,
      added_season: {},
      added_tournament: {},
      all_seasons: [],
      dialog_seasons: [],
      dialog_tournaments: [],
      dialog_divisions: [],
      dialog_teams: []
    };
  },
  async created() {
    await this.getParams();
    await this.fetchTeams();
    await this.$store.dispatch("user/clearTeamParams");
    await this.$store.dispatch("user/getSeasons");
  },
  computed: {
    ...mapState("user", ["teamParams", "teams", "seasons", "divisions", "tournaments", "allSeasons"]),
    ...mapState("team", {
      fetchedTeams: state => state.teams,
    }),
    // teamadminsList() {
    //   return this.teamadmins.map((x) => {
    //     const user = x;
    //     return {
    //       ...user,
    //       fullName: `${user.name} ${user.middleName} ${user.surname}`,
    //     };
    //   });
    // },
  },
  methods: {
    itemSeasonChange(e) {
      this.selected = e;
      this.$nextTick(() => {
        this.selected_season = null;
      });
    },
    getTitle(item) {
      return item.tournament.title
    },
    closeSeasonDialog() {
      this.season_dialog = false;
      this.fetchDialogSeasons();
    },
    async openAdminDialog() {
      await this.fetchDialogSeasons();
      await Axios.get(`${GET_TEAMS}`).then(response => {
        this.dialog_teams = response.data;
      })
    },
    async fetchDialogSeasons() {
      this.dialog_seasons = [];
      this.dialog_tournaments = [];
      this.dialog_divisions = [];
      this.selected_season = {};
      this.selected_tournament = {};
      this.selected_division = {};
      await Axios.get(`${GET_SEASONS}`).then(response => {
        this.dialog_seasons = response.data;
      })
    },
    async fetchDialogTournaments() {
      this.dialog_tournaments = [];
      this.dialog_divisions = [];
      this.selected_tournament = {};
      this.selected_division = {};
      await apiLeagues.getLeagueTournaments({seasonId: this.selected_season.id, leagueId: this.leagueId}).then(response => {
        response.data.forEach(item => {
          this.dialog_tournaments.push(item.tournament);
        });
      });
    },
    async fetchDialogDivisions() {
      this.dialog_divisions = [];
      this.selected_division = {};
      return Axios.get(`${GET_TOURNAMENT_DIVISIONS}?tournamentId=${this.selected_tournament.id}`).then(response => {
        response.data.forEach(item => {
          this.dialog_divisions.push(item.division);
        })
      })
    },
    closeAdminDialog() {
      this.season = {};
      this.tournament = {};
      this.division = {};
      this.clearTeams();
      this.admins_dialog = false;
    },
    validate() {
      return this.tournamentTitle !== "" && this.added_season !== {}
    },

    async addTournament() {
      if (this.validate()) {
        await this.$store.dispatch("user/createTournament", {
          title: this.tournamentTitle,
          seasonId: this.selected_season.id,
          leagueId: this.leagueId
        });
        await this.fetchDialogTournaments();
        this.tournamentTitle = "";
        this.tournament_dialog = false;
      }
    },

    validateSeason() {
      return this.added_season !== {}
    },

    async addSeason() {
      if (this.validateSeason()) {
        // await this.$store.dispatch("user/addSeasonToLeague", {leagueId: this.leagueId, seasonId: this.added_season.id});
        await this.$store.dispatch("user/getLeagueSeasons", this.leagueId)
        await this.fetchDialogSeasons();
        this.added_season = {};
        this.season_dialog = false;
      }
    },

    validateDivisionData() {
      return this.divisionTitle !== "" && this.added_tournament !== {}
    },

    async addDivision() {
      if (this.validateDivisionData()) {
        await this.$store.dispatch("user/createDivision", {
          title: this.divisionTitle,
          tournamentId: this.selected_tournament.id,
        })
        await this.fetchDialogDivisions();
        this.divisionTitle = "";
        this.division_dialog = false;
      }
    },
    // async getAllTeamAdministrator() {
    //   await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TEAM_ADMIN`)
    //   .then( (res) => {
    //     this.admins = res.data
    //     console.log(this.admins)
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   })
    // },
    clearTeams() {
      this.$store.dispatch("user/clearTeams");
    },
    getParams() {
      this.$store.dispatch("user/clearTeams");
      this.$store.dispatch("user/getSeasons");
      this.$store.dispatch("user/getDivisions");
    },
    fetchTournaments() {
      this.clearTeams();
      this.tournament = ''
      this.division = ''
      this.$store.dispatch("user/getLeagueTournaments", {seasonId: this.season.id, leagueId: this.leagueId})
    },
    fetchDivisions() {
      this.clearTeams();
      this.division = ''
      this.$store.dispatch("user/getTournamentDivisions", this.tournament.tournament.id)
    },
    async getTeams() {
      this.teamsFetching = true;
      await this.fetchTeams();
      this.teamsFetching = false;
    },
    fetchTeams() {
      const filters = [
        {
          "filterConditions": "EQUAL",
          "value": {
            "type": "UUID",
            "uuid": this.division.id,
          },
          "parameter": "divisionId",
        },
      ];
      const params = {};
      const config = [filters, params];
      return this.$store
          .dispatch("user/getTournamentTeams", config);
    },

    async addTeam() {
      this.adding = true;
      await this.$store.dispatch("user/addTeam", {
        "divisionId": this.selected_division.id,
        "teamId": this.selected_team.id,
      });
      this.selected_season = "";
      this.selected_tournament = "";
      this.selected_division = "";
      this.selected_team = "";
      await this.getTeams();
      this.admins_dialog = false;
      await this.$store.dispatch("user/getLeagueSeasons", this.leagueId);
      this.season = {};
      this.tournament = {};
      this.division = {};
      this.clearTeams();
      this.adding = false;
      // const data = {
      //   leagueId: this.leagueId,
      //   userId: this.selected_user.id,
      //   roleId: this.organizerRoleId,
      // };
      // await Axios.post(`${ADD_USER_TO_LEAGUE}`, data)
      //   .then(() => {
      //     this.admins_dialog = false;
      //     this.selected_user = null;
      //     this.getLeagueAdministrators();
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
      //   .finally(() => {
      //     this.adding = false;
      //   });
    },
    async removeTeam(id) {
      await Axios.delete(`${ADD_TEAM_TO_DIVISION}?divisionId=${this.division.id}&teamId=${id}`)
          .then(() => {
            this.getTeams();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.adding = false;
          });
    },

    async getLeagueAdministrators() {
      await this.$store.dispatch("leagues/getLeagueAdministrators", this.leagueId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
