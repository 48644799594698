<template>
  <div>
    <div class="text-h6">Организаторы лиги</div>
    <v-row>
      <v-col cols="6" class="" v-show="administrators.length">
        <div class="mt-4 my-auto" v-for="(item, i) in administrators" :key="i">
          <span class="mr-4 ">{{ item.user.surname }} {{ item.user.name }} {{ item.user.middleName }}</span>
          <v-icon v-if="administrators.length > 1" @click="removeOrganizer(item.user.id)">
            mdi-close
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="admins_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить организатора
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить организатора</div>
          <div class="mb-4">
            <v-icon @click.stop="admins_dialog = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-col cols="12" class="text-h6 mb-n4">Организаторы лиги</v-col>
          <v-autocomplete
            v-model="selected_user"
            :items="otherOrganizers"
            :loading="is_searching"
            :search-input.sync="search_text"
            color="white"
            solo
            flat
            single-line
            hide-selected
            hide-no-data
            item-text="fullName"
            item-value="id"
            label="Поиск пользователя"
            placeholder="Поиск пользователя"
            prepend-inner-icon="mdi-magnify"
            return-object
            clearable
            hide-details="auto"
            class="rounded-lg"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-btn
            class="body-2"
            @click="admins_dialog = false"
            elevation="0"
          >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="body-2"
            @click="addOrganizer"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { ADD_USER_TO_LEAGUE } from "@/api";
import { apiUser } from "@/service";

export default {
  props: {
    leagueId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_user: null,
      search_text: "",
      is_searching: false,
      admins_dialog: false,
      adding: false,
      users: [],
      userOrganizerId: "",
      otherOrganizers: []
    };
  },
  async created() {
    this.getLeagueAdministrators();
    await apiUser.getUsersByRole("TOURNAMENT_ORGANIZER")
      .then((response) => {
      this.otherOrganizers = response.data;
      this.otherOrganizers.forEach((user) => {
          user.fullName = `${user.name} ${user.middleName} ${user.surname}`
      })
    });
  },
  computed: {
    ...mapState("leagues", ["administrators", "organizerRoleId"]),
    // teamadminsList() {
    //   return this.teamadmins.map((x) => {
    //     const user = x;
    //     return {
    //       ...user,
    //       fullName: `${user.name} ${user.middleName} ${user.surname}`,
    //     };
    //   });
    // },
  },
  methods: {
    // async getAllTeamAdministrator() {
    //   await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TEAM_ADMIN`)
    //   .then( (res) => {
    //     this.admins = res.data
    //     console.log(this.admins)
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   })
    // },

    async addOrganizer() {
      this.adding = true;
      const data = {
        leagueId: this.leagueId,
        userId: this.selected_user.id,
        roleId: this.organizerRoleId,
      };
      await Axios.post(`${ADD_USER_TO_LEAGUE}`, data)
        .then(() => {
          this.admins_dialog = false;
          this.selected_user = null;
          this.getLeagueAdministrators();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.adding = false;
        });
    },
    async removeOrganizer(id) {
       await Axios.delete(`${ADD_USER_TO_LEAGUE}?leagueId=${this.leagueId}&userId=${id}`)
         .then(() => {
          this.getLeagueAdministrators();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.adding = false;
        });
    },

    async getLeagueAdministrators() {
      await this.$store.dispatch("leagues/getLeagueAdministrators", this.leagueId);
    }
  },
};
</script>

<style lang="scss" scoped></style>
