<template>
  <div>
    <v-breadcrumbs :items="breadcrumb_items" class="pl-0 pt-0" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" @click="$router.push(item.to)" >
        <span :style="`color: black; font-size: 24px`">
          {{ item.text }}
        </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <p class="mb-8" style="font-size: 18px">Выберите турнир:</p>
    <TournamentsComponent
      v-if="isLoading === false"
      :data="tournaments"
      :type="{func: toDivisions}"
    />
    <app-loader v-else/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TournamentsComponent from "@/components/Leagues/TournamentsComponent";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "LeagueTournament",
  components: { TournamentsComponent, AppLoader },
  computed: {
    ...mapState("leagues", ["tournaments", "teamParams"]),
  },
  props: {
    leagueId: String,
  },
  beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Турниры",
        disabled: false,
        exact: true,
        to: { name: "league-seasons", params: { leagueId: this.leagueId } },
      },
      {
        text: this.teamParams.season.title,
        disabled: false,
        exact: true,
        to: { name: "league-seasons", params: { leagueId: this.leagueId } },
      },
    ];
  },
  created() {
    this.getTournaments();
  },
  data() {
    return {
      breadcrumb_items: [],
      tournamentsInfo: [],
      isLoading: true,
    };
  },
  methods: {
    async getTournaments() {
      await this.$store.dispatch("leagues/getLeagueTournaments", {seasonId: this.teamParams.season.id, leagueId: this.leagueId});
      this.isLoading = false;
    },
    toDivisions(tournament) {
      this.$store.dispatch("leagues/setTeamTournament", tournament);
      this.$router.push({ name: "league-divisions", params: { leagueId: this.leagueId } });
    },
  },
};
</script>

<style scoped>
.container {
  background-color: white;
  height: 80px;
  border-radius: 10px;
}
</style>
