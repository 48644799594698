var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white",staticStyle:{"border-radius":"12px"}},[_c('v-container',{staticClass:"pt-0 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"mb-2 d-flex flex-column  align-center",attrs:{"cols":"3"}},[_c('div',[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"contain":"","tile":"","size":"180"}},[_c('v-img',{attrs:{"contain":"","src":_vm.league.logo != null
                          ? _vm.league.logo
                          : require('@/assets/team_room_1.jpg')}})],1)],1),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-h5 mb-0"},[_vm._v(_vm._s(_vm.league.fullTitle))])]),_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.league.city)+" ")])])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-container',[_c('p',{staticClass:"text-h4"},[_vm._v("Турниры")]),_c('v-row',{staticClass:"mx-n4"},[_c('v-col',{staticClass:"pb-0 mt-0",attrs:{"cols":"4"}},[_c('v-select',{staticClass:"elevation-2",attrs:{"items":_vm.seasons,"value":"Сезон","placeholder":"Сезон","item-text":"title","solo":"","flat":"","return-object":"","hide-details":"auto"},on:{"change":_vm.fetchTournaments},model:{value:(_vm.season),callback:function ($$v) {_vm.season=$$v},expression:"season"}})],1),(Object.keys(_vm.season).length)?_c('v-col',{staticClass:"mt-1",attrs:{"cols":"1"}},[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1837448325),model:{value:(_vm.new_dialog),callback:function ($$v) {_vm.new_dialog=$$v},expression:"new_dialog"}},[_c('v-card',{staticClass:"py-3",attrs:{"loading":_vm.adding_tournaments}},[_c('v-card-title',{staticClass:"justify-space-between"},[_c('div',{staticClass:"text-h5 black--text"},[_vm._v("Добавить турнир")]),_c('div',{staticClass:"mb-4"},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();_vm.new_dialog = false}}},[_vm._v("mdi-close")])],1)]),_c('v-card-text',{staticClass:"mb-4"},[_c('v-col',{staticClass:"text-h6 mb-n4",attrs:{"cols":"12"}},[_vm._v("Название турнира")]),_c('v-text-field',{staticClass:"ml-3 mr-3",attrs:{"placeholder":"Название турнира","rules":_vm.tournamentTitleRules},model:{value:(_vm.newTournamentTitle),callback:function ($$v) {_vm.newTournamentTitle=$$v},expression:"newTournamentTitle"}})],1),_c('v-card-actions',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"body-2",attrs:{"elevation":"0"},on:{"click":function($event){_vm.new_dialog = false}}},[_vm._v(" Назад ")]),_c('v-spacer'),_c('v-btn',{staticClass:"body-2",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.addTournament}},[_vm._v(" Добавить ")])],1)],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[(!_vm.isFetching)?_c('div',_vm._l((_vm.tournaments),function(item){return _c('v-row',{key:item.id,staticClass:"container mb-2 mt-3 elevation-4 rounded-lg"},[_c('p',{staticClass:"mt-4 ml-4"},[_vm._v(_vm._s(item.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.setEditingTournament(item)}}},'v-btn',attrs,false),on),[_vm._v("Редактировать ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"py-3",attrs:{"loading":_vm.adding_tournaments}},[_c('v-card-title',{staticClass:"justify-space-between"},[_c('div',{staticClass:"text-h5 black--text"},[_vm._v("Редактировать турнир")]),_c('div',{staticClass:"mb-4"},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_vm._v("mdi-close")])],1)]),_c('v-card-text',{staticClass:"mb-4"},[_c('v-col',{staticClass:"text-h6 mb-n4",attrs:{"cols":"12"}},[_vm._v("Название турнира")]),_c('v-text-field',{staticClass:"ml-3 mr-3",attrs:{"placeholder":"Название турнира","rules":_vm.tournamentTitleRules},model:{value:(_vm.tournamentTitle),callback:function ($$v) {_vm.tournamentTitle=$$v},expression:"tournamentTitle"}})],1),_c('v-card-actions',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"body-2",attrs:{"elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Назад ")]),_c('v-spacer'),_c('v-btn',{staticClass:"body-2",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.editTournament}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-btn',{staticClass:"mt-2 ml-2 mr-2",attrs:{"color":"#C62828"},on:{"click":function($event){return _vm.removeTournament(item.id)}}},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-delete-outline")])],1)],1)}),1):_c('div',{staticStyle:{"height":"200px"}},[_c('app-loader')],1)])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }