import { apiLeagues as api } from "@/service";

export const namespaced = true;

export const state = () => ({
  leagues: [],
  league: {},
  arenas: [],
  administrators: [],
  cities: [],
  new_administrators: [],
  seasons: [],
  divisions: [],
  tournaments: [],
  teamParams: {
    season: "",
    division: "",
    tournament: "",
  },
  teams: [],
  organizerRoleId: "",
  paginationLength: 0,
  numFound: 0,
  allkidsTournaments: [],
  kidsTournaments: [],
  kidsTournament: {},
  kidsTournamentArenas: [],
});

export const mutations = {
  SET_LEAGUES(state, leagues) {
    state.leagues = leagues;
  },
  SET_LEAGUE(state, league) {
    state.league = league;
  },
  ADD_LEAGUE(state, league) {
    state.league.push(league);
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  SET_ADMINISTRATORS(state, administrators) {
    state.administrators = administrators;
  },
  SET_ORGANIZER_ROLE(state, role) {
    state.organizerRoleId = role;
  },
  SET_ARENAS(state, arenas) {
    state.arenas = arenas;
  },
  DELETE_LEAGUE(state, leagueId) {
    state.leagues = state.leagues.filter(
      (league) => league.league.id !== leagueId
    );
  },
  SET_SEASONS(state, seasons) {
    state.seasons = seasons;
  },
  SET_TOURNAMENTS(state, tournaments) {
    state.tournaments = tournaments;
  },
  SET_DIVISIONS(state, divisions) {
    state.divisions = divisions;
  },
  SET_TEAM_SEASON(state, season) {
    state.teamParams.season = season;
  },
  SET_TEAM_DIVISION(state, division) {
    state.teamParams.division = division;
  },
  SET_TEAM_TOURNAMENT(state, tournament) {
    state.teamParams.tournament = tournament;
  },
  SET_TEAMS(state, teams) {
    state.teams = teams;
  },
  SET_KIDS_TOURNAMENT(state, kidsTournament) {
    state.kidsTournament = kidsTournament;
  },
  SET_KIDS_TOURNAMENT_ARENAS(state, kidsTournamentArenas) {
    state.kidsTournamentArenas = kidsTournamentArenas;
  },
  SET_ALL_KIDS_TOURNAMENTS(state, allkidsTournaments) {
    state.allkidsTournaments = allkidsTournaments;
  },
  ADD_KIDS_TOURNAMENT(state, kidsTournament) {
    state.kidsTournaments.push(kidsTournament);
  },
  ADD_KIDS_TOURNAMENT_ARENA(state, arena) {
    state.kidsTournamentArenas.push(arena);
  },
  DELETE_KIDS_TOURNAMENT(state, tournamentId) {
    state.kidsTournaments = state.kidsTournaments.filter(
      (tour) => tour.id !== tournamentId
    );
  },
};

export const actions = {
  getLeagueAdministrators({ commit }, leagueId) {
    return api
      .getLeagueAdministrators(leagueId)
      .then((response) => {
        let adm = response.data.filter((item) =>
          item.user.roles.some((role) => role.name === "TOURNAMENT_ORGANIZER")
        );
        for (let item of adm) {
          for (let role of item.user.roles) {
            if (role.name === "TOURNAMENT_ORGANIZER") {
              commit("SET_ORGANIZER_ROLE", role.id);
            }
          }
        }
        commit("SET_ADMINISTRATORS", adm);
      })
      .catch((err) => console.log(err));
  },
  getLeagueArenas({ commit }, leagueId) {
    return api
      .getLeagueArenas(leagueId)
      .then((response) => {
        commit("SET_ARENAS", response.data);
      })
      .catch((err) => console.log(err));
  },
  getLeagues({ commit }, filters) {
    return api
      .getLeagues(filters)
      .then((response) => {
        const res = response.data;
        commit("SET_LEAGUES", res);
        return {
          paginationLength: 0,
          numFound: 1,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },
  filterLeagues({ commit }, filters, parameters) {
    return api
      .filterLeagues(filters, parameters)
      .then((response) => {
        const res = response.data.content;
        commit("SET_LEAGUES", res);
        return {
          paginationLength: response.data.totalPages,
          numFound: response.data.totalElements,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },
  filteringLeagues({ commit }, data) {
    return api
      .filteringLeagues(data)
      .then((response) => {
        const res = response.data.content;
        commit("SET_LEAGUES", res);
        return {
          paginationLength: response.data.totalPages,
          numFound: response.data.totalElements,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },
  searchLeagues({ commit }, params) {
    return api.searchLeagues(params).then((response) => {
      const res = response.data.content;
      commit("SET_LEAGUES", res);
      return {
        paginationLength: response.data.totalPages,
        numFound: response.data.totalElements,
      };
    });
  },
  getLeague({ commit }, leagueId) {
    return api
      .getLeague(leagueId)
      .then((response) => {
        commit("SET_LEAGUE", response.data);
      })
      .catch((err) => console.log(err));
  },
  saveLeague({ commit }, league) {
    return api
      .postLeague(league)
      .then((response) => {
        const res = response.data;
        // console.log("🚀 ~ file: arena.js ~ line 314 ~ .then ~ res", response);
        commit("ADD_LEAGUE", res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  postLeague(_context, league) {
    return api
      .postLeague(league)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  addKidsTournament({ commit }, data) {
    return api
      .addKidsTournament(data)
      .then((response) => {
        commit("ADD_KIDS_TOURNAMENT", response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  getKidsTournament({ commit }, tournamentId) {
    return api
      .getKidsTournament(tournamentId)
      .then((response) => {
        commit("SET_KIDS_TOURNAMENT", response.data);
      })
      .catch((err) => console.log(err));
  },
  getKidsTournamentArenas({ commit }, tournamentId) {
    return api
      .getKidsTournamentArenas(tournamentId)
      .then((response) => {
        commit("SET_KIDS_TOURNAMENT_ARENAS", response.data);
      })
      .catch((err) => console.log(err));
  },
  postKidsTournamentArena({ commit }, data) {
    return api
      .postKidsTournamentArena(data)
      .then((response) => {
        commit("ADD_KIDS_TOURNAMENT_ARENA", response.data);
      })
      .catch((err) => console.log(err));
  },
  deleteKidsTournamentArena(_context, { arenaId, tournamentId }) {
    return api
      .deleteKidsTournamentArena({ arenaId, tournamentId })
      .then(() => {})
      .catch((err) => console.log(err));
  },
  createUserTournament(_context, data) {
    return api
      .createUserTournament(data)
      .then((response) => {
        // commit("SET_USER_TOURNAMENT", response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  updateKidsTournament(_context, params) {
    return api
      .updateKidsTournament(params)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  deleteKidsTournament({ commit }, tournamentId) {
    return api
      .deleteKidsTournament(tournamentId)
      .then((response) => {
        commit("DELETE_KIDS_TOURNAMENT", tournamentId);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  filterKidsTournaments({ commit }, data) {
    return api
      .filterKidsTournaments(data)
      .then((response) => {
        commit("SET_ALL_KIDS_TOURNAMENTS", response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  putLeague(_context, league) {
    return api
      .putLeague(league)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCities({ commit }) {
    return api
      .getLeaguesCities()
      .then((response) => {
        commit("SET_CITIES", response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
  },
  addNewArena({ commit }, arena) {
    commit("ADD_NEW_ARENA", arena);
  },
  clearNewArenas({ commit }) {
    commit("CLEAR_NEW_ARENAS");
  },
  filterNewArenas({ commit }, arenaId) {
    commit("FILTER_NEW_ARENAS", arenaId);
  },
  deleteLeague({ commit }, leagueId) {
    return api
      .deleteLeague(leagueId)
      .then(() => {
        commit("DELETE_LEAGUE", leagueId);
      })
      .catch((err) => console.log(err));
  },
  getSeasons({ commit }) {
    return api.getSeasons().then((response) => {
      commit("SET_SEASONS", response.data);
    });
  },
  getLeagueTournaments({ commit }, params) {
    return api.getLeagueTournaments(params).then((response) => {
      let tournaments = [];
      response.data.forEach((item) => {
        tournaments.push(item.tournament);
      });
      commit("SET_TOURNAMENTS", tournaments);
    });
  },
  getTournamentDivisions({ commit }, tournamentId) {
    return api.getTournamentDivisions(tournamentId).then((response) => {
      let TournamentDivisions = [];
      response.data.forEach((item) => TournamentDivisions.push(item.division));
      commit("SET_DIVISIONS", TournamentDivisions);
    });
  },
  setTeamSeason({ commit }, season) {
    commit("SET_TEAM_SEASON", season);
  },
  setTeamDivision({ commit }, division) {
    commit("SET_TEAM_DIVISION", division);
  },
  setTeamTournament({ commit }, tournament) {
    commit("SET_TEAM_TOURNAMENT", tournament);
  },
  getDivisionTeams({ commit }, filters, parameters) {
    return api
      .getDivisionTeams(filters, parameters)
      .then((response) => {
        const res = response.data;
        commit("SET_TEAMS", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export const getters = {
  GET_LEAGUES(state) {
    return state.leagues;
  },
  GET_TOURNAMENT_DIVISIONS(state) {
    return state.divisions;
  },
};
