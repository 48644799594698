<template>
  <div class="footer grey darken-4">
  </div>
</template>

<script>
export default {
  name: "Footer"

}
</script>

<style>
.footer {
  height: 150px;
}
</style>