<template>
  <v-container>
    <v-row no-gutters>
      <AppBreadcrumb :items="breadcrumb_items" />
    </v-row>
    <v-row no-gutters>
      <v-col
        class="mt-3 d-flex flex-column"
        cols="2"
        sm="5"
        md="4"
        lg="3"
        xl="2"
      >
        <div class="d-flex align-center">
          <div>
            <v-avatar size="240" style="border-radius: 50%">
              <v-img
                :src="
                  user.profilePicture
                    ? user.profilePicture
                    : require('@/assets/unknown.jpeg')
                "
              ></v-img>
            </v-avatar>
          </div>
        </div>
        <div class="mt-5 d-flex align-center">
          <v-spacer></v-spacer>
          <div class="text-h5" style="font-weight: 500">
            {{ user.surname }} {{ user.name[0] }}. {{ user.middleName[0] }}.
          </div>
          <div class="ml-2">
            <v-btn
              icon
              @click="
                $router.push({
                  name: 'admin-edit-user',
                  params: { userId: user.id },
                })
              "
            >
              <v-icon size="25" color="#000"> mdi-pencil </v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
        </div>
        <div class="mt-8">
          <v-navigation-drawer permanent>
            <v-list dense nav>
              <v-list-item
                v-for="item in items"
                :key="item.title"
                @click="changeSection(item)"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </div>
      </v-col>
      <v-col
        class="white"
        style="border-radius: 12px"
        cols="10"
        sm="7"
        md="8"
        lg="9"
        xl="10"
      >
        <div
          style="font-size: 2rem; font-weight: 400; line-height: 2.5rem"
          class="d-flex justify-center mt-3"
        >
          <MainInfo v-if="title === 'Персональные данные'" v-bind:user="user" />
          <ArenaSection v-if="title === 'Арены'" v-bind:title="title" />
          <CampSection v-if="title === 'Лагеря и сборы'" v-bind:title="title" />
          <SchoolSection v-if="title === 'Школы'" v-bind:title="title" />
          <LeagueSection v-if="title === 'Лиги'" v-bind:title="title" />
          <KidsTournamentSection
            v-if="title === 'Детские турниры'"
            v-bind:title="title"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainInfo from "./components/MainInfo.vue";
import ArenaSection from "./components/ArenaSection.vue";
import CampSection from "./components/CampSection.vue";
import SchoolSection from "./components/SchoolSection.vue";
import LeagueSection from "./components/LeagueSection.vue";
import KidsTournamentSection from "./components/KidsTournamentSection.vue";
import { mapState } from "vuex";

export default {
  components: {
    MainInfo,
    ArenaSection,
    CampSection,
    SchoolSection,
    LeagueSection,
    KidsTournamentSection,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      breadcrumb_items: [
        {
          text: "Личный кабинет",
          disabled: true,
        },
      ],
      items: [
        { title: "Персональные данные" },
        { title: "Арены" },
        { title: "Школы" },
        { title: "Лагеря и сборы" },
        { title: "Лиги" },
        { title: "Детские турниры" },
      ],
      title: "Персональные данные",
    };
  },
  mounted() {
    this.$store.dispatch("user/getUser", this.userId);
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    changeSection({ title }) {
      this.title = title;
    },
  },
};
</script>

<style lang="scss" scoped></style>
