<template>
  <v-tabs vertical class="pl-0 rounded-lg my-sidetabs" v-model="sidebar" v-if="team.roleId == userAdminRoleId">
    <v-tab
    @click="scrollToTop"
    class="sidebar_text"
    v-for="(item, i) in team_adminsidebar_items"
    :key="i"
    router
    :to="item.link"
    exact-path
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
  <v-tabs vertical class="pl-0 rounded-lg my-sidetabs" v-model="sidebar" v-else-if="team.roleId == userTrainerRoleId">
    <v-tab
        @click="scrollToTop"
        class="sidebar_text"
        v-for="(item, i) in team_adminsidebar_items"
        :key="i"
        router
        :to="item.link"
        exact-path
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
  <v-tabs vertical class="pl-0 rounded-lg my-sidetabs" v-model="sidebar" v-else>
    <v-tab
    @click="scrollToTop"
    class="sidebar_text"
    v-for="(item, i) in team_playersidebar_items"
    :key="i"
    router
    :to="item.link"
    exact-path
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";

export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userAdminRoleId: "",
      userTrainerRoleId: "",
      sidebar: null,
      isTeamAdmin: false,
      team_adminsidebar_items: [
        {
          text: "Информация",
          link: { name: "admin-team-information", params: { teamId: this.team.teamId} },
        },
        {
          text: "Команда",
          link: { name: "admin-team-composition", params: { teamId: this.team.teamId} },
        },
        {
          text: "Заявки",
          link: { name: "admin-team-application-forms", params: { teamId: this.team.teamId} },
        },
        {
          text: "Мероприятия",
          link: { name: "admin-team-events", params: { teamId: this.team.teamId} },
        },
      ],
      team_playersidebar_items: [
        {
          text: "Информация",
          link: { name: "admin-team-information", params: { teamId: this.team.teamId} },
        },
        {
          text: "Команда",
          link: { name: "admin-team-composition", params: { teamId: this.team.teamId} },
        },
        {
          text: "Мероприятия",
          link: { name: "admin-team-events-player", params: { teamId: this.team.teamId} },
        },
      ],
    };
  },
  mounted(){
    this.getUserRoles()
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.user.id}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "TEAM_ADMIN"){
            this.userAdminRoleId = item.id
          }
          if(item.name == "ARENA_ADMIN"){
            // console.log(item.id)
          }
          if(item.name == "TRAINER"){
            this.userTrainerRoleId = item.id
          }
        });
      })
      .catch((error) => {
        console.error(error);
      })
    },

    scrollToTop() {
      window.scroll({
        top: 155,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
};
</script>

<style >
.sidebar_text{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: none !important;
}

.v-tabs-slider-wrapper{
  display: none;
}
</style>
