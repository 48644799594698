<template>
  <div>
    <div class="text-h6 mb-4">Арены</div>
    <v-row>
      <v-col cols="6" class="" v-show="arenas.length">
        <div class="mt-4 my-auto" v-for="{ arena } in arenas" :key="arena.id">
          <span class="mr-4">{{ arena.fullTitle }}</span>
          <v-icon class="" @click="removeArena(arena.id)"> mdi-close </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="admins_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить арену
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить арену</div>
          <div class="mb-4">
            <v-icon @click.stop="admins_dialog = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-col cols="12" class="text-h6 mb-n4">Арены</v-col>
          <v-autocomplete
            v-model="selected_arena"
            :items="arenasList"
            :loading="is_searching"
            :search-input.sync="search_text"
            color="white"
            solo
            flat
            single-line
            hide-selected
            hide-no-data
            item-text="fullTitle"
            item-value="id"
            label="Поиск арены"
            placeholder="Поиск арены"
            prepend-inner-icon="mdi-magnify"
            return-object
            clearable
            hide-details="auto"
            class="rounded-lg"
          ></v-autocomplete>
          <div v-if="isFind">{{ isFind }}</div>
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-btn class="body-2" @click="admins_dialog = false" elevation="0">
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="primary" class="body-2" @click="addArena">
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { ADD_ARENA_TO_CAMP } from "@/api";
import { apiArena } from "@/service";

export default {
  props: {
    campId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_arena: null,
      search_text: "",
      isFind: false,
      is_searching: false,
      admins_dialog: false,
      adding: false,
      users: [],
      userOrganizerId: "",
      otherOrganizers: [],
      arenasList: [],
    };
  },
  watch: {
    search_text() {
      this.arenasList.filter(({ title }) => {
        this.isFind = title === this.search_text ? true : false;
      });
    },
  },
  async created() {
    this.getCampArenas(this.campId);
    const { data } = await apiArena.getArenasList();
    this.arenasList = data;
    console.log(this.arenas);
  },
  computed: {
    ...mapState("camps", ["arenas"]),
  },
  methods: {
    async addArena() {
      this.adding = true;
      const data = {
        campId: this.campId,
        arenaId: this.selected_arena.id,
      };
      await axios
        .post(`${ADD_ARENA_TO_CAMP}`, data)
        .then(() => {
          this.admins_dialog = false;
          this.selected_arena = null;
          this.getCampArenas(this.campId);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.adding = false;
        });
    },
    async removeArena(id) {
      await axios
        .delete(`${ADD_ARENA_TO_CAMP}?arenaId=${id}&campId=${this.campId}`)
        .then(() => {
          this.getCampArenas(this.campId);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.adding = false;
        });
    },

    ...mapActions("camps", ["getCampArenas"]),
  },
};
</script>

<style lang="scss" scoped></style>
