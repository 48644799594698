<template>
  <v-card
    class="mx-auto rounded-lg"
    elevation="0"
  >
    <v-list color="#E2E2E2" style="padding: 0">
      <v-list-item>

        <v-list-item-title>Мои школы</v-list-item-title>

        <v-list-item-icon>
          <v-icon
            @click="
            $router.push({
              name: 'create-school-information',
              params: { userId },
            })
          "
            color="#000">mdi-plus</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-group
        style="background-color: #fff"
        v-for="(school, i) in schools"
        :key="i"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>{{school.school.title}}</v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn @click.stop="editSchool(school.school.id)" small icon>
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn @click.stop="openWarningDialog(school.school.id)" :key="school.school.id" small icon>
            <v-icon small>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>

<!--        <v-card elevation="0" class="pl-4">-->
<!--          <AppArenaVerticalSidebar  :key="componentKey" :arena="arena" />-->
<!--        </v-card>-->

      </v-list-group>
      <v-dialog v-model="warning_dialog" max-width="600">
        <v-card class="grey lighten-5">
          <v-card-text class="text-h5 black--text pt-5">
            Вы действительно хотите удалить эту школу?
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn
              class="body-2"
              @click="warning_dialog = false"
              elevation="0"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="error"
              class="body-2"
              @click="deleteTournament"
            >
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-card>
</template>

<script>
// import axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import { mapState } from "vuex";
import Axios from "axios";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sidebar: null,
      tournaments: [],
      componentKey: "",
      isTournamentOrganizer: false,
      allUserRoles: [],
      userArenaAdminRoleId: "",
      warning_dialog: false,
      tId: "",
    };
  },
  async created(){
    await this.getUserRoles();
    await this.getAllAdminSchools();
  },
  computed: {
    ...mapState("user", ["user", "schools"]),
  },
  methods: {
    getAllAdminSchools() {
      return this.$store.dispatch("user/getSchools", {
        userId: this.userId,
        roleId: this.userArenaAdminRoleId
      });
      // return axios.get(`${GET_USER_BY_ID}${this.userId}/leagues?roleId=${this.userTournamentAdminRoleId}`)
      //   .then( (res) => {
      //     console.log('line 90',res.data)
      //     // this.tournaments = res.data
      //     res.data.forEach((item) => {
      //       this.tournaments.push(item.league)
      //     })
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
    },

    openWarningDialog(leagueId){
      this.warning_dialog = true
      this.tId = leagueId
    },

    changeTournaments(id){
      this.componentKey = id
    },

    getUserRoles() {
      return Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
        .then( (res) => {
          this.allUserRoles = res.data
          this.allUserRoles.forEach(item => {
            if(item.name === "ARENA_ADMIN"){
              this.isTournamentOrganizer = true
              this.userArenaAdminRoleId = item.id
            }
          });
        })
        .catch((error) => {
          console.error(error);
        })
    },

    // goToAdminTournamentEdit(id) {
    //   const arenaId = id
    //
    //   this.$router.push({
    //     name: "edit-complex-information",
    //     params: { arenaId },
    //   });
    // },
    //
    editSchool(id) {
      const schoolId = id;
      this.$router.push({
        name: "admin-school-edit",
        params: { schoolId },
      });
    },

    deleteTournament() {
      this.$store.dispatch("user/deleteSchool", this.tId);
      this.warning_dialog = false;
      // this.tournaments.filter(tournament => tournament.id !== leagueId);
    },


  }
};
</script>

<style>
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child){
  margin-left: 10px;
}

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
  min-width: 0;
}
</style>
