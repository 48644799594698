<template>
  <div class="white" style="border-radius: 12px;">
    <v-container class="pt-0 pb-0">
      <v-row>
        <v-col cols="3" class="mb-2 d-flex flex-column  align-center">
          <div>
            <v-avatar class="rounded-lg" contain tile size="180">
              <v-img
                  contain
                  :src="
                        league.logo != null
                            ? league.logo
                            : require('@/assets/team_room_1.jpg')
                        "
              ></v-img>
            </v-avatar>
          </div>
          <div class="mt-2">
            <p class="text-h5 mb-0" >{{ league.fullTitle }}</p>
          </div>
          <div class="mt-4">
            <p class="grey--text">
              {{ league.city }}
            </p>
          </div>
          <div>
            <a
                v-for="(item, index) in valid_contact_list"
                class="reset-link"
                :key="index"
                :href="item.link"
                target="_blank"
            >
              <v-btn
                  elevation="0"
                  x-small
                  color="transparent"
                  height="40px"
                  class="mx-1"
              >
                <v-icon color="#000"> {{ item.icon }}</v-icon>
              </v-btn>
            </a>
          </div>
        </v-col>
        <v-col cols="9">
          <v-container
          >
            <p class="text-h4">Дивизионы</p>
            <v-row class="mx-n4" >
              <v-col cols="4" class="pb-0 mt-0">
                <v-select
                    :items="seasons"
                    value="Сезон"
                    v-model="season"
                    placeholder="Сезон"
                    item-text="title"
                    solo
                    flat
                    return-object
                    hide-details="auto"
                    @change="fetchTournaments"
                    class="elevation-2"
                ></v-select>
              </v-col>
              <v-col cols="4" class="pb-0 mt-0">
                <v-select
                    :items="tournaments"
                    value="Турнир"
                    v-model="tournament"
                    placeholder="Турнир"
                    item-text="title"
                    solo
                    flat
                    return-object
                    hide-details="auto"
                    @change="fetchDivisions"
                    class="elevation-2"
                ></v-select>
              </v-col>
              <v-col cols="1" class="mt-1" v-if="Object.keys(tournament).length">
                <v-dialog v-model="new_dialog" max-width="800">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                    ><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                  <v-card :loading="adding_tournaments" class="py-3">
                    <v-card-title class="justify-space-between">
                      <div class="text-h5 black--text">Добавить дивизион</div>
                      <div class="mb-4">
                        <v-icon @click.stop="new_dialog = false">mdi-close</v-icon>
                      </div>
                    </v-card-title>
                    <v-card-text class="mb-4">
                      <v-col cols="12" class="text-h6 mb-n4">Название дивизиона</v-col>
                      <v-text-field
                          class="ml-3 mr-3"
                          placeholder="Название дивизиона"
                          v-model="newDivisionTitle"
                          :rules="divisionTitleRules"
                      />
                    </v-card-text>
                    <v-card-actions class="mt-3">
                      <v-btn
                          class="body-2"
                          @click="new_dialog = false"
                          elevation="0"
                      >
                        Назад
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                          elevation="0"
                          color="primary"
                          class="body-2"
                          @click="addDivision"
                      >
                        Добавить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <div>
                  <div v-if="!isFetching">
                    <v-row class="container mb-2 mt-3 elevation-4 rounded-lg"
                           v-for="item in divisions"
                           :key="item.id"
                    >
                      <p class="mt-4 ml-4">{{ item.title }}</p>
                      <v-spacer/>
                      <v-dialog v-model="dialog" max-width="800">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              elevation="0"
                              color="primary"
                              class="mt-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="setEditingDivision(item)"
                          >Редактировать
                          </v-btn>
                        </template>
                        <v-card :loading="adding_tournaments" class="py-3">
                          <v-card-title class="justify-space-between">
                            <div class="text-h5 black--text">Редактировать дивизион</div>
                            <div class="mb-4">
                              <v-icon @click.stop="dialog = false">mdi-close</v-icon>
                            </div>
                          </v-card-title>
                          <v-card-text class="mb-4">
                            <v-col cols="12" class="text-h6 mb-n4">Название дивизиона</v-col>
                            <v-text-field
                                class="ml-3 mr-3"
                                placeholder="Название дивизиона"
                                v-model="divisionTitle"
                                :rules="divisionTitleRules"
                            />
                          </v-card-text>
                          <v-card-actions class="mt-3">
                            <v-btn
                                class="body-2"
                                @click="dialog = false"
                                elevation="0"
                            >
                              Назад
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                elevation="0"
                                color="primary"
                                class="body-2"
                                @click="editDivision"
                            >
                              Сохранить
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-btn
                          color="#C62828"
                          class="mt-2 ml-2 mr-2" @click="removeDivision(item.id)"><v-icon light>mdi-delete-outline</v-icon></v-btn>
                    </v-row>
                  </div>
                  <div style="height: 200px" v-else>
                    <app-loader/>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import {GET_DIVISION, GET_SEASONS, GET_TOURNAMENT_DIVISIONS} from "@/api";
import {apiLeagues} from "@/service";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "AdminTournamentInformation",
  components: {
    AppLoader
  },
//   props: {
//     leagueId: {
//       type: String,
//       required: true,
//     },
//   },
  watch:{
    '$route.params.leagueId'() {
      const leagueId = this.leagueId;
      this.$store.dispatch("leagues/getLeague", leagueId).then(() => {});

    }
  },
  filters: {
    descriptionLength(value) {
      if (!value) return "";
      if (value.length < 30) return value;
      return value.slice(0, 30) + "...";
    },
  },
  computed: {
    leagueId() {
      return this.$route.params.leagueId;
    },
    ...mapState("leagues", ["league"]),
    ...mapState("league", ["users"]),
    ...mapState("auth", ["userId"]),
    media() {
      let _media = [];
      if (this.league.gallery) {
        this.league.gallery.forEach((x) => {
          const item = {
            thumb: x,
            src: x,
            caption: "<h4></h4>",
          };
          _media.push(item);
        });
      }
      return _media;
    },
  },
  created() {
    const leagueId = this.leagueId;
    this.$store.dispatch("leagues/getLeague", leagueId).then(() => {});
    this.fetchSeasons();
  },
  methods: {
    async addDivision() {
      await this.$store.dispatch("user/createDivision", {
        title: this.newDivisionTitle,
        tournamentId: this.tournament.id,
      })
      await this.fetchDivisions();
      this.newDivisionTitle = "";
      this.new_dialog = false;
    },
    async editDivision() {
      await Axios.put(`${GET_DIVISION}/${this.divisionId}`, {title: this.divisionTitle});
      this.dialog = false;
      await this.fetchDivisions();
    },
    setEditingDivision(item) {
      this.divisionId = item.id;
      this.divisionTitle = item.title;
    },
    async removeDivision(id) {
      // await Axios.delete(`${GET_SEASONS}`).then(response => {
      //   this.seasons = response.data;
      // })
      console.log(id)
    },
    async fetchSeasons() {
      this.seasons = [];
      this.season = {};
      await Axios.get(`${GET_SEASONS}`).then(response => {
        this.seasons = response.data;
      })
    },
    async fetchTournaments() {
      this.tournaments = [];
      await apiLeagues.getLeagueTournaments({seasonId: this.season.id, leagueId: this.leagueId}).then(response => {
        response.data.forEach(item => {
          this.tournaments.push(item.tournament);
        });
      });
    },
    async fetchDivisions() {
      this.isFetching = true;
      this.divisions = [];
      this.selected_division = {};
      await Axios.get(`${GET_TOURNAMENT_DIVISIONS}?tournamentId=${this.tournament.id}`).then(response => {
        response.data.forEach(item => {
          this.divisions.push(item.division);
        })
      });
      this.isFetching = false;
    },
  },
  data() {
    return {
      contact: null,
      premises_tab: null,
      arenaId: null,
      advert_nav: ["Команда ищет игроков", "Команда ищет тренера"],
      contact_list: null,
      readMoreInfo: false,
      leagueArenas: [],
      readMorePlayers: false,
      valid_contact_list: [],
      season: {},
      seasons: [],
      tournaments: [],
      tournament: {},
      divisions: [],
      isFetching: false,
      dialog: false,
      divisionTitleRules: [
        v => !!v || "Название дивизиона не может быть пустым"
      ],
      divisionTitle: "",
      divisionId: "",
      adding_tournaments: false,
      new_dialog: false,
      newDivisionTitle: '',
    };
  },
};
</script>

<style>
.description {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
}
</style>
