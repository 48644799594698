<template>
  <div class="white" style="border-radius: 12px;">
    <v-container class="pt-0 pb-0">
      <p class="text-h4 mb-0">Мероприятия команды</p>
    </v-container>
    <v-container>
      <div class="d-flex align-center justify-center white">
        <v-btn
        fab
        text
        
        color="grey darken-2"
        @click="prev"
        >
          <v-icon >
            mdi-chevron-left
          </v-icon>
        </v-btn>
          
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
        fab
        text
        
        color="grey darken-2"
        @click="next"
        >
          <v-icon >
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
      <v-sheet height="600" class="px-4 pb-4 overflow-auto">
        <v-calendar
          v-show="value !== 3"
          ref="calendar"
          v-model="date"
          :weekdays="weekday"
          type="month"
          :events="ievents"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          locale="ru-RU"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        
        <div v-show="value === 3 && events.length === 0" class="text-center">
          <h3 class="grey--text lighten-3--text text-h6 mb-2">
            Нет мероприятий
          </h3>
        </div>
      </v-sheet>
      <v-dialog
      max-width="900"
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement">
          <v-card
          style="border: 1px solid #379AD3; border-radius: 12px"
          class="pb-5"
          color="#F5FAFD"
          elevation="0">
            <div style="background-color: #fff; border-bottom: 1px solid #379AD3;">
              <v-card-title class="text-h5" style="font-weight: 500;">{{selectedEvent.name}}, {{rightTime(selectedEvent.start)}}-{{rightTime(selectedEvent.end)}}</v-card-title>
            </div>

            <v-card-text class="text-h5 pb-2" style="color: #000">Тип: {{selectedEvent.type}} </v-card-text>
            <v-card-text class="text-h5 pb-2" style="color: #000">Место: <span style="background: #FFFFFF; border-radius: 12px; padding: 5px 20px">{{trainingPlace}} </span> </v-card-text>
            <v-card-text class="text-h5 pb-2" style="color: #000">Время: {{rightTime(selectedEvent.start)}}-{{rightTime(selectedEvent.end)}} </v-card-text>
            <v-card-text class="text-h5 pb-2" style="color: #000">Коментарий: {{selectedEvent.description}}</v-card-text>
            <v-row class="ml-4 mr-4 mt-5">
                <v-col cols="6">
                    <v-btn
                    @click="getPlayerStatus(selectedEvent.id, absent)"
                    color="#C3E1F2"
                    style="border-radius: 12px; color: #045A8C"
                    block
                    :disabled="isAbsent"
                    depressed>Я не приду</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                    @click="getPlayerStatus(selectedEvent.id, attended)"
                    color="#0681C8"
                    dark
                    style="border-radius: 12px;"
                    block
                    :disabled="isAttended"
                    depressed>Я приду</v-btn>
                </v-col>
            </v-row>
          </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_TEAM, USER_EVENT, GET_ARENA, EVENT_USER } from "@/api";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "AdminTeamEvents",
  data(){
    return{
      teamEvents: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey lighten-1",
      ],
      eventId: "",
      attendedUsers: [],
      userObject: {
        userId: "",
        eventId: "",
        status: "",
        comment: ""
      },
      attended: "attended",
      absent: "absent",
      isAttended: false,
      isAbsent: false,
      comment: "",
      objectUserEvent: {},
      mode: "column",
      weekday: [1, 2, 3, 4, 5, 6, 0],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedEvent: {},
      value: 0,
      selectedOpen: false,
      selectedElement: null,
      trainingPlace: "",
      userEventInfo: {},
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    teamId() {
      return this.$route.params.teamId;
    },
    ievents() {
      const events = [];
      this.teamEvents.forEach((event) => {
        const nEvents = this.generateEvents(event);
        events.push(...nEvents);
      });
      events.sort(function(a,b){
        return new Date(a.start) - new Date(b.start);
      });
      return events;
    },
  },
  methods: {
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.getObjectUserEvent()
        this.getEventArena()
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    getEventColor(event) {
      return event.color;
    },

    viewDay({ date }) {
      this.value = 0;
      this.date = date;
      this.type = "day";
    },

    async getAllTeamEvents() {
      await Axios.get(`${GET_TEAM}${this.teamId}/events`)
      .then( (res) => {
        this.teamEvents = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getEventArena() {
      await Axios.get(`${GET_ARENA}${this.selectedEvent.arenaId}`)
      .then( (res) => {
        this.trainingPlace = res.data.title
      })
      .catch((error) => {
        console.error(error);
      })
    },

    rightTime(date) {
      var time = moment(date)
      return time.format("H:mm");
    },

    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("D MMMM, dddd");
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    generateEvents(event) {
      const selectedDays = event.days;
      const startDate = event.startDate,
        endDate = event.endDate,
        startTime = event.startTime,
        endTime = event.endTime,
        title = event.title,
        description = event.description,
        type = event.type,
        id = event.id,
        arenaId = event.arenaId,
        repeat = event.repeat;
      const color = this.colors[this.rnd(0, this.colors.length - 1)];

      const startMoment = moment(
        `${startDate}T${startTime ? startTime : "00:00"}:00`
      );
      const endMoment = moment(`${endDate}T${endTime ? endTime : "00:00"}:00`);
      const arr_events = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < selectedDays.length; j++) {
          if (Number(startMoment.day()) === selectedDays[j]) {
            const startMomentDate = startMoment.format("YYYY-MM-DDTHH:mm:ss");
            const beginMoment = moment(startMomentDate);

            while (
              Math.ceil(endMoment.diff(beginMoment, "weeks", true)) !== 0
            ) {
              const stopInterval = beginMoment.format("YYYY-MM-DD");
              const stopMoment = moment(
                `${stopInterval}T${endTime ? endTime : "00:00"}:00`
              );
              arr_events.push({
                name: title,
                start: beginMoment.format("YYYY-MM-DDTHH:mm:ss"),
                end: stopMoment.format("YYYY-MM-DDTHH:mm:ss"),
                color: color,
                timed: true,
                type: type,
                id: id,
                arenaId: arenaId,
                description: description,
              });
              if (repeat === 0) break;
              beginMoment.add(1, "weeks");
            }

            break;
          }
        }
        startMoment.add(1, "days");
      }
      return arr_events;
    },

    getPlayerStatus(eventId, status){
      this.userObject.userId = this.user.id
      this.userObject.eventId = eventId
      this.userObject.status = status
      this.userObject.comment = this.comment
      this.getObjectUserEvent()
    },

    async getObjectUserEvent(){
      const eventId = this.selectedEvent.id
      const userId = this.user.id
      await Axios.get(`${USER_EVENT}?eventId=${eventId}&userId=${userId}`)
      .then( (res) => {
        if(res.data.status == "FOUND"){
          this.userEventInfo = res.data.eventUser
          if (this.userObject.status == ""){
            if(this.userEventInfo.status == "ignored"){
              this.isAttended = false
              this.isAbsent = false
            } else if(this.userEventInfo.status == "attended") {
              this.isAttended = true
              this.isAbsent = false
            } else if (this.userEventInfo.status == "absent"){
              this.isAbsent = true
              this.isAttended = false
            }
          } else {
            this.changePlayerStatus()
          }
        } else {
          if (this.userObject.status == ""){
            this.isAttended = false
            this.isAbsent = false
          } else {
            this.postPlayerStatus()
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async changePlayerStatus(){
      await Axios.put(`${USER_EVENT}?id=${this.userEventInfo.id}`, this.userObject)
      .then( () => {
        if(this.userObject.status == "attended"){
          this.isAttended = true
          this.isAbsent = false
        } else if(this.userObject.status == "absent"){
          this.isAbsent = true
          this.isAttended = false
        }
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async postPlayerStatus(){
      await Axios.post(`${EVENT_USER}`, this.userObject)
      .then( () => {
        if(this.userObject.status == "attended"){
          this.isAttended = true
          this.isAbsent = false
        } else if(this.userObject.status == "absent"){
          this.isAbsent = true
          this.isAttended = false
        }
      })
      .catch((error) => {
        console.error(error);
      })
    },
  },
  created(){
    this.getAllTeamEvents()
  },
  watch:{
    '$route.params.teamId'() {
      this.getAllTeamEvents()
    }
  },
}
</script>

<style scoped>
.player_comment{
  color: #697984;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
</style>
