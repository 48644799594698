<template>
  <div class="grey lighten-4" id="school">
    <div class="banner-room">
      <v-container class="pt-16 pb-16 white--text">
        <div class="text-h4">Спортивные школы</div>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-tabs v-model="value" class="d-flex flex-no-wrap rounded-lg">
              <v-tab class="px-6" v-for="item in room_nav" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="pt-16 pb-0" v-show="value === 0">
      <div class="pb-16">
        <v-row dense>
          <v-col class="d-flex" cols="12" md="2">
            <v-select
              :items="cities"
              v-model="sort_by_city"
              solo
              flat
              hide-details="auto"
              @change="getState"
            ></v-select>
          </v-col>
          <v-col cols="9" md="8" lg="9">
            <v-text-field
              label="Поиск"
              single-line
              v-model="searchState"
              prepend-inner-icon="mdi-magnify"
              solo
              flat
              hide-details="auto"
              class="rounded-lg"
              @change="getState"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="2" lg="1">
            <v-btn
              class="rounded-lg"
              large
              depressed
              height="48px"
              width="100%"
              color="primary"
              @click="getState"
            >
              Найти
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="6" md="4" lg="3" xl="2">
            <v-select
              :items="sort_by"
              v-model="sort_state"
              solo
              flat
              item-text="value"
              item-value="key"
              prepend-icon="mdi-sort"
              hide-details="auto"
              return-object
              @change="getState"
            ></v-select>
          </v-col>
          <v-col class="my-auto" cols="6" md="4">
            <div class="body-1 grey--text">
              Найдено: {{ numFoundState }} результатов
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" md="4" lg="3" xl="2">
            <v-select
              :items="display_items"
              v-model="numItemsState"
              item-text="state"
              item-value="value"
              solo
              flat
              return-object
              hide-details="auto"
              @change="getState"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-row dense class="mx-n4" v-if="!isFetching">
        <v-col
          class="pa-4"
          cols="12"
          md="6"
          xl="4"
          v-for="(item, i) in state"
          :key="i"
        >
          <router-link
            :to="{ name: 'sport-school', params: { schoolId: item.id } }"
            class="undo-link-default"
          >
            <school-card :school="item" />
          </router-link>
        </v-col>
      </v-row>
      <app-loader style="height: 650px" v-else />
      <div class="text-center py-10" v-if="state.length">
        <v-pagination
          color="grey"
          v-model="pageState"
          :length="paginationStateLength"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-container>
    <v-container class="pt-16 pb-0" v-show="value === 1">
      <div class="pb-16">
        <v-row dense>
          <v-col class="d-flex" cols="12" md="2">
            <v-select
              :items="cities"
              v-model="sort_by_city"
              solo
              flat
              hide-details="auto"
              @change="fetchCom"
            ></v-select>
          </v-col>
          <v-col cols="9" md="8" lg="9">
            <v-text-field
              label="Поиск"
              single-line
              prepend-inner-icon="mdi-magnify"
              v-model="searchCom"
              solo
              flat
              hide-details="auto"
              class="rounded-lg"
              @change="fetchCom"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="2" lg="1">
            <v-btn
              class="rounded-lg"
              large
              depressed
              height="48px"
              width="100%"
              color="primary"
            >
              Найти
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="6" md="4" lg="3" xl="2">
            <v-select
              :items="sort_by"
              v-model="sort_com"
              value="0"
              solo
              flat
              item-text="value"
              item-value="key"
              prepend-icon="mdi-sort"
              hide-details="auto"
              return-object
              @change="fetchCom"
            ></v-select>
          </v-col>
          <v-col class="my-auto" cols="6" md="4">
            <div class="body-1 grey--text">
              Найдено: {{ numFoundCom }} результатов
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" md="4" lg="3" xl="2">
            <v-select
              :items="display_items"
              v-model="numItemsCom"
              item-text="state"
              item-value="value"
              solo
              flat
              return-object
              hide-details="auto"
              @change="fetchCom"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-row dense class="mx-n4" v-if="!isFetching">
        <v-col
          class="pa-4"
          cols="12"
          md="4"
          xl="3"
          v-for="(item, i) in com"
          :key="i"
        >
          <router-link
            :to="{ name: 'sport-school', params: { schoolId: item.id } }"
            class="undo-link-default"
          >
            <school-card :school="item" />
          </router-link>
        </v-col>
      </v-row>
      <app-loader style="height: 650px" v-else />
      <div class="text-center py-10" v-if="com.length">
        <v-pagination
          color="grey"
          v-model="pageCom"
          :length="paginationComLength"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SchoolCard from "@/components/School/SchoolCard";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "SportSchools",
  components: { SchoolCard, AppLoader },
  computed: {
    ...mapState("school", ["cities", "com", "state"]),
  },
  filters: {
    descriptionLength(value) {
      if (value.length < 30) return value;
      return value.slice(0, 30) + "...";
    },
  },
  watch: {
    pageState() {
      this.saveStatePageNumber();
    },
    pageCom() {
      this.saveComPageNumber();
    },
    value() {
      if (this.value === 0) {
        this.getState();
      } else {
        this.fetchCom();
      }
      this.saveNavValue();
    },
  },
  async mounted() {
    this.setNavValue();
    await this.$store.dispatch("school/getCities");
    this.cities.unshift("Все города");
    await this.getState();
  },
  data() {
    return {
      pageCom: 1,
      pageState: 1,
      searchCom: "",
      searchState: "",
      paginationComLength: 12,
      paginationStateLength: 12,

      state_room: false,
      com_room: true,
      page: 1,
      value: 0,
      room_tab: null,
      room_nav: [
        "Государственные спортивные школы",
        "Школы дополнительной подготовки",
      ],
      com_tags: ["Москва", "Казань"],
      sort_by_city: "Все города",
      state_tags: [""],
      numItemsCom: { state: "Показывать по 12", value: 12 },
      numItemsState: { state: "Показывать по 12", value: 12 },
      display_items: [
        { state: "Показывать по 12", value: 12 },
        { state: "Показывать по 24", value: 24 },
        { state: "Показывать по 36", value: 36 },
      ],
      sort_com: { key: 1, value: "По имени (от А до Я)" },
      sort_state: { key: 1, value: "По имени (от А до Я)" },
      sort_by: [
        { key: 1, value: "По имени (от А до Я)" },
        { key: 0, value: "По имени (от Я до А)" },
      ],
      numFoundCom: 0,
      numFoundState: 0,
      elevation: 0,
      selected: false,
      isFetching: false,
    };
  },
  methods: {
    goToArena() {
      const leagueId = this.league.id;
      this.$router.push({
        // path: `league/${leagueId}`,
        name: "league-information",
        params: {
          leagueId: leagueId,
        },
      });
    },
    openCardMap() {
      this.$router.push({
        name: "arena-map-all",
        query: { arenaTitle: `${this.arena.title}` },
      });
    },
    showRoom() {
      this.com_room = true;
      this.state_room = false;
    },
    isValidOutput(input) {
      return input && input !== "string" ? input : null;
    },
    isValidCityOutput(input) {
      return input && input !== "string" ? ", " + input : null;
    },
    showState() {
      this.com_room = false;
      this.state_room = true;
    },
    comClicked() {
      this.$router.push({ name: "sport-schools" });
    },
    fetchCom() {
      this.setComPage();
      let filters;
      filters = {
        city: this.sort_by_city,
        currentPage: this.pageCom,
        pageSize: this.numItemsCom.value,
        queryString: this.searchCom,
        sortBy: this.sort_com.key,
        approved: true,
      };

      this.$store
        .dispatch("school/filterSchools", { filters, type: "COMMERCIAL" })
        .then(({ pagination, numFound }) => {
          this.paginationComLength = pagination;
          this.numFoundCom = numFound;
        });
    },
    async getState() {
      this.isFetching = true;
      await this.fetchState();
      this.isFetching = false;
    },
    fetchState() {
      this.setStatePage();
      const filters = {
        city: this.sort_by_city,
        currentPage: this.pageState,
        pageSize: this.numItemsState.value,
        queryString: this.searchState,
        sortBy: this.sort_state.key,
        approved: true,
      };

      return this.$store
        .dispatch("school/filterSchools", { filters, type: "STATE" })
        .then(({ pagination, numFound }) => {
          this.paginationStateLength = pagination;
          this.numFoundState = numFound;
        });
    },

    saveNavValue() {
      localStorage.setItem("schoolNavigation", this.value);
    },

    setNavValue() {
      const navValue = localStorage.getItem("schoolNavigation");
      if (navValue == 0 || navValue == 1) {
        this.value = Number(navValue);
      } else {
        this.value = 0;
      }
    },

    saveStatePageNumber() {
      localStorage.setItem("statePage", this.pageState);
      this.getState();
    },

    setStatePage() {
      const pageNumber = localStorage.getItem("statePage");
      if (pageNumber > 0) {
        this.pageState = Number(pageNumber);
      } else {
        this.pageState = 1;
      }
    },

    saveComPageNumber() {
      localStorage.setItem("comPage", this.pageCom);
      this.fetchCom();
    },

    setComPage() {
      const pageNumber = localStorage.getItem("comPage");
      if (pageNumber > 0) {
        this.pageCom = Number(pageNumber);
      } else {
        this.pageCom = 1;
      }
    },
  },
};
</script>

<style>
.undo-link-default {
  text-decoration: none;
}

.banner-room {
  background: url("../../assets/banner-room.jpg") no-repeat center center;
  background-size: cover;
}

#school .v-tabs:not(.v-tabs--vertical) .v-tab {
  white-space: unset;
}

#school .position-more {
  position: absolute;
  bottom: 14px;
}
</style>
