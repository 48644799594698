<template>
  <v-card elevation="0" height="180px" class="pa-5 rounded-xl">
    <div class="d-flex">
      <div class="pr-4">
        <v-avatar v-if="forum.type == 'TRAINERTEAM' || forum.type == 'PLAYERTEAM'" class="rounded-lg"  tile size="80">
          <v-img
          :src="
            user.profilePicture
            ? user.profilePicture
            : require('@/assets/unknown.jpeg')
          "
          ></v-img>
        </v-avatar>
        <v-avatar v-if="forum.type == 'TEAMTRAINER' || forum.type == 'TEAMPLAYER'" class="rounded-lg"  tile size="80">
          <v-img
          :src="
            team.profilePicture
            ? team.profilePicture
            : require('@/assets/team_room_1.jpg')
          "
          ></v-img>
        </v-avatar>
      </div>
      <div class="d-flex flex-no-wrap">
        <v-card-text class="pa-0">
          <div class="body-1 grey--text">
            {{ dateFormat(forum.date) }}
          </div>
          <div class="text-h6 my-1">{{ forum.title }}</div>
          <div class="body-2 grey--text">{{ forum.organizer }}</div>
        </v-card-text>
      </div>
    </div>  
    <div v-if="forum.description.length <= 100" class="mt-2 text-justify">
      {{forum.description}}
    </div>
    <div v-else class="mt-2 text-justify">
      {{ forum.description.slice(0, 100) + " ..." }}
    </div>
    <div v-if="forum.type == 'TEAMPLAYER'" class="d-flex">
      <div
        v-if="isValid(forum.role)"
        class="body-2 blue--text"
        :class="{ 'mr-16': isValid(forum.role) }"
      >
        Амплуа: <span style="color: black"> {{ forum.role }} </span>
      </div>
      <div class="body-2 blue--text" v-if="isValid(forum.grip)">
        Хват: <span style="color: black"> {{ forum.grip }} </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import Axios from "axios";
import { GET_USER_BY_ID, GET_TEAM } from "@/api";

export default {
  props: {
    forum: {
      type: Object,
      required: true,
    },
  },
  watch: {
    forum(value){
      if(value.type == "PLAYERTEAM" || value.type == "TRAINERTEAM"){
        this.getUser()
      } else {
        this.getTeam()
      } 
    }
  },
  created() {
    if(this.forum.type == "PLAYERTEAM" || this.forum.type == "TRAINERTEAM"){
      this.getUser()
    } else {
      this.getTeam()
    }    
  },
  data() {
    return {
      user: {},
      team: {},
    };
  },
  methods: {
    async getUser() {
      const userId = this.forum.userId
      await Axios.get(`${GET_USER_BY_ID}${userId}`)
      .then((res) => {
        this.user = res.data
      })
      .catch((error) => {
        console.error(error);
      });
    },
    
    async getTeam() {
      const teamId = this.forum.teamId
      await Axios.get(`${GET_TEAM}${teamId}`)
      .then((res) => {
        this.team = res.data
      })
      .catch((error) => {
        console.error(error);
      });
    },

    isValid(input) {
      if (input) return true;
      return false;
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return formatter.format(newDate);
    },
  },
  filters: {},
  computed: {},
};
</script>

<style></style>
