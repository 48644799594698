<template>
  <div>
    <p class="text-h4">{{ league.title }}</p>
    <p class="text-h6">Информация</p>
    <div v-if="league.description">
      <div v-if="league.description.length < 580">
        <p
          style="white-space: pre-line"
          class="text-justify"
          v-html="league.description"
        ></p>
      </div>
      <div v-else>
        <p
          style="white-space: pre-line"
          class="text-justify"
          v-if="!readMoreInfo"
          v-html="league.description.slice(0, 580) + '...'"
        ></p>
        <p
          style="white-space: pre-line"
          class="text-justify"
          v-else
          v-html="league.description"
        ></p>
      </div>
      <v-btn
        color="grey lighten-2 mb-5"
        v-show="league.description.length > 580"
        elevation="0"
        @click="readMoreInfo = !readMoreInfo"
      >
        {{ readMoreInfo ? "Скрыть" : "Раскрыть описание" }}
      </v-btn>
    </div>
    <!--    <v-row>-->
    <!--      <v-col cols="12" md="4">-->
    <!--        <p class="text-h6">Социальные сети</p>-->
    <!--        <div class="text&#45;&#45;accent-3">-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <div v-if="administrators.length !== 0">
      <p class="text-h6">Руководство</p>
      <div class="administration">
        <v-row>
          <league-administration v-for="item in administrators" :key="item.id" :administrator="item" />
        </v-row>
      </div>
    </div>
    <div v-if="media ? media.length : false">
      <p class="text-h6 font-weight-bold mt-3">Галерея</p>
      <v-row>
        <v-col cols="6" md="4" lg="3" v-for="(item, i) in media" :key="i">
          <v-img
            style="height: 150px"
            :src="item.src"
            @click="openGallery(i)"
          ></v-img>
          <light-box
            ref="lightbox"
            :media="media"
            :show-caption="true"
            :show-light-box="false"
          />
        </v-col>
        <LightBox
          ref="lightbox"
          :media="media"
          :show-caption="true"
          :show-light-box="false"
        />
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" md="4">
        <div v-if="league.address">
          <p class="text-h6 mb-1">Адрес</p>
          <p class="blue--text">
            {{ league.address }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0 pt-0">
      <v-col cols="12" md="8" v-if="surfaces.length !== 0" class="mt-4">
        <p class="grey--text">Найдено арен: {{surfaces.length}}</p>
        <section class="wrapper-map">
          <LeagueMap
            :coords="coords"
            :surfaces="surfaces"
            :zoom="zoom"
            @set-coords="coords = $event"
          />
        </section>
      </v-col>
      <v-col cols="12" md="4">
        <p class="text-h6 font-weight-bold mt-4" v-if="league.contacts">Контакты</p>
        <p
            style="white-space: pre"
          v-if="league.contacts"
        >{{ league.contacts }}
        </p>
        <p class="text-h6 font-weight-bold mt-2">Социальные сети</p>
        <div>
          <a :href="`https://instagram.com/${league.instagram}`" class="text-decoration-none" v-if="league.instagram">
            <div style="display: inline-block" class="mr-2">
              <img height="35px" width="35px" :src="require('@/assets/sm_icons/instagram_color.svg')">
            </div>
          </a>
          <a :href="`https://vk.com/${league.vk}`" class="text-decoration-none ml-4" v-if="league.vk">
            <div style="display: inline-block" class="mr-2">
              <img height="35px" width="35px" :src="require('@/assets/sm_icons/vk_color.svg')">
            </div>
          </a>
          <a :href="league.site" class="text-decoration-none ml-4" v-if="league.site">
            <div style="display: inline-block" class="mr-2">
              <img height="35px" width="35px" :src="require('@/assets/sm_icons/web_color.svg')">
            </div>
          </a>
        </div>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LightBox from "vue-image-lightbox";
import LeagueAdministration from "@/components/Leagues/LeagueAdministration";
import LeagueMap from "@/components/Leagues/LeagueMap";
import { ADD_ARENA_TO_LEAGUE } from "@/api";
import axios from "axios";

export default {
  components: {
    LeagueAdministration,
    LightBox,
    LeagueMap,
  },
  props: {
    leagueId: {
      type: String,
      required: true,
    },
  },
  filters: {
    descriptionLength(value) {
      if (!value) return "";
      if (value.length < 30) {
        return value;
      }
      return value.slice(0, 30) + "...";
    },
  },
  computed: {
    ...mapState("leagues", ["league", "administrators"]),
    media() {
      let _media = [];
      if (this.league.gallery) {
        this.league.gallery.forEach((x) => {
          const item = {
            thumb: x,
            src: x,
            caption: "<h4></h4>",
          };
          _media.push(item);
        });
      }
      return _media;
    },
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    fetchAdministrators() {
      return this.$store.dispatch("leagues/getLeagueAdministrators", this.leagueId);
    },
    fetchArenas() {
      return axios.get(`${ADD_ARENA_TO_LEAGUE}?leagueId=${this.leagueId}`).then(response => {
        response.data.forEach(item => {
          let coords = `${item.arenaLat}, ` + `${item.arenaLan}`;
          this.coords = [Number(item.arenaLat), Number(item.arenaLan)];
          const point = {
            id: item.arenaId,
            city: item.arenaCity,
            title: item.arenaTitle,
            address: item.arenaAddress,
            coords,
          };
          this.surfaces.push(point);
        });
      });
    },
  },
  async created() {
    await this.fetchArenas();
    this.fetchAdministrators();
    await this.$store.dispatch("leagues/getLeagueSeasons", this.leagueId);
  },
  data() {
    return {
      readMoreInfo: null,
      selectedItem: 0,
      readMoreActivated: false,
      zoom: 12,
      surfaces: [],
      coords: [],
      contacts: [],
    };
  },
};
</script>

<style>
.border > .right-border {
  border-right: 1px solid #ccc;
}

.border:last-child .right-border {
  border-right: unset;
}

.wrapper-map {
  background-color: #ccc;
  height: 600px;
  width: 100%;
  position: relative;
  margin: 10px auto;
}

.administration {
  display: flex;
  /*flex-wrap: wrap;*/
  flex-flow: wrap;
  /*flex-direction: row*/;
}
</style>
