import { apiLeagues, apiSchool, apiUser as api } from "@/service";

export const namespaced = true;

export const state = () => ({
  user: {},
  arenas: [],
  new_arenas: [],
  arena: {},
  arenasTitle: [],
  camps: [],
  teams: [],
  team: {},
  leagues: [],
  seasons: [],
  divisions: [],
  tournaments: [],
  teamParams: {
    season: "",
    division: "",
    tournament: "",
  },
  addedTeams: [],
  forums: [],
  selected_arenas: [],
  roles: [],
  role: {},
  schools: [],
  allSeasons: [],
  userTournaments: [],
});
export const getters = {
  userId: (state) => {
    state.user.id;
  },
};

export const mutations = {
  SET_ARENAS(state, arenas) {
    state.arenas = arenas;
  },
  SET_ARENA(state, arena) {
    state.arena = arena;
  },
  SET_TEAMS(state, teams) {
    state.teams = teams;
  },
  SET_TEAM(state, team) {
    state.team = team;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ROLE(state, role) {
    state.role = role;
  },
  SET_FORUMS(state, forums) {
    state.forums = forums;
  },
  ADD_TO_SELECTED_ARENAS(state, arena) {
    state.selected_arenas.push(arena);
  },
  ADD_FORUM(state, forum) {
    state.forums.push(forum);
  },
  ADD_TEAM(state, team) {
    state.teams.push(team);
  },
  ADD_LEAGUE(state, league) {
    state.leagues.push(league);
  },
  DELETE_LEAGUE(state, leagueId) {
    state.leagues = state.leagues.filter((x) => x.league.id !== leagueId);
  },
  DELETE_SCHOOL(state, schoolId) {
    state.schools = state.schools.filter((x) => x.school.id !== schoolId);
  },
  SET_LEAGUES(state, leagues) {
    state.leagues = leagues;
  },
  ADD_ROLE(state, role) {
    state.roles.push(role);
  },
  REMOVE_FROM_SELECTED_ARENAS(state, arena) {
    state.selected_arenas = state.selected_arenas.filter(
      (x) => x.id !== arena.id
    );
  },
  EMPTY_SELECTED_ARENAS(state) {
    state.selected_arenas = [];
  },
  DELETE_TEAM(state, teamId) {
    state.teams = state.teams.filter((x) => x.team.id !== teamId);
  },
  DELETE_ARENA(state, arenaId) {
    state.arenas = state.arenas.filter((x) => x.arenaId !== arenaId);
  },
  DELETE_FORUM(state, forumId) {
    state.forums = state.forums.filter((forum) => forum.id !== forumId);
  },
  CLEAR_NEW_ARENAS(state) {
    state.new_arenas = [];
  },
  FILTER_NEW_ARENAS(state, arenaId) {
    state.new_arenas = state.new_arenas.filter(
      (arena) => arena.arenaId !== arenaId
    );
  },
  ADD_NEW_ARENA(state, arena) {
    state.new_arenas.push(arena);
  },
  SET_ARENAS_TITLE(state, arenasTitle) {
    state.arenas = arenasTitle;
  },
  SET_TEAM_SEASON(state, season) {
    state.teamParams.season = season;
  },
  SET_TEAM_DIVISION(state, division) {
    state.teamParams.division = division;
  },
  SET_TEAM_TOURNAMENT(state, tournament) {
    state.teamParams.tournament = tournament;
  },
  SET_TOURNAMENTS(state, tournaments) {
    state.tournaments = tournaments;
  },
  SET_DIVISIONS(state, divisions) {
    state.divisions = divisions;
  },
  SET_SEASONS(state, seasons) {
    state.seasons = seasons;
  },
  ADD_TOURNAMENT(state, tournament) {
    state.tournaments.push(tournament);
  },
  ADD_DIVISION(state, division) {
    state.divisions.push(division);
  },
  SET_SCHOOLS(state, schools) {
    state.schools = schools;
  },
  ADD_SCHOOL(state, school) {
    state.schools.push(school);
  },
  ADD_SEASON(state, season) {
    state.seasons.push(season);
  },
  SET_ALL_SEASONS(state, seasons) {
    state.allSeasons = seasons;
  },
  SET_USER_TOURNAMENTS(state, userTournaments) {
    state.userTournaments = userTournaments;
  },
  ADD_CAMP(state, camp) {
    state.camps.push(camp);
  },
  DELETE_CAMP(state, campId) {
    state.camps = state.camps.filter(({ camp }) => camp.id !== campId);
  },
  SET_CAMPS(state, camps) {
    state.camps = camps;
  },
};

export const actions = {
  async getUserArenasTitle({ commit }, userId) {
    const { data } = await api.getArenasTitle(userId);
    commit("SET_ARENAS_TITLE", data);
  },
  getArenas({ commit }, userId) {
    return api
      .getArenas(userId)
      .then((response) => {
        const res = response.data;
        commit("SET_ARENAS", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  addNewArena({ commit }, arena) {
    commit("ADD_NEW_ARENA", arena);
  },
  clearNewArenas({ commit }) {
    commit("CLEAR_NEW_ARENAS");
  },
  filterNewArenas({ commit }, arenaId) {
    commit("FILTER_NEW_ARENAS", arenaId);
  },
  getTeams({ commit }, userId) {
    return api
      .getTeams(userId)
      .then((response) => {
        const res = response.data;
        commit("SET_TEAMS", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getTeam({ commit }, teamId) {
    return api
      .getTeam(teamId)
      .then((response) => {
        const res = response.data;
        commit("SET_TEAM", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  filterAdminTeams({ commit }, filters) {
    return api
      .filterAdminTeams(filters)
      .then((response) => {
        const res = response.data;
        console.log("🚀 ~ file: arena.js ~ line 183 ~ .then ~ res", res);

        commit("SET_TEAMS", res.content);
        return {
          paginationLength: res.totalPages,
          numFound: res.totalElements,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },
  filterAdminAllTeams({ commit }, filters) {
    return api
      .filterAdminAllTeams(filters)
      .then((response) => {
        const res = response.data;
        console.log("🚀 ~ file: arena.js ~ line 183 ~ .then ~ res", res);

        commit("SET_TEAMS", res.content);
        return {
          paginationLength: res.totalPages,
          numFound: res.totalElements,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },
  filterAdminArenas({ commit }, filters) {
    return api
      .filterAdminArenas(filters)
      .then((response) => {
        const res = response.data;
        console.log(
          "🚀 ~ file: arena.js ~ line 136 ~ .then ~ res",
          res.content
        );
        commit("SET_ARENAS", res.content);
        return {
          paginationLength: res.totalPages,
          numFound: res.totalElements,
        };
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  getUser({ commit }, userId) {
    return api
      .getUser(userId)
      .then((response) => {
        commit("SET_USER", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getForum(_t, forumId) {
    return api
      .getForum(forumId)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getForums({ commit }, userId) {
    return api
      .getForums(userId)
      .then((response) => {
        commit("SET_FORUMS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getUserRoles({ commit }, userId) {
    return api
      .getRoles(userId)
      .then((response) => {
        commit("SET_ROLES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getRole({ commit }, roleId) {
    return api
      .getRole(roleId)
      .then((response) => {
        commit("SET_ROLE", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  createUserTeam({ commit }, { userTeamId, team }) {
    return api
      .createUserTeam(userTeamId)
      .then(() => {
        commit("ADD_TEAM", team);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  createUserLeague({ commit }, { userLeagueId, league }) {
    return api
      .createUserLeague(userLeagueId)
      .then(() => {
        commit("ADD_LEAGUE", league);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  createUserSchool({ commit }, { userSchoolId, school }) {
    return api
      .createUserSchool(userSchoolId)
      .then(() => {
        commit("ADD_SCHOOL", school);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  getLeagues({ commit }, params) {
    return api
      .getLeagues(params[0], params[1])
      .then((response) => {
        commit("SET_LEAGUES", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  postForum({ commit }, forum) {
    return api
      .postForum(forum)
      .then((response) => {
        console.log(
          "🚀 ~ file: user.js ~ line 150 ~ .then ~ response",
          response.data
        );

        commit("ADD_FORUM", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  putForum(_commit, forumObj) {
    return api
      .putForum(forumObj)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  },
  putUser(_commit, userObj) {
    console.log(userObj);
    return api
      .putUser(userObj)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  patchRole(_commit, userObj) {
    console.log(userObj);
    return api
      .patchRole(userObj)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  getRoleID(_commit, roleName) {
    return api
      .getRoleID(roleName)
      .then((response) => {
        const roleId = response.data.id;
        return roleId;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  createRole({ dispatch }, userRole) {
    console.log(
      "🚀 ~ file: user.js ~ line 206 ~ createRole ~ userRole",
      userRole
    );

    return api
      .createUserRole(userRole)
      .then(() => {
        const notification = {
          type: "success",
          message: "Роль пользователя успешно добавлена",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  addToSelectedArenas({ commit }, arena) {
    commit("ADD_TO_SELECTED_ARENAS", arena);
  },
  removeFromSelectedArenas({ commit }, arena) {
    commit("REMOVE_FROM_SELECTED_ARENAS", arena);
  },
  deleteSelected({ commit, state }) {
    let promiseDeleting = [];
    state.selected_arenas.forEach(({ id }) => {
      promiseDeleting.push(
        api
          .deleteArena(id)
          .then(() => {
            const narenas = state.arenas.filter((x) => x.id !== id);
            commit("SET_ARENAS", narenas);
          })
          .catch((err) => {
            console.log(err);
          })
      );
    });
    Promise.all(promiseDeleting).then(() => {
      commit("EMPTY_SELECTED_ARENAS");
    });
  },
  deleteArena({ commit }, arenaId) {
    api
      .deleteArena(arenaId)
      .then(() => {
        commit("DELETE_ARENA", arenaId);
      })
      .catch((err) => console.log(err));
  },
  deleteTeam({ commit }, { teamId }) {
    return api
      .deleteTeam(teamId)
      .then(() => {
        commit("DELETE_TEAM", teamId);
      })
      .catch((err) => console.log(err));
  },
  deleteForum({ commit }, forumId) {
    api
      .deleteForum(forumId)
      .then(() => {
        commit("DELETE_FORUM", forumId);
      })
      .catch((err) => console.log(err));
  },
  deleteLeague({ commit }, leagueId) {
    api
      .deleteLeague(leagueId)
      .then(() => {
        commit("DELETE_LEAGUE", leagueId);
      })
      .catch((err) => console.log(err));
  },
  deleteSchool({ commit }, schoolId) {
    api
      .deleteSchool(schoolId)
      .then(() => {
        commit("DELETE_SCHOOL", schoolId);
      })
      .catch((err) => console.log(err));
  },
  setTeamSeason({ commit }, season) {
    commit("SET_TEAM_SEASON", season);
  },
  setTeamDivision({ commit }, division) {
    commit("SET_TEAM_DIVISION", division);
  },
  setTeamTournament({ commit }, tournament) {
    commit("SET_TEAM_TOURNAMENT", tournament);
  },
  getTournamentTeams({ commit }, filters, parameters) {
    return apiLeagues
      .getDivisionTeams(filters, parameters)
      .then((response) => {
        const res = response.data;
        commit("SET_TEAMS", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getSeasons({ commit }) {
    return apiLeagues.getSeasons().then((response) => {
      commit("SET_SEASONS", response.data);
    });
  },
  getLeagueTournaments({ commit }, params) {
    return apiLeagues.getLeagueTournaments(params).then((response) => {
      commit("SET_TOURNAMENTS", response.data);
    });
  },
  getTournamentDivisions({ commit }, tournamentId) {
    return apiLeagues.getTournamentDivisions(tournamentId).then((response) => {
      let TournamentDivisions = [];
      response.data.forEach((item) => TournamentDivisions.push(item.division));
      commit("SET_DIVISIONS", TournamentDivisions);
    });
  },
  clearTeamParams({ commit }) {
    commit("SET_DIVISIONS", []);
    commit("SET_SEASONS", []);
    commit("SET_TOURNAMENTS", []);
  },
  clearTeams({ commit }) {
    commit("SET_TEAMS", []);
  },
  getAllSeasons({ commit }) {
    return apiLeagues.getAllSeasons().then((response) => {
      commit("SET_ALL_SEASONS", response.data);
    });
  },
  createTournament({ commit }, params) {
    return apiLeagues.createTournament(params).then((response) => {
      return apiLeagues
        .addTournamentLeague({
          tournamentId: response.data.id,
          seasonId: params.seasonId,
          leagueId: params.leagueId,
        })
        .then(() => {
          commit("ADD_TOURNAMENT", response.data);
        });
    });
  },
  addSeasonToLeague({ commit }, params) {
    return apiLeagues.addSeasonToLeague(params).then((response) => {
      commit("ADD_SEASON", response.data);
    });
  },
  createDivision({ commit }, params) {
    return apiLeagues.createDivision(params.title).then((response) => {
      return apiLeagues
        .addDivisionToTournament({
          tournamentId: params.tournamentId,
          divisionId: response.data.id,
        })
        .then(() => {
          commit("ADD_DIVISION", response.data);
        });
    });
  },
  addTeam({ commit }, team) {
    return apiLeagues.addTeam(team).then(() => {
      commit("ADD_TEAM", {});
    });
  },
  getSchools({ commit }, params) {
    return api
      .getSchools(params.userId, params.roleId)
      .then((response) => {
        commit("SET_SCHOOLS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  postSchool({ commit }, params) {
    return apiSchool
      .postSchool(params)
      .then((response) => {
        commit("ADD_SCHOOL", response.data);
        return response.data;
      })
      .catch((error) => console.log(error));
  },
  putSchool(_context, school) {
    return apiSchool
      .putSchool(school)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getKidsTournaments({ commit }, { userId, roleId }) {
    return api
      .getKidsTournaments(userId, roleId)
      .then((response) => {
        const res = response.data;
        commit("SET_USER_TOURNAMENTS", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCamps({ commit }, params) {
    return api
      .getCamps(params[0], params[1])
      .then((response) => {
        commit("SET_CAMPS", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  deleteCamp({ commit }, campId) {
    api
      .deleteCamp(campId)
      .then(() => {
        commit("DELETE_CAMP", campId);
      })
      .catch((err) => console.log(err));
  },
  createUserCamp({ commit }, { userCampId, camp }) {
    return api
      .createUserCamp(userCampId)
      .then(() => {
        commit("ADD_CAMP", camp);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
};
