<template>
  <div>
    <div class="text-h6">Тренеры</div>
    <v-row v-show="trainers.length">
      <v-col cols="4" class="" v-for="(item, i) in trainers" :key="i">
        <v-card color="transparent" elevation="0" >
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3 rounded-lg" size="125" tile>
              <v-img
              :src="
                item.user.profilePicture.length
                ? item.user.profilePicture
                : require('@/assets/team_room_1.jpg')
              "
              >
                <v-container class="ma-1">
                  <v-row>
                    <v-btn
                    @click.stop="removeTrainer(item.user.id)"
                    :class="[selected ? 'primary' : '']"
                    x-small
                    class="rounded-lg"
                    color="#fff"
                    height="20px"
                    width="20px"
                    elevation="0"
                    >
                      <v-icon size="18">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-img>
            </v-avatar>
            <v-card-text>
              <div class="title mb-1">
                {{
                  item.user.surname +
                  " " +
                  item.user.name +
                  " " +
                  item.user.middleName
                }}
              </div>
              <div class="body-1 blue--text mb-1">
                {{ item.user.age ? item.user.age + ",  " : "" }}
                {{ item.user.city ? item.user.city : "" }}
              </div>
              <div v-for="(role, r) in item.user.roles" :key="r">
                <div v-if="role.name == 'TRAINER'" class="body-1 grey--text">
                  Тренер{{ role.status ? ", " + role.status : "" }}
                </div>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="trainers_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить тренера
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить тренера</div>
          <div class="mb-4">
            <v-icon @click.stop="trainers_dialog = false">mdi-close </v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
            <v-col cols="12" class="text-h6 mb-n4">Тренеры</v-col>
            <v-autocomplete
              v-model="selected_user"
              :items="teamtrainersList"
              :loading="is_searching"
              :search-input.sync="search_text"
              color="white"
              solo
              flat
              single-line
              hide-selected
              hide-no-data
              item-text="fullName"
              item-value="id"
              label="Поиск тренера"
              placeholder="Поиск тренера"
              prepend-inner-icon="mdi-magnify"
              return-object
              clearable
              hide-details="auto"
              class="rounded-lg"
            ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
            <v-btn
              class="body-2"
              @click="trainers_dialog = false"
              elevation="0"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              class="body-2"
              @click="getUserRoles"
            >
              Добавить
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { CREATE_TEAM_USER, GET_TEAM, GET_USER_BY_ID } from "@/api";
import moment from "moment";

export default {
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_user: null,
      search_text: "",
      is_searching: false,
      trainers_dialog: false,
      adding: false,
      trainers: [],
      userTrainerRoleId: "",
    };
  },
  created(){
    this.$store.dispatch("teamplayer/getTrainers");
    this.getAllTeamTrainer()
  },
  computed: {
    ...mapState("teamplayer", { teamtrainers: "trainers", }),
    teamtrainersList() {
      this.teamtrainers.forEach(item => {
        this.trainers.forEach(elem => {
          if(item.id === elem.user.id){
            const indx = this.teamtrainers.indexOf(item)
            this.teamtrainers.splice(indx, 1)
          }
        })
      })
      return this.teamtrainers.map((x) => {
        const user = x;
        return {
          ...user,
          fullName: `${user.surname} ${user.name} ${user.middleName}, ${this.rightDate(user.birthDate)}`,
        };
      });
    },
  },
  methods: {
    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("DD.MM.YYYY");
    },

    async getAllTeamTrainer() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TRAINER`)
      .then( (res) => {
        this.trainers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.selected_user.id}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "TRAINER"){
            this.userTrainerRoleId = item.id
          }
        });
        this.addTrainer()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async addTrainer() {
      this.adding = true
      const data = {
        teamId: this.teamId,
        userId: this.selected_user.id,
        roleId: this.userTrainerRoleId,
        visibility: 1,
        approved: "accepted"
      };
      await Axios.post(`${CREATE_TEAM_USER}`, data)
      .then( (res) => {
        console.log(res.data)
        this.trainers_dialog = false;
        this.selected_user = null;
        this.getAllTeamTrainer()
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.adding = false
      })
    },
    async removeTrainer(id) {
      await Axios.delete(`${CREATE_TEAM_USER}?teamId=${this.teamId}&userId=${id}`)
      .then( () => {
        this.getAllTeamTrainer()
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.adding = false
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
