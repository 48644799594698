<template>
  <div>
    <div class="text-h6 mb-4">Тренеры</div>
    <v-row>
      <v-col cols="6" class="" v-show="school_trainers.length">
        <div class="mt-4 my-auto" v-for="(item, i) in school_trainers" :key="i">
          <span class="mr-4 ">{{ item.user.surname }} {{ item.user.name }} {{ item.user.middleName }}</span>
          <v-icon class="" @click="removeTrainer(item.user.id)">
            mdi-close
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="trainers_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить тренера
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить тренера</div>
          <div class="mb-4">
            <v-icon @click.stop="trainers_dialog = false">mdi-close </v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
            <v-col cols="12" class="text-h6 mb-n4">Тренеры</v-col>
            <v-autocomplete
              v-model="selected_user"
              :items="all_trainers"
              :loading="is_searching"
              :search-input.sync="search_text"
              color="white"
              solo
              flat
              single-line
              hide-selected
              hide-no-data
              :item-text="getItemText"
              item-value="id"
              label="Поиск тренера"
              placeholder="Поиск тренера"
              prepend-inner-icon="mdi-magnify"
              return-object
              clearable
              hide-details="auto"
              class="rounded-lg"
            ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
            <v-btn
              class="body-2"
              @click="trainers_dialog = false"
              elevation="0"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              class="body-2"
              @click="getUserRoles"
            >
              Добавить
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import {CREATE_SCHOOL_USER, GET_TEAM, GET_USER_BY_ID} from "@/api";
import moment from "moment";

export default {
  props: {
    schoolId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_user: null,
      search_text: "",
      is_searching: false,
      trainers_dialog: false,
      adding: false,
      trainers: [],
      userTrainerRoleId: "",
    };
  },
  async created(){
    await this.$store.dispatch("school/getSchoolTrainers", this.schoolId);
    await this.$store.dispatch("school/getAllTrainers");
    this.getAllTeamTrainer()
  },
  computed: {
    ...mapState("school", { school_trainers: "school_trainers", all_trainers: "all_trainers" }),
    teamtrainersList() {
      this.teamtrainers.forEach(item => {
        this.trainers.forEach(elem => {
          if(item.id === elem.user.id){
            const indx = this.teamtrainers.indexOf(item)
            this.teamtrainers.splice(indx, 1)
          }
        })
      })
      return this.teamtrainers.map((x) => {
        const user = x;
        return {
          ...user,
          fullName: `${user.surname} ${user.name} ${user.middleName}, ${this.rightDate(user.birthDate)}`,
        };
      });
    },
  },
  methods: {
    getItemText(item) {
      return`${item.surname} ${item.name} ${item.middleName}`
    },
    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("DD.MM.YYYY");
    },

    async getAllTeamTrainer() {
      await Axios.get(`${GET_TEAM}${this.schoolId}/users?approved=accepted&role=TRAINER`)
      .then( (res) => {
        this.trainers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.selected_user.id}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "TRAINER"){
            this.userTrainerRoleId = item.id
          }
        });
        this.addTrainer()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async addTrainer() {
      this.adding = true
      this.userTrainerRoleId = "";
      await Axios
          .get(`${GET_USER_BY_ID}${this.selected_user.id}/roles`)
          .then(response => {
            response.data.forEach(role => {
              if (role.name === "TRAINER") {
                this.userTrainerRoleId = role.id;
              }
            })
          })
      const data = {
        schoolId: this.schoolId,
        userId: this.selected_user.id,
        roleId: this.userTrainerRoleId
      };
      await Axios.post(`${CREATE_SCHOOL_USER}`, data)
      .then( (res) => {
        console.log(res.data)
        this.trainers_dialog = false;
        this.selected_user = null;
        this.$store.dispatch("school/getSchoolTrainers", this.schoolId)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.adding = false
      })
    },
    async removeTrainer(id) {
      await Axios.delete(`${CREATE_SCHOOL_USER}?schoolId=${this.schoolId}&userId=${id}`)
      .then( () => {
        this.$store.dispatch("school/getSchoolTrainers", this.schoolId);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.adding = false
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
