<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="">
        <div>
          <v-breadcrumbs :items="breadcrumb_items" class="px-3"></v-breadcrumbs>
        </div>
      </v-row>
      <div>
        <div class="text-h5 pb-3 pt-5 font-weight-bold">
          Редактировать команду
        </div>
        <v-row>
          <v-col cols="4" class="pr-10">
            <div class="body-1 mb-4 font-weight-bold">Эмблема команды</div>

            <admin-image-uploader v-model="avatar">
              <div slot="activator">
                <div v-if="!avatar" class="white rounded-xl pa-4">
                  <v-avatar
                    width="100%"
                    height="200"
                    v-ripple
                    tile
                    class="white rounded-xl"
                  >
                    <div class="upload-border rounded-xl pa-4">
                      <div class="my-4">
                        <v-icon large color="#379AD3"
                          >mdi-cloud-upload-outline</v-icon
                        >
                      </div>
                      <div class="body-1 mb-2 font-weight-bold">
                        Загрузите логотип
                      </div>
                      <div class="body-2 mb-4 grey--text">
                        Поддерживаемые форматы: PNG, JPG
                      </div>
                    </div>
                  </v-avatar>
                </div>
                <div v-else class="white rounded-xl pa-4">
                  <v-avatar width="100%" height="200" tile v-ripple>
                    <v-img
                      class="ma-10 rounded-xl"
                      :src="avatar.imageURL"
                      alt="avatar"
                      cover
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
            </admin-image-uploader>
          </v-col>
          <v-col>
            <div class="body-1 mb-4 font-weight-bold">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Название команды"
                v-model="title"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-select
                :items="locations"
                v-model="location"
                placeholder="Населеный пункт"
                solo
                flat
                item-text="state"
                item-value="value"
                return-object
                hide-details="auto"
              ></v-select>
            </div>
            <div class="mb-4">
              <v-select
                :items="categories"
                v-model="category"
                placeholder="Тип команды"
                solo
                flat
                item-text="text"
                item-value="value"
                return-object
                hide-details="auto"
              ></v-select>
            </div>
          </v-col>
        </v-row>

        <div>
          <div class="text-h6 mb-2">Описание</div>
          <v-textarea
            solo
            v-model="description"
            name=""
            flat
            elevation="0"
          ></v-textarea>
        </div>
        <div class="mb-4">
          <AdminSocialMedia
            :items="social_media"
            @newMedias="setSocialMedias"
          ></AdminSocialMedia>
        </div>
        <div class="mb-6">
          <AdminGallery :items="galleryPics"> </AdminGallery>
        </div>
        <div class="mb-6">
          <AdminTeamPlayers :teamId="teamId" />
        </div>
        <div class="mb-6">
          <AdminTeamTrainers :teamId="teamId" />
        </div>
        <div class="mb-6">
          <AdminTeamAdministrator :teamId="teamId" />
        </div>

        <div class="mb-6" v-if="contacts.length != 0">
          <AdminContactForm
            :getContact="getContact"
            :contact.sync="contacts"
          ></AdminContactForm>
        </div>
      </div>
      <div class="d-flex mt-16">
        <v-btn
          @click="updateTeam"
          large
          elevation="0"
          color="primary"
          class="body-2 px-6 mr-2"
        >
          Сохранить
        </v-btn>
        <v-btn
          color="grey lighten-2"
          large
          class="body-2 px-6 ml-2"
          elevation="0"
          @click="
            $router.push({
              name: 'user-profile',
              params: { userId: userId },
            })
          "
        >
          Отменить
        </v-btn>
      </div>
      <v-dialog v-model="errorDialog" max-width="300">
        <v-card>
          <div class="text-center px-4 pt-6">{{ errorText }}</div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="goToProfile" text>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminGallery from "@/components/Admin/AdminGallery.vue";
import AdminContactForm from "@/components/Admin/AdminContactForm.vue";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import { mapState } from "vuex";
import AdminTeamPlayers from "../../../../components/Admin/AdminTeamPlayers.vue";
import AdminTeamTrainers from "../../../../components/Admin/AdminTeamTrainers.vue";
import AdminTeamAdministrator from "../../../../components/Admin/AdminTeamAdministrator.vue";

export default {
  components: {
    AdminImageUploader,
    AdminGallery,
    AdminContactForm,
    AdminSocialMedia,
    AdminTeamPlayers,
    AdminTeamTrainers,
    AdminTeamAdministrator,
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    team: {
      type: Object,
      required: true,
    },
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("user", ["team"]),
    ...mapState("file", ["croppedImg"]),
    social_media_display() {
      return this.social_media.filter((x) => x.link);
    },
    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },
  mounted() {
    this.$store.dispatch("user/getTeam", this.teamId).then(() => {
      const team = this.team;
      this.galleryPics = team.gallery;
      this.title = team.title;
      this.location = team.city;
      this.category = this.covertToCategory(team.type);

      this.avatar = team.profilePicture
        ? { imageURL: team.profilePicture }
        : null;

      this.contacts = team.contact;
      console.log(this.contacts);
      this.social_media[0].link = team.vk;
      this.social_media[1].link = team.whatsApp;
      this.social_media[2].link = team.website;
      this.social_media[3].link = team.instagram;
      this.social_media[4].link = team.facebook;
      this.description = team.description;
    });
  },
  created() {
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId: this.userId } },
      },
      {
        text: "Мои команды",
        disabled: false,
        exact: true,
        to: {
          name: "admin-team",
          params: { userId: this.userId },
        },
      },
      {
        text: "Редактировать команду",
        disabled: true,
        to: "",
      },
    ];
  },
  data() {
    return {
      errorDialog: false,
      errorText: "",
      files: {},
      galleryPics: [],
      title: "",
      description: "",
      location: "",
      locations: ["Москва"],
      category: "",
      categories: [
        { value: "KID", text: "Детскaя" },
        { value: "ADULT", text: "Взрослая" },
        { value: "YOUTH", text: "Юношеская" },
        { value: "FEMALE", text: "Женская" },
      ],
      avatar: null,
      contacts: "",
      galleryName: "multifile",
      checkbox: null,
      telephone: "",
      email: "",
      errMessage: "",
      social_media_dialog: false,
      toggle_social_media: null,
      social_media_text: "",
      contact_dialog: false,
      album_dialog: false,
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: "",
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
        {
          id: 4,
          name: "instagram",
          link: "",
          icon: "mdi-instagram",
        },
        {
          id: 5,
          name: "facebook",
          link: "",
          icon: "mdi-facebook",
        },
      ],
      breadcrumb_items: [],
    };
  },
  methods: {
    setSocialMedias(value) {
      this.social_media = value;
    },
    covertToCategory(categoryString) {
      if (categoryString === "KID") return { value: "KID", text: "Детскaя" };
      if (categoryString === "ADULT")
        return { value: "ADULT", text: "Взрослая" };
      if (categoryString === "YOUTH")
        return { value: "YOUTH", text: "Юношеская" };
      if (categoryString === "FEMALE")
        return { value: "FEMALE", text: "Женская" };
    },
    updateTeam() {
      let whatsapp = "";
      if (this.social_media[1].link) {
        whatsapp = `https://wa.me/${this.social_media[1].link
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")}`;
      }
      const data = {
        title: this.title,
        miniDescription: "",
        city: this.location,
        type: this.category.value,
        level: "",
        description: this.description,
        profilePicture: this.profilePicture,
        editedProfilePicture: this.croppedImg,
        gallery: this.galleryPics,
        contact: this.contacts,
        instagram: this.social_media[3].link,
        vk: this.social_media[0].link,
        website: this.social_media[2].link,
        whatsApp: whatsapp,
        facebook: this.social_media[4].link,
        classmates: "",
        tiktok: "",
        youtube: "",
        arena: null,
        twitter: null,
      };
      console.log(data);
      this.$store
        .dispatch("team/putTeam", { teamId: this.teamId, team: data })
        .then(() => {
          this.errorDialog = true;
          this.errorText =
            "Ваша заявка принята на рассмотрение. Решение будет выслано на почту после рассмотрения";
        });
    },
    goToProfile() {
      this.errorDialog = false;
      this.$router.push({
        name: "user-profile",
        params: { userId: this.userId },
      });
    },
    getContact(data) {
      this.contacts = data.contact;
    },
  },
};
</script>

<style>
.upload-border {
  border: 1px dashed #c3e1f2;
}
</style>
