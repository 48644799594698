<template>
  <v-tabs vertical class="pl-0 rounded-lg my-sidetabs" v-model="sidebar">
    <v-tab
      class="sidebar_text"
      v-for="(item, i) in items"
      :key="i"
      router
      :to="item.link"
      exact-path
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sidebar: null,
    };
  },
};
</script>

<style >
.sidebar_text{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: none !important;
}
</style>
