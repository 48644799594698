<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="justify-center">
        <v-col cols="12" lg="10" xl="8">
          <v-row class="">
            <div>
              <v-breadcrumbs
                :items="breadcrumb_items"
                class="px-3"
              ></v-breadcrumbs>
            </div>
          </v-row>
          <div>
            <div class="text-h5 font-weight-bold pb-3 pt-5">Информация</div>

            <div class="text-h6 mb-4">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Имя лагеря"
                v-model="title"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-text-field
                label="Город"
                v-model="city"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-text-field
                label="Адрес"
                v-model="address"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-text-field
                label="Вступительный взнос"
                v-model="entranceFees"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <v-row>
              <v-col cols="4">
                <AppSelectDatePickerSolo
                  :label="startDate"
                  :date.sync="startDate"
                />
              </v-col>
              <v-col cols="4">
                <AppSelectDatePickerSolo
                  :label="endDate"
                  :date.sync="endDate"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="uiState.agesList"
                  v-model="age"
                  dense
                  single-line
                  outlined
                  hide-details
                  :label="String(age)"
                  class="ma-2 white"
                ></v-select>
              </v-col>
            </v-row>
            <div class="mb-4 mt-4">
              <div class="text-h6 mb-4">Основное изображение турнира</div>
              <v-row>
                <v-col class="pa-2" cols="6" sm="4" md="3">
                  <admin-image-uploader v-model="avatar">
                    <div slot="activator">
                      <div v-if="!avatar" class="white rounded-xl pa-4">
                        <v-avatar
                          width="100%"
                          height="200"
                          v-ripple
                          tile
                          class="white rounded-xl"
                        >
                          <div class="upload-border rounded-xl pa-4">
                            <div class="my-4">
                              <v-icon large color="#379AD3"
                                >mdi-cloud-upload-outline
                              </v-icon>
                            </div>
                            <div class="body-1 mb-2 font-weight-bold">
                              Загрузите логотип
                            </div>
                            <div class="body-2 mb-4 grey--text">
                              Поддерживаемые форматы: PNG, JPG
                            </div>
                          </div>
                        </v-avatar>
                      </div>
                      <div v-else class="white rounded-xl pa-4">
                        <v-avatar width="100%" height="200" tile v-ripple>
                          <v-img
                            class="ma-10 rounded-xl"
                            :src="avatar"
                            alt="avatar"
                            cover
                          ></v-img>
                        </v-avatar>
                      </div>
                    </div>
                  </admin-image-uploader>
                </v-col>
              </v-row>
            </div>
            <div>
              <div class="text-h6 mb-2">Описание</div>
              <v-textarea
                solo
                v-model="description"
                name=""
                flat
                elevation="0"
              ></v-textarea>
            </div>
            <div class="mb-4">
              <AdminSocialMedia :items="social_media"></AdminSocialMedia>
            </div>
            <div class="mb-6">
              <AdminCampArena :campId="campId" />
            </div>
            <div class="mb-6">
              <AdminContactForm
                :getContact="getContact"
                :contact="contacts"
              ></AdminContactForm>
            </div>
            <div class="mb-6">
              <AdminGallery :items="galleryPics"></AdminGallery>
            </div>
          </div>
          <div class="d-flex">
            <v-btn
              @click="updateCamp"
              large
              elevation="0"
              color="primary"
              class="body-2 px-6 mr-2"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="grey lighten-2"
              large
              class="body-2 px-6 ml-2"
              elevation="0"
              @click="
                $router.push({
                  name: 'user-profile',
                  params: { userId },
                })
              "
            >
              Отменить
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="errorDialog" max-width="300">
        <v-card>
          <div class="text-center px-4 pt-6">{{ errorText }}</div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="goToProfile" text>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminGallery from "@/components/Admin/AdminGallery.vue";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import AdminContactForm from "../../../../components/Admin/AdminContactForm.vue";
import AdminCampArena from "@/components/Admin/AdminCampArena.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminImageUploader,
    AdminGallery,
    AdminSocialMedia,
    AdminContactForm,
    AdminCampArena,
  },
  props: {
    campId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("camps", ["camp"]),
    social_media_display() {
      return this.social_media.filter((x) => x.link);
    },
    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },
  async mounted() {
    await this.getCamp();
  },
  async beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId: this.userId } },
      },
      {
        text: "Редактировать лагерь",
        disabled: true,
        to: "",
      },
    ];
  },
  data() {
    return {
      title: "",
      description: "",
      city: "",
      contacts: "",
      address: "",
      startDate: "",
      endDate: "",
      age: "",
      entranceFees: "",
      galleryPics: [],
      documents: [],
      avatar: null,
      errorDialog: false,
      errorText: "",

      uiState: {
        agesList: [
          2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
          2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
        ],
      },
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: "",
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
      ],

      files: {},
      // locations: ["Москва"],
      galleryName: "multifile",
      checkbox: null,
      telephone: "",
      email: "",
      errMessage: "",
      social_media_dialog: false,
      toggle_social_media: null,
      social_media_text: "",
      contact_dialog: false,
      album_dialog: false,
      breadcrumb_items: [],
    };
  },
  methods: {
    getCamp() {
      return this.$store.dispatch("camps/getCamp", this.campId).then(() => {
        const camp = this.camp;
        this.galleryPics = camp.gallery;
        this.title = camp.title;
        this.city = camp.city;
        this.description = camp.description;
        this.address = camp.address;
        this.startDate = camp.startDate;
        this.endDate = camp.endDate;
        this.age = camp.age;
        this.entranceFees = camp.entranceFees;
        this.contacts = camp.contact ? camp.contact : "";
        this.documents = camp.documents;
        this.avatar = camp.logo ? { imageURL: camp.logo } : null;
        this.social_media[0].link = camp.vk;
        this.social_media[1].link = camp.whatsApp;
        this.social_media[2].link = camp.website;
      });
    },
    getContact(data) {
      this.contacts = data.contact;
    },
    updateCamp() {
      const data = {
        title: this.title,
        city: this.city,
        description: this.description,
        address: this.address,
        startDate: this.startDate,
        endDate: this.endDate,
        age: this.age,
        entranceFees: this.entranceFees,
        contact: this.contacts,
        documents: this.documents,
        logo: !this.avatar ? "" : this.avatar.imageURL,
        vk: this.social_media[0].link,
        whatsApp: this.social_media[1].link,
        website: this.social_media[2].link,
        gallery: this.galleryPics,
        approved: false,
      };
      this.putCamp({ campId: this.campId, camp: data });
      this.errorDialog = true;
      this.errorText =
        "Ваша заявка принята на рассмотрение. Решение будет выслано на почту после рассмотрения";
    },
    goToProfile() {
      this.errorDialog = false;
      this.$router.push({
        name: "user-profile",
        params: { userId: this.userId },
      });
    },
    ...mapActions("camps", ["putCamp"]),
  },
};
</script>

<style>
.upload-border {
  border: 1px dashed #c3e1f2;
}
</style>
