<template>
  <div>
    <div class="text-h6">Игроки</div>
    <v-row v-show="players.length">
      <v-col cols="4" class="" v-for="(item, i) in players" :key="i" >
        <v-card color="transparent" elevation="0">
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3 rounded-lg" size="125" tile>
              <v-img
              :src="
              item.user.profilePicture
                ? item.user.profilePicture
                : require('@/assets/team_room_1.jpg')
              "
              >
                <v-container class="ma-1">
                  <v-row>
                    <v-btn
                    @click.stop="removePlayer(item.user.id)"
                    :class="[selected ? 'primary' : '']"
                    x-small
                    class="rounded-lg"
                    color="#fff"
                    height="20px"
                    width="20px"
                    elevation="0"
                    >
                      <v-icon size="18">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-img>
            </v-avatar>
            <v-card-text>
              <div class="title font-weight-regular mb-1">
                {{
                item.user.surname +
                " " +
                item.user.name +
                " " +
                item.user.middleName
                }}
              </div>
              <div class="body-1 blue--text mb-1">
                {{ item.user.age ? item.user.age + ", " : "" }}
                {{ item.user.city ? item.user.city : "" }}
              </div>
              <div v-for="(role, i) in item.user.roles" :key="i" class="body-1 grey--text">
                <div v-if="role.name == 'PLAYER'">
                  {{ role.grip ? role.grip : "" }}
                  {{ role.position ? role.position : "" }}
                </div>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="players_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить игрока
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить игрока</div>
          <div class="mb-4">
            <v-icon @click.stop="players_dialog = false">mdi-close </v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
            <v-col cols="12" class="text-h6 mb-n4">Игроки</v-col>
            <v-autocomplete
              v-model="selected_user"
              :items="teamplayersList"
              :loading="is_searching"
              :search-input.sync="search_text"
              color="white"
              solo
              flat
              single-line
              hide-selected
              hide-no-data
              item-text="fullName"
              item-value="id"
              label="Поиск игрока"
              placeholder="Поиск игрока"
              prepend-inner-icon="mdi-magnify"
              return-object
              clearable
              hide-details="auto"
              class="rounded-lg"
            ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
            <v-btn
              class="body-2"
              @click="players_dialog = false"
              elevation="0"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              class="body-2"
              @click="getUserRoles"
            >
              Добавить
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { CREATE_TEAM_USER, GET_TEAM, GET_USER_BY_ID } from "@/api";
import moment from "moment";

export default {
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_user: null,
      search_text: "",
      is_searching: false,
      players_dialog: false,
      adding: false,
      players: [], 
      userPlayerRoleId: "",     
    };
  },
  created(){
    this.$store.dispatch("teamplayer/getTeamPlayers");
    this.getAllTeamPlayers()
  },
  computed: {
    ...mapState("teamplayer", { teamplayers: "players", }),
    teamplayersList() {
      this.teamplayers.forEach(item => {
        this.players.forEach(elem => {
          if(item.id === elem.user.id){
            const indx = this.teamplayers.indexOf(item)
            this.teamplayers.splice(indx, 1)
          }
        })
      })
      return this.teamplayers.map((x) => {
        const user = x;
        return {
          ...user,
          fullName: `${user.surname} ${user.name} ${user.middleName}, ${this.rightDate(user.birthDate)}`,
        };
      });
    },
  },
  methods: {
    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("DD.MM.YYYY");
    },
    
    async getAllTeamPlayers() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=PLAYER`)
      .then( (res) => {
        this.players = res.data
        console.log(this.players)
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.selected_user.id}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "PLAYER"){
            this.userPlayerRoleId = item.id
          } 
        });
        this.addPlayer()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async addPlayer() {
      this.adding = true
      const data = {
        teamId: this.teamId,
        userId: this.selected_user.id,
        visibility: 1,
        roleId: this.userPlayerRoleId,
        approved: "accepted"
      };
      await Axios.post(`${CREATE_TEAM_USER}`, data)
        .then( () => {
          this.players_dialog = false;
          this.selected_user = null;
          this.getAllTeamPlayers()
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
            this.adding = false
        })
    },
    async removePlayer(id) {
      console.log(id)
        await Axios.delete(`${CREATE_TEAM_USER}?teamId=${this.teamId}&userId=${id}`)
        .then( () => {
          this.getAllTeamPlayers()
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
            this.adding = false
        })
    },
  },
};
</script>

<style lang="scss" scoped></style>
