<template>
  <div class="white" style="border-radius: 12px;">
    <v-container class="pt-0 pb-0">
        <v-row>
          <v-col cols="12">
            <v-tabs style="border-radius: 12px 12px 0px 0px;" fixed-tabs v-model="value" >
              <v-tab  v-for="item in application_nav" :key="item.value">
                {{ item.state }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
    </v-container>
    <v-container v-show="value == 0">
      <p class="text-h4" v-if="teamPlayers ? teamPlayers.length : false">
        Игроки
      </p>
      <v-row dense class="mx-n4">
        <v-col cols="6" v-for="(item, i) in teamPlayers" :key="i">
            <router-link
            :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
            class="undo-link-default"
            >
              <v-card color="transparent" elevation="0">
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3 rounded-lg" size="125" tile>
                    <v-img
                      
                      :src="
                        item.user.profilePicture != null
                          ? item.user.profilePicture
                          : require('@/assets/team_room_1.jpg')
                      "
                    ></v-img>
                  </v-avatar>
                  <v-card-text>
                    <div class="text-h5 mb-2">
                      {{
                        item.user.name +
                        " " +
                        item.user.middleName +
                        " " +
                        item.user.surname
                      }}
                    </div>
                    <div class="body-1 blue--text mb-1">
                      {{ item.user.age ? item.user.age + ", " : "" }}
                      {{ item.user.city ? item.user.city : "" }}
                    </div>
                    <div v-for="(role, i) in item.user.roles" :key="i" class="body-1 mb-2">
                      <div v-if="role.name == 'PLAYER'">
                        {{ role.grip ? role.grip : "" }}
                        {{ role.position ? role.position : "" }}
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </router-link>
          </v-col>
      </v-row>
    </v-container>
    <v-container v-show="value == 1">
      <p class="text-h4" v-if="teamTrainers ? teamTrainers.length : false">
        Тренеры
      </p>
      <v-row dense class="mx-n4">
        <v-col cols="12" md="6" v-for="(item, i) in teamTrainers" :key="i">
          <router-link
          :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
          class="undo-link-default"
          >
            <v-card color="transparent" elevation="0" >
              <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3 rounded-lg" size="125" tile>
                  <v-img
                    :src="
                      item.user.profilePicture.length
                        ? item.user.profilePicture
                        : require('@/assets/team_room_1.jpg')
                    "
                  ></v-img>
                </v-avatar>
                <v-card-text>
                  <div class="text-h5 mb-2">
                    {{
                      item.user.name +
                      " " +
                      item.user.middleName +
                      " " +
                      item.user.surname
                    }}
                  </div>
                  <div class="body-1 blue--text mb-1">
                    {{ item.user.age ? item.user.age + ",  " : "" }}
                    {{ item.user.city ? item.user.city : "" }}
                  </div>
                  <div v-for="(role, i) in item.user.roles" :key="i" class="body-1 mb-2">
                      <div v-if="role.name == 'TRAINER'">
                        {{ role.status ? role.status : "" }}
                      </div>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
      <p class="text-h4" v-if="teamAdmin ? teamAdmin.length : false">
        Администраторы
      </p>
      <v-row dense class="mx-n4">
        <v-col cols="12" md="6" v-for="(item, i) in teamAdmin" :key="i">
          <router-link
          :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
          class="undo-link-default"
          >
            <v-card color="transparent" elevation="0" >
              <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3 rounded-lg" size="125" tile>
                  <v-img
                    :src="
                      item.user.profilePicture.length
                        ? item.user.profilePicture
                        : require('@/assets/team_room_1.jpg')
                    "
                  ></v-img>
                </v-avatar>
                <v-card-text>
                  <div class="text-h5 mb-2">
                    {{
                      item.user.name +
                      " " +
                      item.user.middleName +
                      " " +
                      item.user.surname
                    }}
                  </div>
                  <div class="body-1 blue--text mb-1">
                    {{ item.user.age ? item.user.age + ",  " : "" }}
                    {{ item.user.city ? item.user.city : "" }}
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_TEAM } from "@/api";

export default {
  name: "AdminTeamComposition",
  data() {
    return {
      application_nav: [
        {
          state: "Игроки и команда",
          value: 0,
        },
        {
          state: "Персонал",
          value: 1,
        },
      ],
      value: 0,
      teamPlayers: [],
      teamTrainers: [],
      teamAdmin: [],
    }
  },
  created(){
    this.getAllTeamPlayers()
    this.getAllTeamTrainer()
    this.getAllTeamAdmin()
  },
  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
  },
  methods: {
    async getAllTeamPlayers() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=PLAYER`)
      .then( (res) => {
        this.teamPlayers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getAllTeamTrainer() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TRAINER`)
      .then( (res) => {
        this.teamTrainers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getAllTeamAdmin() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TEAM_ADMIN`)
      .then( (res) => {
        this.teamAdmin = res.data

      })
      .catch((error) => {
        console.error(error);
      })
    },
  },
  watch:{
    '$route.params.teamId'() {
        this.value = 0
        this.getAllTeamPlayers()
        this.getAllTeamTrainer()
        this.getAllTeamAdmin()
    }
  },
}
</script>
<style scoped>
.active_tab{
    color: #000;
    background-color: #C3E1F2;
    border-radius: 12px 12px 0px 0px;
}
</style>
