import { apiFile as api } from "@/service";
export const namespaced = true;

export const state = () => ({
  croppedImg: null,
});

export const mutations = {
  SET_IMG(state, img) {
    state.croppedImg = img;
  },
};

export const actions = {
  uploadFile(_commit, formData) {
    return api.postFile(formData).then((response) => {
      return response.data;
    });
  },
  uploadGallery(_commit, formData) {
    return api.postFile(formData).then((response) => {
      return response.data.fileUrl;
    });
  },
  setCroppedImg({ commit }, img) {
    commit("SET_IMG", img);
  },
};

export const getters = {};
