<template>
  <v-container>
    <div
      style="
        text-decoration: none;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 500;
      "
    >
      {{ title }}
    </div>
    <v-row class="mt-5" dense>
      <v-col class="d-flex" cols="12" md="2">
        <v-select
          :items="todoMode"
          v-model="currentTodoMode"
          solo
          flat
          outlined
          hide-details="auto"
          v-on:change="fetchData(true)"
        ></v-select>
      </v-col>
      <v-col cols="9" md="8" lg="9">
        <v-text-field
          label="Поиск по названию"
          single-line
          prepend-inner-icon="mdi-magnify"
          solo
          flat
          outlined
          hide-details="auto"
          class="rounded-lg"
          color="primary"
          v-model="uiState.queryString"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="2" lg="1">
        <v-btn
          class="rounded-lg"
          large
          depressed
          height="55px"
          width="100%"
          color="primary"
          v-on:click="fetchData()"
        >
          Найти
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-3" no-gutters>
      <v-col class="d-flex" cols="4" md="2">
        <v-select
          :items="cityList"
          v-model="uiState.city"
          solo
          flat
          outlined
          hide-details="auto"
          v-on:change="fetchData(true)"
        ></v-select>
      </v-col>
      <v-col class="d-flex ml-3" cols="4" md="2">
        <v-select
          :items="sortBy"
          v-model="uiState.sort"
          solo
          flat
          outlined
          hide-details="auto"
          v-on:change="fetchData()"
        ></v-select>
      </v-col>
      <v-col class="d-flex ml-3" cols="4" md="2">
        <v-select
          v-model="display_item"
          :items="display_items"
          item-text="state"
          item-value="value"
          solo
          flat
          return-object
          outlined
          hide-details="auto"
          v-on:change="fetchData()"
        ></v-select>
      </v-col>
      <v-col class="my-auto ml-3" cols="6" md="4">
        <div class="body-1 grey--text">Найдено: {{ numFound }} результатов</div>
      </v-col>
    </v-row>
    <v-row class="mt-4" no-gutters>
      <v-col
        class="pa-4"
        cols="12"
        md="4"
        xl="3"
        v-for="(
          { id, profilePicture, editedProfilePicture, title, address }, i
        ) in state"
        :key="i"
      >
        <v-card class="mx-auto" max-width="400" hover>
          <router-link
            :to="{
              path: `/arena/${id}`,
              params: { arenaId: id },
            }"
            class="undo-link-default"
          >
            <v-img
              class="white--text align-end"
              height="250px"
              :src="
                editedProfilePicture ? editedProfilePicture : profilePicture
              "
            >
            </v-img>

            <v-card-title class="text--primary">
              <div>{{ title ? `${title.slice(0, 20)}...` : "Нет имени" }}</div>
            </v-card-title>
          </router-link>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="blue"
              text
              v-show="buttonToggle"
              v-on:click="isApproved({ isApproved: true, id: id })"
            >
              Одобрить
            </v-btn>
            <v-btn
              color="red"
              text
              v-show="!buttonToggle"
              v-on:click="isApproved({ isApproved: false, id: id })"
            >
              Отклонить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center py-10">
      <v-pagination
        color="grey"
        :length="paginationLength"
        :total-visible="7"
        v-model="uiState.currentPage"
        v-on:input="fetchData()"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    title: String,
  },
  data() {
    return {
      todoMode: ["Одобренные запросы", "Неодобренные запросы"],
      currentTodoMode: "Одобренные запросы",
      cityList: [],
      paginationLength: 0,
      numFound: 0,
      buttonToggle: false,
      sortBy: ["По имени (от А до Я)", "По имени (от Я до А)"],
      display_items: [
        { state: "Показывать по 9", value: 9 },
        { state: "Показывать по 27", value: 27 },
        { state: "Показывать по 42", value: 42 },
        { state: "Показывать по 81", value: 81 },
      ],
      display_item: { state: "Показывать по 9", value: 9 },
      uiState: {
        city: "Все города",
        currentPage: 1,
        queryString: "",
        sort: "По имени (от А до Я)",
        approved: true,
      },
    };
  },
  watch: {
    currentTodoMode() {
      this.buttonToggle = !this.buttonToggle;
    },
  },
  created() {
    this.getCities();
    this.cityList = ["Все города", ...this.cities];
    this.fetchData();
  },
  computed: {
    ...mapState("school", ["state", "school", "cities"]),
  },
  methods: {
    async fetchData(toStartPage = false) {
      const { city, currentPage, queryString, sort } = this.uiState;
      const filters = {
        city,
        pageSize: this.display_item.value,
        ...(sort === "По имени (от А до Я)"
          ? { sortBy: 1 }
          : { sortBy: false }),
        queryString,
        ...(toStartPage ? { currentPage: 1 } : { currentPage }),
        ...(this.currentTodoMode === "Одобренные запросы"
          ? { approved: true }
          : { approved: false }),
      };

      this.uiState.currentPage = toStartPage ? 1 : currentPage;
      const { pagination, numFound } = await this.filterSchools({
        filters,
        type: "STATE",
      });
      this.paginationLength = pagination;
      this.numFound = numFound;
    },
    async isApproved({ isApproved, id }) {
      await this.getSchool(id);
      this.school.approved = isApproved;
      await this.putSchool({ schoolId: id, school: this.school });
      this.fetchData();
    },
    ...mapActions("school", [
      "filterSchools",
      "putSchool",
      "getSchool",
      "getCities",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
