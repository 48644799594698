<template>
  <v-card elevation="0" style="border: 1px solid #379AD3;" height="200px" class="pa-5 rounded-xl">
    <div class="d-flex flex-no-wrap">
      <v-card-text class="pa-0">
        <div class="body-1 grey--text">
          {{ dateFormat(forum.date) }}
        </div>
        <div class="text-h6 mb-2">{{ forum.title }}</div>
      </v-card-text>
      <slot name="edit-delete"></slot>
    </div>
    <div v-if="forum.description.length <= 100" class="mb-4 text-justify">
      {{forum.description}}
    </div>
    <div v-else class="mb-4 text-justify">
      {{ forum.description.slice(0, 100) + " ..." }}
    </div>
    <div v-if="forum.type == 'TEAMPLAYER'" class="d-flex mb-2">
      <div
        v-if="isValid(forum.role)"
        class="body-2 blue--text"
        :class="{ 'mr-16': isValid(forum.role) }"
      >
        Амплуа: <span style="color: black"> {{ forum.role }} </span>
      </div>
      <div class="body-2 blue--text" v-if="isValid(forum.grip)">
        Хват: <span style="color: black"> {{ forum.grip }} </span>
      </div>
    </div>
    <v-row>
      <v-col cols="12">
        <div> <span class="blue--text body-2">Организатор:</span> {{forum.organizer}}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    forum: {
      type: Object,
      required: true,
    },
  },

  mounted() {},
  data() {
    return {};
  },
  methods: {
    isValid(input) {
      if (input) return true;
      return false;
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formatter.format(newDate);
    },
  },
  filters: {},
  computed: {},
};
</script>

<style></style>
