<template>
  <div>
    <v-breadcrumbs :items="breadcrumb_items" class="pl-0 pt-0" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" @click="$router.push(item.to)" >
        <span :style="`color: black; font-size: 24px`">
          {{ item.text }}
        </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <p class="mb-6" style="font-size: 18px">Выберите команду:</p>
    <div v-if="!isFetching">
      <div v-if="teams.length">
        <v-row class="container mb-2 mt-2 item_cursor elevation-1"
               v-for="item in teams"
               :key="item.id"
               @click="toTeam(item.team.id)"
        >
          <v-card color="transparent" elevation="0" >
            <div class="d-flex flex-no-wrap">
              <v-avatar class="ma-3 rounded-lg" size="125" tile>
                <v-img
                    :src="
                        item.team.profilePicture != null
                          ? item.team.profilePicture
                          : require('@/assets/team_room_1.jpg')
                      "
                ></v-img>
              </v-avatar>
              <v-card-text>
                <div
                    class="body-1 blue--text mb-2"
                    style="text-decoration: none"
                >
                  {{ item.team.city }}
                </div>
                <div class="text-h5 mb-2">
                  {{ item.team.title }}
                </div>
                <!-- <div class="body-1 grey--text">
                          {{ item.team.miniDescription | descriptionLength }}
                        </div> -->
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </div>
    <div v-else style="height: 150px">
      <app-loader/>
    </div>

  </div>
<!--  <div v-else>-->
<!--    <v-breadcrumbs :items="breadcrumb_items" class="pl-0 pt-0" large>-->
<!--      <template v-slot:item="{ item }">-->
<!--        <v-breadcrumbs-item :disabled="item.disabled" @click="$router.push(item.to)" >-->
<!--        <span :style="`color: black; font-size: 24px`">-->
<!--          {{ item.text }}-->
<!--        </span>-->
<!--        </v-breadcrumbs-item>-->
<!--      </template>-->
<!--    </v-breadcrumbs>-->
<!--  </div>-->
</template>

<script>
import { mapState } from "vuex";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "LeagueTeams",
  props: {
    leagueId: String,
  },
  computed: {
    ...mapState("leagues", ["teamParams", "teams"]),
  },
  components: {
    AppLoader
  },
  beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Турниры",
        disabled: false,
        exact: true,
        to: { name: "league-seasons", params: { leagueId: this.leagueId } },
      },
      {
        text: this.teamParams.season.title,
        disabled: false,
        exact: true,
        to: { name: "league-seasons", params: { leagueId: this.leagueId } },
      },
      {
        text: this.teamParams.tournament.title,
        disabled: false,
        exact: true,
        to: { name: "league-tournament", params: { leagueId: this.leagueId } },
      },
      {
        text: this.teamParams.division.title,
        disabled: false,
        exact: true,
        to: { name: "league-divisions", params: { leagueId: this.leagueId } },
      },
    ];
  },
  async created() {
    await this.fetchTeams();
    this.isFetching = false;
    console.log(this.teamParams)
  },
  data() {
    return {
      breadcrumb_items: [],
      isFetching: true
    }
  },
  methods: {
    fetchTeams() {
      const filters = [
        {
          "filterConditions": "EQUAL",
          "value": {
            "type": "UUID",
            "uuid": this.teamParams.division.id,
          },
          "parameter": "divisionId",
        }
      ];
      const params = {
        // sortAscending: true,
        // sortParameter: "id",
      };
      const config = [filters, params];
      return this.$store.dispatch("leagues/getDivisionTeams", config);
    },
    toTeam(id) {
      this.$router.push({name: 'team-name', params: {teamId: id}})
    }
  },
};
</script>

<style scoped>
.container {
  background-color: white;
  border-radius: 10px;
}
.item_cursor {
  cursor: pointer;
}
</style>
