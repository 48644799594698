<template>
  <v-tabs  vertical class="pl-0 rounded-lg my-sidetabs" v-model="sidebar">
    <v-tab
    @click="scrollToTop"
    class="sidebar_text"
    v-for="(item, i) in arena_sidebar_items"
    :key="i"
    router
    :to="item.link"
    exact-path
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    arena: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sidebar: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 155,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  created() {
    this.arena_sidebar_items = [
        {
          text: "Информация",
          link: { name: "complex-information", params: { arenaId: this.arena.arenaId} },
        },
        {
          text: "Платные услуги",
          link: { name: "admin-payment-portal", params: { arenaId: this.arena.arenaId} },
        },
        {
          text: "Список команд",
          link: { name: "admin-team-list", params: { arenaId: this.arena.arenaId} },
        },
        {
          text: "Тренерский состав",
          link: { name: "admin-train-staff", params: { arenaId: this.arena.arenaId} },
        },
        {
          text: "Мероприятия",
          link: { name: "admin-schedule-event", params: { arenaId: this.arena.arenaId} },
        },
      ];      
  },
  
};
</script>

<style >
.sidebar_text{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: none !important;
}

.v-tabs-slider-wrapper{
  display: none;
}
</style>
