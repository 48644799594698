<template>
  <v-tabs vertical class="pl-0 rounded-lg my-sidetabs" v-model="sidebar" v-if="isOrganizer">
    <v-tab
    @click="scrollToTop"
    class="sidebar_text"
    v-for="(item, i) in league_adminsidebar_items"
    :key="i"
    router
    :to="item.link"
    exact-path
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";

export default {
  props: {
    league: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userAdminRoleId: "",
      sidebar: null,
      isOrganizer: false,
      isTeamAdmin: false,
      league_adminsidebar_items: [
        {
          text: "Турниры",
          link: { name: "admin-tournament-information", params: { leagueId: this.league.id} },
        },
        {
          text: "Дивизионы",
          link: { name: "admin-divisions-information", params: { leagueId: this.league.id} },
        },
      ],
    };
  },
  mounted(){
    this.getUserRoles()
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.user.id}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name === "TOURNAMENT_ORGANIZER"){
            this.isOrganizer = true;
          }
        });
      })
      .catch((error) => {
        console.error(error);
      })
    },

    scrollToTop() {
      window.scroll({
        top: 155,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
};
</script>

<style >
.sidebar_text{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: none !important;
}

.v-tabs-slider-wrapper{
  display: none;
}
</style>
