<template>
  <div class="grey lighten-4">
    <div class="banner-league">
      <v-container class="pt-16 pb-16 white--text">
        <div class="text-h4">Детские турниры</div>
      </v-container>
    </div>
    <v-container class="pt-10 pb-0 mb-0">
      <v-row dense>
        <v-col class="d-flex" cols="12" md="2">
          <v-select
            :items="cities"
            v-model="city"
            solo
            flat
            hide-details="auto"
            @change="getKidsTournaments"
          ></v-select>
        </v-col>
        <v-col cols="9" md="8" lg="9">
          <v-text-field
            label="Поиск по названию турнира"
            single-line
            prepend-inner-icon="mdi-magnify"
            solo
            flat
            hide-details="auto"
            class="rounded-lg"
            v-model="search"
            @keypress.enter="getKidsTournaments"
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="2" lg="1">
          <v-btn
            class="rounded-lg"
            large
            depressed
            height="48px"
            width="100%"
            color="primary"
            @click="getKidsTournaments"
          >
            Найти
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <AppSelectDatePickerSolo :label="start" :date.sync="startDate" />
        </v-col>
        <v-col cols="3">
          <AppSelectDatePickerSolo :label="end" :date.sync="endDate" />
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Год рождения"
            solo
            flat
            hide-details="auto"
            class="rounded-lg"
            v-model="birthYear"
            @keypress.enter="getKidsTournaments"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" md="4" lg="3" xl="2">
          <v-select
            :items="display_items"
            item-text="state"
            item-value="value"
            v-model="show"
            @change="getKidsTournaments"
            return-object
            solo
            flat
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="6" md="4" lg="3" xl="2">
          <v-select
            prepend-icon="mdi-sort"
            :items="sort_by"
            v-model="sortBy"
            item-text="value"
            item-value="key"
            return-object
            solo
            flat
            hide-details="auto"
            @change="getKidsTournaments"
          ></v-select>
        </v-col>
        <v-col class="my-auto" cols="6" md="4">
          <div class="body-1 grey--text">
            Найдено результатов: {{ numFound }}
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <!-- <v-col cols="6" md="4" lg="3" xl="2">
          <v-select
            :items="display_items"
            item-text="state"
            item-value="value"
            v-model="show"
            @change="getKidsTournaments"
            return-object
            solo
            flat
            hide-details="auto"
          ></v-select>
        </v-col> -->
      </v-row>
      <v-row class="mx-n4" v-if="!isFetching">
        <v-col
          class="pa-4"
          cols="12"
          md="6"
          xl="4"
          v-for="(item, i) in allkidsTournaments.content"
          :key="i"
        >
          <kids-tournaments-mini-card :kidsTournaments="item" />
        </v-col>
      </v-row>
      <app-loader style="height: 650px" v-else />

      <div class="text-center py-10" v-if="allkidsTournaments.content.length">
        <v-pagination
          color="grey"
          v-model="page"
          :length="paginationLength"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppLoader from "../../components/AppUnit/AppLoader.vue";
import AppSelectDatePickerSolo from "../../components/AppUnit/AppSelectDatePickerSolo.vue";
import KidsTournamentsMiniCard from "../../components/KidsTournaments/KidsTournamentsMiniCard.vue";

export default {
  components: {
    AppLoader,
    KidsTournamentsMiniCard,
    AppSelectDatePickerSolo,
  },
  name: "KidsTournamentsMain",
  computed: {
    ...mapState("leagues", ["cities", "allkidsTournaments"]),
  },
  created() {
    this.$store.dispatch("leagues/getCities").then(() => {
      this.cities.unshift("Все города");
    });
    this.getKidsTournaments();
  },
  mounted() {},
  data() {
    return {
      city: "Москва",
      search: "",
      numFound: 0,
      sort_by: [
        { key: true, value: "По имени (от А до Я)" },
        { key: false, value: "По имени (от Я до А)" },
      ],
      sortBy: { key: true, value: "По имени (от А до Я)" },
      display_items: [
        { state: "Показывать по 10", value: 10 },
        { state: "Показывать по 30", value: 30 },
        { state: "Показывать по 50", value: 50 },
        { state: "Показывать по 100", value: 100 },
      ],
      show: { state: "Показывать по 10", value: 10 },
      page: 1,
      paginationLength: 0,
      isFetching: false,
      start: "Дата начала проведения",
      end: "Дата конца проведения",
      startDate: "",
      endDate: "",
      birthYear: "",
    };
  },
  methods: {
    getKidsTournaments() {
      this.isFetching = true;
      const data = {
        currentPage: this.page,
        pageSize: this.show.value,
        sortAscending: this.sortBy.key,
        filters: [
          {
            filterConditions: "EQUAL",
            value: {
              type: "String",
              string: this.city,
            },
            parameter: "city",
          },
          {
            filterConditions: "EQUAL",
            value: {
              type: "Boolean",
              boolean: true,
            },
            parameter: "approved",
          },
          {
            filterConditions: "EQUAL",
            value: {
              type: "String",
              string: "KIDS",
            },
            parameter: "type",
          },
        ],
      };
      if (this.search != "") {
        const filter = {
          filterConditions: "CONTAINS",
          value: {
            type: "String",
            string: this.search,
          },
          parameter: "title",
        };
        data.filters.push(filter);
      }
      if (this.endDate !== "" && this.startDate !== "") {
        const filterEnd = {
          filterConditions: "SMALLERTHAN",
          value: {
            type: "Date",
            date: this.endDate,
          },
          parameter: "end_date",
        };
        const filterStart = {
          filterConditions: "GREATERTHAN",
          value: {
            type: "Date",
            date: this.startDate,
          },
          parameter: "start_date",
        };

        data.filters.push(filterEnd);
        data.filters.push(filterStart);
      }
      if (this.birthYear !== "") {
        const filterAge = {
          filterConditions: "EQUAL",
          value: {
            type: "Int",
            int: Number(this.birthYear),
          },
          parameter: "age",
        };

        data.filters.push(filterAge);
      }
      this.$store.dispatch("leagues/filterKidsTournaments", data).then(() => {
        console.log(this.allkidsTournaments);
        this.numFound = this.allkidsTournaments.totalElements;
        this.paginationLength = this.allkidsTournaments.totalPages;
        this.isFetching = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.undo-link-default {
  text-decoration: none;
}
.banner-league {
  background: url("../../assets/school_bg.jpg") no-repeat center center;
  background-size: cover;
  height: 240px;
}
</style>
