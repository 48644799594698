<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <span class="text-h5 font-weight-bold pb-3 pt-5"
            >Дополнительная информация</span
          >
        </v-col>
        <v-col cols="12">
          <AdminSocialMedia :items="social_media" />
        </v-col>
        <v-col cols="12">
          <AdminCampArena :campId="campId" />
        </v-col>
        <v-col cols="12">
          <AdminContactForm :getContact="getContact" :contact="contacts" />
        </v-col>
        <v-col cols="12">
          <AdminGallery :items="galleryPics" />
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <v-btn
              @click="addAdditionInfo"
              large
              elevation="0"
              color="primary"
              class="body-2 px-6 mr-2"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="grey lighten-2"
              large
              class="body-2 px-6 ml-2"
              elevation="0"
              @click="
                $router.push({
                  name: 'user-profile',
                  params: { userId },
                })
              "
            >
              Отменить
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="errorDialog" max-width="300">
        <v-card>
          <div class="text-center px-4 pt-6">{{ errorText }}</div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="goToProfile" text>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import AdminGallery from "@/components/Admin/AdminGallery.vue";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import AdminContactForm from "@/components/Admin/AdminContactForm.vue";
import AdminCampArena from "@/components/Admin/AdminCampArena.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AdminSocialMedia,
    AdminContactForm,
    AdminGallery,
    AdminCampArena,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    campId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state: {},
      contacts: "",
      errorDialog: false,
      errorText: "",
      galleryPics: [],
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: "",
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
      ],
    };
  },
  computed: {
    ...mapState("camps", ["camp"]),
  },
  mounted() {
    this.fetchCamp();
  },
  methods: {
    async fetchCamp() {
      await this.getCamp(this.campId);
      this.state = this.camp;
    },
    addAdditionInfo() {
      this.state.vk = this.social_media[0].link;
      this.state.whatsApp = this.social_media[1].link;
      this.state.website = this.social_media[2].link;
      this.state.contact = this.contacts;
      this.state.gallery = this.galleryPics;
      this.state.approved = false;
      this.putCamp({
        campId: this.campId,
        camp: this.state,
      });
      this.errorDialog = true;
      this.errorText =
        "Ваша заявка принята на рассмотрение. Решение будет выслано на почту после рассмотрения";
    },
    getContact(data) {
      this.contacts = data.contact;
    },
    goToProfile() {
      this.errorDialog = false;
      this.$router.push({
        name: "user-profile",
        params: { userId: this.userId },
      });
    },
    ...mapActions("camps", ["getCamp", "putCamp"]),
  },
};
</script>

<style lang="scss" scoped></style>
