<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="">
        <div>
          <v-breadcrumbs :items="breadcrumb_items"></v-breadcrumbs>
        </div>
      </v-row>
      <div>
        <div class="text-h5 pb-3 pt-5 font-weight-bold">Редактировать лигу</div>
        <v-row>
          <v-col cols="4" class="pr-10">
            <div class="body-1 mb-4 font-weight-bold">Логотип лиги</div>

            <admin-image-uploader v-model="avatar">
              <div slot="activator">
                <div v-if="!avatar" class="white rounded-xl pa-4">
                  <v-avatar
                    width="100%"
                    height="200"
                    v-ripple
                    tile
                    class="white rounded-xl"
                  >
                    <div class="upload-border rounded-xl pa-4">
                      <div class="my-4">
                        <v-icon large color="#379AD3"
                          >mdi-cloud-upload-outline
                        </v-icon>
                      </div>
                      <div class="body-1 mb-2 font-weight-bold">
                        Загрузите логотип
                      </div>
                      <div class="body-2 mb-4 grey--text">
                        Поддерживаемые форматы: PNG, JPG
                      </div>
                    </div>
                  </v-avatar>
                </div>
                <div v-else class="white rounded-xl pa-4">
                  <v-avatar width="100%" height="200" tile v-ripple>
                    <v-img
                      class="ma-10 rounded-xl"
                      :src="avatar.imageURL"
                      alt="avatar"
                      cover
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
            </admin-image-uploader>
          </v-col>
          <v-col>
            <div class="body-1 mb-4 font-weight-bold">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Полное название турнира / лиги"
                v-model="fullTitle"
                :rules="fullTitleRules"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-text-field
                label="Сокращенное название турнира / лиги"
                v-model="title"
                :rules="shortTitleRules"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-select
                v-model="location"
                :items="cities"
                :rules="cityRules"
                label="Город"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-select>
            </div>
          </v-col>
        </v-row>

        <div>
          <div class="text-h6 mb-2">Описание</div>
          <v-textarea
            solo
            v-model="description"
            name=""
            flat
            elevation="0"
          ></v-textarea>
        </div>
        <div class="mb-4">
          <AdminTournamentArena :league-id="leagueId" />
        </div>
        <div class="mb-4">
          <AdminTournamentTeams :league-id="leagueId" />
        </div>

        <div class="mb-4">
          <AdminContactForm
            :getContact="getContact"
            :contact="contacts"
          ></AdminContactForm>
        </div>
        <div class="mb-4">
          <AdminTournamentOrganizer :league-id="leagueId" />
        </div>
        <div class="mb-4">
          <AdminSocialMedia
            :items="social_media"
            @newMedias="setSocialMedias"
          ></AdminSocialMedia>
        </div>
        <div class="mb-4">
          <AdminGallery :items="galleryPics"></AdminGallery>
        </div>
      </div>
      <div class="d-flex mt-16">
        <v-btn
          @click="updateLeague"
          large
          elevation="0"
          color="primary"
          class="body-2 px-6 mr-2"
        >
          Сохранить
        </v-btn>
        <v-btn
          color="grey lighten-2"
          large
          class="body-2 px-6 ml-2"
          elevation="0"
          @click="
            $router.push({
              name: 'user-profile',
              params: { userId: userId },
            })
          "
        >
          Отменить
        </v-btn>
      </div>
      <v-dialog v-model="errorDialog" max-width="300">
        <v-card>
          <div class="text-center px-4 pt-6">{{ errorText }}</div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="goToProfile" text>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminGallery from "@/components/Admin/AdminGallery.vue";
// import AdminContact from "@/components/Admin/AdminContact.vue";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import { mapState } from "vuex";
// import AdminArenaContact from "@/components/Admin/AdminArenaContact";
import AdminTournamentOrganizer from "@/components/Admin/AdminTournamentOrganizer";
import AdminTournamentArena from "@/components/Admin/AdminTournamentArena";
import AdminContactForm from "../../../../components/Admin/AdminContactForm.vue";
import AdminTournamentTeams from "@/components/Admin/AdminTournamentTeams";
// import AdminTeamPlayers from '../../../../components/Admin/AdminTeamPlayers.vue';
// import AdminTeamTrainers from '../../../../components/Admin/AdminTeamTrainers.vue';
// import AdminTeamAdministrator from '../../../../components/Admin/AdminTeamAdministrator.vue';

export default {
  components: {
    AdminTournamentTeams,
    AdminTournamentOrganizer,
    // AdminArenaContact,
    AdminImageUploader,
    AdminGallery,
    AdminSocialMedia,
    AdminTournamentArena,
    AdminContactForm,
    // AdminTeamPlayers,
    // AdminTeamTrainers,
    // AdminTeamAdministrator,
  },
  props: {
    leagueId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("leagues", ["league", "cities"]),
    social_media_display() {
      return this.social_media.filter((x) => x.link);
    },
    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },
  async mounted() {
    await this.$store.dispatch("leagues/getCities");
  },
  created() {
    window.scrollTo(0, 0);
  },
  async beforeMount() {
    await this.getLeague();
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId: this.userId } },
      },
      {
        text: "Мои лиги",
        disabled: false,
        exact: true,
        to: {
          name: "admin-league",
          params: { userId: this.userId },
        },
      },
      {
        text: "Редактировать турнир",
        disabled: true,
        to: "",
      },
    ];
  },
  data() {
    return {
      files: {},
      galleryPics: [],
      title: "",
      description: "",
      location: "",
      locations: ["Москва"],
      avatar: null,
      contacts: "",
      galleryName: "multifile",
      checkbox: null,
      telephone: "",
      email: "",
      errMessage: "",
      social_media_dialog: false,
      toggle_social_media: null,
      social_media_text: "",
      contact_dialog: false,
      album_dialog: false,
      fullTitle: "",
      errorDialog: false,
      errorText: "",
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        // {
        //   id: 2,
        //   name: "whatsapp",
        //   link: "",
        //   icon: "mdi-whatsapp",
        // },
        {
          id: 2,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
        {
          id: 3,
          name: "instagram",
          link: "",
          icon: "mdi-instagram",
        },
        // {
        //   id: 5,
        //   name: "facebook",
        //   link: "",
        //   icon: "mdi-facebook",
        // },
      ],
      breadcrumb_items: [],
      fullTitleRules: [(v) => !!v || "Введите полное название"],
      shortTitleRules: [(v) => !!v || "Введите сокращенное название"],
      cityRules: [(v) => !!v || "Выберите город"],
    };
  },
  methods: {
    setSocialMedias(value) {
      this.social_media = value;
    },
    validate() {
      if (this.fullTitle && this.title && this.location) {
        return true;
      }
      return false;
    },
    goToProfile() {
      this.errorDialog = true;
      this.$router.push({
        name: "user-profile",
        params: { userId: this.userId },
      });
    },
    getLeague() {
      return this.$store
        .dispatch("leagues/getLeague", this.leagueId)
        .then(() => {
          const league = this.league;
          console.log(league);
          this.galleryPics = league.gallery;
          this.title = league.title;
          this.location = league.city;
          this.contacts = league.contacts ? league.contacts : "";
          this.avatar = league.logo ? { imageURL: league.logo } : null;
          this.social_media[0].link = league.vk;
          this.social_media[1].link = league.site;
          this.social_media[2].link = league.instagram;
          this.description = league.description;
          this.fullTitle = league.fullTitle;
        });
    },
    getContact(data) {
      this.contacts = data.contact;
    },
    updateLeague() {
      if (this.validate()) {
        const data = {
          title: this.title,
          fullTitle: this.fullTitle,
          // miniDescription: "",
          city: this.location,
          // type: this.category.value,
          // level: "",
          description: this.description,
          logo: this.profilePicture,
          editedLogo: this.league.editedLogo,
          gallery: this.galleryPics,
          contacts: this.contacts,
          // mails: this.contact.mail,
          instagram: this.social_media[2].link,
          vk: this.social_media[0].link,
          site: this.social_media[1].link,
          approved: false,
        };
        this.$store.dispatch("leagues/putLeague", {
          leagueId: this.leagueId,
          league: data,
        });
        this.errorDialog = true;
        this.errorText =
          "Ваша заявка принята на рассмотрение. Решение будет выслано на почту после рассмотрения";
      }
      // let whatsapp = "";
      // if (this.social_media[1].link) {
      //   whatsapp = `https://wa.me/${this.social_media[1].link
      //     .replace("(", "")
      //     .replace(")", "")
      //     .replace(" ", "")}`;
      // }
    },
  },
};
</script>

<style>
.upload-border {
  border: 1px dashed #c3e1f2;
}
</style>
