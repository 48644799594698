<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="justify-center">
        <v-col cols="12" lg="10" xl="8">
          <v-row class="">
            <div>
              <v-breadcrumbs
                :items="breadcrumb_items"
                class="px-3"
              ></v-breadcrumbs>
            </div>
          </v-row>
          <div>
            <div class="text-h5 font-weight-bold pb-3 pt-5">Информация</div>

            <div class="text-h6 mb-4">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Название школы"
                v-model="fullTitle"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
                id="fullTitle"
                :rules="fullTitleRules"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-select
                label="Город"
                :items="cities"
                v-model="city"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
                id="city"
                :rules="cityRules"
              ></v-select>
            </div>
            <div class="mb-4">
              <v-select
                v-model="type"
                :items="types"
                item-text="state"
                item-value="value"
                label="Тип"
                outlined
                return-object
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-select>
            </div>
            <div class="mb-4">
              <div class="text-h6 mb-4">Логотип школы</div>
              <v-row>
                <v-col class="pa-2" cols="6" sm="4" md="3">
                  <admin-image-uploader v-model="avatar">
                    <div slot="activator">
                      <div v-if="!avatar" class="white rounded-xl pa-4">
                        <v-avatar
                          width="100%"
                          height="200"
                          v-ripple
                          tile
                          class="white rounded-xl"
                        >
                          <div class="upload-border rounded-xl pa-4">
                            <div class="my-4">
                              <v-icon large color="#379AD3"
                                >mdi-cloud-upload-outline
                              </v-icon>
                            </div>
                            <div class="body-1 mb-2 font-weight-bold">
                              Загрузите логотип
                            </div>
                            <div class="body-2 mb-4 grey--text">
                              Поддерживаемые форматы: PNG, JPG
                            </div>
                          </div>
                        </v-avatar>
                      </div>
                      <div v-else class="white rounded-xl pa-4">
                        <v-avatar width="100%" height="200" tile v-ripple>
                          <v-img
                            class="ma-10 rounded-xl"
                            :src="avatar.imageURL"
                            alt="avatar"
                            cover
                          ></v-img>
                        </v-avatar>
                      </div>
                    </div>
                  </admin-image-uploader>
                </v-col>
              </v-row>
            </div>
            <div>
              <div class="text-h6 mb-2">Описание</div>
              <v-textarea
                solo
                v-model="description"
                name=""
                flat
                elevation="0"
              ></v-textarea>
            </div>
          </div>
          <div class="d-flex">
            <v-btn
              @click="saveNewSchool"
              large
              elevation="0"
              color="primary"
              class="body-2 px-6 mr-2"
            >
              Далее
            </v-btn>
            <v-btn
              color="grey lighten-2"
              large
              class="body-2 px-6 ml-2"
              elevation="0"
              @click="
                $router.push({
                  name: 'user-profile',
                  params: { userId },
                })
              "
            >
              Отменить
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import { mapState } from "vuex";
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
// import axios from "axios";

export default {
  components: {
    AdminImageUploader,
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
    search(input) {
      input && input !== this.address && this.findCoordinateGivenAddress(input);
    },
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  async created() {
    window.scrollTo(0, 0);
    await this.$store.dispatch("school/getCities");
    console.log("cities is here", this.cities);
    await this.getUserRoles();
    const userId = this.userId;
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId } },
      },
      {
        text: "Мои школы",
        disabled: false,
        exact: true,
        to: { name: "admin-add-tournament", params: { userId } },
      },
      {
        text: "Добавить школу",
        disabled: true,
        exact: true,
        to: "",
      },
    ];
  },
  computed: {
    ...mapState("user", ["user", "new_arenas"]),
    ...mapState("school", ["cities"]),
    ...mapState("file", ["croppedImg"]),
    // ...mapState("leagues", ["new_arenas"]),
    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },
  data() {
    return {
      arenaAdminRoleId: "",
      isLoading: false,
      type: {},
      types: [
        { state: "Государственная", value: "STATE" },
        { state: "Частная", value: "COMMERCIAL" },
      ],
      galleryPics: [],
      map: {},
      fullTitle: "",
      shortTitle: "",
      description: "",
      metro: [],
      address: "",
      city: "",
      avatar: null,
      tag_chips: [],
      checkbox: null,
      contacts: "",
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: "",
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
        {
          id: 4,
          name: "instagram",
          link: "",
          icon: "mdi-instagram",
        },
        {
          id: 5,
          name: "facebook",
          link: "",
          icon: "mdi-facebook",
        },
      ],
      breadcrumb_items: [],
      id: "",
      errors: [],
      fullTitleRules: [(v) => !!v || "Введите полное название"],
      shortTitleRules: [(v) => !!v || "Введите сокращенное название"],
      cityRules: [(v) => !!v || "Выберите город"],
    };
  },
  methods: {
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },

    async saveNewSchool() {
      if (this.validate()) {
        const data = {
          title: this.fullTitle,
          description: this.description,
          profilePicture: !this.avatar ? "" : this.avatar.imageURL,
          editedProfilePicture: this.croppedImg,
          city: this.city,
          type: this.type.value,
        };

        await this.$store
          .dispatch("school/postSchool", data)
          .then((response) => {
            console.log(response);
            const { id } = response;
            this.id = id;
            const userSchoolId = {
              userId: this.userId,
              schoolId: id,
              roleId: this.arenaAdminRoleId,
            };

            console.log(
              "🚀 ~ file: CreateAdminTeam.vue ~ line 287 ~ .then ~ userLeague",
              userSchoolId
            );

            this.$store.dispatch("user/createUserSchool", {
              userSchoolId,
              school: response,
            });
            // .then(() => {
            //   this.$router.push({
            //     name: "admin-tournament-edit",
            //     params: { id },
            //   });
            // });
            // this.new_arenas.forEach(item => {
            //   console.log(item)
            //   let data = {
            //     leagueId: id,
            //     arenaId: item.arenaId,
            //   }
            //   console.log("added arena", data)
            //   axios.post(`${ADD_ARENA_TO_LEAGUE}`, data)
            // })
          })
          .catch((err) => {
            console.log(err);
          });
        // this.$router.push({
        //   name: "admin-tournament-edit",
        //   params: { leagueId: this.id },
        // });
        await this.$router.push({
          name: "admin-school-edit",
          params: { schoolId: this.id },
        });
      }
    },
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
        .then((res) => {
          res.data.forEach((item) => {
            if (item.name === "ARENA_ADMIN") {
              this.arenaAdminRoleId = item.id;
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    validate() {
      // if (this.fullTitle && this.shortTitle && this.city) {
      //   return true;
      // }
      // if (!this.fullTitle) {
      //   this.errors.push("Введите полное название лиги");
      //   document.getElementById("fullTitle").style.color = "red";
      // }
      // return false;
      return true;
    },

    getContact(data) {
      this.contacts = data.contact;
    },
  },
};
</script>

<style></style>
