<template>
  <div>
    <v-breadcrumbs :items="breadcrumb_items" class="pl-0 pt-0" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" @click="$router.push(item.to)" >
        <span :style="`color: black; font-size: 24px`">
          {{ item.text }}
        </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <p class="mb-8" style="font-size: 18px">Выберите дивизион:</p>
    <TournamentsComponent
      v-if="isLoaded === true"
      :data="divisions"
      :type="{func: toTeams}"
    />
    <app-loader v-else/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TournamentsComponent from "@/components/Leagues/TournamentsComponent";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "LeagueDivisions",
  components: { TournamentsComponent, AppLoader },
  computed: {
    ...mapState("leagues", ["divisions", "teamParams"]),
  },
  props: {
    leagueId: String
  },
  async created() {
    await this.getDivisions();
    this.isLoaded = true;
  },
  beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Турниры",
        disabled: false,
        exact: true,
        to: { name: "league-seasons", params: { leagueId: this.leagueId } },
      },
      {
        text: this.teamParams.season.title,
        disabled: false,
        exact: true,
        to: { name: "league-seasons", params: { leagueId: this.leagueId } },
      },
      {
        text: this.teamParams.tournament.title,
        disabled: false,
        exact: true,
        to: { name: "league-tournament", params: { leagueId: this.leagueId } },
      },
    ];
  },
  data() {
    return {
      breadcrumb_items: [],
      divisionsInfo: [],
      isLoaded: false,
    };
  },
  methods: {
    toTeams(division) {
      this.$store.dispatch("leagues/setTeamDivision", division);
      this.$router.push({name: "league-teams", params: { leagueId: this.leagueId }});
    },
    getDivisions() {
      return this.$store.dispatch("leagues/getTournamentDivisions", this.teamParams.tournament.id);
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  height: 80px;
  border-radius: 10px;
}
</style>
