<template>
  <div class="grey lighten-4">
    <v-img
      color="grey"
      height="350px"
      width="100%"
      src="@/assets/school_bg.jpg"
    >
      <v-container class="pt-8 pb-0">
        <v-row>
          <div>
            <v-breadcrumbs
              dark
              :items="breadcrumb_items"
              class="px-3"
            ></v-breadcrumbs>
          </div>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mb-3">
          <v-col cols="8" sm="6" md="7" lg="5">
            <p class="text-h5 white--text">{{ school.city }}</p>
            <p class="text-h4 white--text">{{ school.title }}</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-avatar class="ma-3" size="180" tile>
            <v-img
              :src="
                school.profilePicture != null
                  ? school.profilePicture
                  : require('@/assets/team_room_1.jpg')
              "
            ></v-img>
          </v-avatar>
        </v-row>
      </v-container>
    </v-img>
    <v-container>
      <v-row class="mt-5">
        <v-col cols="12">
          <p class="text-h6">Описание</p>
          <div v-if="school.description">
            <div v-if="school.description.length < 580">
              <p
                style="white-space: pre-line"
                class="text-justify"
                v-html="school.description"
              ></p>
            </div>
            <div v-else>
              <p
                style="white-space: pre-line"
                class="text-justify"
                v-if="!readMoreInfo"
                v-html="school.description.slice(0, 580) + '...'"
              ></p>
              <p
                style="white-space: pre-line"
                class="text-justify"
                v-else
                v-html="school.description"
              ></p>
            </div>
            <v-btn
              color="grey lighten-2 mb-5"
              v-show="school.description.length > 580"
              elevation="0"
              @click="readMoreInfo = !readMoreInfo"
            >
              {{ readMoreInfo ? "Скрыть" : "Раскрыть описание" }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <div v-if="school_trainers.length !== 0">
            <p class="text-h6">Тренеры</p>
            <div class="administration">
              <v-row>
                <league-administration
                  v-for="item in school_trainers"
                  :key="item.id"
                  :administrator="item"
                />
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <div v-if="school.address">
            <p class="text-h6 mb-1">Адрес</p>
            <p class="blue--text">
              {{ school.address }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col cols="12" md="8" v-if="coords.length !== 0" class="mt-4">
          <section class="wrapper-map">
            <LeagueMap
              :coords="coords"
              :surfaces="surfaces"
              :zoom="zoom"
              @set-coords="coords = $event"
            />
          </section>
        </v-col>
        <v-col cols="12" md="4">
          <p class="text-h6 font-weight-bold mt-4" v-if="school.contact">
            Контакты
          </p>
          <p style="white-space: pre" v-if="school.contact">
            {{ school.contact }}
          </p>
          <p class="text-h6 font-weight-bold mt-2">Социальные сети</p>
          <div>
            <a
              :href="`https://instagram.com/${school.instagram}`"
              class="text-decoration-none"
              v-if="school.instagram"
            >
              <div style="display: inline-block" class="mr-2">
                <img
                  height="35px"
                  width="35px"
                  :src="require('@/assets/sm_icons/instagram_color.svg')"
                />
              </div>
            </a>
            <a
              :href="`https://vk.com/${school.vk}`"
              class="text-decoration-none ml-4"
              v-if="school.vk"
            >
              <div style="display: inline-block" class="mr-2">
                <img
                  height="35px"
                  width="35px"
                  :src="require('@/assets/sm_icons/vk_color.svg')"
                />
              </div>
            </a>
            <a
              :href="school.site"
              class="text-decoration-none ml-4"
              v-if="school.site"
            >
              <div style="display: inline-block" class="mr-2">
                <img
                  height="35px"
                  width="35px"
                  :src="require('@/assets/sm_icons/web_color.svg')"
                />
              </div>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import leagueAdministration from "@/components/Leagues/LeagueAdministration";
import axios from "axios";
import { GET_SCHOOL_ARENAS } from "@/api";
import LeagueMap from "@/components/Leagues/LeagueMap";

export default {
  components: {
    leagueAdministration,
    LeagueMap,
  },
  props: {
    schoolId: {
      type: String,
      required: true,
    },
  },
  filters: {
    descriptionLength(value) {
      if (!value) return "";
      if (value.length < 30) return value;
      return value.slice(0, 30) + "...";
    },
  },
  computed: {
    ...mapState("school", ["school", "school_trainers"]),
    valid_contact_list() {
      return this.contact_list.filter((x) => x.link !== "");
    },
    media() {
      let _media = [];
      if (this.school.gallery) {
        this.school.gallery.forEach((x) => {
          const item = {
            thumb: x,
            src: x,
            caption: "<h4></h4>",
          };
          _media.push(item);
        });
      }
      return _media;
    },
  },
  async created() {
    const schoolId = this.schoolId;
    await this.$store.dispatch("school/getSchool", schoolId);
    await this.$store.dispatch("school/getSchoolTrainers", schoolId);
    await this.fetchArenas();

    this.breadcrumb_items = [
      {
        text: "Школы",
        disabled: false,
        to: { name: "sport-schools", params: { schoolId } },
        exact: true,
      },
      {
        text: this.school.title,
        disabled: true,
        to: "",
      },
    ];
    const schoolItem = this.school;

    this.contact_list = [
      {
        icon: "mdi-whatsapp",
        link: `${schoolItem.whatsApp ? schoolItem.whatsApp : ""}`,
      },
      {
        icon: "mdi-instagram",
        link: `${schoolItem.instagram ? schoolItem.instagram : ""}`,
      },
      {
        icon: "mdi-alpha-k-box",
        link: `${schoolItem.vk ? schoolItem.vk : ""}`,
      },
      {
        icon: "mdi-web",
        link: `${schoolItem.website ? schoolItem.website : ""}`,
      },
      {
        icon: "mdi-music-note-outline",
        link: `${schoolItem.tiktok ? schoolItem.tiktok : ""}`,
      },
      {
        icon: "mdi-twitter",
        link: `${schoolItem.twitter ? schoolItem.twitter : ""}`,
      },
      {
        icon: "mdi-youtube",
        link: `${schoolItem.youtube ? schoolItem.youtube : ""}`,
      },
      {
        icon: "mdi-facebook",
        link: `${schoolItem.facebook ? schoolItem.facebook : ""}`,
      },
    ];
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formatter.format(newDate);
    },
    fetchArenas() {
      return axios
        .get(`${GET_SCHOOL_ARENAS}?schoolId=${this.schoolId}`)
        .then((response) => {
          response.data.forEach((item) => {
            let coords = `${item.arena.lat}, ` + `${item.arena.lan}`;
            this.coords = [Number(item.arena.lat), Number(item.arena.lan)];
            const point = {
              id: item.arena.id,
              city: item.arena.city,
              title: item.arena.fullTitle,
              address: item.arena.address,
              coords,
            };
            this.surfaces.push(point);
          });
        });
    },
  },
  data() {
    return {
      contact: null,
      premises_tab: null,
      arenaId: null,
      advert_nav: ["Команда ищет игроков", "Команда ищет тренера"],
      contact_list: null,
      readMoreInfo: false,
      breadcrumb_items: null,
      zoom: 12,
      surfaces: [],
      coords: [],
    };
  },
};
</script>

<style>
.description {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
}
</style>
