<template>
  <div class="grey lighten-4">
    <div class="detail-banner">
      <v-container class="pt-10 pb-16">
        <v-row no-gutters>
          <v-col cols="12" sm="8" class="d-flex order-sm-1 mb-10">
            <div class="white--text primary-text">
              <v-breadcrumbs
                :items="breadcrumb_items"
                class="px-3 white--text primary-text"
              ></v-breadcrumbs>
            </div>
          </v-col>
          <v-col
            class="d-flex justify-sm-end order-sm-4 mt-sm-13 align-end"
            cols="8"
            sm="6"
          >
            <v-avatar class="rounded" size="180" tile>
              <v-img :src="this.camp.logo"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="d-flex justify-end order-sm-2" cols="4">
            <div class="d-flex flex-column flex-sm-row">
              <a
                :href="this.camp.whatsApp"
                class="nav__item mt-10 mt-sm-0 d-flex align-center"
              >
                <v-icon color="white"> mdi-whatsapp </v-icon>
              </a>

              <a
                :href="this.camp.vk"
                class="nav__item mt-10 mt-sm-0 d-flex align-center"
              >
                <v-icon color="white"> mdi-alpha-k-box-outline </v-icon>
              </a>

              <a
                :href="this.camp.website"
                class="nav__item mt-10 mt-sm-0 d-flex align-center"
              >
                <v-icon color="white"> mdi-web </v-icon>
              </a>
            </div>
          </v-col>
          <v-col
            class="d-flex flex-column order-sm-3 mt-10 mt-sm-15"
            cols="12"
            sm="6"
          >
            <div class="white--text primary-text">
              {{ this.camp.startDate }} — {{ this.camp.endDate }}
            </div>
            <div class="text-h3 white--text mt-3">
              {{ this.camp.title }}
            </div>
            <div class="blue--text primary-text mt-5">
              Россия, г.{{ this.camp.city }}, {{ this.camp.address }}
            </div>
            <div class="white--text primary-text mt-3">
              Год рождения: {{ this.camp.age }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex order-sm-5 mt-10">
            <div>
              <div class="white--text primary-text">Вступительный взнос</div>
              <div
                class="blue--text mt-3"
                style="
                  font-size: 1.5rem;
                  font-weight: 500;
                  line-height: 1.75rem;
                  text-decoration: none;
                "
              >
                {{ this.camp.entranceFees }} РУБ
              </div>
              <div class="white--text primary-text mt-1">
                + 2000 РУБ за каждого игрока
              </div>
            </div>
          </v-col>
          <v-col
            class="d-flex justify-sm-end order-sm-5 mt-5 mt-sm-15"
            cols="12"
            sm="6"
          >
            <div>
              <v-btn
                class="rounded-lg white--text"
                color="#0681C8"
                x-large
                elevation="0"
              >
                Участвовать
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="pt-10">
      <v-row class="d-flex flex-column" no-gutters>
        <v-col
          ><span style="font-size: 2rem; font-weight: 400; line-height: 2.5rem"
            >О сборах</span
          ></v-col
        >
        <v-col>
          <p class="primary__text mt-5">{{ this.camp.description }}</p>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-column mt-8" no-gutters>
        <v-col><span class="primary__title">Адрес</span></v-col>
        <v-col class="mt-2"
          ><span
            class="blue--text"
            style="
              text-decoration: none;
              font-size: 1.5rem;
              line-height: 1.75rem;
              font-weight: 500;
            "
            >Россия, г.{{ this.camp.city }}, {{ this.camp.address }}</span
          ></v-col
        >
      </v-row>
      <v-row class="d-flex flex-column mt-8" no-gutters>
        <v-col class="mt-4">
          <div class="primary__text">
            <p>Ближайшие станции метро:</p>
            <p>м.Первомайская, м.Щёлковская, м.Измайловская</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-8" no-gutters>
        <v-col>
          <section class="wrapper-map-main">
            <ArenaMap
              :coords="coords"
              :surfaces="mapPoints"
              :zoom="zoom"
              :key="key"
              @set-coords="coords = $event"
            />
          </section>
        </v-col>
      </v-row>
      <v-row class="mt-16" no-gutters>
        <v-col cols="12"
          ><span
            style="
              text-decoration: none;
              font-size: 1.5rem;
              line-height: 1.75rem;
              font-weight: 500;
            "
            >Место проведения тренировок</span
          ></v-col
        >
        <v-col
          class="d-flex flex-column flex-sm-row mt-5"
          v-for="{ arena } in arenas"
          :key="arena.id"
        >
          <v-img
            max-height="180"
            max-width="180"
            :src="arena.profilePicture"
            class="rounded mr-4 mb-2 mb-sm-0"
          ></v-img>
          <div class="mt-5 mt-sm-0">
            <span
              style="font-size: 2rem; font-weight: 500; line-height: 2.5rem"
              >{{ arena.fullTitle }}</span
            >
            <p class="primary__text gray--text mt-2">
              {{ arena.city }}
            </p>
            <div class="mt-16">
              <v-btn
                class="rounded-lg"
                color="#e0e0e0"
                x-large
                elevation="0"
                @click="
                  $router.push({
                    name: 'arena-information',
                    params: { arenaId: arena.id },
                  })
                "
              >
                Подробнее
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-16" no-gutters>
        <v-col cols="12"
          ><span
            style="
              text-decoration: none;
              font-size: 1.5rem;
              line-height: 1.75rem;
              font-weight: 500;
            "
            >Организаторы</span
          ></v-col
        >
        <v-col class="d-flex flex-row mt-5">
          <v-img
            max-height="180"
            max-width="180"
            :src="require('@/assets/team_room_3.jpg')"
            class="rounded mr-4 mb-2 mb-sm-0"
          ></v-img>
          <div class="d-flex flex-column justify-space-between">
            <div class="d-flex flex-column">
              <span
                class="blue--text primary-text"
                style="text-decoration: none"
              >
                Россия, Воронеж
              </span>
              <span
                class="mt-2"
                style="
                  font-size: 1.5rem;
                  font-weight: 500;
                  line-height: 1.75rem;
                "
                >Название школы</span
              >
            </div>
            <div>
              <v-btn class="rounded-lg" color="#e0e0e0" x-large elevation="0">
                Подробнее
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-column flex-sm-row mt-9" no-gutters>
        <v-col class="d-flex flex-row">
          <div>
            <v-img
              max-height="120"
              max-width="120"
              :src="require('@/assets/player_4.jpg')"
              class="rounded mr-4 mb-2 mb-sm-0"
            ></v-img>
          </div>
          <div class="d-flex flex-column">
            <span
              style="font-size: 1.5rem; font-weight: 500; line-height: 1.75rem"
              >Фамилия Имя Отчество</span
            >
            <span
              class="blue--text primary-text mt-2"
              style="text-decoration: none"
              >Возраст, город</span
            >
            <span class="grey--text primary-text" style="margin-top: 5px"
              >Должность</span
            >
          </div>
        </v-col>
        <v-col class="d-flex flex-row">
          <div>
            <v-img
              max-height="120"
              max-width="120"
              :src="require('@/assets/player_5.jpg')"
              class="rounded mr-4 mb-2 mb-sm-0"
            ></v-img>
          </div>
          <div class="d-flex flex-column">
            <span
              style="font-size: 1.5rem; font-weight: 500; line-height: 1.75rem"
              >Фамилия Имя Отчество</span
            >
            <span
              class="blue--text primary-text mt-2"
              style="text-decoration: none"
              >Возраст, город</span
            >
            <span class="grey--text primary-text" style="margin-top: 5px"
              >Должность</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-16 d-flex flex-column" no-gutters>
        <v-col
          ><span
            style="
              text-decoration: none;
              font-size: 1.5rem;
              line-height: 1.75rem;
              font-weight: 500;
            "
            >Документы</span
          ></v-col
        >
        <v-col class="mt-5"
          ><v-btn class="rounded-lg" color="#e0e0e0" x-large elevation="0">
            <v-icon dark right class="mr-2"> mdi-file-document-outline</v-icon>
            Регламент Открытого первенства г. Москвы среди команд юниоров
          </v-btn></v-col
        >
        <v-col class="mt-2">
          <v-btn class="rounded-lg" color="#e0e0e0" x-large elevation="0">
            <v-icon dark right class="mr-2"> mdi-file-document-outline</v-icon>
            Регламент Открытого первенства г. Москвы (2004-2008 г.р.)
          </v-btn>
        </v-col>
        <v-col class="mt-2">
          <v-btn class="rounded-lg" color="#e0e0e0" x-large elevation="0">
            <v-icon dark right class="mr-2"> mdi-file-document-outline</v-icon>
            Регламент Кубков г. Москвы по хоккею среди команд (2009-2011 г.р.)
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-16 d-flex flex-column" no-gutters>
        <v-col
          ><span
            style="
              text-decoration: none;
              font-size: 1.5rem;
              line-height: 1.75rem;
              font-weight: 500;
            "
            >Галерея</span
          ></v-col
        >
        <v-col class="mt-2 d-flex flex-wrap">
          <v-img
            v-for="(url, index) in this.camp.gallery"
            :key="index"
            max-height="186"
            max-width="282"
            :src="url"
            class="rounded mr-4 mt-6"
          ></v-img>
        </v-col>
      </v-row>
      <v-row
        class="mt-16 d-flex flex-column"
        style="margin-bottom: 148px"
        no-gutters
      >
        <v-col
          ><span
            style="
              text-decoration: none;
              font-size: 1.5rem;
              line-height: 1.75rem;
              font-weight: 500;
            "
            >Контакты</span
          ></v-col
        >
        <v-col cols="3" class="mt-5">
          {{ this.camp.contact }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ArenaMap from "@/components/Arena/ArenaMap";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ArenaMap,
  },
  props: ["id"],
  watch: {
    mapPoints() {
      this.key += 1;
    },
  },
  data() {
    return {
      cities: ["Москва", "Казань"],
      city: "Москва",
      campId: "",
      coords: [55.7539, 37.6208],
      key: 0,
      zoom: 10,
      search: "",
      options: {
        layout: "default#image",
        imageSize: [30, 40],
        contentOffset: [0, 0],
      },
      numFound: 0,
      selectedSurfaces: this.$selectedSurfaces,
      detail: this.$route.params.id,
      breadcrumb_items: "",
    };
  },
  computed: {
    ...mapState("camps", ["camp", "arenas"]),
  },
  mounted() {
    this.campId = this.id;
    this.getCamp(this.campId);
    this.getCampArenas(this.campId);
  },
  methods: {
    // goToArena(arenaId) {

    // },
    ...mapActions("camps", ["getCamp", "getCampArenas"]),
  },
  created() {
    this.breadcrumb_items = [
      {
        text: "Все лагеря",
        to: { name: "camps-fees" },
      },
      {
        text: this.camp.title,
        disabled: true,
        exact: true,
        to: "",
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.detail-banner {
  background: url("../../assets/banner-arena.jpg") no-repeat center center;
  background-size: cover;
}

@media (min-width: 600px) {
  .nav__item:not(:last-child) {
    margin: 0px 40px 0px 0px;
  }
}

p {
  margin: 0;
}

.btn {
  background-color: #e0e0e0;
}

.wrapper-map-main {
  background-color: #ccc;
  max-height: 400px;
  max-width: 894px;
}

.primary-text {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.primary__title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}
</style>
