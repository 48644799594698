<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()">
      <slot name="activator"></slot>
      <v-progress-linear
        class="mt-2 rounded-lg"
        :value="uploadPercentage"
        v-if="isUploading"
      ></v-progress-linear>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      type="file"
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange($event.target.name, $event.target.files)"
      style="display: none"
    />
    <!-- error dialog displays any potential error messages -->
    <v-dialog v-model="errorDialog" max-width="300">
      <v-card>
        <div class="text-center px-4 pt-6">{{ errorText }}</div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" @click="errorDialog = false" text>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isCropping" max-width="300">
      <v-card width="auto">
        <div class="example">
          <cropper ref="cropper" class="example-cropper" :src="image" />
          <drop-zone v-model="image" class="pb-15" />
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" @click="cropImage"> Сохранить </v-btn>
          </v-card-actions>
        </div></v-card
      >
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { GET_FILE } from "../../api";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { mapActions, mapState } from "vuex";

export default {
  name: "AdminImageUploader",
  components: {
    Cropper,
  },
  data: () => ({
    errorDialog: null,
    errorText: "",
    uploadFieldName: "file",
    maxSize: 1024,
    uploadPercentage: 0,
    isUploading: false,
    image: null,
    imageFile: null,
    isCropping: false,
  }),
  props: {
    value: Object,
  },
  computed: {
    ...mapState("file", ["croppedImg"]),
  },
  methods: {
    cropImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const formData = new FormData();
        canvas.toBlob(async (blob) => {
          const testFile = new File([blob], `${this.imageFile.name}`, {
            type: `${this.imageFile.type}`,
          });
          formData.append("file", testFile);
          const res = await axios.post(`${GET_FILE}`, formData, {
            onUploadProgress: function (progressEvent) {
              this.isUploading = true;
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          });
          this.setCroppedImg(res.data.fileUrl);
          this.$emit("input", {
            imageURL: this.image,
            name: this.imageFile,
          });
          this.isUploading = false;
          this.isCropping = false;
        });
      }
    },
    launchFilePicker() {
      console.log(this.$refs.file);
      this.$refs.file.click();
    },
    async getImageSize(imageDataUrl) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve({ w: img.width, h: img.height });
        img.onerror = reject;
        img.src = imageDataUrl;
      });
    },
    async onFileChange(fieldName, file) {
      const { maxSize } = this;
      let imageFile = file[0];
      if (file.length > 0) {
        let size = imageFile.size / maxSize / maxSize;
        if (!imageFile.type.match("image.*")) {
          // check whether the upload is an image
          this.errorDialog = true;
          this.errorText = "Пожалуйста, выберите файл изображения";
        } else if (size > 2) {
          // check whether the size is greater than the size limit
          this.errorDialog = true;
          this.errorText =
            "Ваш файл слишком велик! Пожалуйста, выберите изображение размером менее 2 МБ";
        } else {
          // Append file into FormData and turn file into image URL
          let formData = new FormData();
          formData.append("file", imageFile);
          this.imageFile = imageFile;

          const res = await axios.post(`${GET_FILE}`, formData, {
            onUploadProgress: function (progressEvent) {
              this.isUploading = true;
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          });

          this.image = res.data.fileUrl;
          this.isCropping = true;

          // const imageSize = await this.getImageSize(res.data.fileUrl);

          // if (imageSize.w < 400 || imageSize.h < 400) {
          //   this.errorDialog = true;
          //   this.errorText =
          //     "Изображение должно иметь размер не менее 400 точек в ширину и не менее 400 точек в высоту.";
          //   this.isUploading = false;
          // } else {
          //   this.isUploading = false;
          //   this.$emit("input", {
          //     imageURL: res.data.fileUrl,
          //     name: imageFile.name,
          //   });
          // }
        }
      }
    },
    ...mapActions("file", ["setCroppedImg"]),
  },
};
</script>

<style></style>
