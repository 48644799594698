<template>
  <div class="white" style="border-radius: 12px;">
    <v-container class="pt-0 pb-0">
        <v-row>
          <v-col cols="12">
            <v-tabs style="border-radius: 12px 12px 0px 0px;" fixed-tabs v-model="value" >
              <v-tab  v-for="item in application_nav" :key="item.value" @change="changeApplicationType(item.key)">
                {{ item.state }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
    </v-container>
    <v-container v-show="value == 0">
      <div v-if="applications.length == 0" class="d-flex flex-column  align-center">
        <div class="grey--text text-h4 mt-3">
          Этот раздел пустой
        </div>
        <div>
          <v-icon color="#E0E0E0" size="300">
            mdi-emoticon-cry-outline
          </v-icon>
        </div>
      </div>
        <div v-for="(item, i) in applications" :key="i">
          <div class="d-flex flex-no-wrap mt-4">
            <div class="pr-4">
                <v-avatar style="border-radius: 50%"  size="140">
                <v-img
                    :src="
                    item.user.profilePicture
                        ? item.user.profilePicture
                        : require('@/assets/unknown.jpeg')
                    "
                ></v-img>
                </v-avatar>
            </div>
            <div>
                <p class="text-h4 mb-1">{{ item.user.surname }} {{ item.user.name }} {{ item.user.middleName }}</p>
                <p class="text-h5 mb-1">
                    {{ item.user.city ? item.user.city : "" }}
                    {{ ", " + item.user.age ? item.user.age : "" }}
                </p>
                <div v-for="(role, r) in item.user.roles" :key="r">
                    <div v-if="role.name == 'PLAYER'">
                        <p class="text-h6 mb-0" style="font-weight: normal;">
                            {{ role.position ? role.position : "" }}
                        </p>
                    </div>
                </div>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column align-center">
                <v-btn
                @click="dealWithApplication(item.user.id, true)"
                class="rounded-lg mb-2"
                max-height="40"
                block
                dark
                color="#0681C8"
                depressed>Принять</v-btn>
                <v-btn
                @click="dealWithApplication(item.user.id, false)"
                class="rounded-lg mb-2"
                max-height="40"
                block
                dark
                color="#045A8C"
                depressed>Отклонить</v-btn>
                <v-btn
                @click="$router.push({ name: 'room-player-card', params: { playerId: item.user.id}})"
                class="rounded-lg"
                max-height="40"
                block
                color="#C3E1F2"
                depressed>Подробнее</v-btn>
            </div>
          </div>
        </div>
    </v-container>
    <v-container v-show="value == 1 || value == 2">
      <div v-if="applications.length == 0" class="d-flex flex-column  align-center">
        <div class="grey--text text-h4 mt-3">
          Этот раздел пустой
        </div>
        <div>
          <v-icon color="#E0E0E0" size="300">
            mdi-emoticon-cry-outline
          </v-icon>
        </div>
      </div>
        <div v-for="(item, i) in applications" :key="i">
          <div class="d-flex flex-no-wrap mt-4">
            <div class="pr-4">
                <v-avatar style="border-radius: 50%"  size="140">
                <v-img
                    :src="
                    item.user.profilePicture
                        ? item.user.profilePicture
                        : require('@/assets/unknown.jpeg')
                    "
                ></v-img>
                </v-avatar>
            </div>
            <div>
                <p class="text-h4 mb-1">{{ item.user.surname }} {{ item.user.name }} {{ item.user.middleName }}</p>
                <p class="text-h5 mb-1">
                    {{ item.user.city ? item.user.city : "" }}
                    {{ ", " + item.user.age ? item.user.age : "" }}
                </p>
                <div v-for="(role, r) in item.user.roles" :key="r">
                    <div v-if="role.name == 'PLAYER'">
                        <p class="text-h6 mb-0" style="font-weight: normal;">
                            {{ role.position ? role.position : "" }}
                        </p>
                    </div>
                </div>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column align-center">
                <v-btn
                @click="$router.push({ name: 'room-player-card', params: { playerId: item.user.id}})"
                class="rounded-lg"
                max-height="40"
                block
                color="#C3E1F2"
                depressed>Подробнее</v-btn>
            </div>
          </div>
        </div>
    </v-container>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_TEAM, ADD_USER_TO_TEAM, GET_USER_BY_ID } from "@/api";

export default {
  name: "AdminTeamApplicationForms",
  data() {
    return {
      application_nav: [
        {
          state: "Активные",
          value: 0,
          key: "waiting"
        },
        {
          state: "Одобренные",
          value: 1,
          key: "accepted"
        },
        {
          state: "Отклоненные",
          value: 2,
          key: "refused"
        },
      ],
      value: 0,
      approved: "waiting",
      applications: [],
      userId: "",
      applicationId: "",
      isApproved: false,
      isapproved: "",
      userAdminRoleId: "",
      userPlayerRoleId: "",
      userTrainerRoleId: "",
    }
  },
  created(){
    this.getActiveApplications()
  },
  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
  },
  methods: {
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "PLAYER"){
            this.userPlayerRoleId = item.id
          } 
          if(item.name == "TEAM_ADMIN"){
            this.userPlayerRoleId = item.id
          } 
          if(item.name == "TRAINER"){
            this.userPlayerRoleId = item.id
          } 
        });
        this.getApplicationId()()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    changeApplicationType(data){
      this.approved = data
      this.getActiveApplications()
    },

    async getActiveApplications(){
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=${this.approved}&role=PLAYER`)
      .then( (res) => {
        this.applications = res.data
        console.log(this.applications)
      })
      .catch((error) => {
        console.error(error);
      })
    },

    dealWithApplication(id, action){
      this.userId = id
      this.isApproved = action
      this.getUserRoles()
    },

    async getApplicationId(){
      await Axios.get(`${ADD_USER_TO_TEAM}?teamId=${this.teamId}&userId=${this.userId}`)
      .then( (res) => {
        this.applicationId = res.data.id
        this.putApplication()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async putApplication(){
      const appId = this.applicationId
      if(this.isApproved == true){
        this.isapproved = "accepted"
      } else {
        this.isapproved = "refused"
      }
      const data = {
        teamId: this.teamId,
        userId: this.userId,
        roleId: this.userPlayerRoleId,
        visibility: 1,
        approved: this.isapproved
      }
        await Axios.put(`${ADD_USER_TO_TEAM}?id=${appId}`, data)
        .then( () => {
          this.getActiveApplications()
        })
        .catch((error) => {
          console.error(error);
        })
    },

    toPlayerCard(id){
      this.$router.push({ path: `room/${id}` })
    },
  },
  watch:{
    '$route.params.teamId'() {
      this.value = 0
      this.approved = "waiting"
      this.getActiveApplications()
    }
  },
}
</script>
<style scoped>
.active_tab{
  color: #000;
  background-color: #C3E1F2;
  border-radius: 12px 12px 0px 0px;
}
</style>
