<template>
  <div>
    <div class="white" style="border-radius: 12px">
      <v-container>
        <!-- <v-row class="">
        <div>
          <v-breadcrumbs :items="breadcrumb_items" class="px-3"></v-breadcrumbs>
        </div>
      </v-row> -->
        <v-row>
          <v-col cols="4" md="3">
            <admin-image-uploader v-model="avatar">
              <div slot="activator">
                <div v-if="!avatar" class="white rounded-xl pa-4">
                  <v-avatar
                    width="100%"
                    height="200"
                    v-ripple
                    tile
                    class="white rounded-xl"
                  >
                    <div class="upload-border rounded-xl pa-4">
                      <div class="my-4">
                        <v-icon large color="#379AD3"
                          >mdi-cloud-upload-outline</v-icon
                        >
                      </div>
                      <div class="body-1 mb-2 font-weight-bold">
                        Загрузите логотип
                      </div>
                      <div class="body-2 mb-4 grey--text">
                        Поддерживаемые форматы: PNG, JPG
                      </div>
                    </div>
                  </v-avatar>
                </div>
                <div v-else class="white rounded-xl pa-4">
                  <v-avatar width="100%" height="200" tile v-ripple>
                    <v-img
                      class="ma-10 rounded-xl"
                      :src="avatar.imageURL"
                      alt="avatar"
                      cover
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
            </admin-image-uploader>
          </v-col>
          <v-col>
            <AdminSocialMedia :items="social_media"></AdminSocialMedia>
          </v-col>
        </v-row>
        <div class="d-flex flex-column">
          <div class="text-h5 mb-7" style="font-weight: 500">
            Персональные данные
          </div>
          <div class="mb-5">
            <v-text-field
              label="Фамилия"
              autocomplete="new-password"
              solo
              background-color="#F5F5F5"
              flat
              hide-details="auto"
              v-model.trim="user.surname"
              class="rounded-lg"
              :rules="[$v.user.surname.required]"
              @blur="$v.user.surname.$touch()"
            ></v-text-field>
            <template v-if="$v.user.surname.$error">
              <p v-if="!$v.user.surname.required" class="error--text mb-0">
                Фамилия - обязательное поле
              </p>
            </template>
          </div>
          <div class="mb-5">
            <v-text-field
              label="Имя"
              autocomplete="new-password"
              v-model.trim="user.name"
              solo
              background-color="#F5F5F5"
              flat
              hide-details="auto"
              class="rounded-lg"
              :rules="[$v.user.name.required]"
              @blur="$v.user.name.$touch()"
            ></v-text-field>
            <template v-if="$v.user.name.$error">
              <p v-if="!$v.user.name.required" class="error--text mb-0">
                Имя - обязательное поле
              </p>
            </template>
          </div>
          <div class="mb-5">
            <v-text-field
              label="Отчество"
              solo
              background-color="#F5F5F5"
              aria-autocomplete="off"
              v-model="user.middleName"
              autocomplete="new-password"
              flat
              hide-details="auto"
              class="rounded-lg"
              :rules="[$v.user.middleName.required]"
              @blur="$v.user.middleName.$touch()"
            ></v-text-field>
            <template v-if="$v.user.middleName.$error">
              <p v-if="!$v.user.middleName.required" class="error--text mb-0">
                Отчество - обязательное поле
              </p>
            </template>
          </div>
          <div class="mb-5">
            <v-text-field
              label="Номер телефона"
              autocomplete="new-password"
              solo
              background-color="#F5F5F5"
              v-model="user.phone"
              flat
              type="tel"
              placeholder="+9 (999) 999-9999"
              hide-details="auto"
              class="rounded-lg"
              maxlength="17"
              :rules="[$v.user.phone.required, $v.user.phone.exactLength]"
              @blur="$v.user.phone.$touch()"
              @input="enforcePhoneFormat"
            ></v-text-field>
            <template v-if="$v.user.phone.$error">
              <p v-if="!$v.user.phone.required" class="error--text mb-0">
                Номер телефона - обязательное поле
              </p>
              <p v-if="!$v.user.phone.exactLength" class="error--text mb-0">
                Пример: +9 (999) 999-9999
              </p>
            </template>
          </div>
          <div>
            <v-text-field
              autocomplete="new-password"
              label="Электронная почта"
              solo
              background-color="#F5F5F5"
              v-model="user.mail"
              flat
              hide-details="auto"
              class="rounded-lg"
              :rules="[$v.user.mail.required, $v.user.mail.email]"
              @blur="$v.user.mail.$touch()"
            ></v-text-field>
            <template v-if="$v.user.mail.$error">
              <p v-if="!$v.user.mail.required" class="error--text mb-0">
                Электронная почта - обязательное поле
              </p>
              <p v-if="!$v.user.mail.email" class="error--text mb-0">
                Пример: abc@yourcompany.com
              </p>
            </template>
          </div>
          <div class="d-flex mb-5">
            <div class="mt-5 mr-5" style="font-weight: 500">Пол:</div>
            <div>
              <v-radio-group
                v-model="user.gender"
                :rules="[$v.user.gender.required]"
                row
                hide-details="auto"
              >
                <v-radio label="Мужской" value="Мужской"></v-radio>
                <v-radio label="Женский" value="Женский"></v-radio>
              </v-radio-group>
              <template v-if="$v.user.gender.$error">
                <p v-if="!$v.user.gender.required" class="error--text mb-0">
                  Пол нужен
                </p>
              </template>
            </div>
          </div>
        </div>
        <v-row class="">
          <v-col cols="12" md="6" class="mb-2">
            <AppSelectDatePickerEditUser
              :nudgeLeft="0"
              :dense="false"
              :date.sync="user.birthDate"
              label="Дата рождения"
            />
          </v-col>
          <v-col cols="12" md="6" class="mb-2">
            <v-select
              :items="cities"
              v-model="user.city"
              placeholder="Город"
              solo
              background-color="#F5F5F5"
              flat
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <div class="mt-5">
          <v-btn
            large
            class="rounded-lg"
            color="primary"
            elevation="0"
            @click="modifyUser"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="ml-3 rounded-lg"
            large
            color="grey lighten-2"
            elevation="0"
            @click="
              $router.push({
                name: 'user-profile',
                params: { userId },
              })
            "
          >
            назад
          </v-btn>
        </div>
      </v-container>

      <v-dialog v-model="feedback_dialog" max-width="600">
        <v-card class="py-3">
          <v-card-title class="justify-space-between mb-5">
            <div class="body-1 black--text">
              Данный номер или/и электронная почта уже используются
            </div>
            <div class="mb-4">
              <v-icon @click.stop="feedback_dialog = false">mdi-close</v-icon>
            </div>
          </v-card-title>
          <v-card-actions class="mt-n6">
            <v-btn class="body-2 px-4" elevation="0">
              Восстановить доступ</v-btn
            >
            <v-spacer></v-spacer>
            <!-- <v-btn elevation="0" color="primary" class="body-2 px-4">
            Обратиться в тех.поддержку
          </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="white mt-4" style="border-radius: 12px">
      <v-container>
        <div class="text-h5 mb-5" style="font-weight: 500">Изменить пароль</div>
        <div class="d-flex flex-column">
          <div class="mb-5">
            <v-text-field
              v-model="mail"
              :rules="[rules.required]"
              label="Электронная почта"
              solo
              background-color="#F5F5F5"
              persistent-hint
              flat
              hide-details="auto"
              class="rounded-lg"
            >
            </v-text-field>
          </div>
          <div>
            <v-btn
              elevation="0"
              color="primary"
              large
              class="rounded-lg"
              @click="sendCode"
            >
              Отправить код
            </v-btn>
          </div>
        </div>
      </v-container>
      <v-dialog v-model="changePassword" origin="" max-width="700">
        <v-card class="py-3">
          <v-card-title class="justify-center">
            <div class="text-h4 black--text font-weight-bold">
              Восстановить доступ
            </div>
            <v-icon
              color="#000"
              size="30"
              style="position: absolute; top: 28px; right: 30px"
              @click="changePassword = false"
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            <p class="body-1 mb-1 font-weight-bold">
              На вашу почту был отправлен код сброса пароля
            </p>
            <div>
              <div class="mb-5">
                <v-text-field
                  v-model="passCode"
                  :rules="[$v.passCode.required]"
                  @blur="$v.passCode.$touch()"
                  placeholder="Введите код"
                  solo
                  background-color="#F5F5F5"
                  persistent-hint
                  flat
                  hide-details="auto"
                  class="rounded-lg"
                >
                </v-text-field>
                <template v-if="$v.passCode.$error">
                  <div>
                    <span v-if="!$v.passCode.required" class="error--text mb-0">
                      Обязательное поле
                    </span>
                  </div>
                </template>
              </div>
              <v-text-field
                v-model="password"
                flat
                solo
                placeholder="Введите новый пароль"
                background-color="#F5F5F5"
                hide-details="auto"
                class="rounded-lg"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  $v.password.required,
                  $v.password.minLength,
                  $v.password.containSymbol,
                  $v.password.containNumber,
                  $v.password.containBigLetter,
                ]"
                @blur="$v.password.$touch()"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              ></v-text-field>
              <template v-if="$v.password.$error">
                <div>
                  <span v-if="!$v.password.required" class="error--text mb-0">
                    Пароль - обязательное поле
                  </span>
                  <span v-if="!$v.password.minLength" class="error--text mb-0">
                    Пароль должен быть длиннее 5 символов.
                  </span>
                  <span
                    v-if="!$v.password.containSymbol"
                    class="error--text mb-0"
                  >
                    Пароль должен содержать любой из этих символов !@#$%^&.
                  </span>
                  <span
                    v-if="!$v.password.containNumber"
                    class="error--text mb-0"
                  >
                    Пароль должен содержать хотябы одну цифру.
                  </span>
                  <span
                    v-if="!$v.password.containBigLetter"
                    class="error--text mb-0"
                  >
                    Пароль должен содержать хотябы одну букву верхнего регистра.
                  </span>
                </div>
              </template>
              <div class="mt-6">
                <v-btn
                  elevation="0"
                  color="primary"
                  large
                  class="rounded-lg"
                  @click="verifyCode"
                >
                  изменить пароль
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import { required, email, helpers, minLength } from "vuelidate/lib/validators";
const containSymbol = helpers.regex("containSymbol", /[!@#$%^&]/);
const containNumber = helpers.regex("containNumber", /[1-9]/);
const containBigLetter = helpers.regex("containBigLetter", /[A-Z]/);

import { mapState } from "vuex";

export default {
  components: {
    AdminImageUploader,
    AdminSocialMedia,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  created() {
    const userId = this.userId;
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId } },
      },
      {
        text: "изменить пользователя",
        disabled: true,
        exact: true,
        to: "",
      },
    ];
    const user = this.currentUser;
    const { gender, surname, middleName, name, phone, mail, birthDate, city } =
      user;

    this.user = {
      gender,
      surname,
      middleName,
      name,
      phone,
      mail,
      birthDate,
      city,
    };
    this.avatar = user.profilePicture
      ? { imageURL: user.profilePicture }
      : null;
    this.social_media[0].link = user.vk;
    this.social_media[1].link = user.whatsApp;
    this.social_media[2].link = user.website;
    this.social_media[3].link = user.instagram;
    this.social_media[4].link = user.facebook;
  },
  computed: {
    ...mapState("user", { currentUser: "user" }),
    ...mapState("file", ["croppedImg"]),
    social_media_display() {
      return this.social_media.filter((x) => x.link);
    },
    profilePicture() {
      return !this.avatar ? "" : this.avatar.imageURL;
    },
  },
  data() {
    return {
      moderatorId: "ee163205-6897-4df9-987b-610f0616e519",
      changePassword: false,
      password: "",
      mail: "",
      passCode: "",
      rules: {
        required: (value) => !!value || "Обязательное поле",
      },
      showPass: false,
      repeatPassword: "",
      user: {
        gender: "",
        name: "",
        middleName: "",
        surname: "",
        phone: "",
        mail: "",
        birthDate: "",
        city: "",
      },
      cities: ["Москва"],
      feedback_dialog: false,

      avatar: null,
      social_media_dialog: false,
      toggle_social_media: null,
      social_media_text: "",
      errMessage: "",
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: "",
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
        {
          id: 4,
          name: "instagram",
          link: "",
          icon: "mdi-instagram",
        },
        {
          id: 5,
          name: "facebook",
          link: "",
          icon: "mdi-facebook",
        },
      ],
      breadcrumb_items: [],
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(5),
      containSymbol,
      containNumber,
      containBigLetter,
    },
    passCode: { required },
    user: {
      gender: { required },
      name: { required },
      middleName: { required },
      surname: { required },
      phone: {
        required,
        exactLength: (value) => {
          if (value.length === 17) return true;
          return false;
        },
      },
      mail: { required, email },
      city: { required },
    },
    repeatPassword: {
      required,
      sameAsPassword: function (value) {
        return this.user.password === value;
      },
    },
  },
  methods: {
    modifyUser() {
      let whatsapp = "";
      if (this.social_media[1].link) {
        whatsapp = `https://wa.me/${this.social_media[1].link
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")}`;
      }
      const userParams = {
        ...this.user,
        profilePicture: this.profilePicture,
        editedProfilePicture: this.croppedImg,
        instagram: this.social_media[3].link,
        vk: this.social_media[0].link,
        website: this.social_media[2].link,
        whatsApp: whatsapp,
        facebook: this.social_media[4].link,
      };

      console.log("UserParams", userParams);

      this.$store
        .dispatch("auth/updateUser", { userId: this.userId, user: userParams })
        .then(() => {
          if (this.userId === this.moderatorId) {
            this.$router.push({
              name: "moderator-profile",
              params: { userId: this.userId },
            });
          } else {
            this.$router.push({
              name: "user-profile",
              params: { userId: this.userId },
            });
          }
        })
        .catch(() => {});
    },
    removeSocialMedia(item) {
      console.log(item);
      item.link = "";
    },
    addSocialMedia() {
      console.log(this.toggle_social_media);
      const link = this.social_media[this.toggle_social_media].link;
      if (link === "") {
        this.social_media[this.toggle_social_media].link =
          this.social_media_text;
        this.social_media_dialog = false;
      } else {
        this.errMessage = "Ссылка уже существует";
      }
      this.social_media_text = "";
    },
    enforcePhoneFormat() {
      let x = this.user.phone
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);

      let value = !x[2]
        ? x[1]
        : x[1] + " " + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "");
      this.user.phone = "+" + value;
    },
    sendCode() {
      this.$store
        .dispatch("auth/sendConfirmationCode", this.mail)
        .then(() => {
          this.changePassword = true;
        })
        .catch(() => {});
    },
    verifyCode() {
      const credentials = {
        email: this.mail,
        code: this.passCode,
        password: this.password,
      };

      this.$store
        .dispatch("auth/changePassword", credentials)
        .then(() => {
          this.changePassword = false;
          this.$router.push({
            name: "login",
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped></style>
