<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="justify-center">
        <v-col cols="12" lg="10" xl="8">
          <v-row class="">
            <div>
              <v-breadcrumbs
                :items="breadcrumb_items"
                class="px-3"
              ></v-breadcrumbs>
            </div>
          </v-row>
          <div>
            <div class="text-h5 font-weight-bold pb-3 pt-5">Информация</div>

            <div class="text-h6 mb-4">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Имя лагеря"
                v-model="title"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-text-field
                label="Город"
                v-model="city"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-text-field
                label="Вступительный взнос"
                v-model="entranceFees"
                outlined
                flat
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <v-row>
              <v-col cols="4">
                <AppSelectDatePickerSolo
                  label="Дата начала сбора"
                  :date.sync="startDate"
                />
              </v-col>
              <v-col cols="4">
                <AppSelectDatePickerSolo
                  label="Дата окончания сбора"
                  :date.sync="endDate"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="uiState.agesList"
                  v-model="age"
                  dense
                  single-line
                  outlined
                  hide-details
                  label="2002"
                  class="ma-2 white"
                ></v-select>
              </v-col>
            </v-row>
            <AdminSelectLocation
              :address="address"
              :city="city"
              :coordinate="coordinate"
              :metro="metro"
              @update-metro="
                (value) => {
                  metro = value;
                }
              "
              @update-coords="
                (value) => {
                  coordinate = value;
                }
              "
              @update-address="
                (value) => {
                  address = value;
                }
              "
              @update-city="
                (value) => {
                  city = value;
                }
              "
            />
            <div class="mb-4 mt-4">
              <div class="text-h6 mb-4">Основное изображение турнира</div>
              <v-row>
                <v-col class="pa-2" cols="6" sm="4" md="3">
                  <AdminImageUploader v-model="avatar">
                    <div slot="activator">
                      <div v-if="!avatar" class="white rounded-xl pa-4">
                        <v-avatar
                          width="100%"
                          height="200"
                          v-ripple
                          tile
                          class="white rounded-xl"
                        >
                          <div class="upload-border rounded-xl pa-4">
                            <div class="my-4">
                              <v-icon large color="#379AD3"
                                >mdi-cloud-upload-outline
                              </v-icon>
                            </div>
                            <div class="body-1 mb-2 font-weight-bold">
                              Загрузите логотип
                            </div>
                            <div class="body-2 mb-4 grey--text">
                              Поддерживаемые форматы: PNG, JPG
                            </div>
                          </div>
                        </v-avatar>
                      </div>
                      <div v-else class="white rounded-xl pa-4">
                        <v-avatar width="100%" height="200" tile v-ripple>
                          <v-img
                            class="ma-10 rounded-xl"
                            :src="avatar.imageURL"
                            alt="avatar"
                            cover
                          ></v-img>
                        </v-avatar>
                      </div>
                    </div>
                  </AdminImageUploader>
                </v-col>
              </v-row>
            </div>
            <div>
              <div class="text-h6 mb-2">Описание</div>
              <v-textarea
                solo
                v-model="description"
                name=""
                flat
                elevation="0"
              ></v-textarea>
            </div>
            <!-- <div class="mb-4">
              <AdminSocialMedia :items="social_media"></AdminSocialMedia>
            </div>
            <div class="mb-6">
              <AdminContactForm
                :getContact="getContact"
                :contact="contacts"
              ></AdminContactForm>
            </div>
            <div class="mb-6">
              <AdminTournamentArena />
            </div>
            <div class="mb-6">
              <AdminGallery :items="galleryPics"></AdminGallery>
            </div> -->
          </div>
          <div class="d-flex">
            <v-btn
              @click="saveNewCamp"
              large
              elevation="0"
              color="primary"
              class="body-2 px-6 mr-2"
            >
              Далее
            </v-btn>
            <v-btn
              color="grey lighten-2"
              large
              class="body-2 px-6 ml-2"
              elevation="0"
              @click="
                $router.push({
                  name: 'user-profile',
                  params: { userId },
                })
              "
            >
              Отменить
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import TestImageUploader from "@/components/Admin/TestImageUploader.vue";
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminSelectLocation from "@/components/Admin/AdminSelectLocation.vue";
// import AdminGallery from "@/components/Admin/AdminGallery.vue";
// import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import AppSelectDatePickerSolo from "../../../../components/AppUnit/AppSelectDatePickerSolo";
// import AdminContactForm from "@/components/Admin/AdminContactForm.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { GET_USER_BY_ID } from "@/api";

export default {
  components: {
    // TestImageUploader,
    // AdminSocialMedia,
    // AdminContactForm,
    // AdminGallery,
    AdminSelectLocation,
    AdminImageUploader,
    AppSelectDatePickerSolo,
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },

    search(input) {
      input && input !== this.address && this.findCoordinateGivenAddress(input);
    },
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getUserRoles();
    const userId = this.userId;
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId } },
      },
      {
        text: "Создать лагерь",
        disabled: true,
        exact: true,
        to: "",
      },
    ];
  },

  computed: {
    ...mapState("file", ["croppedImg"]),

    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },

  data() {
    return {
      coordinate: {
        lat: "55.753336",
        lon: "37.623084",
      },
      title: "",
      city: "",
      address: "",
      startDate: "",
      endDate: "",
      age: "",
      description: "",
      contact: "",
      entranceFees: "",

      campRoleId: "",

      uiState: {
        agesList: [
          2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
          2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
        ],
      },

      isLoading: false,
      type_of_tournament: ["Лига", "Турнир / Фестиваль", "Детский турнир"],
      galleryPics: [],
      map: {},
      shortTitle: "",
      metro: [],
      ageParticipant: "",
      avatar: null,
      tag_chips: [],
      checkbox: null,
      // contacts: "",
      // social_media: [
      //   {
      //     id: 1,
      //     name: "vk",
      //     link: "",
      //     icon: "mdi-alpha-k-box-outline",
      //   },
      //   {
      //     id: 2,
      //     name: "whatsapp",
      //     link: "",
      //     icon: "mdi-whatsapp",
      //   },
      //   {
      //     id: 3,
      //     name: "web",
      //     link: "",
      //     icon: "mdi-web",
      //   },
      // ],
      breadcrumb_items: [],
    };
  },
  methods: {
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },

    async saveNewCamp() {
      const state = {
        title: this.title,
        description: this.description,
        logo: !this.avatar ? "" : this.avatar.imageURL,
        editedLogo: this.croppedImg,
        // gallery: this.galleryPics,
        city: this.city,
        startDate: this.startDate,
        endDate: this.endDate,
        age: Number(this.age),
        address: this.address,
        entranceFees: this.entranceFees,
        // contact: this.contacts,
        // whatsApp: this.social_media[1].link,
        // vk: this.social_media[0].link,
        // website: this.social_media[2].link,
      };

      const res = await this.postCamp(state);

      const userCampId = {
        campId: res.id,
        userId: this.userId,
        roleId: this.campRoleId,
      };

      await this.createUserCamp({ userCampId, camp: res });

      this.$router.push({
        name: "addition-admin-info",
        params: { userId: this.userId, campId: res.id },
      });
    },

    async getUserRoles() {
      const { data } = await axios.get(`${GET_USER_BY_ID}${this.userId}/roles`);
      data.forEach(({ name, id }) => {
        if (name === "TRAINER") {
          this.campRoleId = id;
        }
      });
    },

    getContact(data) {
      this.contacts = data.contact;
    },

    ...mapActions("camps", ["postCamp"]),
    ...mapActions("user", ["createUserCamp"]),
  },
};
</script>

<style></style>
