<template>
  <!--  <v-flex xs6 lg3 md4 sm5>-->
  <!--    <v-col>-->
  <!--      <v-card-->
  <!--      >-->
  <!--        <v-img-->
  <!--          :src="administrator.user.profilePicture ? administrator.user.profilePicture : require('@/assets/avatar.jpg')"-->
  <!--          height="200px"-->
  <!--        ></v-img>-->

  <!--        <v-card-title>-->
  <!--          {{ administrator.user.name }} {{ administrator.user.middleName }}-->
  <!--        </v-card-title>-->

  <!--&lt;!&ndash;        <v-card-subtitle class="text-body-1 pb-0">&ndash;&gt;-->
  <!--&lt;!&ndash;          {{ administrator.user.name }} {{ administrator.user.middleName }}&ndash;&gt;-->
  <!--&lt;!&ndash;        </v-card-subtitle>&ndash;&gt;-->
  <!--        <v-card-subtitle class="text-body-1 ma-0 pt-0 pb-0">-->
  <!--          {{ administrator.user.phone }}-->
  <!--        </v-card-subtitle>-->

  <!--        <v-card-subtitle class="text-body-1 ma-0 pt-0">-->
  <!--          {{ administrator.user.mail }}-->
  <!--        </v-card-subtitle>-->

  <!--        <v-expand-transition>-->
  <!--          <div v-show="show">-->
  <!--            <v-divider></v-divider>-->

  <!--            <v-card-text>-->
  <!--              I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for-->
  <!--              sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey,-->
  <!--              you-->
  <!--              add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to-->
  <!--              escape.-->
  <!--            </v-card-text>-->
  <!--          </div>-->
  <!--        </v-expand-transition>-->
  <!--      </v-card>-->
  <!--    </v-col>-->
  <!--  </v-flex>-->
  <v-row dense class="">
    <v-col cols="12" md="6">
        <v-card color="transparent" elevation="0" min-width="500px">
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3 rounded-lg" size="125" tile>
              <v-img
                :src="
                      administrator.user.profilePicture.length
                        ? administrator.user.profilePicture
                        : require('@/assets/avatar.jpg')
                    "
              ></v-img>
            </v-avatar>
            <v-card-text>
              <div class="mb-2 font-weight-regular text-h6">
                {{
                  administrator.user.surname +
                  " " +
                  administrator.user.name +
                  " " +
                  administrator.user.middleName
                }}
              </div>
              <div class="body-1 blue--text mb-2">
                {{ administrator.user.city ? administrator.user.city : "" }}
              </div>

            </v-card-text>
          </div>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LeagueAdministration",
  props: {
    administrator: Object,
  },
  data() {
    return {
      width: 0,
    };
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.card {
  flex: 1 0 21%;
}
</style>
