<template>
  <div class="grey lighten-4">
    <v-container class="pt-8 pb-0">
      <v-row>
        <div>
          <v-breadcrumbs :items="breadcrumb_items" class="px-3"></v-breadcrumbs>
        </div>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <p class="text-h5">Выбранные арены</p>
          <p class="grey--text" v-show="!selected_arenas.length">
            Нет выбранных арен. Чтобы добавить арену, нажмите на значок добавить
            на карточке арены.
          </p>
          <v-row>
            <v-col cols="4" v-for="(arena, i) in selected_arenas" :key="i">
              <ArenaChosen
                :arena="arena"
                :index="i"
                @arenaRemoved="reInitCalendar"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn
        @click="add_arena_dialog = true"
        color="primary"
        elevation="0"
        >Добавить арену
      </v-btn>
      <!-- <v-btn
        @click="$router.push({ name: 'arena' })"
        color="primary"
        elevation="0"
        >Добавить арену
      </v-btn> -->
    </v-container>
    <v-container class="mt-10">
      <p class="text-h4">Расписание мероприятий</p>
      <div class="rounded-lg white">
        <v-sheet
          tile
          height="54"
          class="d-flex align-center py-6"
          color="#EBF5FB"
        >
          <div style="width: 200px" class="ml-3">
            <v-menu
              v-model="date_picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  single-line
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="date_picker = false"
                locale="ru-RU"
              ></v-date-picker>
            </v-menu>
          </div>
          <!-- <div style="width: 200px">
            <v-select
              v-model="mode"
              :items="mode_lesson"
              dense
              single-line
              outlined
              hide-details
              label="Все виды занятий"
              class="ma-2 white"
            ></v-select>
          </div> -->

          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="600" class="px-4 pb-4">
          <v-calendar
            ref="calendar"
            v-model="date"
            :weekdays="weekday"
            locale="ru-RU"
            type="category"
            category-show-all
            @click:event="showEvent"
            :categories="categories"
            :events="ievents"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :interval-format="intervalFormat"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar elevation="0" :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.description"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </div>
    </v-container>
    <v-dialog v-model="add_arena_dialog" max-width="600">
      <v-card class="grey lighten-5">
        <v-card-title class="py-3">
          <v-row>
            <v-col cols="11">
              <div class="text-h5 black--text">Добавить арену</div>
            </v-col>
            <v-col>
              <div class="mb-4">
                <v-icon @click.stop="add_arena_dialog = false"
                  >mdi-close</v-icon
                >
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selected_arena"
            :items="arenas"
            :loading="is_searching"
            color="white"
            solo
            flat
            single-line
            hide-selected
            hide-no-data
            item-text="title"
            item-value="id"
            label="Поиск арены по названию"
            placeholder="Поиск арены по названию"
            prepend-inner-icon="mdi-magnify"
            return-object
            hide-details="auto"
            class="rounded-lg"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-btn
            class="body-2"
            @click="add_arena_dialog = false"
            elevation="0"
          >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="body-2"
            @click="addArena"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ArenaChosen from "@/components/Arena/ArenaChosen.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: { ArenaChosen },
  computed: {
    ...mapState("arena", ["selected_arenas", "selected_events", "arenas"]),
    ievents() {
      let events = [];
      // const arenaCount = this.selected_arenas.length
      // for (let i = 0; i < arenaCount; i++) {
      //   this.selected_events[i].forEach((event) => {
      //     const nEvents = this.generateEvents(event);
      //     events.push(...nEvents);
      //   })
      // }
      // console.log(events)
      events = this.generateEvents()
      return events;
    },
  },
  data() {
    return {
      add_arena_dialog: false,
      is_searching: false,
      selected_arena: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      breadcrumb_items: [
        {
          text: "Ледовые дворцы и арены",
          disabled: false,
          to: "/arena",
        },
        {
          text: "Расписание мероприятий",
          disabled: true,
          to: "/event_schedule",
        },
      ],
      dialog: false,
      currentDay: "23.09.2021, пн",
      mode: "column",
      mode_lesson: ["0", "1", "2"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      value: "",
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey lighten-1",
      ],
      categories: [],
      date_picker: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    intervalFormat(interval) {
      return interval.time;
    },
    
    generateEvents() {
      let _events = []
      const arenaCount = this.selected_arenas.length;
      for (let i = 0; i < arenaCount; i++) {
        const eventCount = this.selected_events[i]
          ? this.selected_events[i].length
          : 0;
        for (let j = 0; j < eventCount; j++) {
          const event = this.selected_events[i][j]
          const selectedDays = event.days;
          const startDate = event.startDate,
            endDate = event.endDate,
            startTime = event.startTime,
            endTime = event.endTime,
            title = event.title,
            description = event.description,
            repeat = event.repeat;
          const color = this.colors[this.rnd(0, this.colors.length - 1)];
          const id = event.id
          const startMoment = moment(
            `${startDate}T${startTime ? startTime : "00:00"}:00`
          );
          const endMoment = moment(`${endDate}T${endTime ? endTime : "00:00"}:00`);
          for (let a = 0; a < 7; a++) {
            for (let b = 0; b < selectedDays.length; b++) {
              if (Number(startMoment.day()) === selectedDays[b]) {
                const startMomentDate = startMoment.format("YYYY-MM-DDTHH:mm:ss");
                const beginMoment = moment(startMomentDate);

                while (
                  Math.ceil(endMoment.diff(beginMoment, "weeks", true)) !== 0
                ) {
                  const stopInterval = beginMoment.format("YYYY-MM-DD");
                  const stopMoment = moment(
                    `${stopInterval}T${endTime ? endTime : "00:00"}:00`
                  );
                  _events.push({
                    name: title,
                    start: beginMoment.format("YYYY-MM-DDTHH:mm:ss"),
                    end: stopMoment.format("YYYY-MM-DDTHH:mm:ss"),
                    color: color,
                    timed: true,
                    description: description,
                    id: id,
                    category: this.categories[i],
                  });
                  if (repeat === 0) break;
                  beginMoment.add(1, "weeks");
                }

                break;
              }
            }
            startMoment.add(1, "days");
          }

          // _events.push({
          //   name: event.title,
          //   start: "2022-03-31T12:00:00",
          //   end: "2022-03-31T14:00:00",
          //   color: this.colors[this.rnd(0, this.colors.length - 1)],
          //   timed: true,
          //   description: event.description,
          //   category: this.categories[i],
          // });
        }
      }
      this.events = _events;
      return this.events
    },
    reInitCalendar() {
      this.categories = this.selected_arenas.map((x) => x.title);
      this.generateEvents();
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    addArena(){
      // console.log(this.selected_arena)
      this.$store.dispatch("arena/addToSelectedArenas", this.selected_arena).then(() => {
        this.reInitCalendar()
        this.add_arena_dialog = false
      })
      
    },
  },
  mounted() {
    this.$store.dispatch("arena/getEventsFromSelectedArenas");
    this.categories = this.selected_arenas.map((x) => x.title);
    console.log(this.selected_arenas)
    this.generateEvents()
    this.$store.dispatch("arena/getAllArenas")
  },
};
</script>

<style></style>
