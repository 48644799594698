<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="justify-center">
        <v-col cols="12" lg="10" xl="8">
          <v-row class="">
            <div>
              <v-breadcrumbs
                :items="breadcrumb_items"
                class="px-3"
              ></v-breadcrumbs>
            </div>
          </v-row>
          <div>
            <div class="text-h5 font-weight-bold pb-3 pt-5">Информация</div>

            <div class="text-h6 mb-4">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                v-show="type.value === 'LEAGUE'"
                label="Наименование лиги (полное)"
                v-model="fullTitle"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
                id="fullTitle"
                :rules="fullTitleRules"
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-text-field
                label="Наименование лиги"
                v-model="shortTitle"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
                id="shortTitle"
                :rules="shortTitleRules"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <v-row>
                <v-col cols="6">
                  <v-select
                    label="Город"
                    :items="cities"
                    v-model="city"
                    outlined
                    flat
                    dense
                    hide-details="auto"
                    class="rounded-lg"
                    id="city"
                    :rules="cityRules"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Тип"
                    :items="tournamentType"
                    v-model="type"
                    outlined
                    flat
                    dense
                    item-text="translation"
                    item-value="value"
                    return-object
                    hide-details="auto"
                    class="rounded-lg"
                    :rules="typeRules"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <div class="mb-4">
              <div v-if="type.value == 'KIDS'" class="text-h6 mb-4">
                Основное изображение турнира
              </div>
              <div v-else class="text-h6 mb-4">Основное изображение лиги</div>
              <v-row>
                <v-col class="pa-2" cols="6" sm="4" md="3">
                  <admin-image-uploader v-model="avatar">
                    <div slot="activator">
                      <div v-if="!avatar" class="white rounded-xl pa-4">
                        <v-avatar
                          width="100%"
                          height="200"
                          v-ripple
                          tile
                          class="white rounded-xl"
                        >
                          <div class="upload-border rounded-xl pa-4">
                            <div class="my-4">
                              <v-icon large color="#379AD3"
                                >mdi-cloud-upload-outline
                              </v-icon>
                            </div>
                            <div class="body-1 mb-2 font-weight-bold">
                              Загрузите логотип
                            </div>
                            <div class="body-2 mb-4 grey--text">
                              Поддерживаемые форматы: PNG, JPG
                            </div>
                          </div>
                        </v-avatar>
                      </div>
                      <div v-else class="white rounded-xl pa-4">
                        <v-avatar width="100%" height="200" tile v-ripple>
                          <v-img
                            class="ma-10 rounded-xl"
                            :src="avatar.imageURL"
                            alt="avatar"
                            cover
                          ></v-img>
                        </v-avatar>
                      </div>
                    </div>
                  </admin-image-uploader>
                </v-col>
              </v-row>
            </div>
            <div v-if="type.value == 'KIDS'">
              <v-row>
                <v-col cols="3">
                  <AppSelectDatePicker :label="start" :date.sync="startDate" />
                </v-col>
                <v-col cols="3">
                  <AppSelectDatePicker :label="end" :date.sync="endDate" />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Вступительный взнос"
                    v-model="entranceFee"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    class="rounded-lg"
                    ref="entranceFee"
                    :rules="[rules.required, rules.isFloatNumber]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Год рождения от"
                    v-model="startAge"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    class="rounded-lg"
                    ref="startAge"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="3">
                  <v-text-field
                    label="Год рождения до"
                    v-model="endAge"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    class="rounded-lg"
                    ref="endAge"
                    :rules="[rules.required, rules.isIntNumber, rules.endAge]"
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </div>
            <div class="mt-2">
              <div class="text-h6 mb-2">Описание</div>
              <v-textarea
                solo
                v-model="description"
                name=""
                flat
                elevation="0"
                hide-details="auto"
              ></v-textarea>
            </div>
          </div>
          <div class="d-flex mt-2">
            <v-btn
              @click="saveNewTournament"
              large
              elevation="0"
              color="primary"
              class="body-2 px-6 mr-2"
            >
              Далее
            </v-btn>
            <v-btn
              color="grey lighten-2"
              large
              class="body-2 px-6 ml-2"
              elevation="0"
              @click="
                $router.push({
                  name: 'user-profile',
                  params: { userId },
                })
              "
            >
              Отменить
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import { mapActions, mapState } from "vuex";
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import AppSelectDatePicker from "../../../../components/AppUnit/AppSelectDatePicker.vue";

export default {
  components: {
    AdminImageUploader,
    AppSelectDatePicker,
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
    search(input) {
      input && input !== this.address && this.findCoordinateGivenAddress(input);
    },
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.dispatch("leagues/getCities");
    this.getUserRoles();
    const userId = this.userId;
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId } },
      },
      {
        text: "Мои лиги",
        disabled: false,
        exact: true,
        to: { name: "admin-add-tournament", params: { userId } },
      },
      {
        text: "Создать турнир",
        disabled: true,
        exact: true,
        to: "",
      },
    ];
  },
  computed: {
    ...mapState("user", ["user", "new_arenas"]),
    ...mapState("leagues", ["cities"]),
    ...mapState("file", ["croppedImg"]),
    // ...mapState("leagues", ["new_arenas"]),
    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },
  data() {
    return {
      tournamentRoleId: "",
      isLoading: false,
      type_of_tournament: ["Лига", "Турнир / Фестиваль", "Детский турнир"],
      tournamentType: [
        {
          value: "LEAGUE",
          translation: "Лига",
        },
        {
          value: "KIDS",
          translation: "Детский турнир",
        },
      ],
      type: "",
      galleryPics: [],
      map: {},
      fullTitle: "",
      shortTitle: "",
      description: "",
      startDate: "",
      endDate: "",
      start: "Дата начала проведения",
      end: "Дата конца проведения",
      startAge: 0,
      endAge: null,
      metro: [],
      address: "",
      city: "",
      avatar: null,
      tag_chips: [],
      checkbox: null,
      entranceFee: "",
      contacts: "",
      breadcrumb_items: [],
      id: "",
      errors: [],
      fullTitleRules: [(v) => !!v || "Введите полное название"],
      shortTitleRules: [(v) => !!v || "Введите сокращенное название"],
      cityRules: [(v) => !!v || "Выберите город"],
      typeRules: [(v) => !!v || "Выберите тип"],
      required: [
        (v) => {
          return !!v || "Обязательно для заполнения";
        },
      ],
      isFloatNumber: [
        (v) => {
          const pattern = /^[1-9][0-9]*(\.[0-9]+)?$/;
          return (
            pattern.test(v) ||
            "Это должно быть число с точкой в качестве разделителя"
          );
        },
      ],
      isIntNumber: [
        (v) => {
          const pattern = /^[1-9][0-9]*$/;
          return pattern.test(v) || "Это должно быть целое число";
        },
      ],
      isendAge: [
        (v) => {
          return (
            Number(v) >= this.startAge ||
            "Это число должно быть больше начального возраста"
          );
        },
      ],
      rules: {
        required: (value) => {
          return !!value || "Обязательно для заполнения";
        },
        isFloatNumber: (value) => {
          const pattern = /^[1-9][0-9]*(\.[0-9]+)?$/;
          return (
            pattern.test(value) ||
            "Это должно быть число с точкой в качестве разделителя"
          );
        },
        isIntNumber: (value) => {
          const pattern = /^[1-9][0-9]*$/;
          return pattern.test(value) || "Это должно быть целое число";
        },
        endAge: (value) => {
          return (
            Number(value) >= this.startAge ||
            "Это число должно быть больше начального возраста"
          );
        },
        startAge: (value) => {
          return (
            Number(value) <= this.endAge ||
            "Это число должно быть больше начального возраста"
          );
        },
      },
    };
  },
  methods: {
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },

    async saveNewTournament() {
      if (this.validate()) {
        const data = {
          title: this.shortTitle,
          description: this.description,
          logo: !this.avatar ? "" : this.avatar.imageURL,
          editedLogo: this.croppedImg,
          gallery: this.galleryPics,
          city: this.city,
          vk: "",
          ...(this.type.value === "KIDS"
            ? { contact: this.contacts }
            : { contacts: this.contacts }),
          ...(this.type.value === "KIDS" ? {} : { fullTitle: "" }),
          ...(this.type.value === "KIDS" ? {} : { instagram: "" }),
          ...(this.type.value === "KIDS" ? { website: "" } : { site: "" }),
          ...(this.type.value === "KIDS" ? {} : { youtube: "" }),
          ...(this.type.value === "KIDS" ? { type: this.type.value } : {}),
          ...(this.type.value === "KIDS" ? { startDate: this.startDate } : {}),
          ...(this.type.value === "KIDS" ? { endDate: this.endDate } : {}),
          ...(this.type.value === "KIDS"
            ? { entranceFee: this.entranceFee }
            : {}),
          ...(this.type.value === "KIDS" ? { age: this.startAge } : {}),
          ...(this.type.value === "KIDS" ? { whatsApp: "" } : {}),
          ...(this.type.value === "KIDS" ? { documents: [] } : {}),
          approved: false,
        };

        if (this.type.value == "KIDS") {
          const { id } = await this.addKidsTournament(data);
          this.id = id;
          const userTournamentId = {
            userId: this.userId,
            tournamentId: id,
            roleId: this.tournamentRoleId,
          };

          this.createUserTournament(userTournamentId);
          this.$router.push({
            name: "admin-kids-tournament-edit",
            params: { tournamentId: this.id },
          });
        } else {
          const res = await this.postLeague(data);
          this.id = res.id;
          const userLeagueId = {
            userId: this.userId,
            leagueId: res.id,
            roleId: this.tournamentRoleId,
          };
          this.createUserLeague({ userLeagueId, league: res });
          this.$router.push({
            name: "admin-tournament-edit",
            params: { leagueId: this.id },
          });
        }
      }
    },
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
        .then((res) => {
          res.data.forEach((item) => {
            if (item.name === "TOURNAMENT_ORGANIZER") {
              this.tournamentRoleId = item.id;
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    validate() {
      if (this.fullTitle && this.shortTitle && this.city) {
        return true;
      }
      if (this.shortTitle && this.city && this.type.value === "KIDS") {
        return true;
      }
      if (!this.fullTitle) {
        this.errors.push("Введите полное название лиги");
        document.getElementById("fullTitle").style.color = "red";
      }
      return false;
    },

    ...mapActions("leagues", [
      "addKidsTournament",
      "createUserTournament",
      "postLeague",
    ]),
    ...mapActions("user", ["createUserLeague"]),
  },
};
</script>

<style></style>
