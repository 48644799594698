<template>
  <v-card
    class="rounded-lg d-flex"
    :elevation="elevation"
    @mouseover="elevation = '2'"
    @mouseout="elevation = '0'"
    @click="goToArena"
  >
    <v-avatar size="200" tile
      ><img :src="this.pickAvatar(league.editedLogo, league.logo)" alt="logo" />
    </v-avatar>
    <div class="d-flex flex-column justify-space-between">
      <div>
        <v-card-title class="pb-5">
          {{
            league.title.length > 25
              ? league.title.slice(0, 25) + "..."
              : league.title
          }}
        </v-card-title>
        <v-card-subtitle>
          <p class="ma-0">{{ league.city }}</p>
        </v-card-subtitle>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LeagueCardV2",
  props: {
    league: Object,
  },
  mounted() {
    this.selected_arenas.forEach((x) => {
      if (x.id === this.arena.id) {
        this.selected = true;
      }
    });
  },
  data() {
    return {
      elevation: 0,
      selected: false,
    };
  },
  methods: {
    goToArena() {
      const leagueId = this.league.id;
      this.$router.push({
        // path: `league/${leagueId}`,
        name: "league-information",
        params: {
          leagueId: leagueId,
        },
      });
    },
    openCardMap() {
      this.$router.push({
        name: "arena-map-all",
        query: { arenaTitle: `${this.arena.title}` },
      });
    },
    selectArena(arena) {
      this.selected = !this.selected;
      if (this.selected) {
        this.$store.dispatch("arena/addToSelectedArenas", arena);
      } else {
        this.$store.dispatch("arena/removeFromSelectedArenas", arena);
      }
    },
    pickAvatar(croppedImg, originImg) {
      if (croppedImg !== null) {
        return croppedImg;
      }
      return originImg ? originImg : require("@/assets/team_room_1.jpg");
    },
  },
  filters: {
    addressDescription: (value) => {
      if (!value) return "";
      return value.slice(0, 45);
    },
    arenaTitle: (value) => {
      if (!value) return "";
      if (value.length <= 28) return value;
      return value.slice(0, 28) + "..";
    },
  },
  computed: {
    ...mapState("arena", ["selected_arenas"]),
    checkPlus() {
      return this.selected ? "mdi-check" : "mdi-plus";
    },
  },
};
</script>

<style></style>
