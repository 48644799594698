<template>
  <div>
    <div v-if="data.length">
      <v-row class="container mb-2 item_cursor elevation-1"
             v-for="item in data"
             :key="item.id"
             @click="type.func(item)"
      >
        <p class="mt-4 ml-4">{{ item.title }}</p>
      </v-row>
    </div>
    <div v-else class="justify-center">
      <p>empty</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TournamentsComponent",
  props: {
    data: Array,
    type: Object,
    leagueId: String
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  height: 80px;
  border-radius: 10px;
}
.item_cursor {
  cursor: pointer;
}
</style>
