<template>
  <div class="grey lighten-4">
    <v-container class="pt-16 pb-0">
      <div class="pb-16">
        <v-row dense>
          <v-col class="d-flex" cols="12" md="2">
            <v-select
              :items="cities"
              v-model="state.city"
              solo
              flat
              hide-details="auto"
              v-on:change="fetchData(state)"
            ></v-select>
          </v-col>
          <v-col cols="9" md="8" lg="9">
            <v-text-field
              label="Поиск по названию турнира, адресу"
              single-line
              prepend-inner-icon="mdi-magnify"
              solo
              flat
              hide-details="auto"
              class="rounded-lg"
              v-model="state.search"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="2" lg="1">
            <v-btn
              class="rounded-lg"
              large
              depressed
              height="48px"
              width="100%"
              color="primary"
              v-on:click="fetchData(state)"
            >
              Найти
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-8 pb-8 pl-4">
          <div class="text-h5 font-weight-bold">Детские лагеря и сборы</div>
        </v-row>
        <div class="rounded-lg white align-center mb-10">
          <v-sheet
            tile
            class="d-flex align-center flex-wrap justify-center justify-md-center justify-sm-space-around pa-lg-5 pa-md-4"
            color="#EBF5FB"
          >
            <div class="d-flex align-center">
              <div class="d-flex align-center">
                <div style="width: 170px">
                  <AppSelectDatePickerSolo
                    :label="startDateLabel"
                    :date.sync="state.startDate"
                  />
                </div>
                <div class="ml-2" style="width: 170px">
                  <AppSelectDatePickerSolo
                    :label="endDateLabel"
                    :date.sync="state.endDate"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center">
              <div class="ma-2">Год рождения:</div>
              <div class="d-flex align-center">
                <div style="width: 150px">
                  <v-select
                    :items="agesList"
                    v-model="state.startAge"
                    dense
                    single-line
                    outlined
                    hide-details
                    :label="startAgeLabel"
                    class="white"
                  ></v-select>
                </div>
                <div style="width: 150px">
                  <v-select
                    :items="agesList"
                    v-model="state.endAge"
                    dense
                    single-line
                    outlined
                    hide-details
                    :label="endAgeLabel"
                    class="ma-2 white"
                  ></v-select>
                </div>
              </div>
            </div>
          </v-sheet>
        </div>
        <v-row>
          <v-col class="d-flex" cols="6" md="4" lg="3" xl="2">
            <v-select
              :items="sortBy"
              v-model="state.sort"
              prepend-inner-icon="mdi-swap-vertical"
              solo
              flat
              hide-details="auto"
              v-on:change="fetchData(state)"
            ></v-select>
          </v-col>
          <v-col class="my-auto" cols="6" md="4">
            <div class="body-1 grey--text">
              Найдено: {{ camps.totalElements }} результатов
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" md="4" lg="3" xl="2">
            <v-select
              :items="showByList"
              v-model="state.pageSize"
              solo
              flat
              hide-details="auto"
              v-on:change="fetchData(state)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-row dense class="mx-n4 pr-4">
        <v-col
          class="mb-3 pa-4"
          cols="12"
          v-for="{
            id,
            title,
            city,
            address,
            startDate,
            endDate,
            age,
            entranceFees,
            logo,
          } in camps.content"
          :key="id"
        >
          <router-link
            :to="{
              path: `/campInfo/${id}`,
              params: { id },
            }"
            class="undo-link-default d-sm-flex"
          >
            <div>
              <v-avatar class="ma-3 rounded" size="180">
                <v-img
                  :src="logo ? logo : require('@/assets/team_room_1.jpg')"
                ></v-img>
              </v-avatar>
            </div>
            <v-row class="pt-3">
              <v-col class="d-flex flex-wrap justify-space-between">
                <div>
                  <div class="grey--text lighten-3--text text-h6 mb-2">
                    {{ startDate }} - {{ endDate }}
                  </div>
                  <div class="text-h6 mb-2 font-weight-bold">{{ title }}</div>
                  <div
                    class="body-1 blue--text mb-2"
                    style="text-decoration: none"
                  >
                    Россия, г.{{ city }}, {{ address }}
                  </div>
                  <div class="grey--text">
                    Организатор: Васильева Мария Владимировна
                  </div>
                  <div
                    class="body-1 blue--text mb-2 pt-4"
                    style="text-decoration: none"
                  >
                    Год рождения: {{ age }}
                  </div>
                </div>
                <div class="text-lg-right text-md-left">
                  <div class="text-h7 grey--text mb-2">Вступительный взнос</div>
                  <div
                    class="body-1 blue--text mb-2"
                    style="text-decoration: none"
                  >
                    {{ entranceFees }} РУБ
                  </div>
                  <div class="body-1 text-h7 mb-2">
                    + 2000 РУБ за каждого игрока
                  </div>
                </div>
              </v-col>
            </v-row>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center py-10">
      <v-pagination
        color="grey"
        :length="camps.totalPages"
        :total-visible="7"
        v-model="state.currentPage"
        v-on:input="fetchData(state)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AppSelectDatePickerSolo from "../../components/AppUnit/AppSelectDatePickerSolo.vue";

export default {
  name: "CampsFees",
  components: {
    AppSelectDatePickerSolo,
  },
  data() {
    return {
      cities: ["Все города", "Москва", "Казань"],
      sortBy: ["По дате", "По имени"],
      showByList: ["Показывать по 12", "Показывать по 8"],
      startAgeLabel: "2002",
      endAgeLabel: "2022",
      startDateLabel: "01.01.2022",
      endDateLabel: "12.12.2022",
      agesList: [
        2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013,
        2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
      ],
      start: "01.01.2022",
      end: "12.12.2022",
      state: {
        city: "Все города",
        currentPage: 1,
        pageSize: "Показывать по 12",
        sort: "По дате",
        startAge: "",
        endAge: "",
        search: "",
        startDate: "",
        endDate: "",
        approved: true,
      },
    };
  },

  mounted() {
    const defState = {
      currentPage: 1,
      pageSize: 12,
      sort: true,
      approved: true,
    };

    this.fetchCamps(defState);
  },

  computed: {
    ...mapState("camps", ["camps"]),
  },

  methods: {
    fetchData(state) {
      const {
        city,
        currentPage,
        pageSize,
        sort,
        search,
        startDate,
        endDate,
        startAge,
        endAge,
        approved,
      } = state;

      const newState = {
        ...(city === "Все города" ? {} : { city }),
        currentPage,
        ...(pageSize === "Показывать по 12"
          ? { pageSize: 12 }
          : { pageSize: 8 }),
        ...(sort === "По дате" ? { sort: true } : { sort: false }),
        ...(search === "" ? {} : { search }),
        ...(startDate === "" ? {} : { startDate }),
        ...(endDate === "" ? {} : { endDate }),
        ...(startAge === "" ? {} : { startAge }),
        ...(endAge === "" ? {} : { endAge }),
        approved,
      };

      this.fetchCamps(newState);

      this.state.startDate = "";
      this.state.endDate = "";
      this.state.starАge = "";
      this.state.endAge = "";
    },

    ...mapActions("camps", ["fetchCamps"]),
  },
};
</script>

<style>
.undo-link-default {
  text-decoration: none;
}
.banner-room {
  background: url("../../assets/banner-room.jpg") no-repeat center center;
  background-size: cover;
}
</style>
