<template>
  <v-container fill-height fluid style="background-color: inherit">
    <v-row
        alig="center"
        justify="center"
    >
      <pulse-loader/>
    </v-row>
  </v-container>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader"

export default {
  name: "AppLoaderAnimation",
  components: {PulseLoader}
}
</script>

<style scoped>
.loader {
  height: 200px;
}
</style>
