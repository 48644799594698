<template>
  <v-container>
    <div
      style="
        text-decoration: none;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 500;
      "
    >
      {{ title }}
    </div>
    <v-row class="mt-5" dense>
      <v-col class="d-flex" cols="12" md="2">
        <v-select
          :items="todoMode"
          v-model="currentTodoMode"
          solo
          flat
          outlined
          hide-details="auto"
          @change="filterLeagues"
        ></v-select>
      </v-col>
      <v-col cols="9" md="8" lg="9">
        <v-text-field
          label="Поиск по названию турнира"
          single-line
          prepend-inner-icon="mdi-magnify"
          solo
          flat
          outlined
          hide-details="auto"
          class="rounded-lg"
          v-model="search"
          @keypress.enter="filterLeagues"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="2" lg="1">
        <v-btn
          class="rounded-lg"
          large
          depressed
          height="55px"
          width="100%"
          color="primary"
          @click="filterLeagues"
        >
          Найти
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-3" no-gutters>
      <v-col class="d-flex" cols="4" md="2">
        <v-select
          :items="cityList"
          v-model="city"
          solo
          outlined
          flat
          hide-details="auto"
          @change="filterLeagues"
        ></v-select>
      </v-col>
      <v-col class="d-flex ml-3" cols="4" md="2">
        <v-select
          :items="sort_by"
          v-model="sortBy"
          item-text="value"
          item-value="key"
          return-object
          solo
          outlined
          flat
          hide-details="auto"
          @change="filterLeagues"
        ></v-select>
      </v-col>
      <v-col class="d-flex ml-3" cols="4" md="2">
        <v-select
          :items="display_items"
          item-text="state"
          item-value="value"
          v-model="show"
          @change="filterLeagues"
          return-object
          solo
          flat
          outlined
          hide-details="auto"
        ></v-select>
      </v-col>
      <v-col class="my-auto ml-3" cols="6" md="4">
        <div class="body-1 grey--text">Найдено: {{ numFound }} результатов</div>
      </v-col>
    </v-row>
    <v-row class="mt-4" no-gutters>
      <v-col
        class="pa-4"
        cols="12"
        md="4"
        xl="3"
        v-for="({ id, logo, editedLogo, title }, i) in leagues"
        :key="i"
      >
        <v-card class="mx-auto" max-width="400" hover>
          <router-link
            :to="{
              path: `/league/${id}`,
              params: { leagueId: id },
            }"
            class="undo-link-default"
          >
            <v-img
              class="white--text align-end"
              height="250px"
              :src="editedLogo ? editedLogo : logo"
            >
            </v-img>

            <v-card-title class="text--primary">
              <div>{{ title ? `${title.slice(0, 20)}...` : "Нет имени" }}</div>
            </v-card-title>
          </router-link>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="blue"
              text
              v-show="buttonToggle"
              v-on:click="isApproved({ isApproved: true, id: id })"
            >
              Одобрить
            </v-btn>
            <v-btn
              color="red"
              text
              v-show="!buttonToggle"
              v-on:click="isApproved({ isApproved: false, id: id })"
            >
              Отклонить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center py-10">
      <v-pagination
        color="grey"
        v-model="page"
        :length="paginationLength"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    title: String,
  },
  name: "KidsTournamentsMain",
  computed: {
    ...mapState("leagues", ["leagues", "cities", "league"]),
  },
  created() {
    this.getCities();
    this.cityList = ["Все города", ...this.cities];
    this.filterLeagues();
  },
  watch: {
    currentTodoMode() {
      this.buttonToggle = !this.buttonToggle;
    },
  },
  data() {
    return {
      city: "Москва",
      search: "",
      numFound: 0,
      cityList: [],
      buttonToggle: false,
      todoMode: ["Одобренные запросы", "Неодобренные запросы"],
      currentTodoMode: "Одобренные запросы",
      sort_by: [
        { key: true, value: "По именни (от А до Я)" },
        { key: false, value: "По именни (от Я до А)" },
      ],
      sortBy: { key: true, value: "По именни (от А до Я)" },
      display_items: [
        { state: "Показывать по 12", value: 12 },
        { state: "Показывать по 24", value: 24 },
        { state: "Показывать по 36", value: 36 },
      ],
      show: { state: "Показывать по 12", value: 12 },
      page: 1,
      paginationLength: 0,
      isFetching: false,
    };
  },
  methods: {
    async filterLeagues() {
      this.isFetching = true;
      const data = {
        currentPage: this.page,
        pageSize: this.show.value,
        sortAscending: this.sortBy.key,
        filters: [
          {
            filterConditions: "EQUAL",
            value: {
              type: "String",
              string: this.city,
            },
            parameter: "city",
          },
        ],
      };
      if (this.search != "") {
        const filter = {
          filterConditions: "CONTAINS",
          value: {
            type: "String",
            string: this.search,
          },
          parameter: "title",
        };
        data.filters.push(filter);
      }
      if (this.currentTodoMode === "Одобренные запросы") {
        const approvedFilter = {
          filterConditions: "EQUAL",
          value: {
            type: "Boolean",
            boolean: true,
          },
          parameter: "approved",
        };

        data.filters.push(approvedFilter);
      }
      if (this.currentTodoMode !== "Одобренные запросы") {
        const approvedFilter = {
          filterConditions: "EQUAL",
          value: {
            type: "Boolean",
            boolean: false,
          },
          parameter: "approved",
        };

        data.filters.push(approvedFilter);
      }

      const { paginationLength, numFound } = await this.filteringLeagues(data);
      this.numFound = numFound;
      this.paginationLength = paginationLength;
    },
    async isApproved({ isApproved, id }) {
      await this.getLeague(id);
      this.league.approved = isApproved;
      await this.putLeague({
        leagueId: id,
        league: this.league,
      });
      this.filterLeagues();
    },
    ...mapActions("leagues", [
      "filteringLeagues",
      "getCities",
      "getLeague",
      "putLeague",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
