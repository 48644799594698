<template>
  <div class="grey lighten-4" v-if="!isFetching">
    <v-img
      color="grey"
      height="350px"
      width="100%"
      src="@/assets/banner-league.jpg"
    >
      <v-container class="pt-8 pb-0">
        <v-row class="">
          <div>
            <v-breadcrumbs
              dark
              :items="breadcrumb_items"
              class="px-3 ml-4"
            ></v-breadcrumbs>
          </div>
          <v-spacer></v-spacer>
        </v-row>
        <div class="d-flex">
          <div class="d-inline-block mt-5 mb-2 ml-4" lg="10" xl="10">
            <div class="my-auto">
              <p class="text-h4 white--text">{{ league.title }}</p>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-avatar class="rounded-xl" contain tile size="180" lg="2" xl="2">
            <v-img
              contain
              :src="(league.logo ? league.logo : require('@/assets/advert_2.jpg'))"
            ></v-img>
          </v-avatar>
        </div>
      </v-container>
    </v-img>
    <v-container>
      <v-row class="mt-5">
        <v-col cols="5" md="3">
          <v-tabs
            vertical
            class="pl-4 rounded-lg"
            v-model="sideTab"
          >
            <v-tab
              class="tab_text"
              v-for="(item, i) in sidebar_items"
              :key="i"
              router
              exact
              :to="item.link"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="7" md="9">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <app-loader-animation style="height: 1200px" v-else/>
</template>

<script>
import { mapState } from "vuex";
import AppLoaderAnimation from "@/components/AppUnit/AppLoader";

export default {
  props: {
    leagueId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState("leagues", ["league"]),
  },
  components: {
    AppLoaderAnimation,
  },
  watch: {
    '$route.params.leagueId'() {
      this.getLeagueInfo()
    }
  },
  async created() {
    await this.getLeagueInfo()
    window.scrollTo(0, 0)
    this.isFetching = false
  },
  methods: {
    openContactInTab(link) {
      let routeData = this.$router.resolve({ name: `/${link}` });
      window.open(routeData.href, "_blank");
    },
    getLeagueInfo() {
      return this.$store.dispatch("leagues/getLeague", this.leagueId).then(() => {
        this.sidebar_items = [
          {
            text: "Информация",
            link: {
              name: "league-information",
              params: { leagueId: this.leagueId },
            },
          },
          {
            text: "Турниры",
            link: {
              name: "league-seasons",
              params: { leagueId: this.leagueId },
            },
          },
        ];

        const league = this.league;
        this.contact_list = [
          {
            icon: "mdi-instagram",
            link: `${league.instagram ? league.instagram : ""}`,
          },
          {
            icon: "mdi-alpha-k-box",
            link: `${league.vk ? league.vk : ""}`,
          },
          {
            icon: "mdi-youtube",
            link: `${league.youtube ? league.youtube : ""}`,
          },
        ];

        this.breadcrumb_items = [
          {
            text: `${league.city + ' / ' +league.title}`,
            disabled: true,
            to: { name: "" },
          },
        ];
      });
    }
  },

  data() {
    return {
      name: "League",
      isFetching: true,
      contact_list: null,
      sideTab: 0,
      breadcrumb_items: [],
      selectedItem: 0,
      sidebar_items: null,
    };
  },
};
</script>

<style>
.border > .right-border {
  border-right: 1px solid #ccc;
}

.border:last-child .right-border {
  border-right: unset;
}

.tab_text {
  font-family: Roboto, sans-serif;
  font-style: normal;
  text-transform: none;
  justify-content: left;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: normal;
  color: black;
}

.wrapper-map {
  background-color: #ccc;
  height: 600px;
  width: 100%;
  position: relative;
  margin: 10px auto;
}
</style>
