<template>
  <div class="grey lighten-4">
    <v-img
      color="grey"
      height="350px"
      width="100%"
      src="@/assets/school_bg.jpg"
    >
      <v-container class="pt-8 pb-0">
        <v-row>
          <div>
            <v-breadcrumbs
              dark
              :items="breadcrumb_items"
              class="px-3"
            ></v-breadcrumbs>
          </div>
          <v-spacer></v-spacer>
          <div class="pr-3 my-auto">
            <a
              v-for="(item, index) in valid_contact_list"
              class="reset-link"
              :key="index"
              :href="item.link"
              target="_blank"
            >
              <v-btn
                elevation="0"
                x-small
                color="transparent"
                height="40px"
                class="mx-1"
              >
                <v-icon color="white"> {{ item.icon }}</v-icon>
              </v-btn>
            </a>
          </div>
        </v-row>

        <div class="d-flex mt-5 mb-2">
          
          <div class="my-auto">
            <p class="text-h6 mb-2 white--text"> {{rightDate(kidsTournament.startDate)}} — {{rightDate(kidsTournament.endDate)}} </p>
            <p class="text-h4 mb-5 white--text">{{ kidsTournament.title }}</p>
            <p class="blue_text mb-2">
              <v-icon color="#83C0E4">mdi-map-marker-outline</v-icon>
              {{ kidsTournament.city }}
            </p>
            <p class="white--text mb-7">Год рождения: {{kidsTournament.age}}</p>
            <p class="white--text mb-2">Вступительный взнос</p>
            <p class="blue_text text-h5"> {{ kidsTournament.entranceFee }} РУБ</p>
          </div>
          <v-spacer></v-spacer>
          <div class="pr-4">
            <v-avatar class="rounded-lg" contain tile size="180">
              <v-img
                contain
                :src="
                  kidsTournament.logo != null
                    ? kidsTournament.logo
                    : require('@/assets/team_room_1.jpg')
                "
              ></v-img>
            </v-avatar>
          </div>
        </div>
      </v-container>
    </v-img>
    <v-container
      class="mt-10"
      v-if="kidsTournament.description.length"
    >
      <p class="text-h4">О турнире</p>
      <div v-if="kidsTournament.description.length < 580">
        <p class="text-justify" style="white-space: pre-line" v-html="kidsTournament.description"></p>
      </div>
      <div v-else>
        <p
          class="text-justify"
          style="white-space: pre-line"
          v-if="!readMoreInfo"
          v-html="kidsTournament.description.slice(0, 580) + '...'"
        ></p>
        <p class="text-justify" style="white-space: pre-line" v-else v-html="kidsTournament.description"></p>
      </div>
     
      <v-btn
        class="px-6"
        color="grey lighten-2"
        v-if="kidsTournament.description.length > 580"
        x-large
        elevation="0"
        @click.stop="readMoreInfo = !readMoreInfo"
      >
        {{ readMoreInfo ? "Скрыть описание" : "Развернуть описание" }}
      </v-btn>
    </v-container>
    <v-container
      v-show="kidsTournamentArenas ? kidsTournamentArenas.length : false"
    >
      <p class="text-h5 font-weight-bold">Место проведения тренировок</p>
      <v-row dense class="mx-n4" >
        <div v-for="(arena, id) in kidsTournamentArenas" :key="id">
          <v-col class="pt-0" cols="12">
          <v-card color="transparent" elevation="0">
            <div class="d-flex flex-no-wrap">
              <div class="ma-3">
                <v-avatar class="rounded-lg" width="282px" height="186px" tile>
                  <v-img
                    contain
                    :src="
                      arena.arenaLogo != null
                        ? arena.arenaLogo
                        : require('@/assets/team_room_1.jpg')
                    "
                  ></v-img>
                </v-avatar>
              </div>
              <div class="description">
                <v-card-text style="width: 100%">
                  <div class="text-h5 mb-4">{{ arena.arenaTitle }}</div>
                  <div class="body-1 grey--text">
                    {{ arena.arenaCity }}
                  </div>
                </v-card-text>
                <v-card-actions class="pl-4 bottom">
                  <router-link
                    :to="{ name: 'arena-information', params: { arenaId: arena.arenaId } }"
                    class="reset-link"
                  >
                    <v-btn
                      class="px-6"
                      color="grey lighten-2"
                      x-large
                      elevation="0"
                    >
                      Подробнее
                    </v-btn>
                  </router-link>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        </div>
      </v-row>
    </v-container>
    <v-container  v-if="media.length">
      <p class="text-h5 font-weight-bold">Галерея</p>
      <v-row>
        <v-col cols="6" md="4" lg="3" v-for="(item, i) in media" :key="i">
          <v-img style="height: 200px" :src="item" @click="openGallery(i)"></v-img>
        </v-col>
        <LightBox
          ref="lightbox"
          :media="media"
          :show-caption="true"
          :show-light-box="false"
        />
      </v-row>
    </v-container>

    <v-container v-if="kidsTournament.contact.length">
      <p class="text-h5 font-weight-bold">Контакты</p>
      <div>
          <p 
          v-if="kidsTournament.contact" 
          style="white-space: pre-line"
          v-html="kidsTournament.contact">
          </p>
        </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import LightBox from "vue-image-lightbox";

export default {
  name: "KidsTournamentCard",
  components: {
    LightBox
  },
//   props: {
//     kidsTournamentId: {
//       type: String,
//       required: true,
//     },
//   },
  watch: {},
  computed: {
    ...mapState("auth", ["userId"]),
    ...mapGetters("auth", ["loggedIn"]),
    ...mapState("user", ["user"]),
    ...mapState("leagues", ["kidsTournament", "kidsTournamentArenas"]),
    valid_contact_list() {
      return this.contact_list.filter((x) => x.link !== "");
    },
    media() {
      let _media = [];
      if (this.kidsTournament.gallery) {
        this.kidsTournament.gallery.forEach((x) => {
          const item = {
            thumb: x,
            src: x,
            caption: "<h4></h4>",
          };
          _media.push(item);
        });
      }
      return _media;
    },
  },
  created() {
    const kidsTournamentId = this.$route.params.kidsTournamentId;

    this.$store.dispatch("leagues/getKidsTournamentArenas", kidsTournamentId);

    this.$store.dispatch("leagues/getKidsTournament", kidsTournamentId).then(() => {
      const kidsTournament = this.kidsTournament;
      console.log(kidsTournament)
      this.breadcrumb_items = [
        {
          text: "Детские турниры",
          disabled: false,
          exact: true,
          to: { name: "kidsTournaments" },
        },
        {
          text: kidsTournament.title,
          disabled: true,
          to: "",
        },
      ];

      this.contact_list = [
        {
          icon: "mdi-whatsapp",
          link: `${kidsTournament.whatsApp ? kidsTournament.whatsApp : ""}`,
        },
        { 
          icon: "mdi-alpha-k-box", 
          link: `${kidsTournament.vk ? kidsTournament.vk : ""}` },
        {
          icon: "mdi-web",
          link: `${kidsTournament.website ? kidsTournament.website : ""}`,
        },
      ];
    });
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("D MMMM YYYY");
    },
  },
  data() {
    return {
      contact_list: [],
      readMoreInfo: false,
      breadcrumb_items: [],
    };
  },
};
</script>

<style>
.description {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.blue_text{
  color: #83C0E4;
}
</style>