<template>
  <div class="white" style="border-radius: 12px;">
          <v-card-title>
            <div class="text-h5 black--text">Редактировать объявление</div>
          </v-card-title>
          <v-card-text class="mb-4">
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="playerSearch"
                  v-model="mforum.find"
                  placeholder="Тип объявления"
                  solo
                  background-color="#F5F5F5"
                  flat
                  item-text="state"
                  item-value="value"
                  return-object
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col v-if="mforum.find.value == 'TEAMPLAYER' || mforum.find.value == 'TEAMTRAINER'" cols="12">
                <v-select
                  :items="teams"
                  v-model="selectedTeam"
                  placeholder="Команда"
                  solo
                  background-color="#F5F5F5"
                  flat
                  item-text="teamTitle"
                  item-value="teamId"
                  return-object
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <div class="mb-2 text-h6">Название объявления</div>
                <v-text-field
                label="Название объявления"
                v-model="mforum.title"
                background-color="#F5F5F5"
                solo
                flat
                hide-details="auto"
                class="rounded-lg"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="mforum.find.value == 'TEAMPLAYER'">
                <div class="mb-2 text-h6">Амплуа</div>
                <v-select
                :items="positions"
                label="Амплуа"
                v-model="mforum.position"
                background-color="#F5F5F5"
                solo
                flat
                hide-details="auto"
                class="rounded-lg"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="mforum.find.value == 'TEAMPLAYER'">
                <div class="mb-2 text-h6">Хват</div>
                <v-select
                :items="grips"
                label="Хват"
                v-model="mforum.grip"
                background-color="#F5F5F5"
                solo
                flat
                hide-details="auto"
                class="rounded-lg"
                ></v-select>
              </v-col>
              <v-col cols="12" class="">
                <div class="mb-2 text-h6">Описание объявления</div>
                <v-textarea
                  solo
                  v-model="mforum.description"
                  background-color="#F5F5F5"
                  min-height="130"
                  flat
                  elevation="0"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="cities"
                  v-model="mforum.city"
                  placeholder="Город"
                  background-color="#F5F5F5"
                  solo
                  flat
                  item-text="state"
                  item-value="value"
                  return-object
                  hide-details="auto"
                  class="rounded-lg"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-n6 mx-2">
            <v-btn
            class="rounded-lg"
            large
            color="grey lighten-2"
            elevation="0"
            @click="goBack"
            >
              Назад
            </v-btn>
            <v-btn
            large
            class="rounded-lg"
            color="primary"
            elevation="0"
            @click="updateForum"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    forumId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getAllAdminTeams()
    // this.getToEdit();
    const userId = this.userId;
    this.$store.dispatch("user/getUser", userId).then(() => {
      this.getSocialMedia();
    });
    this.$store.dispatch("user/getForums", this.userId);
    this.$store.dispatch("user/getRole", this.roleId);
  },
  computed: {
    ...mapState("user", ["user", "forums", "role"]),
    roleCategory() {
      if (this.role.name === "TRAINER") {
        return this.role.category
          .map((cat) => {
            if (cat == "ADULT") return "Взрослый";
            if (cat == "KID") return "Детский";
            if (cat == "YOUTH") return "Юношеский";
            if (cat == "FEMALE") return "Женский";
          })
          .join(", ");
      } else return "";
    },
    displaySocialMedia() {
      return this.social_media.filter((element) => element.link);
    },
    fullName() {
      const { name, middleName, surname } = this.user;
      return `${surname} ${name} ${middleName}`;
    },
  },
  data() {
    return {
      loading: false,
      social_media: [],
      modifyForumDialog: false,
      mforum: {
        find: {},
        description: "",
        city: "",
        position: "",
        grip: "",
        title: "",
      },
      cities: ["Москва"],
      playerSearch: [],
      positions: ["Защитник", "Нападающий", "Вратарь"],
      grips: ["Левый", "Правый"],
      teams: [],
      selectedTeam: {},
      organizer: "",
      teamId: ""
    };
  },
  methods: {
    goBack(){
      this.$router.push({
        name: "admin-advertisements",
        params: { userId: this.userId },
      });
    },

    async getAllAdminTeams() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/team/titles`)
      .then( (res) => {
        this.teams = res.data.filter((x) => x.approved === "accepted")
        console.log(this.teams)
        this.getUserRoles()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "PLAYER"){
            const obj = { 
              value: "PLAYERTEAM", 
              state: "Игрок ищет команду" 
            }
            this.playerSearch.push(obj)
          } 
          if(item.name == "TRAINER"){
            const obj = { 
              value: "TRAINERTEAM", 
              state: "Тренер ищет команду" 
            }
            this.playerSearch.push(obj)
          } 
          if(item.name == "TEAM_ADMIN"){
            this.teams = this.teams.filter((x) => x.roleId === item.id)
            const arr = [{ 
              value: "TEAMPLAYER", 
              state: "Команда ищет игроков" 
            },
            { 
              value: "TEAMTRAINER", 
              state: "Команда ищет тренера" 
            },]
            this.playerSearch = this.playerSearch.concat(arr)
          }
        });
        this.getToEdit()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    isValid(input) {
      if (input) return true;
      return false;
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formatter.format(newDate);
    },
    getToEdit() {
      const forumId = this.forumId
      this.$store.dispatch("user/getForum", forumId).then((forum) => {
        console.log(
          "🚀 ~ file: UserInformation.vue ~ line 441 ~ this.$store.dispatch ~ response",
          forum
        );
        const { type, description, city, role, grip, id, title, organizer, teamId } = forum;
        this.forumId = id;
        this.mforum = {
          find: { value: `${type}`, state: "" },
          description,
          city,
          position: role,
          grip,
          title,
        };
        this.selectedTeam = { teamTitle: `${organizer}`, teamId: `${teamId}` }
      });
    //   this.modifyForumDialog = true;
    },
    getSocialMedia() {
      const { vk, whatsApp, instagram, website, facebook } = this.user;
      this.social_media = [
        {
          id: 1,
          name: "vk",
          link: vk,
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: whatsApp,
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: website,
          icon: "mdi-web",
        },
        {
          id: 4,
          name: "instagram",
          link: instagram,
          icon: "mdi-instagram",
        },
        {
          id: 5,
          name: "facebook",
          link: facebook,
          icon: "mdi-facebook",
        },
      ];
    },
    initForumDialog() {
      return {
        find: "",
        description: "",
        city: "",
        position: "",
        grip: "",
      };
    },
    
    updateForum() {
      const forumId = this.forumId;
      console.log(this.userId)
      const { description, position, city, grip, find, title } = this.mforum;
      if(this.mforum.find.value == 'TEAMPLAYER' || this.mforum.find.value == 'TEAMTRAINER'){
        this.organizer = this.selectedTeam.teamTitle
        this.teamId = this.selectedTeam.teamId
      } else {
        this.organizer = this.fullName
      }
      const putForum = {
        date: moment().format("YYYY-MM-DD"),
        description,
        grip,
        city,
        role: position,
        type: find.value,
        userId: this.userId,
        title,
        organizer: this.organizer,
        teamId: this.teamId,
      };
      console.log(putForum)
      this.$store
        .dispatch("user/putForum", { forumId, forum: putForum })
        .then(() => {
            this.$router.push({
                name: "admin-advertisements",
                params: { userId: this.userId },
            });
          this.mforum = this.initForumDialog();
          this.modifyForumDialog = false;
          this.$store.dispatch("user/getForums", this.userId);
        });
    },
  },
};
</script>

<style scoped></style>
