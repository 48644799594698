var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-h4"},[_vm._v("Тренерский состав")]),_c('v-tabs',{staticClass:"d-flex flex-no-wrap rounded-lg",model:{value:(_vm.premises_tab),callback:function ($$v) {_vm.premises_tab=$$v},expression:"premises_tab"}},_vm._l((_vm.premises_nav),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background-color":"unset"},model:{value:(_vm.premises_tab),callback:function ($$v) {_vm.premises_tab=$$v},expression:"premises_tab"}},_vm._l((5),function(x){return _c('v-tab-item',{key:x},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.premises_tab == 0),expression:"premises_tab == 0"}],staticClass:"mx-n4 mt-5",attrs:{"dense":""}},_vm._l((_vm.trainers),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('router-link',{staticClass:"undo-link-default",attrs:{"to":{ name: 'room-player-card', params: { playerId: item.id } }}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3 rounded-lg",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":item.profilePicture != null
                        ? item.profilePicture
                        : require('@/assets/player_2.jpg')}})],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.full_name(item))+" ")]),(item.training_direction)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" "+_vm._s(item.training_direction)+" ")]):_vm._e(),_c('div',{staticClass:"body-1 blue--text mb-2"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.age))+" "+_vm._s(_vm.isValidCityOutput(item.city))+" ")]),_c('div',{staticClass:"body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.position))+" ")])])],1)])],1)],1)}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.premises_tab == 1),expression:"premises_tab == 1"}],staticClass:"mx-n4 mt-5",attrs:{"dense":""}},_vm._l((_vm.kidTR),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('router-link',{staticClass:"undo-link-default",attrs:{"to":{ name: 'room-player-card', params: { playerId: item.id } }}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3 rounded-lg",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":item.profilePicture != null
                        ? item.profilePicture
                        : require('@/assets/player_2.jpg')}})],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.full_name(item))+" ")]),(item.training_direction)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" "+_vm._s(item.training_direction)+" ")]):_vm._e(),_c('div',{staticClass:"body-1 blue--text mb-2"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.age))+" "+_vm._s(_vm.isValidCityOutput(item.city))+" ")]),_c('div',{staticClass:"body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.position))+" ")])])],1)])],1)],1)}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.premises_tab == 2),expression:"premises_tab == 2"}],staticClass:"mx-n4 mt-5",attrs:{"dense":""}},_vm._l((_vm.youthTR),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('router-link',{staticClass:"undo-link-default",attrs:{"to":{ name: 'room-player-card', params: { playerId: item.id } }}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3 rounded-lg",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":item.profilePicture != null
                        ? item.profilePicture
                        : require('@/assets/player_2.jpg')}})],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.full_name(item))+" ")]),(item.training_direction)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" "+_vm._s(item.training_direction)+" ")]):_vm._e(),_c('div',{staticClass:"body-1 blue--text mb-2"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.age))+" "+_vm._s(_vm.isValidCityOutput(item.city))+" ")]),_c('div',{staticClass:"body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.position))+" ")])])],1)])],1)],1)}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.premises_tab == 3),expression:"premises_tab == 3"}],staticClass:"mx-n4 mt-5",attrs:{"dense":""}},_vm._l((_vm.adultTR),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('router-link',{staticClass:"undo-link-default",attrs:{"to":{ name: 'room-player-card', params: { playerId: item.id } }}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3 rounded-lg",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":item.profilePicture != null
                        ? item.profilePicture
                        : require('@/assets/player_2.jpg')}})],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.full_name(item))+" ")]),(item.training_direction)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" "+_vm._s(item.training_direction)+" ")]):_vm._e(),_c('div',{staticClass:"body-1 blue--text mb-2"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.age))+" "+_vm._s(_vm.isValidCityOutput(item.city))+" ")]),_c('div',{staticClass:"body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.position))+" ")])])],1)])],1)],1)}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.premises_tab == 4),expression:"premises_tab == 4"}],staticClass:"mx-n4 mt-5",attrs:{"dense":""}},_vm._l((_vm.femaleTR),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('router-link',{staticClass:"undo-link-default",attrs:{"to":{ name: 'room-player-card', params: { playerId: item.id } }}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3 rounded-lg",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":item.profilePicture != null
                        ? item.profilePicture
                        : require('@/assets/player_2.jpg')}})],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.full_name(item))+" ")]),(item.training_direction)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" "+_vm._s(item.training_direction)+" ")]):_vm._e(),_c('div',{staticClass:"body-1 blue--text mb-2"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.age))+" "+_vm._s(_vm.isValidCityOutput(item.city))+" ")]),_c('div',{staticClass:"body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.isValidOutput(item.position))+" ")])])],1)])],1)],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }