import axios from "axios";

// http://193.187.173.125:8090/
// https://api-hockey-io.herokuapp.com
// http://77.73.69.230:8090/
// const token = localStorage.getItem("user", ["token"]);
let token = localStorage.getItem("token");

const apiClient = axios.create({
  baseURL: "https://all-ice-test.ru:8090",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-access-token": token,
  },
  timeout: 10000,
});

export const apiArena = {
  getArenas({ city, currentPage, pageSize, queryString, sort, approved }) {
    console.log(city);
    var url = `/arena/search?approved=${approved}&currentPage=${currentPage}&pageSize=${pageSize}&sort=${sort}`;

    if (queryString !== undefined) {
      url += `&queryString=${queryString}`;
    }

    if (city != "Все города") {
      url += `&city=${city}`;
    }

    return apiClient.get(url);
  },
  getAllArenas() {
    return apiClient.get(`/arenas`);
  },
  getArena(arenaId) {
    return apiClient.get(`/arena/${arenaId}`);
  },
  getServices(arenaId) {
    return apiClient.get(`/arena/${arenaId}/services`);
  },
  getService(serviceId) {
    return apiClient.get(`/service/${serviceId}`);
  },
  filterTeams({
    arenaId,
    city,
    type,
    currentPage,
    pageSize,
    queryString,
    sortBy,
  }) {
    const url =
      `/arena/${arenaId}/teams/LK?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
      `&queryString=${queryString}&type=${type}&sortDir=${sortBy}`;
    return apiClient.get(url);
  },
  filterTrainers({
    arenaId,
    city,
    currentPage,
    pageSize,
    queryString,
    sortBy,
  }) {
    const url =
      `/arena/${arenaId}/users/LK?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
      `&queryString=${queryString}&sortBy=${sortBy}&role=TRAINER`;
    return apiClient.get(url);
  },

  getTeams(arenaId) {
    return apiClient.get(`/arena/${arenaId}/teams`);
  },
  getTeamsVisible(arenaId) {
    return apiClient.get(`/arena/${arenaId}/teams/visible`);
  },
  getTrainers(arenaId, visible) {
    if (visible) {
      return apiClient.get(`/arena/${arenaId}/users/visible`);
    }
    return apiClient.get(`/arena/${arenaId}/users`);
  },
  getTrainersVisible(arenaId) {
    return apiClient.get(`/arena/${arenaId}/trainers`);
  },
  getEvents(arenaId) {
    return apiClient.get(`/arena/${arenaId}/events`);
  },
  getCities() {
    return apiClient.get(`/arena/cities`);
  },
  getPrices(serviceId) {
    return apiClient.get(`/service/${serviceId}/prices`);
  },
  getArenaByUserId(userId) {
    return apiClient.get(`/user/${userId}/arenas`);
  },
  getServicePrices(serviceId) {
    console.log(`/service/${serviceId}/prices`);
    return apiClient.get(`/service/${serviceId}/prices`);
  },

  putArenaTeam({ arenaTeam, checked }) {
    const payload = {
      arenaId: arenaTeam.arenaId,
      teamId: arenaTeam.team.id,
      visibility: checked ? 0 : 1,
    };
    const id = arenaTeam.id;
    return apiClient.put(`/arena/team?id=${id}`, payload);
  },
  putArenaUser({ arenaUser, checked }) {
    const payload = {
      arenaId: arenaUser.arenaId,
      userId: arenaUser.user.id,
      visibility: checked ? 0 : 1,
    };
    const id = arenaUser.id;
    console.log("🚀 ~ file: index.js ~ line 68 ~ putArenaTeam ~ id", {
      id,
      payload,
    });
    return apiClient.put(`/arena/user?id=${id}`, payload);
  },
  putService({ serviceId, service }) {
    return apiClient.put(`/service/${serviceId}`, service);
  },
  putArena({ arenaId, arena }) {
    return apiClient.put(`/arena/${arenaId}`, arena);
  },
  postArena(arena) {
    return apiClient.post("/arena", arena);
  },
  postService(service) {
    return apiClient.post("/service", service);
  },
  postPrice(price) {
    return apiClient.post(`/price`, price);
  },
  postEvent(event) {
    return apiClient.post("/event", event);
  },

  createArenaUser({ arenaId, userId, visibility, roleId }) {
    const payload = {
      arenaId,
      userId,
      visibility,
      roleId,
    };
    return apiClient.post(`/arena/user`, payload);
  },
  createArenaTeam({ arenaId, teamId, visibility }) {
    const payload = {
      arenaId,
      teamId,
      visibility,
    };
    return apiClient.post(`/arena/team`, payload);
  },
  createArenaTrainer({ arenaId, trainerId, visibility }) {
    const payload = {
      arenaId,
      userId: trainerId,
      visibility,
    };
    return apiClient.post(`/arena/user`, payload);
  },

  deleteService(serviceId) {
    return apiClient.delete(`/service/${serviceId}`);
  },
  deletePrice(priceId) {
    return apiClient.delete(`/price/${priceId}`);
  },
  deleteArena(arenaId) {
    return apiClient.delete(`/arena/${arenaId}`);
  },
  deleteEvent(eventId) {
    return apiClient.delete(`/event/${eventId}`);
  },
  deleteTeam({ arenaId, teamId }) {
    return apiClient.delete(`/arena/${arenaId}/team/${teamId}`);
  },
  deleteTrainer({ arenaId, trainerId }) {
    return apiClient.delete(`/arena/${arenaId}/user/${trainerId}`);
  },
  getArenasList() {
    return apiClient.get(`/arenas`);
  },
};

export const apiTeam = {
  getTeams() {
    return apiClient.get(`/teams`);
  },
  getTeam(teamId) {
    return apiClient.get(`/team/${teamId}`);
  },
  getArenas(teamId) {
    return apiClient.get(`/team/${teamId}/arenas`);
  },
  getContacts(teamId) {
    return apiClient.get(`/team/${teamId}/contact`);
  },
  getUsers(teamId) {
    return apiClient.get(`/team/${teamId}/users`);
  },
  getForums(teamId) {
    return apiClient.get(`/team/${teamId}/forums`);
  },
  postTeam(team) {
    return apiClient.post(`/team`, team);
  },
  putTeam({ teamId, team }) {
    return apiClient.put(`/team/${teamId}`, team);
  },

  deleteTeam(teamId) {
    return apiClient.delete(`/team/${teamId}`);
  },
};

export const apiUser = {
  filterAdminTeams({
    userId,
    city,
    type,
    currentPage,
    pageSize,
    queryString,
    sortBy,
  }) {
    const url =
      `/user/${userId}/teams/LK?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
      `&queryString=${queryString}&type=${type}&sortBy=${sortBy}`;
    return apiClient.get(url);
  },
  filterAdminAllTeams({
    userId,
    city,
    currentPage,
    pageSize,
    queryString,
    sortBy,
  }) {
    const url =
      `/user/${userId}/teams/LK?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
      `&queryString=${queryString}&sortBy=${sortBy}`;
    return apiClient.get(url);
  },
  filterTeams({ userId, userTeamFilter }) {
    //TODO: will finally change to this in future
    const { page, search, numItems, sort_asc, address, type } = userTeamFilter;
    const url =
      `/user/${userId}/teams/search?city=${address}&currentPage=${page}&pageSize=${numItems.value}` +
      `&queryString=${search}&sortBy=${sort_asc.key}&type=${type.key}`;
    return apiClient.get(url);
  },

  filterAdminArenas({
    userId,
    city,
    currentPage,
    pageSize,
    queryString,
    sortBy,
  }) {
    var url = ``;
    if (queryString !== undefined) {
      url =
        `/user/${userId}/arenas/LK?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
        `&queryString=${queryString}&sortBy=${sortBy}`;
    } else {
      url =
        `/user/${userId}/arenas/LK?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
        `&sortBy=${sortBy}`;
    }
    return apiClient.get(url);
  },
  filterArenas({ userId, userArenaFilter }) {
    //TODO: will finally change to this in future
    const { page, search, numItems, sort_asc, address, type } = userArenaFilter;
    const url =
      `/user/${userId}/arenas/search?city=${address}&currentPage=${page}&pageSize=${numItems.value}` +
      `&queryString=${search}&sortBy=${sort_asc.key}&type=${type.key}`;
    return apiClient.get(url);
  },

  getTeams(userId) {
    // TODO: Temporal
    return apiClient.get(`/user/${userId}/teams`);
  },
  getTeam(teamId) {
    return apiClient.get(`/team/${teamId}`);
  },
  getArenas(userId) {
    // TODO: Temporal
    return apiClient.get(`/user/${userId}/arenas`);
  },
  getArenasTitle(userId) {
    return apiClient.get(`/user/${userId}/arenas/titles`);
  },
  deleteArena(arenaId) {
    return apiClient.delete(`/arena/${arenaId}`);
  },
  deleteTeam(teamId) {
    return apiClient.delete(`/team/${teamId}`);
  },
  deleteForum(forumId) {
    return apiClient.delete(`/forum/${forumId}`);
  },
  getUser(userId) {
    return apiClient.get(`/user/${userId}`);
  },
  getUsers() {
    return apiClient.get(`/users`);
  },
  getRoles(userId) {
    return apiClient.get(`/user/${userId}/roles`);
  },
  getRole(roleId) {
    return apiClient.get(`/role/${roleId}`);
  },
  createUserTeam(userTeamId) {
    return apiClient.post(`/team/user`, userTeamId);
  },
  getForums(userId) {
    return apiClient.get(`/user/${userId}/forums`);
  },
  getForum(forumId) {
    return apiClient.get(`/forum/${forumId}`);
  },
  postForum(forum) {
    return apiClient.post(`/forum`, forum);
  },
  putForum({ forumId, forum }) {
    console.log("🚀 ~ file: index.js ~ line 230 ~ putForum ~ forum", forum);

    return apiClient.put(`/forum/${forumId}`, forum);
  },
  putUser({ userId, user }) {
    return apiClient.patch(`/user/${userId}`, user);
  },
  patchRole({ roleId, role }) {
    return apiClient.patch(`/role/${roleId}`, role);
  },
  getRoleID(roleName) {
    return apiClient.get(`/role?title=${roleName}`);
  },
  createUserRole(userRole) {
    return apiClient.post(`/role`, userRole);
  },
  getUsersByRole(role) {
    return apiClient.get(`/user/role?role=${role}`);
  },
  createUserLeague(userLeagueId) {
    return apiClient.post(`/league/user`, userLeagueId);
  },
  deleteLeague(leagueId) {
    return apiClient.delete(`/league/${leagueId}`);
  },
  deleteSchool(schoolId) {
    return apiClient.delete(`/school/${schoolId}`);
  },
  getLeagues(userId, roleId) {
    return apiClient.get(`/user/${userId}/leagues?roleId=${roleId}`);
  },
  getKidsTournaments(userId, roleId) {
    return apiClient.get(`/user/${userId}/tournaments?roleId=${roleId}`);
  },
  getSchools(userId, roleId) {
    return apiClient.get(`/user/${userId}/schools?roleId=${roleId}`);
  },
  createUserSchool(userSchoolId) {
    return apiClient.post(`/school/user`, userSchoolId);
  },
  createUserCamp(userCampId) {
    return apiClient.post(`/user/camp`, userCampId);
  },
  deleteCamp(campId) {
    return apiClient.delete(`/camp/${campId}`);
  },
  getCamps(userId, roleId) {
    return apiClient.get(`/user/${userId}/camps?roleId=${roleId}`);
  },
};

export const apiForum = {
  filterForum({ filter, type }) {
    const { page, search, numItems, sort_asc, address } = filter;
    let url =
      `/forum/search?city=${address}&currentPage=${page}&pageSize=${numItems.value}` +
      `&queryString=${search}&sortBy=${sort_asc.key}&type=${type}`;
    if (type == "TEAMPLAYER") {
      url += `&role=${filter.role}&grip=${filter.grip}`;
    }
    return apiClient.get(url);
  },
  getForums() {
    return apiClient.get(`/forums`);
  },
  getForum(forumId) {
    return apiClient.get(`/forum/${forumId}`);
  },
  getCities() {
    return apiClient.get(`/forums/cities`);
  },
};

export const apiSchool = {
  filterSchools(filter, type) {
    console.log(filter);
    const { city, currentPage, pageSize, queryString, sortBy, approved } =
      filter;
    let url;
    if (city !== "Все города") {
      url =
        `/school/search?approved=${approved}&city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
        `&queryString=${queryString}&sort=${sortBy}&type=${type}`;
    } else {
      url =
        `/school/search?approved=${approved}&currentPage=${currentPage}&pageSize=${pageSize}` +
        `&queryString=${queryString}&sort=${sortBy}&type=${type}`;
    }
    return apiClient.get(url);
  },
  getSchools() {
    return apiClient.get(`/schools`);
  },
  getSchool(schoolId) {
    return apiClient.get(`/school/${schoolId}`);
  },
  getCities() {
    return apiClient.get(`/schools/cites`); //TODO: correctly spell out
  },
  postSchool(params) {
    return apiClient.post(`/school`, params);
  },
  getSchoolTrainers(schoolId) {
    return apiClient.get(
      `/school/${schoolId}/users?role=TRAINER&schoolId=${schoolId}`
    );
  },
  getSchoolArenas(schoolId) {
    return apiClient.get(`/school/arenas?schoolId=${schoolId}`);
  },
  putSchool({ schoolId, school }) {
    console.log(schoolId, school);
    return apiClient.put(`/school/${schoolId}`, school);
  },
};

export const apiTeamPlayer = {
  filterTeams({ city, currentPage, pageSize, queryString, sortBy }) {
    let url;
    if (city !== "Все города") {
      url =
        `/team/search?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
        `&queryString=${queryString}&sortBy=${sortBy}`;
    } else {
      url =
        `/team/search?currentPage=${currentPage}&pageSize=${pageSize}` +
        `&queryString=${queryString}&sortBy=${sortBy}`;
    }
    return apiClient.get(url);
  },
  filterPlayers({ city, currentPage, pageSize, queryString, sortBy }) {
    const url =
      `/user/search?city=${city}&currentPage=${currentPage}&pageSize=${pageSize}` +
      `&queryString=${queryString}&role=PLAYER&sortBy=${sortBy}`;
    console.log(url);
    return apiClient.get(url);
  },
  getTeams() {
    return apiClient.get(`/teams`);
  },
  getPlayers() {
    return apiClient.get(`/users`);
  },
  getTeamCities() {
    return apiClient.get(`/team/cities`);
  },
  getPlayerCities() {
    return apiClient.get(`/user/cities`);
  },
  getTrainers() {
    return apiClient.get(`/user/role?role=TRAINER`);
  },
  /////////////////
  getTeamPlayers() {
    return apiClient.get(`/user/role?role=PLAYER`);
  },
  getTeamAdmins() {
    return apiClient.get(`/user/role?role=TEAM_ADMIN`);
  },
  /////////////////
  getPlayerInformation({ id }) {
    return apiClient.get(`/user/${id}/forums`);
  },
};

export const apiLeagues = {
  filterKidsTournaments(data) {
    return apiClient.post(
      `/tournaments/search?currentPage=${data.currentPage}&pageSize=${data.pageSize}&sortAscending=${data.sortAscending}&sortParameter=title`,
      data.filters
    );
  },
  addKidsTournament(data) {
    return apiClient.post(`/tournament`, data);
  },
  createUserTournament(userTournamentId) {
    return apiClient.post(`/user/tournament`, userTournamentId);
  },
  postKidsTournamentArena(data) {
    return apiClient.post(`/tournament/arena`, data);
  },
  deleteKidsTournamentArena({ arenaId, tournamentId }) {
    return apiClient.delete(
      `/tournament/arena?arenaId=${arenaId}&tournamentId=${tournamentId}`
    );
  },
  getKidsTournament(tournamentId) {
    return apiClient.get(`/tournament/${tournamentId}`);
  },
  getKidsTournamentArenas(tournamentId) {
    return apiClient.get(`/tournament/${tournamentId}/arenas`);
  },
  updateKidsTournament(ndata) {
    return apiClient.put(`/tournament/${ndata.tournamentId}`, ndata.data);
  },
  deleteKidsTournament(tournamentId) {
    return apiClient.delete(`/tournament/${tournamentId}`);
  },
  filterLeagues(config) {
    token = localStorage.getItem("token");
    const url = `/league/search`;
    return apiClient.post(url, [...config[0]], {
      params: config[1],
    });
  },
  filteringLeagues(data) {
    return apiClient.post(
      `/league/search?currentPage=${data.currentPage}&pageSize=${data.pageSize}&sortAscending=${data.sortAscending}&sortParameter=title`,
      data.filters
    );
  },
  searchLeagues(config) {
    let url;
    if (config.city === "Все города") {
      url = `/league/search?currentPage=${config.currentPage}&pageSize=${config.pageSize}&queryString=${config.queryString}&sortBy=${config.sortBy}`;
    } else {
      url = `/league/search?city=${config.city}&currentPage=${config.currentPage}&pageSize=${config.pageSize}&queryString=${config.queryString}&sortBy=${config.sortBy}`;
    }
    return apiClient.get(url);
  },
  getLeaguesCities() {
    return apiClient.get("/league/cities");
  },
  getLeagueArenas(leagueId) {
    return apiClient.get(`/league/arena?leagueId=${leagueId}`);
  },
  getLeagueAdministrators(leagueId) {
    return apiClient.get(`/league/${leagueId}/users`);
  },
  getLeagues() {
    return apiClient.get(`/leagues`);
  },
  getLeague(leagueId) {
    return apiClient.get(`/league/${leagueId}`);
  },
  postLeague(league) {
    return apiClient.post("/league", league);
  },
  putLeague({ leagueId, league }) {
    return apiClient.put(`/league/${leagueId}`, league);
  },
  deleteLeague(leagueId) {
    return apiClient.delete(`/league/${leagueId}`);
  },
  getSeasons() {
    return apiClient.get(`/seasons`);
  },
  getLeagueTournaments(params) {
    return apiClient.get(
      `/league/${params.leagueId}/tournaments?seasonId=${params.seasonId}`
    );
  },
  getTournamentDivisions(tournamentId) {
    return apiClient.get(`/tournament/divisions?tournamentId=${tournamentId}`);
  },
  getDivisionTeams(config) {
    token = localStorage.getItem("token");
    const url = `/division/team/search`;
    return apiClient.post(url, [...config[0]], {
      params: config[1],
    });
  },
  createTournament(data) {
    return apiClient.post("/tournament", data);
  },
  addTournamentLeague(params) {
    return apiClient.post("/league/season", {
      leagueId: params.leagueId,
      seasonId: params.seasonId,
      tournamentId: params.tournamentId,
    });
  },
  createDivision(title) {
    return apiClient.post("/division", {
      title: title,
    });
  },
  addDivisionToTournament(params) {
    return apiClient.post("/tournament/division", {
      tournamentId: params.tournamentId,
      divisionId: params.divisionId,
    });
  },
  addSeasonToLeague(params) {
    return apiClient.post("/league/season", {
      leagueId: params.leagueId,
      seasonId: params.seasonId,
    });
  },
  addTeam(team) {
    return apiClient.post("/division/team", {
      ...team,
    });
  },
  getAllSeasons() {
    return apiClient.get("/seasons");
  },
};

export const apiAuth = {
  postUser(user) {
    return apiClient.post(`/registration`, user);
  },
  updateUser({ userId, user }) {
    console.log(
      "🚀 ~ file: index.js ~ line 310 ~ updateUser ~ user",
      user,
      userId
    );

    return apiClient.patch(`/user/${userId}`, user);
  },
  login(credential) {
    return apiClient.post(`/login`, credential);
  },
  sendConfirmationCode(mail) {
    console.log("🚀 ~ file: index.js ~ line 305 ~ forgetPassword ~ mail", {
      mail,
    });

    return apiClient.post(`/sendConfirmationCode`, { mail });
  },
  changePassword(credentials) {
    console.log("🚀 ~ file: index.js ~ line 305 ~ forgetPassword ~ mail", {
      credentials,
    });
    return apiClient.post(`/changePassword`, credentials);
  },
};

export const apiCamps = {
  postCamp(camp) {
    return apiClient.post("/camp", camp);
  },
  deleteCamp(campId) {
    return apiClient.delete(`/camp/${campId}`);
  },
  getCamp(campId) {
    return apiClient.get(`/camp/${campId}`);
  },
  putCamp({ campId, camp }) {
    return apiClient.put(`/camp/${campId}`, camp);
  },
  fetchCamps(state) {
    return axios.get(`https://all-ice-test.ru:8090/camps`, { params: state });
  },
  getCampArenas(campId) {
    return apiClient.get(`/camp/campId/arenas?campId=${campId}`);
  },
};

export const apiFile = {
  postFile(formData) {
    return apiClient.post(`/file`, formData);
  },
};
