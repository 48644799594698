<template>
  <div>
    <div class="text-h6 mb-4">Арены</div>
    <v-row>
      <v-col cols="6" class="" v-show="arenas.length">
        <div class="mt-4 my-auto" v-for="item in arenas" :key="item.id">
          <span class="mr-4 ">{{ item.arena.title }}</span>
          <v-icon class="" @click="removeArena(item.arena.id)">
            mdi-close
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="admins_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить арену
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить арену</div>
          <div class="mb-4">
            <v-icon @click.stop="admins_dialog = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-col cols="12" class="text-h6 mb-n4">Арены</v-col>
          <v-autocomplete
            v-model="selected_arena"
            :items="all_arenas"
            :loading="is_searching"
            :search-input.sync="search_text"
            color="white"
            solo
            flat
            single-line
            hide-selected
            hide-no-data
            item-text="fullTitle"
            item-value="id"
            label="Поиск арены"
            placeholder="Поиск арены"
            prepend-inner-icon="mdi-magnify"
            return-object
            clearable
            hide-details="auto"
            class="rounded-lg"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-btn
            class="body-2"
            @click="admins_dialog = false"
            elevation="0"
          >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="body-2"
            @click="addArena"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import {ADD_ARENA_TO_SCHOOL} from "@/api";

export default {
  props: {
    schoolId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_arena: null,
      search_text: "",
      is_searching: false,
      admins_dialog: false,
      adding: false,
      users: [],
      userOrganizerId: "",
      otherOrganizers: [],
      arenasList: [],
    };
  },
  async created() {
    await this.getSchoolArenas();
    await this.$store.dispatch("arena/getAllArenas");
  },
  computed: {
    ...mapState("school", ["arenas"]),
    ...mapState("arena", {all_arenas: "arenas"}),
    // teamadminsList() {
    //   return this.teamadmins.map((x) => {
    //     const user = x;
    //     return {
    //       ...user,
    //       fullName: `${user.name} ${user.middleName} ${user.surname}`,
    //     };
    //   });
    // },
  },
  methods: {
    // async getAllTeamAdministrator() {
    //   await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TEAM_ADMIN`)
    //   .then( (res) => {
    //     this.admins = res.data
    //     console.log(this.admins)
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   })
    // },

    async addArena() {
      this.adding = true;
      const data = {
        schoolId: this.schoolId,
        arenaId: this.selected_arena.id,
      };
      await Axios.post(`${ADD_ARENA_TO_SCHOOL}`, data)
        .then(() => {
          this.admins_dialog = false;
          this.selected_arena = null;
          this.getSchoolArenas();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.adding = false;
        });
    },
    async removeArena(id) {
      await Axios.delete(`${ADD_ARENA_TO_SCHOOL}?arenaId=${id}&schoolId=${this.schoolId}`)
        .then(() => {
          this.getSchoolArenas();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.adding = false;
        });
    },

    getSchoolArenas() {
      return this.$store.dispatch("school/getSchoolArenas", this.schoolId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
