<template>
  <div>
    <div class="text-h6">Арены</div>
    <v-row>
      <v-col cols="6" class="" v-show="kidsTournamentArenas.length">
        <div class="mt-4 my-auto" v-for="item in kidsTournamentArenas" :key="item.id">
          <v-row>
            <v-col cols="6" class="pt-0">
              <v-card color="transparent" elevation="0">
                <div class="d-flex flex-no-wrap">
                <div class="ma-3">
                  <v-avatar class="rounded-lg" width="282px" height="186px" tile>
                    <v-img
                        contain
                        :src="
                        item.arenaLogo != null
                            ? item.arenaLogo
                            : require('@/assets/team_room_1.jpg')
                        "
                    >
                      <v-container class="ma-1">
                        <v-row>
                          <v-btn
                            @click.stop="removeArena(item.arenaId)"
                            class="rounded-lg pa-0"
                            color="#fff"
                            height="25"
                            elevation="0"
                            >
                              <v-icon >mdi-close</v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-img>
                  </v-avatar>
                </div>
                <div class="description">
                    <v-card-text style="width: 100%">
                    <div class="text-h5 mb-4"> {{ item.arenaTitle }} </div>
                    <div class="body-1 grey--text">
                        {{ item.arenaCity }}
                    </div>
                    </v-card-text>
                    <v-card-actions class="pl-4 bottom">
                    <router-link
                        :to="{ name: 'arena-information', params: { arenaId: item.arenaId } }"
                        class="reset-link"
                    >
                        <v-btn
                        class="px-6"
                        color="grey lighten-2"
                        x-large
                        elevation="0"
                        >
                        Подробнее
                        </v-btn>
                    </router-link>
                    </v-card-actions>
                </div>
                </div>
            </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="admins_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 mt-6"
          color="primary"
          large
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Добавить арену
        </v-btn>
      </template>

      <v-card :loading="adding" class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить арену</div>
          <div class="mb-4">
            <v-icon @click.stop="admins_dialog = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-col cols="12" class="text-h6 mb-n4">Арены</v-col>
          <v-autocomplete
            v-model="selected_arena"
            :items="arenasList"
            :loading="is_searching"
            :search-input.sync="search_text"
            color="white"
            solo
            flat
            single-line
            hide-selected
            hide-no-data
            item-text="fullTitle"
            item-value="id"
            label="Поиск арены"
            placeholder="Поиск арены"
            prepend-inner-icon="mdi-magnify"
            return-object
            clearable
            hide-details="auto"
            class="rounded-lg"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-btn
            class="body-2"
            @click="admins_dialog = false"
            elevation="0"
          >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="body-2"
            @click="addArena"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_arena: null,
      search_text: "",
      is_searching: false,
      admins_dialog: false,
      adding: false,
      users: [],
      userOrganizerId: "",
      otherOrganizers: [],
    };
  },
  async created() {
    this.$store.dispatch("leagues/getKidsTournamentArenas", this.tournamentId);
    this.$store.dispatch("arena/getArenasList")
  },
  computed: {
    ...mapState("leagues", ["kidsTournamentArenas"]),
    ...mapState("arena", ["arenasList"]),
  },
  methods: {

    async addArena() {
      this.adding = true;
      const data = {
        tournamentId: this.tournamentId,
        arenaId: this.selected_arena.id,
      };
      this.$store.dispatch("leagues/postKidsTournamentArena", data).then(()=> {
        this.admins_dialog = false
        this.selected_arena = null
        this.adding = false
        this.$store.dispatch("leagues/getKidsTournamentArenas", this.tournamentId);
      })
    },
    async removeArena(id) {
      const data = {
        arenaId: id,
        tournamentId : this.tournamentId
      }
      this.$store.dispatch("leagues/deleteKidsTournamentArena", data).then(()=> {
        this.$store.dispatch("leagues/getKidsTournamentArenas", this.tournamentId);
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
