<template>
  <v-card
    class="rounded-lg d-flex"
    :elevation="elevation"
    @mouseover="elevation = '2'"
    @mouseout="elevation = '0'"
    @click="goToArena"
  >
    <v-avatar size="200" tile
      ><img
        :src="
          this.pickAvatar(school.editedProfilePicture, school.profilePicture)
        "
        alt="logo"
      />
    </v-avatar>
    <div class="d-flex flex-column justify-space-between">
      <div>
        <v-card-title class="pb-5">
          {{
            school.title.length > 25
              ? school.title.slice(0, 25) + "..."
              : school.title
          }}
        </v-card-title>
        <v-card-subtitle>
          <p class="ma-0">{{ school.city }}</p>
        </v-card-subtitle>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SchoolCard",
  props: {
    school: Object,
  },
  mounted() {
    // this.selected_arenas.forEach((x) => {
    //   if (x.id === this.arena.id) {
    //     this.selected = true;
    //   }
    // });
  },
  data() {
    return {
      elevation: 0,
      selected: false,
    };
  },
  methods: {
    goToArena() {
      const leagueId = this.league.id;
      this.$router.push({
        name: "league-information",
        params: {
          leagueId: leagueId,
        },
      });
    },
    openCardMap() {
      this.$router.push({
        name: "arena-map-all",
        query: { arenaTitle: `${this.arena.title}` },
      });
    },
    selectArena(arena) {
      this.selected = !this.selected;
      if (this.selected) {
        this.$store.dispatch("arena/addToSelectedArenas", arena);
      } else {
        this.$store.dispatch("arena/removeFromSelectedArenas", arena);
      }
    },
    pickAvatar(croppedImg, originImg) {
      if (croppedImg !== null) {
        return croppedImg;
      }
      return originImg ? originImg : require("@/assets/team_room_1.jpg");
    },
  },
};
</script>

<style scoped></style>
