<template>
  <div class="white pa-4" style="border-radius: 12px;">
    <p class="text-h4">Расписание мероприятий</p>
    <v-row class="mt-1">
      <v-col>
        <v-btn
          large
          @click="goToAddEvent"
          class="mr-2 mb-2 primary"
          elevation="0"
        >
          Создать мероприятие
        </v-btn>
      </v-col>
    </v-row>
    <div class="rounded-lg white">
      <v-row class="mt-2 ml-0 mb-0">
        <v-tabs v-model="value" class="d-flex flex-no-wrap rounded-lg">
          <v-tab class="px-10" v-for="item in schedule_nav" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-sheet tile height="60" class="d-flex align-center" color="#EBF5FB">
          <div class="d-flex align-center">
            <v-btn
            fab
            text
            color="grey darken-2"
            @click="prev"
            >
              <v-icon >
                mdi-chevron-left
              </v-icon>
            </v-btn>
              
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
            fab
            text
            color="grey darken-2"
            @click="next"
            >
              <v-icon >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <div style="width: 200px" class="mx-3">
            <v-menu
              v-model="date_picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  single-line
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="date_picker = false"
                :first-day-of-week="1"
                locale="ru-RU"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-sheet>
      <v-sheet height="600" class="px-4 pb-4 mt-4 overflow-auto">
        <v-calendar
          v-show="value !== 3"
          ref="calendar"
          v-model="date"
          :weekdays="weekday"
          :type="type"
          :events="ievents"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          locale="ru-RU"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="secondary" @click="goToEdit(selectedEvent.id)">
                <v-icon size="22" color="#fff">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn icon color="secondary" @click="deleteEvent">
                <v-icon size="22" color="#fff">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.description"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <div v-show="value === 3 && events.length === 0" class="text-center">
          <h3 class="grey--text lighten-3--text text-h6 mb-2">
            Нет мероприятий
          </h3>
        </div>
        <div
          v-show="value === 3"
          v-for="(event, index) in events"
          :key="index"
          class="mb-10 pl-5"
        >
          <ArenaEventCard :event="event"></ArenaEventCard>
        </div>
      </v-sheet>
    </div>
    <!-- <create-event :arenaId="arenaId" :userId="userId" /> -->
  </div>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment";
import ArenaEventCard from "@/components/Arena/ArenaEventCard";
import Axios from "axios";
import { POST_EVENT } from "@/api";
// import CreateEvent from '../../Events/CreateEvent/CreateEvent.vue';

export default {
  components: { 
    ArenaEventCard, 
    // CreateEvent 
  },
  props: {
    arenaId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    '$route.params.arenaId'() {
      this.$store.dispatch("arena/getEvents", this.arenaId);
    },
    value(x) {
      if (x === 0) this.type = "day";
      if (x === 1) this.type = "week";
      if (x === 2) this.type = "month";
    },
  },
  filters: {
    descriptionLength(value) {
      if (!value) return "";
      if (value.length < 30) {
        return value;
      }
      return value.slice(0, 30) + "...";
    },
  },
  computed: {
    ...mapState("arena", ["events"]),
    computedDateFormatted () {
      return this.date ? moment(this.date).format('DD-MM-YYYY') : ''
    },
    ievents() {
      const events = [];
      this.events.forEach((event) => {
        const nEvents = this.generateEvents(event);
        events.push(...nEvents);
      });
      return events;
    },
  },
  created() {
    this.$store.dispatch("arena/getEvents", this.arenaId);
  },
  data() {
    return {
      type: "month",
      schedule_nav: ["Дневное", "Недельное", "Месячное", "Список мероприятий"],
      mode: "column",
      mode_lesson: ["0", "1", "2"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      value: 2,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_picker: false,
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey lighten-1",
      ],
    };
  },
  methods: {
    goToAddEvent() {
      const arenaId = this.arenaId;
      this.$router.push({
        name: "create-event",
        params: { arenaId },
      });
    },
    intervalFormat(interval) {
      return interval.time;
    },
    viewDay({ date }) {
      this.value = 0;
      this.date = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        console.log(this.selectedElement)
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    generateEvents(event) {
      const selectedDays = event.days;
      const startDate = event.startDate,
        endDate = event.endDate,
        startTime = event.startTime,
        endTime = event.endTime,
        title = event.title,
        description = event.description,
        repeat = event.repeat;
      const color = this.colors[this.rnd(0, this.colors.length - 1)];
      const id = event.id

      const startMoment = moment(
        `${startDate}T${startTime ? startTime : "00:00"}:00`
      );
      const endMoment = moment(`${endDate}T${endTime ? endTime : "00:00"}:00`);
      const arr_events = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < selectedDays.length; j++) {
          if (Number(startMoment.day()) === selectedDays[j]) {
            const startMomentDate = startMoment.format("YYYY-MM-DDTHH:mm:ss");
            const beginMoment = moment(startMomentDate);

            while (
              Math.ceil(endMoment.diff(beginMoment, "weeks", true)) !== 0
            ) {
              const stopInterval = beginMoment.format("YYYY-MM-DD");
              const stopMoment = moment(
                `${stopInterval}T${endTime ? endTime : "00:00"}:00`
              );
              arr_events.push({
                name: title,
                start: beginMoment.format("YYYY-MM-DDTHH:mm:ss"),
                end: stopMoment.format("YYYY-MM-DDTHH:mm:ss"),
                color: color,
                timed: true,
                description: description,
                id: id
              });
              if (repeat === 0) break;
              beginMoment.add(1, "weeks");
            }

            break;
          }
        }
        startMoment.add(1, "days");
      }
      return arr_events;
    },

    async deleteEvent(){
      await Axios.delete(`${POST_EVENT}/${this.selectedEvent.id}`)
      .then( () => {
        this.selectedOpen = false
        this.$store.dispatch("arena/getEvents", this.arenaId);
      })
      .catch((error) => {
        console.error(error);
      })
    },

    goToEdit(eventId) {
      this.$router.push({
        name: "admin-team-edit-event",
        params: { eventId },
      });
    },

    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
  },
};
</script>

<style>
.vertical-scroll {
  overflow: auto;
}
</style>
