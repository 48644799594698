<template>
  <v-card class="mx-auto rounded-lg" elevation="0">
    <v-list color="#E2E2E2" style="padding: 0">
      <v-list-item>
        <v-list-item-title>Мои арены</v-list-item-title>

        <v-list-item-icon>
          <v-icon
            @click="
              $router.push({
                name: 'create-complex-information',
                params: { userId },
              })
            "
            color="#000"
            >mdi-plus</v-icon
          >
        </v-list-item-icon>
      </v-list-item>

      <v-list-group
        style="background-color: #fff"
        v-for="arena in arenas"
        :key="arena.arenaId"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title @click="changeArena(arena.arenaId)">{{
            arena.arenaTitle
          }}</v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn @click.stop="goToAdminArenaEdit(arena.arenaId)" small icon>
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn @click.stop="openWarningDialog(arena.arenaId)" small icon>
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>

        <v-card elevation="0" class="pl-4">
          <AppArenaVerticalSidebar :key="componentKey" :arena="arena" />
        </v-card>
      </v-list-group>
    </v-list>
    <v-dialog v-model="warning_dialog" max-width="600">
      <v-card class="grey lighten-5">
        <v-card-text class="text-h5 black--text pt-5">
          Вы действительно хотите удалить эту арену?
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-spacer></v-spacer>
          <v-btn class="body-2" @click="warning_dialog = false" elevation="0">
            Отмена
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="error"
            class="body-2"
            @click="deleteArena"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sidebar: null,
      arenastest: [],
      componentKey: "",
      warning_dialog: false,
      arenaId: "",
    };
  },
  created() {
    this.getUserArenasTitle(this.userId);
  },
  computed: {
    ...mapState("user", ["user", "arenas"]),
  },
  methods: {
    changeArena(id) {
      this.componentKey = id;
    },

    goToAdminArenaEdit(id) {
      const arenaId = id;

      this.$router.push({
        name: "edit-complex-information",
        params: { arenaId },
      });
    },

    openWarningDialog(id) {
      this.warning_dialog = true;
      this.arenaId = id;
    },

    deleteArena() {
      this.$store.dispatch("user/deleteArena", this.arenaId);
      this.warning_dialog = false;
    },
    ...mapActions("user", ["getUserArenasTitle"]),
  },
};
</script>

<style>
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 10px;
}

.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0;
}
</style>
