<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LeagTournaments",
  computed: {
    ...mapState("leagues", ["seasons"]),
  },
  props: {
    leagueId: String
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.container {
  background-color: white;
  height: 80px;
  border-radius: 10px;
}
</style>
