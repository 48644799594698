import { render, staticRenderFns } from "./LeagTournaments.vue?vue&type=template&id=69a86535&scoped=true&"
import script from "./LeagTournaments.vue?vue&type=script&lang=js&"
export * from "./LeagTournaments.vue?vue&type=script&lang=js&"
import style0 from "./LeagTournaments.vue?vue&type=style&index=0&id=69a86535&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a86535",
  null
  
)

export default component.exports