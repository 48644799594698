<template>
  <div class="white" style="border-radius: 12px;">
    <v-container class="pt-0 pb-0">
        <v-row>
            <v-col cols="3" class="mb-2 d-flex flex-column  align-center">
                <div>
                    <v-avatar class="rounded-lg" contain tile size="180">
                    <v-img
                        contain
                        :src="
                        team.profilePicture != null
                            ? team.profilePicture
                            : require('@/assets/team_room_1.jpg')
                        "
                    ></v-img>
                    </v-avatar>
                </div>
                <div class="mt-2">
                    <p class="text-h5 mb-0" >{{ team.title }}</p>
                </div>
                <div class="mt-4">
                    <p class="grey--text">
                        {{ team.city }}
                    </p>
                </div>
                <div>
                    <a
                    v-for="(item, index) in valid_contact_list"
                    class="reset-link"
                    :key="index"
                    :href="item.link"
                    target="_blank"
                    >
                    <v-btn
                        elevation="0"
                        x-small
                        color="transparent"
                        height="40px"
                        class="mx-1"
                    >
                        <v-icon color="#000"> {{ item.icon }}</v-icon>
                    </v-btn>
                    </a>
                </div>
            </v-col>
            <v-col cols="9">
                <v-container
                v-if="team.description ? team.description.length : false"
                >
                    <p class="text-h4">Описание</p>
                    <div v-if="team.description.length < 580">
                        <p class="text-justify" v-html="team.description"></p>
                    </div>
                    <div v-else>
                        <p
                        class="text-justify"
                        v-if="!readMoreInfo"
                        v-html="team.description.slice(0, 580) + '...'"
                        ></p>
                        <p class="text-justify" v-else v-html="team.description"></p>
                    </div>
                    
                    <v-btn
                        class="px-6"
                        color="grey lighten-2"
                        v-if="team.description.length > 580"
                        x-large
                        elevation="0"
                        @click.stop="readMoreInfo = !readMoreInfo"
                    >
                        {{ readMoreInfo ? "Скрыть" : "Показать больше" }}
                    </v-btn>
                </v-container>
                <v-container  v-if="media ? media.length : false">
                <p class="text-h4">Галерея</p>
                <v-row>
                    <v-col cols="6" md="4" lg="3" v-for="(item, i) in media" :key="i">
                    <v-img style="height: 200px" :src="item" @click="openGallery(i)"></v-img>
                    </v-col>
                    <LightBox
                    ref="lightbox"
                    :media="media"
                    :show-caption="true"
                    :show-light-box="false"
                    />
                </v-row>
                </v-container>
                <v-container
                v-show="teamArenas ? teamArenas.length : false"
                >
                <p class="text-h4">Арены</p>
                <v-row dense class="mx-n4" >
                    <div v-for="(arena, id) in teamArenas" :key="id">
                    <v-col class="pt-0" cols="12">
                    <v-card color="transparent" elevation="0">
                        <div class="d-flex flex-no-wrap">
                        <div class="ma-3">
                            <v-avatar class="rounded-lg" width="282px" height="186px" tile>
                            <v-img
                                contain
                                :src="
                                arena.arena.profilePicture != null
                                    ? arena.arena.profilePicture
                                    : require('@/assets/team_room_1.jpg')
                                "
                            ></v-img>
                            </v-avatar>
                        </div>
                        <div class="description">
                            <v-card-text style="width: 100%">
                            <div class="text-h5 mb-4">{{ arena.arena.title }}</div>
                            <div class="body-1 grey--text">
                                {{ arena.arena.city }}
                            </div>
                            </v-card-text>
                            <v-card-actions class="pl-4 bottom">
                            <router-link
                                :to="{ name: 'arena-information', params: { arenaId: arena.arena.id } }"
                                class="reset-link"
                            >
                                <v-btn
                                class="px-6"
                                color="grey lighten-2"
                                x-large
                                elevation="0"
                                >
                                Подробнее
                                </v-btn>
                            </router-link>
                            </v-card-actions>
                        </div>
                        </div>
                    </v-card>
                    </v-col>
                    </div>
                </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { GET_TEAM } from "@/api";
import LightBox from "vue-image-lightbox";

export default {
  name: "TeamName",
  components: {
    LightBox,
  },
//   props: {
//     teamId: {
//       type: String,
//       required: true,
//     },
//   },
  watch:{
    '$route.params.teamId'() {
      this.getAllTeamArenas()
      const teamId = this.teamId;
      this.$store.dispatch("team/getTeam", teamId).then(() => {
      const teamItem = this.team;

      this.contact_list = [
        {
          icon: "mdi-whatsapp",
          link: `${teamItem.whatsApp ? teamItem.whatsApp : ""}`,
        },
        {
          icon: "mdi-instagram",
          link: `${teamItem.instagram ? teamItem.instagram : ""}`,
        },
        { 
          icon: "mdi-alpha-k-box", 
          link: `${teamItem.vk ? teamItem.vk : ""}` },
        {
          icon: "mdi-web",
          link: `${teamItem.website ? teamItem.website : ""}`,
        },
        {
          icon: "mdi-music-note-outline",
          link: `${teamItem.tiktok ? teamItem.tiktok : ""}`,
        },
        {
          icon: "mdi-twitter",
          link: `${teamItem.twitter ? teamItem.twitter : ""}`,
        },
        {
          icon: "mdi-youtube",
          link: `${teamItem.youtube ? teamItem.youtube : ""}`,
        },
        {
          icon: "mdi-facebook",
          link: `${teamItem.facebook ? teamItem.facebook : ""}`,
        },
      ];

      this.validContactList()
    });
      
    }
  },
  filters: {
    descriptionLength(value) {
      if (!value) return "";
      if (value.length < 30) return value;
      return value.slice(0, 30) + "...";
    },
  },
  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
    ...mapState("team", ["team"]),
    ...mapState("team", ["users"]),
    ...mapState("auth", ["userId"]),
    media() {
      let _media = [];
      if (this.team.gallery) {
        this.team.gallery.forEach((x) => {
          const item = {
            thumb: x,
            src: x,
            caption: "<h4></h4>",
          };
          _media.push(item);
        });
      }
      return _media;
    },
  },
  created() {
    this.getAllTeamArenas()
    const teamId = this.teamId;
    this.$store.dispatch("team/getTeam", teamId).then(() => {
      const teamItem = this.team;

      this.contact_list = [
        {
          icon: "mdi-whatsapp",
          link: `${teamItem.whatsApp ? teamItem.whatsApp : ""}`,
        },
        {
          icon: "mdi-instagram",
          link: `${teamItem.instagram ? teamItem.instagram : ""}`,
        },
        { 
          icon: "mdi-alpha-k-box", 
          link: `${teamItem.vk ? teamItem.vk : ""}` },
        {
          icon: "mdi-web",
          link: `${teamItem.website ? teamItem.website : ""}`,
        },
        {
          icon: "mdi-music-note-outline",
          link: `${teamItem.tiktok ? teamItem.tiktok : ""}`,
        },
        {
          icon: "mdi-twitter",
          link: `${teamItem.twitter ? teamItem.twitter : ""}`,
        },
        {
          icon: "mdi-youtube",
          link: `${teamItem.youtube ? teamItem.youtube : ""}`,
        },
        {
          icon: "mdi-facebook",
          link: `${teamItem.facebook ? teamItem.facebook : ""}`,
        },
      ];

      this.validContactList()
    });
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formatter.format(newDate);
    },
    async getAllTeamArenas() {
      await Axios.get(`${GET_TEAM}${this.teamId}/arenas`)
      .then( (res) => {
        this.teamArenas = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    validContactList() {
      return this.valid_contact_list = this.contact_list.filter((x) => x.link !== "");
    },
  },
  data() {
    return {
      contact: null,
      premises_tab: null,
      arenaId: null,
      advert_nav: ["Команда ищет игроков", "Команда ищет тренера"],
      contact_list: null,
      readMoreInfo: false,
      teamArenas: [],
      readMorePlayers: false,
      valid_contact_list: [],
    };
  },
};
</script>

<style>
.description {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
}
</style>
