<template>
  <div class="white" style="border-radius: 12px;">
    <v-container class="pt-0 pb-0">
      <p class="text-h4 mb-0 pt-4">Мероприятия команды</p>
    </v-container>
    <v-container>
      <v-btn 
      color="#C3E1F2" 
      depressed
      @click="add_event_dialog = true">Создать мероприятие</v-btn>
    </v-container>
    <v-container>
      <div class="d-flex align-center justify-center white">
        <v-btn
        fab
        text
        color="grey darken-2"
        @click="prev"
        >
          <v-icon >
            mdi-chevron-left
          </v-icon>
        </v-btn>
          
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
        fab
        text
        color="grey darken-2"
        @click="next"
        >
          <v-icon >
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
      <v-sheet height="600" class="px-4 pb-4 overflow-auto">
        <v-calendar
          v-show="value !== 3"
          ref="calendar"
          v-model="date"
          :weekdays="weekday"
          type="month"
          :events="ievents"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          locale="ru-RU"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        
        <div v-show="value === 3 && events.length === 0" class="text-center">
          <h3 class="grey--text lighten-3--text text-h6 mb-2">
            Нет мероприятий
          </h3>
        </div>
      </v-sheet>
      <v-dialog
      max-width="900"
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement">
          <v-card
          style="border: 1px solid #379AD3; border-radius: 12px"
          class="pb-6"
          color="#F5FAFD"
          elevation="0">
            <div class="d-flex" style="background-color: #fff; border-bottom: 1px solid #379AD3;">
              <v-card-title class="text-h5" style="font-weight: 500;">{{selectedEvent.name}}, {{rightTime(selectedEvent.start)}}-{{rightTime(selectedEvent.end)}}</v-card-title>
              <v-spacer></v-spacer>
              <div class="d-flex align-center mr-4">
                <v-btn
                color="#fff"
                @click="goToEdit(selectedEvent.id)"
                depressed>редактировать</v-btn>
                <v-btn @click.stop="deleteEvent" icon>
                  <v-icon color="#000" size="20">
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <v-card-text class="text-h5 pb-2 pt-2" style="color: #000">Тип: {{selectedEvent.type}} </v-card-text>
            <v-card-text class="text-h5 pb-2" style="color: #000">Место: <span style="background: #FFFFFF; border-radius: 12px; padding: 5px 20px"> {{trainingPlace}} </span> </v-card-text>
            <v-card-text class="text-h5 pb-2" style="color: #000">Время: {{rightTime(selectedEvent.start)}}-{{rightTime(selectedEvent.end)}} </v-card-text>
            <v-card-text class="text-h5 pb-2" style="color: #000">Коментарий: {{selectedEvent.description}}</v-card-text>
            <v-card-title class="text-h5 pt-8 pb-6" style="font-weight: 500;">Посещения</v-card-title>
            <div class="ml-6 mr-6">
              <v-tabs style="border-radius: 12px 12px 0px 0px;" fixed-tabs v-model="tab_value" >
                <v-tab  v-for="item in application_nav" :key="item">
                  {{ item }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab_value" style="background-color: unset; border-radius: 0px 0px 12px 12px">
                <v-tab-item  v-for="x in 4" :key="x">
                  <v-container class="white" style="border-radius: 0px 0px 12px 12px" v-show="tab_value == 0">
                    <v-virtual-scroll
                    :bench="allTeamUsers.length"
                    :items="allTeamUsers"
                    :item-height="50"
                    height="226"
                    >
                      <template v-slot:default="{ item }">
                        <p class="text-h6 mb-0">{{item.user.surname}} {{item.user.name}} {{item.user.middleName}}  </p>
                      </template>
                    </v-virtual-scroll>                
                  </v-container>
                  <v-container class="white" style="border-radius: 0px 0px 12px 12px" v-show="tab_value == 1">
                    <div v-if="attendedUsers.length == 0" style="height: 226px" class=" d-flex flex-column  align-center">
                      <div class="grey--text text-h4 mt-3">
                        Таких ответов не поступило
                      </div>
                      <div>
                        <v-icon color="#E0E0E0" size="170">
                          mdi-emoticon-cry-outline
                        </v-icon>
                      </div>
                    </div>
                    <div v-else>
                    <v-virtual-scroll
                    :bench="attendedUsers.length"
                    :items="attendedUsers"
                    :item-height="50"
                    height="226"
                    >
                      <div v-if="attendedUsers.length == 0" class="d-flex flex-column  align-center">
                        <div class="grey--text text-h4 mt-3">
                          Нет ответивших игроков
                        </div>
                        <div>
                          <v-icon color="#E0E0E0" size="100">
                            mdi-emoticon-cry-outline
                          </v-icon>
                        </div>
                      </div>
                      <template v-slot:default="{ item }">
                        <p class="text-h6 mb-0">{{item.userSurName}} {{item.userName}} {{item.userMiddleName}}<span class="player_comment"> {{item.comment}}</span> </p>
                      </template>
                    </v-virtual-scroll>   
                    </div>             
                  </v-container>
                  <v-container class="white" style="border-radius: 0px 0px 12px 12px" v-show="tab_value == 2">
                    <div v-if="absentUsers.length == 0" style="height: 226px" class=" d-flex flex-column  align-center">
                      <div class="grey--text text-h4 mt-3">
                        Таких ответов не поступило
                      </div>
                      <div>
                        <v-icon color="#E0E0E0" size="170">
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </div>
                    </div>
                    <div v-else>
                    <v-virtual-scroll
                    :bench="absentUsers.length"
                    :items="absentUsers"
                    :item-height="50"
                    height="226"
                    >
                      <template v-slot:default="{ item }">
                        <p class="text-h6 mb-0">{{item.userSurName}} {{item.userName}} {{item.userMiddleName}}<span class="player_comment"> {{item.comment}}</span> </p>
                      </template>
                    </v-virtual-scroll>
                    </div>             
                  </v-container>
                  <v-container class="white" style="border-radius: 0px 0px 12px 12px" v-show="tab_value == 3">
                    <div v-if="ignoredUsers.length == 0" style="height: 226px" class=" d-flex flex-column  align-center">
                      <div class="grey--text text-h4 mt-3">
                        Все игроки ответили
                      </div>
                      <div>
                        <v-icon color="#E0E0E0" size="170">
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </div>
                    </div>
                    <div v-else>
                    <v-virtual-scroll
                    :bench="ignoredUsers.length"
                    :items="ignoredUsers"
                    :item-height="50"
                    height="226"
                    >
                      <template v-slot:default="{ item }">
                        <p class="text-h6 mb-0">{{item.userSurName}} {{item.userName}} {{item.userMiddleName}}</p>
                      </template>
                    </v-virtual-scroll> 
                    </div>               
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card>
      </v-dialog>
    </v-container>
    <div>
      <v-dialog v-model="add_event_dialog" max-width="600">
        <v-card class="grey lighten-5">
          <v-card-title class="py-3">
            <v-row>
              <v-col cols="11">
                <div class="text-h5 black--text">Выберите арену</div>
              </v-col>
              <v-col>
                <div class="mb-4">
                  <v-icon @click.stop="add_event_dialog = false"
                    >mdi-close</v-icon
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="selectedArena"
              :items="teamArenas"
              color="white"
              solo
              flat
              single-line
              hide-selected
              hide-no-data
              label="Выберите арену"
              item-text="title"
              item-value="arenaId"
              return-object
              hide-details="auto"
              class="rounded-lg"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-btn
              class="body-2"
              @click="add_event_dialog = false"
              elevation="0"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              class="body-2"
              @click="addEvent(selectedArena.arenaId)"
            >
              Готово
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_TEAM, GET_ATTENDED_USERS, GET_ARENA, POST_EVENT } from "@/api";
import moment from "moment";

export default {
  name: "AdminTeamEvents",
  data(){
    return{
      benched: 5,
      teamArenas: [],
      add_event_dialog: false,
      selectedArena: {},
      teamEvents: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey lighten-1",
      ],
      application_nav: [ "Вся команда", "Придут", "Не придут", "Не ответили"],
      value: 0,
      tab_value: 0,
      eventId: "",
      allTeamUsers: [],
      attendedUsers: [],
      absentUsers: [],
      ignoredUsers: [],
      mode: "column",
      weekday: [1, 2, 3, 4, 5, 6, 0],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedEvent: {},
      selectedOpen: false,
      selectedElement: null,
      trainingPlace: "",
    }
  },
  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
    ievents() {
      const events = [];
      this.teamEvents.forEach((event) => {
        const nEvents = this.generateEvents(event);
        events.push(...nEvents);
      });
      events.sort(function(a,b){
        return new Date(a.start) - new Date(b.start);
      });
      return events;
    },
  },
  methods: {
    async getAllTeamArenas() {
      await Axios.get(`${GET_TEAM}${this.teamId}/arenas`)
      .then( (res) => {
        res.data.forEach(item => {
          const obj = {
            title: item.arena.title,
            arenaId: item.arena.id
          }
          this.teamArenas.push(obj)
        });
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getEventArena() {
      await Axios.get(`${GET_ARENA}${this.selectedEvent.arenaId}`)
      .then( (res) => {
        this.trainingPlace = res.data.title
      })
      .catch((error) => {
        console.error(error);
      })
    },

    addEvent(id){
      const arenaId = id
      this.$router.push({
        name: "admin-team-create-event",
        params: { arenaId: arenaId },
      });
    },

    getEventColor(event) {
      return event.color;
    },

    viewDay({ date }) {
      this.value = 0;
      this.date = date;
      this.type = "day";
    },

    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.tab_value = 0
        this.getAllUsers()
        this.getAttendedUsers()
        this.getAbsentUsers()
        this.getIgnoredUsers()
        this.getEventArena()
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    async getAllTeamEvents() {
      await Axios.get(`${GET_TEAM}${this.teamId}/events`)
      .then( (res) => {
        this.teamEvents = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    rightTime(date) {
      var time = moment(date)
      return time.format("H:mm");
    },

    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("D MMMM, dddd");
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    generateEvents(event) {
      const selectedDays = event.days;
      const startDate = event.startDate,
        endDate = event.endDate,
        startTime = event.startTime,
        endTime = event.endTime,
        title = event.title,
        description = event.description,
        type = event.type,
        id = event.id,
        arenaId = event.arenaId,
        repeat = event.repeat;
      const color = this.colors[this.rnd(0, this.colors.length - 1)];

      const startMoment = moment(
        `${startDate}T${startTime ? startTime : "00:00"}:00`
      );
      const endMoment = moment(`${endDate}T${endTime ? endTime : "00:00"}:00`);
      const arr_events = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < selectedDays.length; j++) {
          if (Number(startMoment.day()) === selectedDays[j]) {
            const startMomentDate = startMoment.format("YYYY-MM-DDTHH:mm:ss");
            const beginMoment = moment(startMomentDate);

            while (
              Math.ceil(endMoment.diff(beginMoment, "weeks", true)) !== 0
            ) {
              const stopInterval = beginMoment.format("YYYY-MM-DD");
              const stopMoment = moment(
                `${stopInterval}T${endTime ? endTime : "00:00"}:00`
              );
              arr_events.push({
                name: title,
                start: beginMoment.format("YYYY-MM-DDTHH:mm:ss"),
                end: stopMoment.format("YYYY-MM-DDTHH:mm:ss"),
                color: color,
                timed: true,
                type: type,
                id: id,
                arenaId: arenaId,
                description: description,
              });
              if (repeat === 0) break;
              beginMoment.add(1, "weeks");
            }

            break;
          }
        }
        startMoment.add(1, "days");
      }
      return arr_events;
    },

    async getAttendedUsers() {
      const eventId = this.selectedEvent.id
      await Axios.get(`${GET_ATTENDED_USERS}?attended=attended&eventId=${eventId}`)
      .then( (res) => {
        this.attendedUsers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getAbsentUsers() {
      const eventId = this.selectedEvent.id
      await Axios.get(`${GET_ATTENDED_USERS}?attended=absent&eventId=${eventId}`)
      .then( (res) => {
        this.absentUsers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getIgnoredUsers() {
      const eventId = this.selectedEvent.id
      await Axios.get(`${GET_ATTENDED_USERS}?attended=ignored&eventId=${eventId}`)
      .then( (res) => {
        this.ignoredUsers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getAllUsers() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=PLAYER`)
      .then( (res) => {
        this.allTeamUsers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    goToEdit(eventId) {
      this.$router.push({
        name: "admin-team-edit-event",
        params: { eventId },
      });
    },

    async deleteEvent() {
      await Axios.delete(`${POST_EVENT}/${this.selectedEvent.id}`)
      .then( () => {
        this.selectedOpen = false
        this.getAllTeamArenas()
        this.getAllTeamEvents()
      })
      .catch((error) => {
        console.error(error);
      })
    },
  },
  created(){
    this.getAllTeamArenas()
    this.getAllTeamEvents()
  },
  watch:{
    '$route.params.teamId'() {
      this.value = 0
      this.getAllTeamArenas()
      this.getAllTeamEvents()
    }
  },
}
</script>

<style scoped>
.player_comment{
  color: #697984;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
</style>
