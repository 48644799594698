<template>
  <div>
    <div class="text-h6 mb-2">Контакты</div>
    <v-row>
      <v-col cols="6" class="" v-show="contact.length != 0">
        <div class="my-auto">
          <div>
            <p class="text-justify mb-0" style="white-space: pre-line" v-html="contact"></p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="contact_dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mr-2 mt-4"
            color="primary"
            large
            elevation="0"
            v-bind="attrs"
            v-on="on"
            @click="getNewContact"
        >
          {{
            contact.length
                ? "Изменить контакты"
                : "Добавить контакты"
          }}
        </v-btn>
      </template>

      <v-card class="py-3">
        <v-card-title class="justify-space-between">
          <div class="text-h5 black--text">Добавить контакты</div>
          <div class="mb-4">
            <v-icon @click.stop="contact_dialog = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-row>
            <!-- <v-col cols="12" class="text-h6 mb-n4">Телефоны</v-col>
            <v-col
              cols="12"
              class="mb-n2 my-auto"
              v-for="(item, i) in contact.tel"
              :key="i"
            >
              <span class="mr-4">{{ item }}</span>
              <v-icon class="" @click="removeTelephoneItem(i)">
                mdi-delete
              </v-icon>
            </v-col> -->
            <v-col class="mb-2 d-flex">
              <v-textarea
                  outlined
                  v-model="telephone"
                  flat
                  elevation="0"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-n6 mx-2">
          <!-- <v-btn
            class="body-2 px-4"
            @click="contact_dialog = false"
            elevation="0"
          >
            Назад
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="body-2 px-4"
              @click="addContactTelephone"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: String,
      required: true,
    },
    getContact: {
      type: Function
    }
  },
  mounted() {
    this.getNewContact()
  },
  watch: {
    contact() {
      this.telephone = this.contact
    },
  },
  data() {
    return {
      telephone: this.contact,
      contact_dialog: false,
    };
  },
  methods: {
    getNewContact() {
      if (!this.contact) {
        this.contact = ""
      }
      this.telephone = this.contact
    },
    addContactTelephone() {
      this.getContact({
        contact: this.telephone
      })
      this.contact_dialog = false
    },
    removeTelephoneItem() {
      this.contact = ""
      this.getContact({
        contact: this.contact
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
