<template>
  <div class="grey lighten-4">
    <div class="banner-room">
        <v-container class="pt-16 pb-16 white--text">
            <v-row>
                <div>
                    <v-breadcrumbs
                    dark
                    :items="breadcrumb_items"
                    class="px-3"
                    ></v-breadcrumbs>
                </div>
            </v-row>
            <div class="my-auto">
                <p class="mb-1">{{rightDate(forum.date)}}</p>
                <p class="text-h4">{{ forum.title }}</p>
                <p>
                <v-icon color="white">mdi-map-marker-outline</v-icon>
                {{ forum.city }}
                </p>
            </div>
        </v-container>
    </div>
    <v-container
    class="mt-5"
    v-if="forum.description ? forum.description.length : false"
    >
      <p class="text-h5">Описание</p>
      <div v-if="forum.description.length < 580">
        <p class="text-justify" v-html="forum.description"></p>
      </div>
      <div v-else>
        <p
          class="text-justify"
          v-if="!readMoreInfo"
          v-html="forum.description.slice(0, 580) + '...'"
        ></p>
        <p class="text-justify" v-else v-html="forum.description"></p>
      </div>
     
      <v-btn
        class="px-6"
        color="grey lighten-2"
        v-if="forum.description.length > 580"
        x-large
        elevation="0"
        @click.stop="readMoreInfo = !readMoreInfo"
      >
        {{ readMoreInfo ? "Скрыть" : "Развернуть" }}
      </v-btn>
    </v-container>
    <v-container v-if="isUser">
        <div class="mb-4 text-h5">Подробнее об игроке</div>
        <v-row class="mb-4">
          <v-col cols="12">
            <router-link
              :to="{ name: 'room-player-card', params: { playerId: user.id } }"
              class="undo-link-default"
            >
              <v-card color="transparent" elevation="0" >
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3 rounded-lg" size="125" tile>
                    <v-img
                      :src="
                        user.profilePicture != null
                          ? user.profilePicture
                          : require('@/assets/team_room_1.jpg')
                      "
                    ></v-img>
                  </v-avatar>
                  <v-card-text>
                    <div class="text-h5 mb-2">
                      {{ user.surname + " " + user.name + " " + user.middleName }}
                    </div>
                    <div v-if="isPlayer" class="d-flex">
                      <div
                        class="body-2 grey--text mr-12"
                        v-if="isValidOutput(playerRole.grip)"
                      >
                        Хват: {{ playerRole.grip }}
                      </div>
                      <div
                        class="body-2 grey--text"
                        v-if="isValidOutput(playerRole.position)"
                      >
                        Амплуа: {{ playerRole.position }}
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
    </v-container>
    <div v-else>
      <v-container v-if="forum.type == 'TEAMPLAYER'">
        <div class="mb-4 text-h5">Необходимые требования</div>
        <div class="d-flex">
          <div
          class="mr-12"
          v-if="isValidOutput(forum.grip)"
          >
            Хват: {{ forum.grip }}
          </div>
          <div
          v-if="isValidOutput(forum.role)"
          >
            Амплуа: {{ forum.role }}
          </div>
        </div>
      </v-container>
      <v-container>
        <div class="mb-4 text-h5">Подробнее о команде</div>
        <v-row class="mb-4">
          <v-col cols="12">
            <router-link
              :to="{ name: 'team-name', params: { teamId: team.id } }"
              class="undo-link-default"
            >
              <v-card color="transparent" elevation="0">
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3 rounded-lg" size="125" tile>
                    <v-img
                      :src="
                        team.profilePicture != null
                          ? team.profilePicture
                          : require('@/assets/team_room_1.jpg')
                      "
                    ></v-img>
                  </v-avatar>
                  <v-card-text>
                    <div
                      class="body-1 blue--text mb-2"
                      style="text-decoration: none"
                    >
                      {{ team.city }}
                    </div>
                    <div class="text-h5 mb-2">{{ team.title }}</div>
                  </v-card-text>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_FORUM, GET_USER_BY_ID, GET_TEAM } from "@/api";
import moment from "moment";

export default {
  data() {
    return {
      forum: {},
      breadcrumb_items: [],
      readMoreInfo: false,
      userId: "",
      teamId: "",
      playerRole: {},
      isUser: false,
      isPlayer: false,
      user: {},
      team: {},
    }
  },
  computed: {
    advertisementId() {
      return this.$route.params.advertisementId;
    },
  },
  created(){
    this.getForum()
  },
  methods: {
    async getForum() {
      const id = this.advertisementId
      await Axios.get(`${GET_FORUM}${id}`)
      .then( (res) => {
        this.forum = res.data
        if(this.forum.type == "PLAYERTEAM" || this.forum.type == "TRAINERTEAM"){
          this.userId = this.forum.userId
          this.getUser()
          this.getUserRoles()
        } else {
          this.teamId = this.forum.teamId
          this.getTeam()
        }
        console.log(this.forum)
        this.breadcrumb_items = [
          {
            text: "Список объявлений",
            disabled: false,
            exact: true,
            to: { name: "substitute-bench" },
          },
          {
            text: this.forum.title,
            disabled: true,
            to: "",
          },
        ];
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUser() {
      const id = this.userId
      await Axios.get(`${GET_USER_BY_ID}${id}`)
      .then( (res) => {
        this.user = res.data
        this.isUser = true
        console.log(res.data)
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getTeam() {
      const id = this.teamId
      await Axios.get(`${GET_TEAM}${id}`)
      .then( (res) => {
        this.team = res.data
        console.log(res.data)
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getUserRoles() {
      const id = this.userId
      await Axios.get(`${GET_USER_BY_ID}${id}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(this.forum.type == "PLAYERTEAM"){
            if(item.name == "PLAYER"){
              this.isPlayer = true
              this.playerRole = item
            } 
          } else if (this.forum.type == "TRAINERTEAM"){
            if(item.name == "TRAINER"){
              this.playerRole = item
              console.log(this.playerRole)
            } 
          }
          
        });
      })
      .catch((error) => {
        console.error(error);
      })
    },

    rightDate(date) {
      var time = moment(date).locale('ru')
      return time.format("D MMMM YYYY");
    },

    isValidOutput(input) {
      return input && input !== "string" ? input : null;
    },
  },
}
</script>
