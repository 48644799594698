<template>
  <v-card class="mx-auto rounded-lg" elevation="0">
    <v-list color="#E2E2E2" style="padding: 0">
      <v-list-item>
        <v-list-item-title>Мои лиги</v-list-item-title>

        <v-list-item-icon>
          <v-icon
            @click="
              $router.push({
                name: 'create-tournament-information',
                params: { userId },
              })
            "
            color="#000"
            >mdi-plus</v-icon
          >
        </v-list-item-icon>
      </v-list-item>

      <v-list-group
        style="background-color: #fff"
        v-for="(tournament, i) in leagues"
        :key="i + 1"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>{{ tournament.league.title }}</v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn @click.stop="editTournament(tournament.league.id)" small icon>
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            @click.stop="openWarningDialog(tournament.league.id)"
            :key="tournament.league.id"
            small
            icon
          >
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>

        <v-card elevation="0" class="pl-4">
          <AppTournamentVerticalSidebar
            :key="componentKey"
            :league="tournament.league"
          />
        </v-card>
      </v-list-group>
      <v-list-group
        style="background-color: #fff"
        v-for="(tournament, i) in userTournaments"
        :key="i"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>{{
            tournament.tournamentTitle
          }}</v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn
            @click.stop="editKidsTournament(tournament.tournamentId)"
            small
            icon
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            @click.stop="openWarningDialogKids(tournament.tournamentId)"
            :key="tournament.tournamentId"
            small
            icon
          >
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-list-group>
      <v-dialog v-model="warning_dialog" max-width="600">
        <v-card class="grey lighten-5">
          <v-card-text class="text-h5 black--text pt-5">
            Вы действительно хотите удалить эту лигу?
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn class="body-2" @click="warning_dialog = false" elevation="0">
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="error"
              class="body-2"
              @click="deleteTournament"
            >
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="warning_dialog_kids" max-width="600">
        <v-card class="grey lighten-5">
          <v-card-text class="text-h5 text-center black--text pt-5">
            Вы действительно хотите удалить этот детский турнир?
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn
              class="body-2"
              @click="warning_dialog_kids = false"
              elevation="0"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="error"
              class="body-2"
              @click="deleteKidsTournament"
            >
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-card>
</template>

<script>
// import axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import { mapState } from "vuex";
import Axios from "axios";
import AppTournamentVerticalSidebar from "@/components/AppUnit/AppTournamentVerticalSidebar";

export default {
  components: { AppTournamentVerticalSidebar },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sidebar: null,
      tournaments: [],
      componentKey: "",
      isTournamentOrganizer: false,
      allUserRoles: [],
      userTournamentAdminRoleId: "",
      warning_dialog: false,
      warning_dialog_kids: false,
      tId: "",
    };
  },
  async created() {
    await this.getUserRoles();
    this.getAllAdminTournaments();
    this.$store.dispatch("user/getKidsTournaments", {
      userId: this.userId,
      roleId: this.userTournamentAdminRoleId,
    });
  },
  computed: {
    ...mapState("user", ["user", "leagues", "userTournaments"]),
  },
  methods: {
    getAllAdminTournaments() {
      return this.$store.dispatch("user/getLeagues", [
        this.userId,
        this.userTournamentAdminRoleId,
      ]);
    },

    openWarningDialog(leagueId) {
      this.warning_dialog = true;
      this.tId = leagueId;
    },

    openWarningDialogKids(tournamentId) {
      this.warning_dialog_kids = true;
      this.tId = tournamentId;
    },

    changeTournaments(id) {
      this.componentKey = id;
    },

    getUserRoles() {
      return Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
        .then((res) => {
          this.allUserRoles = res.data;
          this.allUserRoles.forEach((item) => {
            if (item.name === "TOURNAMENT_ORGANIZER") {
              this.isTournamentOrganizer = true;
              this.userTournamentAdminRoleId = item.id;
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    editTournament(id) {
      const leagueId = id;
      this.$router.push({
        name: "admin-tournament-edit",
        params: { leagueId },
      });
    },

    editKidsTournament(id) {
      const tournamentId = id;
      this.$router.push({
        name: "admin-kids-tournament-edit",
        params: { tournamentId },
      });
    },

    deleteTournament() {
      this.$store.dispatch("user/deleteLeague", this.tId);
      this.warning_dialog = false;
    },

    deleteKidsTournament() {
      this.$store
        .dispatch("leagues/deleteKidsTournament", this.tId)
        .then(() => {
          this.$store.dispatch("user/getKidsTournaments", {
            userId: this.userId,
            roleId: this.userTournamentAdminRoleId,
          });
          this.warning_dialog_kids = false;
        });
    },
  },
};
</script>

<style>
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 10px;
}

.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0;
}
</style>
