import { apiCamps as api } from "@/service";

export const namespaced = true;

export const state = () => ({
  camps: [],
  camp: [],
  arenas: [],
});

export const mutations = {
  SET_CAMP(state, camp) {
    state.camp = camp;
  },
  UPDATE_CAMPS(state, camps) {
    state.camps = camps;
  },
  SET_ARENAS(state, arenas) {
    state.arenas = arenas;
  },
};

export const actions = {
  postCamp(_context, camp) {
    return api
      .postCamp(camp)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
  getCamp({ commit }, campId) {
    return api
      .getCamp(campId)
      .then((response) => {
        commit("SET_CAMP", response.data);
      })
      .catch((err) => console.log(err));
  },
  putCamp(_context, camp) {
    return api
      .putCamp(camp)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCampArenas({ commit }, campId) {
    return api
      .getCampArenas(campId)
      .then((resp) => {
        commit("SET_ARENAS", resp.data);
      })
      .catch((err) => console.log(err));
  },

  async fetchCamps({ commit }, state) {
    const res = await api.fetchCamps(state);
    const { data } = res;

    commit("UPDATE_CAMPS", data);
  },
};

export const getters = {
  GET_CAMPS(state) {
    return state.camps;
  },
  GET_CAMP(state) {
    return state.camp;
  },
};
