<template>
  <div class="grey lighten-4">
    <div class="banner-league">
      <v-container class="pt-16 pb-16 white--text">
        <div class="text-h4">Лиги и турниры</div>
        <v-row class="mt-2">
          <v-col cols="8">
            <v-tabs v-model="value" class="d-flex flex-no-wrap rounded-lg">
              <v-tab class="px-6" v-for="item in leag_tour_nav" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Leagues v-if="!value"/>
  </div>

</template>

<script>
import Leagues from "./Leagues";
export default {
  name: "LeagueTournaments",
  components: { Leagues },
  data() {
    return {
      leag_tour_nav: ["Лиги", "Турниры и фестивали"],
      value: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.banner-league {
  background: url("../../assets/banner-league.jpg") no-repeat center center;
  background-size: cover;
}
</style>
