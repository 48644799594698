<template>
  <div>
    <v-breadcrumbs :items="breadcrumb_items" class="pl-0 pt-0" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" @click="$router.push(item.to)" >
        <span :style="`color: black; font-size: 24px`">
          {{ item.text }}
        </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <p class="mb-8" style="font-size: 18px">Выберите сезон:</p>
    <TournamentsComponent
      v-if="!isLoading"
      :data="seasons"
      :type="{func: toTournaments}"
    />
    <app-loader v-else/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TournamentsComponent from "@/components/Leagues/TournamentsComponent";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "LeagueSeasons",
  components: { TournamentsComponent, AppLoader },
  computed: {
    ...mapState("leagues", ["seasons", "teamParams"]),
  },
  props: {
    leagueId: String,
  },
  created() {
    this.getSeasons();
  },
  data() {
    return {
      isLoading: true,
      breadcrumb_items: [],
      seasonsInfo: [],
    };
  },
  beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Турниры",
        disabled: false,
        exact: true,
      },
    ];
  },
  methods: {
    async getSeasons() {
      await this.$store.dispatch("leagues/getSeasons");
      this.isLoading = false;
    },
    toTournaments(season) {
      this.$store.dispatch("leagues/setTeamSeason", season);
      this.$router.push({ name: "league-tournament", params: { leagueId: this.leagueId } });
    },
  },
};
</script>

<style scoped>
.container {
  background-color: white;
  height: 80px;
  border-radius: 10px;
}
</style>
