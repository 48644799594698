<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="">
        <div>
          <v-breadcrumbs :items="breadcrumb_items"></v-breadcrumbs>
        </div>
      </v-row>
      <div>
        <div class="text-h5 pb-3 pt-5 font-weight-bold">
          Редактировать школу
        </div>
        <v-row>
          <v-col cols="4" class="pr-10">
            <div class="body-1 mb-4 font-weight-bold">Логотип школы</div>

            <admin-image-uploader v-model="avatar">
              <div slot="activator">
                <div v-if="!avatar" class="white rounded-xl pa-4">
                  <v-avatar
                    width="100%"
                    height="200"
                    v-ripple
                    tile
                    class="white rounded-xl"
                  >
                    <div class="upload-border rounded-xl pa-4">
                      <div class="my-4">
                        <v-icon large color="#379AD3"
                          >mdi-cloud-upload-outline
                        </v-icon>
                      </div>
                      <div class="body-1 mb-2 font-weight-bold">
                        Загрузите логотип
                      </div>
                      <div class="body-2 mb-4 grey--text">
                        Поддерживаемые форматы: PNG, JPG
                      </div>
                    </div>
                  </v-avatar>
                </div>
                <div v-else class="white rounded-xl pa-4">
                  <v-avatar width="100%" height="200" tile v-ripple>
                    <v-img
                      class="ma-10 rounded-xl"
                      :src="avatar.imageURL"
                      alt="avatar"
                      cover
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
            </admin-image-uploader>
          </v-col>
          <v-col>
            <div class="body-1 mb-4 font-weight-bold">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Название школы"
                v-model="title"
                :rules="shortTitleRules"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-select
                v-model="location"
                :items="cities"
                :rules="cityRules"
                label="Город"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-select>
            </div>
            <div class="mb-4">
              <v-select
                v-model="type"
                :items="types"
                item-text="state"
                item-value="value"
                label="Тип"
                outlined
                return-object
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-select>
            </div>
          </v-col>
        </v-row>

        <div>
          <div class="text-h6 mb-2">Описание</div>
          <v-textarea
            solo
            v-model="description"
            name=""
            flat
            elevation="0"
          ></v-textarea>
        </div>
        <div>
          <!--          <AdminSelectLocation metro="Юго-Западная" coordinate="" city="Москва" address=""/>-->
        </div>
        <div>
          <div class="mb-4">
            <AdminSchoolTrainers :school-id="schoolId" />
          </div>
          <div class="mb-4">
            <AdminSchoolArena :school-id="schoolId" />
          </div>
          <div class="mb-4">
            <AdminContactForm
              :getContact="getContact"
              :contact.sync="contacts"
            />
          </div>
          <div class="mb-4">
            <AdminSocialMedia
              :items="social_media"
              @newMedias="setSocialMedias"
            ></AdminSocialMedia>
          </div>
        </div>
        <!--        <div class="mb-4">-->
        <!--          <AdminTournamentArena :league-id="leagueId"/>-->
        <!--        </div>-->
        <!--        <div class="mb-4">-->
        <!--          <AdminTournamentTeams :league-id="leagueId"/>-->
        <!--        </div>-->

        <!--        <div class="mb-4">-->
        <!--          <AdminContactForm :getContact="getContact" :contact="contacts"></AdminContactForm>-->
        <!--        </div>-->
        <!--        <div class="mb-4">-->
        <!--          <AdminTournamentOrganizer :league-id="leagueId" />-->
        <!--        </div>-->
        <!--        <div class="mb-4">-->
        <!--          <AdminSocialMedia :items="social_media"></AdminSocialMedia>-->
        <!--        </div>-->
        <!--        <div class="mb-4">-->
        <!--          <AdminGallery :items="galleryPics"></AdminGallery>-->
        <!--        </div>-->
      </div>
      <div class="d-flex mt-16">
        <v-btn
          @click="updateSchool"
          large
          elevation="0"
          color="primary"
          class="body-2 px-6 mr-2"
        >
          Сохранить
        </v-btn>
        <v-btn
          color="grey lighten-2"
          large
          class="body-2 px-6 ml-2"
          elevation="0"
          @click="
            $router.push({
              name: 'user-profile',
              params: { userId: userId },
            })
          "
        >
          Отменить
        </v-btn>
      </div>
      <v-dialog v-model="errorDialog" max-width="300">
        <v-card>
          <div class="text-center px-4 pt-6">{{ errorText }}</div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="goToProfile" text>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminSchoolTrainers from "@/components/Admin/AdminSchoolTrainers";
import AdminSchoolArena from "@/components/Admin/AdminSchoolArena";
import AdminContactForm from "@/components/Admin/AdminContactForm";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia";
// import AdminGallery from "@/components/Admin/AdminGallery.vue";
// import AdminContact from "@/components/Admin/AdminContact.vue";
// import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import { mapActions, mapState } from "vuex";
// import AdminSelectLocation from "@/components/Admin/AdminSelectLocation";
// import AdminArenaContact from "@/components/Admin/AdminArenaContact";
// import AdminTournamentOrganizer from "@/components/Admin/AdminTournamentOrganizer";
// import AdminTournamentArena from "@/components/Admin/AdminTournamentArena";
// import AdminContactForm from '../../../../components/Admin/AdminContactForm.vue';
// import AdminTournamentTeams from "@/components/Admin/AdminTournamentTeams";
// import AdminTeamPlayers from '../../../../components/Admin/AdminTeamPlayers.vue';
// import AdminTeamTrainers from '../../../../components/Admin/AdminTeamTrainers.vue';
// import AdminTeamAdministrator from '../../../../components/Admin/AdminTeamAdministrator.vue';

export default {
  components: {
    AdminContactForm,
    AdminSchoolArena,
    AdminSchoolTrainers,
    AdminSocialMedia,
    // AdminTournamentTeams,
    // AdminTournamentOrganizer,
    // AdminArenaContact,
    AdminImageUploader,
    // AdminSelectLocation
    // AdminGallery,
    // AdminSocialMedia,
    // AdminTournamentArena,
    // AdminContactForm,
    // AdminTeamPlayers,
    // AdminTeamTrainers,
    // AdminTeamAdministrator,
  },
  props: {
    schoolId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("school", ["school", "cities"]),
    social_media_display() {
      return this.social_media.filter((x) => x.link);
    },
    profilePicture() {
      return this.avatar ? this.avatar.imageURL : "";
    },
  },
  async mounted() {
    await this.$store.dispatch("school/getCities");
  },
  async created() {
    window.scrollTo(0, 0);
    await this.getSchool();
  },
  async beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId: this.userId } },
      },
      // {
      //   text: "Мои лиги",
      //   disabled: false,
      //   exact: true,
      //   to: {
      //     name: "admin-league",
      //     params: { userId: this.userId },
      //   },
      // },
      {
        text: "Редактировать школу",
        disabled: true,
        to: "",
      },
    ];
  },
  data() {
    return {
      files: {},
      galleryPics: [],
      title: "",
      description: "",
      location: "",
      locations: ["Москва"],
      avatar: null,
      editedProfilePicture: null,
      contacts: "",
      galleryName: "multifile",
      checkbox: null,
      telephone: "",
      email: "",
      errMessage: "",
      social_media_dialog: false,
      toggle_social_media: null,
      social_media_text: "",
      contact_dialog: false,
      album_dialog: false,
      errorDialog: false,
      errorText: "",
      fullTitle: "",
      type: { state: "Государственная", value: "STATE" },
      types: [
        { state: "Государственная", value: "STATE" },
        { state: "Частная", value: "COMMERCIAL" },
      ],
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        // {
        //   id: 2,
        //   name: "whatsapp",
        //   link: "",
        //   icon: "mdi-whatsapp",
        // },
        {
          id: 2,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
        {
          id: 3,
          name: "instagram",
          link: "",
          icon: "mdi-instagram",
        },
        // {
        //   id: 5,
        //   name: "facebook",
        //   link: "",
        //   icon: "mdi-facebook",
        // },
      ],
      breadcrumb_items: [],
      fullTitleRules: [(v) => !!v || "Введите полное название"],
      shortTitleRules: [(v) => !!v || "Введите сокращенное название"],
      cityRules: [(v) => !!v || "Выберите город"],
    };
  },
  methods: {
    validate() {
      return true;
    },
    setSocialMedias(value) {
      this.social_media = value;
    },
    getSchool() {
      return this.$store
        .dispatch("school/getSchool", this.schoolId)
        .then((school) => {
          this.title = school.title;
          this.editedProfilePicture = school.editedProfilePicture;
          this.location = school.city;
          this.contacts = school.contact ? school.contact : "";
          this.avatar = school.profilePicture
            ? { imageURL: school.profilePicture }
            : null;
          this.social_media[0].link = school.vk;
          this.social_media[1].link = school.website;
          this.social_media[2].link = school.instagram;
          this.description = school.description;
          this.fullTitle = school.title;
          this.type =
            school.type === "STATE"
              ? { state: "Государственная", value: "STATE" }
              : { state: "Частная", value: "COMMERCIAL" };
        });
    },
    getContact(data) {
      this.contacts = data.contact;
    },
    async updateSchool() {
      if (this.validate()) {
        const data = {
          title: this.title,
          city: this.location,
          description: this.description,
          profilePicture: this.profilePicture,
          editedProfilePicture: this.editedProfilePicture,
          type: this.type.value,
          // gallery: this.galleryPics,
          contact: this.contacts,
          // // mails: this.contact.mail,
          instagram: this.social_media[2].link,
          vk: this.social_media[0].link,
          website: this.social_media[1].link,
          approved: false,
        };

        await this.putSchool({ schoolId: this.schoolId, school: data });
        this.errorDialog = true;
        this.errorText =
          "Ваша заявка принята на рассмотрение. Решение будет выслано на почту после рассмотрения";
      }
    },
    goToProfile() {
      this.errorDialog = true;
      this.$router.push({
        name: "user-profile",
        params: { userId: this.userId },
      });
    },
    ...mapActions("user", ["putSchool"]),
  },
};
</script>

<style>
.upload-border {
  border: 1px dashed #c3e1f2;
}
</style>
