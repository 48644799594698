<template>
  <v-container class="pt-10 pb-0 mb-0">
    <v-row dense>
      <v-col class="d-flex" cols="12" md="2">
        <v-select
          :items="cities"
          v-model="city"
          solo
          flat
          @change="getLeagues"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="8" md="8" lg="9">
        <v-text-field
          label="Поиск по названию турнира"
          single-line
          prepend-inner-icon="mdi-magnify"
          solo
          flat
          hide-details="auto"
          class="rounded-lg"
          @change="getLeagues"
          v-model="search"
          @keypress.enter="getLeagues"
        ></v-text-field>
      </v-col>
      <v-col cols="3" xs="2" sm="3" md="2" lg="1">
        <v-btn
          class="rounded-lg"
          large
          depressed
          height="48px"
          width="100%"
          color="primary"
          @click="getLeagues"
          >Найти
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-md-0 mt-xs-5 pb-8 pl-4">
      <div class="text-h4 font-weight-medium">Лиги</div>
    </v-row>
    <v-row class="hidden-md-and-up">
      <v-col class="my-auto" cols="12">
        <div class="body-1 grey--text">Найдено: {{ numFound }}</div>
      </v-col>
      <v-col class="d-flex" cols="6">
        <v-select
          prepend-inner-icon="mdi-swap-vertical"
          :items="sort_by"
          v-model="sortBy"
          solo
          flat
          hide-details="auto"
        ></v-select>
      </v-col>
      <v-col cols="6" md="4" lg="3" xl="2">
        <v-select
          value="Показывать по 12"
          @change="setSize"
          solo
          flat
          hide-details="auto"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="hidden-sm-and-down">
      <v-col class="d-flex" cols="6" md="4" lg="3" xl="2">
        <v-select
          prepend-inner-icon="mdi-swap-vertical"
          :items="sort_by"
          v-model="sort_state"
          item-text="value"
          item-value="key"
          return-object
          solo
          flat
          hide-details="auto"
          @change="getLeagues"
        ></v-select>
      </v-col>
      <v-col class="my-auto" cols="6" md="4">
        <div class="body-1 grey--text">Найдено: {{ numFound }}</div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="4" lg="3" xl="2">
        <v-select
          :items="display_items"
          v-model="numItemsState"
          item-text="state"
          item-value="value"
          @change="getLeagues"
          return-object
          solo
          flat
          hide-details="auto"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense class="mx-n4" v-if="!isFetching">
      <v-col
        class="pa-4"
        cols="12"
        md="6"
        xl="4"
        v-for="(item, i) in leagues"
        :key="i"
      >
        <league-card-v2 :league="item" />
      </v-col>
    </v-row>
    <app-loader style="height: 650px" v-else />

    <div class="text-center py-10" v-if="leagues.length">
      <!-- <v-pagination
        color="grey"
        :length="paginationLength"
        :total-visible="7"
        v-model="page"
        v-on:input="getLeagues()"
      ></v-pagination> -->
      <v-pagination
        color="grey"
        v-model="page"
        :length="paginationLength"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import LeagueCardV2 from "@/components/Leagues/LeagueCardV2";
import AppLoader from "@/components/AppUnit/AppLoader";

export default {
  name: "Leagues",
  components: { LeagueCardV2, AppLoader },
  computed: {
    ...mapState("leagues", ["leagues", "cities"]),
    chunkedLeagues() {
      return _.chunk(this.$store.state.leagues.leagues, 3);
    },
  },
  watch: {
    page() {
      this.savePageNumber();
      // this.getLeagues()
    },
  },
  data() {
    return {
      isFetching: false,
      sort_by_city: ["Все города"],
      city: "Москва",
      sortBy: "По имени",
      pageSize: 12,
      paginationLength: 1,
      search: "",
      numItems: { state: "Показывать по 12", value: 12 },
      sort_com: { key: 1, value: "По имени (от А до Я)" },
      sort_state: { key: 1, value: "По имени (от А до Я)" },
      sort_by: [
        { key: 1, value: "По имени (от А до Я)" },
        { key: 0, value: "По имени (от Я до А)" },
      ],
      numItemsCom: { state: "Показывать по 12", value: 12 },
      numItemsState: { state: "Показывать по 12", value: 12 },
      display_items: [
        { state: "Показывать по 12", value: 12 },
        { state: "Показывать по 24", value: 24 },
        { state: "Показывать по 36", value: 36 },
      ],
      numFound: 0,
      page: 1,
    };
  },
  async created() {
    await this.fetchCities();
    this.cities.unshift("Все города");
    await this.getLeagues();
  },
  methods: {
    setSize() {
      if (this.show === "Показывать по 12") {
        this.pageSize = 12;
      } else {
        this.pageSize = 24;
      }
    },
    async fetchCities() {
      return this.$store.dispatch("leagues/getCities");
    },
    getLeagues() {
      this.isFetching = true;
      this.fetchLeagues();
      this.isFetching = false;
    },
    async fetchLeagues() {
      this.setPage();
      const data = {
        currentPage: this.page,
        pageSize: this.numItemsState.value,
        sortAscending: this.sort_state.key,
        filters: [
          {
            filterConditions: "EQUAL",
            value: {
              type: "String",
              string: this.city,
            },
            parameter: "city",
          },
          {
            filterConditions: "EQUAL",
            value: {
              type: "Boolean",
              boolean: true,
            },
            parameter: "approved",
          },
        ],
      };
      if (this.search != "") {
        const filter = {
          filterConditions: "CONTAINS",
          value: {
            type: "String",
            string: this.search,
          },
          parameter: "title",
        };
        data.filters.push(filter);
      }

      const { paginationLength, numFound } = await this.filteringLeagues(data);
      this.numFound = numFound;
      this.paginationLength = paginationLength;
      // const params = {
      //   currentPage: this.page,
      //   pageSize: this.numItemsState.value,
      //   queryString: this.search,
      //   city: this.city,
      //   sortBy: this.sort_state.key,
      // };
      // return this.$store
      //   .dispatch("leagues/searchLeagues", params)
      //   .then((response) => {
      //     this.paginationLength = response.paginationLength;
      //     this.numFound = response.numFound;
      //   });
    },
    // filterLeagues(filter) {
    //   return Promise.resolve(undefined);
    // }
    savePageNumber() {
      localStorage.setItem("leaguePage", this.page);
      this.getLeagues();
    },

    setPage() {
      const pageNumber = localStorage.getItem("leaguePage");
      if (pageNumber > 0) {
        this.page = Number(pageNumber);
      } else {
        this.page = 1;
      }
    },
    ...mapActions("leagues", ["filteringLeagues"]),
  },
};
</script>

<style scoped></style>
