<template>
  <v-card
    class="rounded-lg d-flex"
    :elevation="elevation"
    @mouseover="elevation = '2'"
    @mouseout="elevation = '0'"
    @click="goToArena"
  >
    <v-avatar size="200" tile
      ><img
        :src="this.pickAvatar(kidsTournaments.logo, kidsTournaments.editedLogo)"
        alt="logo"
      />
    </v-avatar>
    <div class="d-flex flex-column justify-space-between">
      <div>
        <v-card-title class="pb-5">
          {{
            kidsTournaments.title.length > 25
              ? kidsTournaments.title.slice(0, 25) + "..."
              : kidsTournaments.title
          }}
        </v-card-title>
        <v-card-subtitle>
          <p class="ma-0">{{ kidsTournaments.city }}</p>
        </v-card-subtitle>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "KidsTournamentsMiniCard",
  props: {
    kidsTournaments: Object,
  },
  mounted() {},
  data() {
    return {
      elevation: 0,
    };
  },
  methods: {
    goToArena() {
      const kidsTournamentId = this.kidsTournaments.id;
      this.$router.push({
        name: "kids-tournaments-information",
        params: {
          kidsTournamentId: kidsTournamentId,
        },
      });
    },
    pickAvatar(croppedImg, originImg) {
      if (croppedImg !== null) {
        return croppedImg;
      }
      return originImg ? originImg : require("@/assets/team_room_1.jpg");
    },
  },
  filters: {},
  computed: {},
};
</script>

<style></style>
