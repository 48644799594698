import { apiSchool as api, apiUser } from "@/service";

export const namespaced = true;

export const state = () => ({
  com: [],
  state: [],
  cities: [],
  school: {},
  school_trainers: [],
  all_trainers: [],
  arenas: [],
});

export const mutations = {
  SET_COM(state, schools) {
    state.com = schools;
  },
  SET_STATE(state, schools) {
    state.state = schools;
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  SET_SCHOOL(state, school) {
    state.school = school;
  },
  SET_SCHOOL_TRAINERS(state, trainers) {
    state.school_trainers = trainers;
  },
  SET_ALL_TRAINERS(state, trainers) {
    state.all_trainers = trainers;
  },
  SET_ARENAS(state, arenas) {
    state.arenas = arenas;
  },
};

export const actions = {
  filterSchools({ commit }, { filters, type }) {
    return api
      .filterSchools(filters, type)
      .then((response) => {
        const res = response.data;
        if (type === "STATE") commit("SET_STATE", res.content);
        else commit("SET_COM", res.content);
        return {
          pagination: res.totalPages,
          numFound: res.totalElements,
        };
      })
      .catch((err) => console.log(err));
  },
  getCities({ commit }) {
    return api
      .getCities()
      .then((response) => {
        commit("SET_CITIES", response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
  },
  getSchool({ commit }, schoolId) {
    return api
      .getSchool(schoolId)
      .then((response) => {
        commit("SET_SCHOOL", response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
  },
  postSchool({ commit }, params) {
    return api
      .postSchool(params)
      .then((response) => {
        commit("ADD_SCHOOL", response.data);
        return response.data;
      })
      .catch((error) => console.log(error));
  },
  getSchoolTrainers({ commit }, schooldId) {
    return api
      .getSchoolTrainers(schooldId)
      .then((response) => {
        commit("SET_SCHOOL_TRAINERS", response.data);
      })
      .catch((err) => console.log(err));
  },
  getSchoolArenas({ commit }, schooldId) {
    return api
      .getSchoolArenas(schooldId)
      .then((response) => {
        commit("SET_ARENAS", response.data);
      })
      .catch((err) => console.log(err));
  },
  getAllTrainers({ commit }) {
    return apiUser.getUsersByRole("TRAINER").then((response) => {
      commit("SET_ALL_TRAINERS", response.data);
    });
  },
  putSchool(_context, school) {
    return api
      .putSchool(school)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export const getters = {};
