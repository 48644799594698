<template>
  <div class="grey lighten-4">
    <v-img
      color="grey"
      height="350px"
      width="100%"
      src="@/assets/banner-room.jpg"
    >
      <v-container class="pt-8 pb-0">
        <v-row class="">
          <div>
            <v-breadcrumbs
              dark
              :items="breadcrumb_items"
              class="px-3"
            ></v-breadcrumbs>
          </div>
          <v-spacer></v-spacer>
          <div class="pr-3 my-auto">
            <a
              v-for="(item, index) in valid_contact_list"
              class="reset-link"
              :key="index"
              :href="item.link"
              target="_blank"
            >
              <v-btn
                elevation="0"
                x-small
                color="transparent"
                height="40px"
                class="mx-1"
              >
                <v-icon color="white"> {{ item.icon }}</v-icon>
              </v-btn>
            </a>
          </div>
        </v-row>

        <div class="d-flex mt-5 mb-2">
          <div class="pr-4">
            <v-avatar class="rounded-lg" contain tile size="180">
              <v-img
                contain
                :src="
                  team.profilePicture != null
                    ? team.profilePicture
                    : require('@/assets/team_room_1.jpg')
                "
              ></v-img>
            </v-avatar>
          </div>
          <div class="my-auto">
            <p class="text-h4 white--text">{{ team.title }}</p>
            <p class="white--text">
              <v-icon color="white">mdi-map-marker-outline</v-icon>
              {{ team.city }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn 
            @click="$router.push({ name: 'login'})"
            v-show="!loggedIn"
            outlined
            color="#fff"
            elevation="0">Вступить в команду</v-btn>
            <div v-if="!isStatus || userStatus == 'refused'">
              <v-btn 
              @click="checkUserRole"
              v-show="loggedIn"
              outlined
              color="#fff"
              elevation="0">Вступить в команду</v-btn>
            </div>
            <div 
            v-else-if="userStatus == 'accepted'"
            class="white--text text-h7">
              Вы состоите в команде
            </div>
            <div 
            v-else-if="userStatus == 'waiting'"
            class="white--text text-h7">
              Ваша заявка отправлена
            </div>
          </div>
        </div>
      </v-container>
    </v-img>
    <v-container
      class="mt-10"
      v-if="team.description ? team.description.length : false"
    >
      <p class="text-h4">Описание</p>
      <div v-if="team.description.length < 580">
        <p class="text-justify" v-html="team.description"></p>
      </div>
      <div v-else>
        <p
          class="text-justify"
          v-if="!readMoreInfo"
          v-html="team.description.slice(0, 580) + '...'"
        ></p>
        <p class="text-justify" v-else v-html="team.description"></p>
      </div>
     
      <v-btn
        class="px-6"
        color="grey lighten-2"
        v-if="team.description.length > 580"
        x-large
        elevation="0"
        @click.stop="readMoreInfo = !readMoreInfo"
      >
        {{ readMoreInfo ? "Скрыть описание" : "Развернуть описание" }}
      </v-btn>
    </v-container>
    <v-container
      v-show="teamArenas ? teamArenas.length : false"
    >
      <p class="text-h5 font-weight-bold">Место проведения тренировок</p>
      <v-row dense class="mx-n4" >
        <div v-for="(arena, id) in teamArenas" :key="id">
          <v-col class="pt-0" cols="12">
          <v-card color="transparent" elevation="0">
            <div class="d-flex flex-no-wrap">
              <div class="ma-3">
                <v-avatar class="rounded-lg" width="282px" height="186px" tile>
                  <v-img
                    contain
                    :src="
                      arena.arena.profilePicture != null
                        ? arena.arena.profilePicture
                        : require('@/assets/team_room_1.jpg')
                    "
                  ></v-img>
                </v-avatar>
              </div>
              <div class="description">
                <v-card-text style="width: 100%">
                  <div class="text-h5 mb-4">{{ arena.arena.title }}</div>
                  <div class="body-1 grey--text">
                    {{ arena.arena.city }}
                  </div>
                </v-card-text>
                <v-card-actions class="pl-4 bottom">
                  <router-link
                    :to="{ name: 'arena-information', params: { arenaId: arena.arena.id } }"
                    class="reset-link"
                  >
                    <v-btn
                      class="px-6"
                      color="grey lighten-2"
                      x-large
                      elevation="0"
                    >
                      Подробнее
                    </v-btn>
                  </router-link>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        </div>
      </v-row>
    </v-container>
    <v-container  v-if="media ? media.length : false">
      <p class="text-h5 font-weight-bold">Галерея</p>
      <v-row>
        <v-col cols="6" md="4" lg="3" v-for="(item, i) in media" :key="i">
          <v-img style="height: 200px" :src="item" @click="openGallery(i)"></v-img>
        </v-col>
        <LightBox
          ref="lightbox"
          :media="media"
          :show-caption="true"
          :show-light-box="false"
        />
      </v-row>
    </v-container>
    <v-container
      v-if="
        teamTrainers || teamPlayers || teamAdmin
          ? teamTrainers.length || teamPlayers.length || teamAdmin.length
          : false
      "
    >
      <p class="text-h5 font-weight-bold">Состав</p>
      <p class="text-h6" v-if="(teamTrainers ? teamTrainers.length : false) || (teamAdmin ? teamAdmin.length : false)">
        Тренеры, сотрудники
      </p>
      <v-row dense class="mx-n4">
        <v-col cols="12" md="6" v-for="(item, i) in teamAdmin" :key="i">
          <router-link
          :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
          class="undo-link-default"
          >
            <v-card color="transparent" elevation="0" >
              <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3 rounded-lg" size="125" tile>
                  <v-img
                    :src="
                      item.user.profilePicture.length
                        ? item.user.profilePicture
                        : require('@/assets/team_room_1.jpg')
                    "
                  ></v-img>
                </v-avatar>
                <v-card-text>
                  <div class="text-h5 mb-2">
                    {{
                      item.user.surname +
                      " " +
                      item.user.name +
                      " " +
                      item.user.middleName
                    }}
                  </div>
                  <div class="body-1 blue--text mb-2">
                    {{ item.user.age ? item.user.age + ",  " : "" }}
                    {{ item.user.city ? item.user.city : "" }}
                  </div>
                  <div v-for="(role, r) in item.user.roles" :key="r">
                    <div v-if="role.name == 'TEAM_ADMIN'" class="body-1 grey--text">
                      Администратор команды
                    </div>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" md="6" v-for="(item, i) in teamTrainers" :key="i">
          <router-link
          :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
          class="undo-link-default"
          >
            <v-card color="transparent" elevation="0" >
              <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3 rounded-lg" size="125" tile>
                  <v-img
                    
                    :src="
                      item.user.profilePicture.length
                        ? item.user.profilePicture
                        : require('@/assets/team_room_1.jpg')
                    "
                  ></v-img>
                </v-avatar>
                <v-card-text>
                  <div class="text-h5 mb-2">
                    {{
                      item.user.surname +
                      " " +
                      item.user.name +
                      " " +
                      item.user.middleName
                    }}
                  </div>
                  <div class="body-1 blue--text mb-2">
                    {{ item.user.age ? item.user.age + ",  " : "" }}
                    {{ item.user.city ? item.user.city : "" }}
                  </div>
                  <div v-for="(role, r) in item.user.roles" :key="r">
                    <div v-if="role.name == 'TRAINER'" class="body-1 grey--text">
                      Тренер{{ role.status ? ", " + role.status : "" }}
                    </div>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <p class="text-h6 mt-8" v-if="teamPlayers ? teamPlayers.length : false">
        Игроки
      </p>
      <v-row dense class="mx-n4">
        <v-col cols="6" v-for="(item, i) in teamPlayers" :key="i">
          <div v-show="!readMorePlayers && i < 4">
            <router-link
            :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
            class="undo-link-default"
            >
              <v-card color="transparent" elevation="0">
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3 rounded-lg" size="125" tile>
                    <v-img
                      :src="
                        item.user.profilePicture != null
                          ? item.user.profilePicture
                          : require('@/assets/team_room_1.jpg')
                      "
                    ></v-img>
                  </v-avatar>
                  <v-card-text>
                    <div class="text-h5 mb-2">
                      {{
                        item.user.surname +
                        " " +
                        item.user.name +
                        " " +
                        item.user.middleName
                      }}
                    </div>
                    <div class="body-1 blue--text mb-2">
                      {{ item.user.age ? item.user.age + ", " : "" }}
                      {{ item.user.city ? item.user.city : "" }}
                    </div>
                    <div v-for="(role, i) in item.user.roles" :key="i" class="body-1 grey--text mb-2">
                      <div v-if="role.name == 'PLAYER'">
                        {{ role.grip ? role.grip : "" }}
                        {{ role.position ? role.position : "" }}
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </router-link>
          </div>
          <div v-if="readMorePlayers">
            <router-link
            :to="{ name: 'room-player-card', params: { playerId: item.user.id } }"
            class="undo-link-default"
            >
              <v-card color="transparent" elevation="0">
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3 rounded-lg" size="125" tile>
                    <v-img
                      :src="
                        item.user.profilePicture != null
                          ? item.user.profilePicture
                          : require('@/assets/team_room_1.jpg')
                      "
                    ></v-img>
                  </v-avatar>
                  <v-card-text>
                    <div class="text-h5 mb-2">
                      {{
                        item.user.surname +
                        " " +
                        item.user.name +
                        " " +
                        item.user.middleName
                      }}
                    </div>
                    <div class="body-1 blue--text mb-2">
                      {{ item.user.age ? item.user.age + ", " : "" }}
                      {{ item.user.city ? item.user.city : "" }}
                    </div>
                    <div v-for="(role, i) in item.user.roles" :key="i" class="body-1 grey--text mb-2">
                      <div v-if="role.name == 'PLAYER'">
                        {{ role.grip ? role.grip : "" }}
                        {{ role.position ? role.position : "" }}
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0">
          <v-btn
          color="grey lighten-2"
          v-show="teamPlayers.length > 4"
          elevation="0"
          @click="readMorePlayers = !readMorePlayers"
          >
            {{ readMorePlayers ? "Скрыть" : "Показать больше" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="mt-5"
    >
      <p class="text-h5 font-weight-bold">Мероприятия</p>
      <v-row>
        <v-col>
          <v-btn
          depressed
          dark
          @click="event_dialog = true"
          color="#0681C8"
          height="48"
          style="border-radius: 8px;"
          >
            Посмотреть календарь мероприятий
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="mt-5"
      v-if="
        teamFindPlayer || teamFindTrainer
          ? teamFindPlayer.length || teamFindTrainer.length
          : false
      "
    >
      <p class="text-h5 font-weight-bold">Активные объявления</p>
      <v-row class="mt-2 ml-0">
        <v-tabs v-model="premises_tab" class="d-flex flex-no-wrap rounded-lg">
          <v-tab v-for="item in advert_nav" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-tabs-items v-model="premises_tab" style="background-color: unset">
        <v-tab-item v-for="i in 2" :key="i">
          <v-row dense class="mt-5" v-show="premises_tab === 0">
            <v-col
              cols="12"
              md="6"
              v-for="(item, i) in teamFindPlayer"
              :key="i"
            >
              <v-card elevation="0" class="pa-5 rounded-xl">
                <div class="d-flex">
                  <div class="pr-4">
                    <v-avatar class="rounded-lg" contain tile size="80">
                      <v-img
                        contain
                        :src="
                          team.profilePicture != null
                            ? team.profilePicture
                            : require('@/assets/team_room_1.jpg')
                        "
                      ></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-flex flex-no-wrap">
                    <v-card-text class="pa-0">
                      <div class="body-1 grey--text">
                        {{ dateFormat(item.date) }}
                      </div>
                      <div class="text-h6 my-1">{{ item.title }}</div>
                      <div class="body-2 grey--text">{{ team.title }}</div>
                    </v-card-text>
                  </div>
                </div>
                
                <div class="mb-4 text-justify">
                  {{ item.description.slice(0, 290) }}
                </div>
                <p class="body-2 mb-2 font-weight-bold">Необходимые требования:</p>
                <div class="d-flex">
                  <div class="body-2 blue--text">Хват: {{ item.grip }}</div>
                  <div class="body-2 blue--text ml-16">
                    Амплуа: {{ item.role }}
                  </div>
                </div>
                <!-- <div class="d-flex">
                  <div class="body-2 blue--text">Хват: {{ item.grip }}</div>
                  <div class="body-2 blue--text ml-16">
                    Уровень: {{ item.level }}
                  </div>
                </div> -->
              </v-card>
            </v-col>
          </v-row>
          <v-row dense class="mt-5" v-show="premises_tab == 1">
            <v-col
              cols="12"
              md="6"
              v-for="(item, i) in teamFindTrainer"
              :key="i"
            >
              <v-card elevation="0" class="pa-5 rounded-xl">
                <div class="d-flex">
                  <div class="pr-4">
                    <v-avatar class="rounded-lg" contain tile size="80">
                      <v-img
                        contain
                        :src="
                          team.profilePicture != null
                            ? team.profilePicture
                            : require('@/assets/team_room_1.jpg')
                        "
                      ></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-flex flex-no-wrap">
                    <v-card-text class="pa-0">
                      <div class="body-1 grey--text">
                        {{ dateFormat(item.date) }}
                      </div>
                      <div class="text-h6 my-1">{{ item.title }}</div>
                      <div class="body-2 grey--text">{{ team.title }}</div>
                    </v-card-text>
                  </div>
                </div>
                
                <div class="text-justify">
                  {{ item.description.slice(0, 290) }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>

    <v-container v-if="team.contact.length != 0">
      <p class="text-h5 font-weight-bold">Контакты</p>
      <div>
          <p 
          v-if="team.contact" 
          style="white-space: pre-line"
          v-html="team.contact">
          </p>
        </div>
    </v-container>

    <v-dialog v-model="role_dialog" max-width="700">
        <v-card class="grey lighten-5">
          <v-card-text class="text-h5 black--text pt-5 d-flex align-center justify-center">
            В качестве кого вы хотите вступить в эту команду?
          </v-card-text>
          <v-card-actions class="mt-3 pb-5">
            <v-spacer></v-spacer>
            <v-btn
              @click="roleChoosing(playerRole)"
              color="#0681C8"
              height="40"
              width="150"
              dark
              elevation="0"
            >
              Игрок
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              height="40"
              width="150"
              color="#0681C8"
              dark
              @click="roleChoosing(trainerRole)"
            >
              Тренер
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
      max-width="950"
      v-model="event_dialog">
      <div class="rounded-lg white">
        <v-row class="ml-0 mr-0 mb-0">
          <v-tabs v-model="value" class="d-flex justify-center flex-no-wrap mt-2 rounded-lg">
            <v-tab class="px-10" v-for="item in schedule_nav" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-row>
        <v-sheet tile height="60" class="d-flex align-center" color="#EBF5FB">
          <div class="d-flex align-center">
            <v-btn
            fab
            text
            color="grey darken-2"
            @click="prev"
            >
              <v-icon >
                mdi-chevron-left
              </v-icon>
            </v-btn>
              
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
            fab
            text
            color="grey darken-2"
            @click="next"
            >
              <v-icon >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <div style="width: 200px" class="mx-3">
            <v-menu
              v-model="date_picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  single-line
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="date_picker = false"
                :first-day-of-week="1"
                locale="ru-RU"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-sheet>
        <v-sheet height="600" class="px-4 pb-4 overflow-auto">
          <v-calendar
            v-show="value !== 3"
            ref="calendar"
            v-model="date"
            :weekdays="weekday"
            :type="type"
            :events="ievents"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            event-text-color="#000"
            locale="ru-RU"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar elevation="0" :color="selectedEvent.color">
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.description"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <div v-show="value === 3 && teamEvents.length === 0" class="text-center">
            <h3 class="grey--text lighten-3--text text-h6 mb-2">
              Нет мероприятий
            </h3>
          </div>
          <div
            v-show="value === 3"
            v-for="(event, index) in teamEvents"
            :key="index"
            class="mb-10 pl-5"
          >
            <ArenaEventCard :event="event"></ArenaEventCard>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Axios from "axios";
import { GET_TEAM, ADD_USER_TO_TEAM, GET_USER_BY_ID } from "@/api";
import moment from "moment";
import LightBox from "vue-image-lightbox";
import ArenaEventCard from '../../components/Arena/ArenaEventCard.vue';

export default {
  name: "TeamName",
  components: {
    LightBox,
    ArenaEventCard
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
  filters: {
    descriptionLength(value) {
      if (!value) return "";
      if (value.length < 30) return value;
      return value.slice(0, 30) + "...";
    },
  },
  watch: {
    value(x) {
      if (x === 0) this.type = "day";
      if (x === 1) this.type = "week";
      if (x === 2) this.type = "month";
    },
  },
  computed: {
    ...mapState("team", ["team"]),
    ...mapState("team", ["users"]),
    ...mapState("team", ["forums"]),
    ...mapState("auth", ["userId"]),
    ...mapGetters("auth", ["loggedIn"]),
    ...mapState("user", ["user"]),
    computedDateFormatted () {
      return this.date ? moment(this.date).format('DD-MM-YYYY') : ''
    },
    valid_contact_list() {
      return this.contact_list.filter((x) => x.link !== "");
    },
    teamFindPlayer() {
      return this.forums.filter((x) => x.type === "TEAMPLAYER");
    },
    teamFindTrainer() {
      return this.forums.filter((x) => x.type === "TEAMTRAINER");
    },
    trainers() {
      return this.users.filter((x) => x.user.role === "TRAINER");
    },
    players() {
      return this.users.filter((x) => x.user.role === "PLAYER");
    },
    media() {
      let _media = [];
      if (this.team.gallery) {
        this.team.gallery.forEach((x) => {
          const item = {
            thumb: x,
            src: x,
            caption: "<h4></h4>",
          };
          _media.push(item);
        });
      }
      return _media;
    },
    ievents() {
      const events = [];
      this.teamEvents.forEach((event) => {
        const nEvents = this.generateEvents(event);
        events.push(...nEvents);
      });
      events.sort(function(a,b){
        return new Date(a.start) - new Date(b.start);
      });
      return events;
    },
  },
  created() {
    this.getAllTeamPlayers()
    this.getAllTeamTrainer()
    this.getAllTeamArenas()
    this.getAllTeamAdmin()
    this.getAllTeamTitles()
    this.getUserRoles()
    this.getAllTeamEvents()
    const teamId = this.$route.params.teamId;

    this.$store.dispatch("team/getPlayers", teamId)
    this.$store.dispatch("team/getForums", teamId).then(() => {
      console.log(this.forums)
    })
    this.$store.dispatch("team/getTeam", teamId).then(() => {
      const teamItem = this.team;
      this.breadcrumb_items = [
        {
          text: "Список команд",
          disabled: false,
          exact: true,
          to: { name: "room" },
        },
        {
          text: teamItem.title,
          disabled: true,
          to: "",
        },
      ];

      this.contact_list = [
        {
          icon: "mdi-whatsapp",
          link: `${teamItem.whatsApp ? teamItem.whatsApp : ""}`,
        },
        {
          icon: "mdi-instagram",
          link: `${teamItem.instagram ? teamItem.instagram : ""}`,
        },
        { 
          icon: "mdi-alpha-k-box", 
          link: `${teamItem.vk ? teamItem.vk : ""}` },
        {
          icon: "mdi-web",
          link: `${teamItem.website ? teamItem.website : ""}`,
        },
        {
          icon: "mdi-music-note-outline",
          link: `${teamItem.tiktok ? teamItem.tiktok : ""}`,
        },
        {
          icon: "mdi-twitter",
          link: `${teamItem.twitter ? teamItem.twitter : ""}`,
        },
        {
          icon: "mdi-youtube",
          link: `${teamItem.youtube ? teamItem.youtube : ""}`,
        },
        {
          icon: "mdi-facebook",
          link: `${teamItem.facebook ? teamItem.facebook : ""}`,
        },
      ];
    });
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formatter.format(newDate);
    },
    async getAllTeamPlayers() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=PLAYER`)
      .then( (res) => {
        this.teamPlayers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getAllTeamTrainer() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TRAINER`)
      .then( (res) => {
        this.teamTrainers = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getAllTeamAdmin() {
      await Axios.get(`${GET_TEAM}${this.teamId}/users?approved=accepted&role=TEAM_ADMIN`)
      .then( (res) => {
        this.teamAdmin = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },
    async getAllTeamArenas() {
      await Axios.get(`${GET_TEAM}${this.teamId}/arenas`)
      .then( (res) => {
        this.teamArenas = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getAllTeamTitles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/team/titles`)
      .then( (res) => {
        res.data.find(item => {
          if(item.teamId == this.teamId){
            this.userStatus = item.approved
            this.isStatus = true
          }
        })
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
      .then( (res) => {
        this.allUserRoles = res.data
      })
      .catch((error) => {
        console.error(error);
      })
    },

    checkUserRole(){
      const playerRole = this.allUserRoles.filter((x) => x.name === "PLAYER")
      const trainerRole = this.allUserRoles.filter((x) => x.name === "TRAINER")
      if(playerRole.length == 1 && trainerRole.length == 1){
        this.playerRole = playerRole[0].id
        this.trainerRole = trainerRole[0].id
        this.role_dialog = true
      } else if(playerRole.length == 1) {
        this.roleId = playerRole[0].id
        this.joinTeam()
      } else if(trainerRole.length == 1){
        this.roleId = trainerRole[0].id
        this.joinTeam()
      } else {
        this.$router.push({
          name: "user-profile",
          params: { userId: this.userId },
        });
        const notification = {
          type: "success",
          message: "Зополните роль для вступления в команду",
        };
        this.$store.dispatch("notification/add", notification, { root: true });
      }
    },

    roleChoosing(id) {
      this.roleId = id
      this.role_dialog = false
      this.joinTeam()
    },

    async joinTeam() {
      const data = {
        teamId: this.teamId,
        userId: this.userId,
        roleId: this.roleId,
        visibility: 1,
        approved: "waiting"
      };
      await Axios.post(`${ADD_USER_TO_TEAM}`,data)
      .then( () => {
        this.getAllTeamTitles()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getAllTeamEvents() {
      await Axios.get(`${GET_TEAM}${this.teamId}/events`)
      .then( (res) => {
        this.teamEvents = res.data
        console.log(this.teamEvents)
      })
      .catch((error) => {
        console.error(error);
      })
    },

    getEventColor(event) {
      return event.color;
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // this.tab_value = 0
        // this.getAllUsers()
        // this.getAttendedUsers()
        // this.getAbsentUsers()
        // this.getIgnoredUsers()
        // this.getEventArena()
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    viewDay({ date }) {
      this.value = 0;
      this.date = date;
      this.type = "day";
    },

    generateEvents(event) {
      const selectedDays = event.days;
      const startDate = event.startDate,
        endDate = event.endDate,
        startTime = event.startTime,
        endTime = event.endTime,
        title = event.title,
        description = event.description,
        type = event.type,
        id = event.id,
        arenaId = event.arenaId,
        repeat = event.repeat;
      const color = this.colors[this.rnd(0, this.colors.length - 1)];

      const startMoment = moment(
        `${startDate}T${startTime ? startTime : "00:00"}:00`
      );
      const endMoment = moment(`${endDate}T${endTime ? endTime : "00:00"}:00`);
      const arr_events = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < selectedDays.length; j++) {
          if (Number(startMoment.day()) === selectedDays[j]) {
            const startMomentDate = startMoment.format("YYYY-MM-DDTHH:mm:ss");
            const beginMoment = moment(startMomentDate);

            while (
              Math.ceil(endMoment.diff(beginMoment, "weeks", true)) !== 0
            ) {
              const stopInterval = beginMoment.format("YYYY-MM-DD");
              const stopMoment = moment(
                `${stopInterval}T${endTime ? endTime : "00:00"}:00`
              );
              arr_events.push({
                name: title,
                start: beginMoment.format("YYYY-MM-DDTHH:mm:ss"),
                end: stopMoment.format("YYYY-MM-DDTHH:mm:ss"),
                color: color,
                timed: true,
                type: type,
                id: id,
                arenaId: arenaId,
                description: description,
              });
              if (repeat === 0) break;
              beginMoment.add(1, "weeks");
            }

            break;
          }
        }
        startMoment.add(1, "days");
      }
      return arr_events;
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
  },
  data() {
    return {
      contact: null,
      premises_tab: null,
      arenaId: null,
      advert_nav: ["Команда ищет игроков", "Команда ищет тренера"],
      contact_list: null,
      readMoreInfo: false,
      breadcrumb_items: null,
      teamPlayers: [],
      teamTrainers: [],
      teamAdmin: [],
      teamArenas: [],
      teamTitles: [],
      userStatus: "",
      isStatus: false,
      readMorePlayers: false,
      roleId: "",
      allUserRoles: [],
      event_dialog: false,
      role_dialog: false,
      playerRole: [],
      trainerRole: [],
      schedule_nav: ["Дневное", "Недельное", "Месячное", "Список мероприятий"],
      value: 2,
      date_picker: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      weekday: [1, 2, 3, 4, 5, 6, 0],
      type: "month",
      mode: "column",
      selectedOpen: false,
      selectedElement: null,
      colors: [
        "#EBF5FB",
      ],
      teamEvents: [],
      selectedEvent: {},
    };
  },
};
</script>

<style>
.description {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
}
</style>
