<template>
  <div class="grey lighten-4">
    <v-container class="pb-10">
      <v-row class="">
        <div>
          <v-breadcrumbs :items="breadcrumb_items"></v-breadcrumbs>
        </div>
      </v-row>
      <div>
        <div class="text-h5 pb-3 pt-5 font-weight-bold">
          Редактировать детский турнир
        </div>
        <v-row>
          <v-col cols="4" class="pr-10">
            <div class="body-1 mb-4 font-weight-bold">
              Логотип детского турнира
            </div>

            <admin-image-uploader v-model="avatar">
              <div slot="activator">
                <div v-if="!avatar" class="white rounded-xl pa-4">
                  <v-avatar
                    width="100%"
                    height="200"
                    v-ripple
                    tile
                    class="white rounded-xl"
                  >
                    <div class="upload-border rounded-xl pa-4">
                      <div class="my-4">
                        <v-icon large color="#379AD3"
                          >mdi-cloud-upload-outline
                        </v-icon>
                      </div>
                      <div class="body-1 mb-2 font-weight-bold">
                        Загрузите логотип
                      </div>
                      <div class="body-2 mb-4 grey--text">
                        Поддерживаемые форматы: PNG, JPG
                      </div>
                    </div>
                  </v-avatar>
                </div>
                <div v-else class="white rounded-xl pa-4">
                  <v-avatar width="100%" height="200" tile v-ripple>
                    <v-img
                      class="ma-10 rounded-xl"
                      :src="avatar"
                      alt="avatar"
                      cover
                    ></v-img>
                  </v-avatar>
                </div>
              </div>
            </admin-image-uploader>
          </v-col>
          <v-col>
            <div class="body-1 mb-4 font-weight-bold">Основная информация</div>
            <div class="mb-4">
              <v-text-field
                label="Название детского турнира"
                v-model="fullTitle"
                :rules="fullTitleRules"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-select
                v-model="location"
                :items="cities"
                :rules="cityRules"
                label="Город"
                outlined
                flat
                dense
                hide-details="auto"
                class="rounded-lg"
              ></v-select>
            </div>
            <div>
              <v-row>
                <v-col v-if="haveDate" cols="4">
                  <AppSelectDatePicker :label="start" :date.sync="startDate" />
                </v-col>
                <v-col v-if="haveDate" cols="4">
                  <AppSelectDatePicker :label="end" :date.sync="endDate" />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Вступительный взнос"
                    v-model="entranceFee"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    class="rounded-lg"
                    ref="entranceFee"
                    :rules="[rules.required, rules.isFloatNumber]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Год рождения"
                    v-model="startAge"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    class="rounded-lg"
                    ref="startAge"
                    :rules="[rules.required, rules.isIntNumber]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <div>
          <div class="text-h6 mb-2">Описание</div>
          <v-textarea
            solo
            v-model="description"
            name=""
            flat
            elevation="0"
          ></v-textarea>
        </div>
        <div class="mb-4">
          <AdminKidsTournamentArena :tournamentId="tournamentId" />
          <!-- <AdminTournamentArena :league-id="tournamentId"/> -->
        </div>
        <div class="mb-4">
          <AdminContactForm
            :getContact="getContact"
            :contact="contacts"
          ></AdminContactForm>
        </div>
        <!-- <div class="mb-4">
          <AdminTournamentOrganizer :league-id="tournamentId" />
        </div> -->
        <div class="mb-4">
          <AdminSocialMedia
            :items="social_media"
            @newMedias="setSocialMedias"
          ></AdminSocialMedia>
        </div>
        <div class="mb-4">
          <AdminGallery :items="galleryPics"></AdminGallery>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          @click="updateKidsTournament"
          large
          elevation="0"
          color="primary"
          class="body-2 px-6 mr-2"
        >
          Сохранить
        </v-btn>
        <v-btn
          color="grey lighten-2"
          large
          class="body-2 px-6 ml-2"
          elevation="0"
          @click="
            $router.push({
              name: 'user-profile',
              params: { userId: userId },
            })
          "
        >
          Отменить
        </v-btn>
      </div>
      <v-dialog v-model="errorDialog" max-width="300">
        <v-card>
          <div class="text-center px-4 pt-6">{{ errorText }}</div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="goToProfile" text>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import AdminImageUploader from "@/components/Admin/AdminImageUploader.vue";
import AdminGallery from "@/components/Admin/AdminGallery.vue";
import AdminSocialMedia from "@/components/Admin/AdminSocialMedia.vue";
import { mapState } from "vuex";
// import AdminTournamentArena from "@/components/Admin/AdminTournamentArena";
import AdminContactForm from "../../../../components/Admin/AdminContactForm.vue";
import AppSelectDatePicker from "../../../../components/AppUnit/AppSelectDatePicker.vue";
import AdminKidsTournamentArena from "../../../../components/Admin/AdminKidsTournamentArena.vue";

export default {
  components: {
    AdminImageUploader,
    AdminGallery,
    AdminSocialMedia,
    // AdminTournamentArena,
    AdminContactForm,
    AppSelectDatePicker,
    AdminKidsTournamentArena,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("leagues", ["kidsTournament", "cities"]),
    social_media_display() {
      return this.social_media.filter((x) => x.link);
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.dispatch("leagues/getCities");
    this.$store
      .dispatch("leagues/getKidsTournament", this.tournamentId)
      .then(() => {
        const kidsTournament = this.kidsTournament;
        this.fullTitle = kidsTournament.title;
        this.location = kidsTournament.city;
        this.avatar = kidsTournament.logo;
        this.description = kidsTournament.description;
        this.contacts = kidsTournament.contact;
        this.entranceFee = kidsTournament.entranceFee;
        this.startDate = kidsTournament.startDate;
        this.endDate = kidsTournament.endDate;
        this.haveDate = true;
        this.startAge = kidsTournament.age;
        this.documents = kidsTournament.documents;
        console.log(kidsTournament);
      });
  },
  async beforeMount() {
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId: this.userId } },
      },
      {
        text: "Редактировать детский турнир",
        disabled: true,
        to: "",
      },
    ];
  },
  data() {
    return {
      files: {},
      galleryPics: [],
      description: "",
      location: "",
      locations: ["Москва"],
      avatar: null,
      contacts: "",
      galleryName: "multifile",
      checkbox: null,
      telephone: "",
      email: "",
      errMessage: "",
      start: "Дата начала проведения",
      end: "Дата конца проведения",
      startDate: "",
      endDate: "",
      haveDate: false,
      startAge: null,
      endAge: null,
      entranceFee: null,
      errorDialog: false,
      errorText: "",
      social_media_dialog: false,
      toggle_social_media: null,
      social_media_text: "",
      contact_dialog: false,
      album_dialog: false,
      fullTitle: "",
      social_media: [
        {
          id: 1,
          name: "vk",
          link: "",
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: "",
          icon: "mdi-whatsapp",
        },
        {
          id: 2,
          name: "web",
          link: "",
          icon: "mdi-web",
        },
      ],
      breadcrumb_items: [],
      fullTitleRules: [(v) => !!v || "Введите полное название"],
      cityRules: [(v) => !!v || "Выберите город"],
      rules: {
        required: (value) => {
          return !!value || "Обязательно для заполнения";
        },
        isFloatNumber: (value) => {
          const pattern = /^[1-9][0-9]*(\.[0-9]+)?$/;
          return (
            pattern.test(value) ||
            "Это должно быть число с точкой в качестве разделителя"
          );
        },
        isIntNumber: (value) => {
          const pattern = /^[1-9][0-9]*$/;
          return pattern.test(value) || "Это должно быть целое число";
        },
      },
    };
  },
  methods: {
    setSocialMedias(value) {
      this.social_media = value;
    },
    validate() {
      if (this.fullTitle && this.location) {
        return true;
      }
      return false;
    },
    getContact(data) {
      this.contacts = data.contact;
    },
    goToProfile() {
      this.errorDialog = true;
      this.$router.push({
        name: "user-profile",
        params: { userId: this.userId },
      });
    },
    updateKidsTournament() {
      if (this.validate()) {
        const data = {
          title: this.fullTitle,
          logo: this.avatar,
          editedLogo: this.kidsTournament.editedLogo,
          type: "KIDS",
          city: this.location,
          description: this.description,
          contact: this.contacts,
          startDate: this.startDate,
          endDate: this.endDate,
          age: this.startAge,
          entranceFee: this.entranceFee,
          gallery: this.galleryPics,
          vk: this.social_media[0].link,
          whatsApp: this.social_media[1].link,
          website: this.social_media[2].link,
          documents: [],
          approved: false,
        };
        const ndata = {
          tournamentId: this.tournamentId,
          data: data,
        };
        this.$store.dispatch("leagues/updateKidsTournament", ndata);
        this.errorDialog = true;
        this.errorText =
          "Ваша заявка принята на рассмотрение. Решение будет выслано на почту после рассмотрения";
      }
    },
  },
};
</script>

<style>
.upload-border {
  border: 1px dashed #c3e1f2;
}
</style>
