<template>
  <div>
    <div>
      <v-container class="pb-0">
        <v-row>
          <AppBreadcrumb :items="breadcrumb_items" />
        </v-row>
        <v-row class="mb-4">
          <v-col cols="4" md="3">
            <div class="d-flex align-center">
              <v-spacer></v-spacer>
              <div>
                <v-avatar size="240" style="border-radius: 50%">
                  <v-img
                    :src="
                      user.editedProfilePicture
                        ? user.editedProfilePicture
                        : require('@/assets/unknown.jpeg')
                    "
                  ></v-img>
                </v-avatar>
              </div>
              <v-spacer></v-spacer>
            </div>
            <div class="mt-5 d-flex align-center">
              <v-spacer></v-spacer>
              <div class="text-h5" style="font-weight: 500">
                {{ user.surname }} {{ user.name[0] }}. {{ user.middleName[0] }}.
              </div>
              <div class="ml-2">
                <v-btn
                  icon
                  @click="
                    $router.push({
                      name: 'admin-edit-user',
                      params: { userId: user.id },
                    })
                  "
                >
                  <v-icon size="25" color="#000"> mdi-pencil </v-icon>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
            </div>
            <div class="d-flex align-center mt-2">
              <v-spacer></v-spacer>
              <div v-for="(item, index) in valid_contact_list" :key="index">
                <a class="reset-link" :href="item.link" target="_blank">
                  <v-btn
                    elevation="0"
                    x-small
                    color="transparent"
                    height="40px"
                    class="mx-1"
                  >
                    <v-icon color="black"> {{ item.icon }}</v-icon>
                  </v-btn>
                </a>
              </div>
              <v-spacer></v-spacer>
            </div>
            <div class="mt-11">
              <AppVerticalSidebar :items="main_sidebar_items" />
              <AppTeamSidebar
                v-if="itsTeamAdmin"
                class="mt-4"
                :userId="userId"
              />
              <AppArenaSidebar
                v-if="itsArenaAdmin"
                class="mt-4"
                :userId="userId"
              />
              <AppTournamentSidebar
                v-if="itsTournamentAdmin"
                class="mt-4"
                :userId="userId"
              />
              <AppSchoolSidebar
                v-if="itsArenaAdmin"
                class="mt-4"
                :userId="userId"
              />
              <AppTrainerSidebar
                v-if="itsTeamTrainer"
                class="mt-4"
                :userId="userId"
              />
            </div>
          </v-col>
          <v-col cols="8" md="9">
            <div>
              <v-container class="pa-0">
                <router-view :user="user"></router-view>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import AppTournamentSidebar from "../../components/AppUnit/AppTournamentSidebar";
import AppSchoolSidebar from "@/components/AppUnit/AppSchoolSidebar";
import AppTrainerSidebar from "@/components/AppUnit/AppTrainerSidebar";

export default {
  components: { AppTournamentSidebar, AppSchoolSidebar, AppTrainerSidebar },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getUserRoles();
    this.$store.dispatch("user/getUser", this.userId).then(() => {
      const userId = this.userId;
      this.main_sidebar_items = [
        {
          text: "Профиль",
          link: { name: "user-profile", params: { userId } },
        },
        // {
        //   text: "Центр сообщений",
        //   link: "",
        // },
        // {
        //   text: "Корзина",
        //   link: ``,
        // },
        // {
        //   text: "Избранное",
        //   link: ``,
        // },
        {
          text: "Мои объявления",
          link: { name: "admin-advertisements", params: { userId } },
        },
      ];

      this.mail = this.user.mail;
      const user = this.user;
      this.contact_list = [
        {
          icon: "mdi-whatsapp",
          link: `${user.whatsApp ? user.whatsApp : ""}`,
        },
        {
          icon: "mdi-instagram",
          link: `${user.instagram ? user.instagram : ""}`,
        },
        {
          icon: "mdi-alpha-k-box",
          link: `${user.vk ? user.vk : ""}`,
        },
        {
          icon: "mdi-web",
          link: `${user.website ? user.website : ""}`,
        },
        {
          icon: "mdi-music-note-outline",
          link: `${user.tiktok ? user.tiktok : ""}`,
        },
        {
          icon: "mdi-twitter",
          link: `${user.twitter ? user.twitter : ""}`,
        },
        {
          icon: "mdi-youtube",
          link: `${user.youtube ? user.youtube : ""}`,
        },
        {
          icon: "mdi-facebook",
          link: `${user.facebook ? user.facebook : ""}`,
        },
      ];
    });
  },
  computed: {
    ...mapState("user", ["user"]),
    valid_contact_list() {
      return this.contact_list.filter((x) => {
        if (x.link !== "null") {
          if (x.link) return x.link;
        }
      });
    },
  },
  data() {
    return {
      breadcrumb_items: [
        {
          text: "Личный кабинет",
          disabled: true,
        },
      ],
      main_sidebar_items: [],
      additional_sidebar_items: [],
      arenas_sidebar_items: [],
      itsTeamAdmin: false,
      itsArenaAdmin: false,
      itsTournamentAdmin: false,
      itsTeamTrainer: false,
      sidebar_tab: 0,
      enterMail: true,
      changePassword: false,
      contact_list: [],
      checkbox: false,
      showPass: false,
      rules: {
        required: (value) => !!value || "Обязательный поль.",
      },
      password: "",
      mail: "",
      passCode: "",
      allUserRoles: [],
    };
  },
  methods: {
    validAge(age) {
      if (age) {
        return `${age} год`;
      }
    },
    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
        .then((res) => {
          const userId = this.userId;
          this.allUserRoles = res.data;
          this.allUserRoles.forEach((item) => {
            if (item.name == "TEAM_ADMIN" || item.name == "PLAYER") {
              this.itsTeamAdmin = true;
            } else if (item.name == "ARENA_ADMIN") {
              this.itsArenaAdmin = true;
            } else if (item.name == "TOURNAMENT_ORGANIZER") {
              this.itsTournamentAdmin = true;
            } else if (item.name == "TRAINER") {
              this.itsTeamTrainer = true;
            } else {
              this.additional_sidebar_items = [
                {
                  text: "Мои команды",
                  link: { name: "admin-team", params: { userId } },
                },
                {
                  text: "Мои спортивные комплексы",
                  link: {
                    name: "admin-add-arena-sportcomplex",
                    params: { userId },
                  },
                },

                {
                  text: "Моя организация",
                  link: ``,
                },
                {
                  text: "Мои мероприятия",
                  link: { name: "admin-events", params: { userId } },
                },
                {
                  text: "Календарь мероприятий",
                  link: ``,
                },
                {
                  text: "Мои объявления",
                  link: { name: "admin-advertisements", params: { userId } },
                },
              ];
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // restorePassword() {
    //   this.changePassword = true;
    //   this.enterMail = true;
    // },
  },
};
</script>

<style scoped></style>
