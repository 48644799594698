<template>
  <v-container>
    <div
      style="
        text-decoration: none;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 500;
      "
    >
      Персональные данные
    </div>
    <v-row class="mt-4">
      <v-col cols="6" class="mb-2" v-show="user.birthDate">
        <div class="body-1 mb-2 grey--text">Дата рождения</div>
        <div style="font-size: 16px">
          {{ rightDate(user.birthDate) }}
        </div>
      </v-col>
      <v-col cols="6" class="mb-2" v-show="user.gender">
        <div class="body-1 mb-2 grey--text">Пол</div>
        <div style="font-size: 16px">{{ user.gender }}</div>
      </v-col>
      <v-col cols="6" class="mb-2" v-show="user.phone">
        <div class="body-1 mb-2 grey--text">Номер телефона</div>
        <div style="font-size: 16px">
          {{ user.phone }}
        </div>
      </v-col>
      <v-col cols="6" class="mb-2" v-show="user.mail">
        <div class="body-1 mb-2 grey--text">Электронная почта</div>
        <div class="blue--text" style="font-size: 16px">
          {{ user.mail }}
        </div>
      </v-col>
      <v-col cols="6" class="mb-2" v-show="user.address">
        <div class="body-1 mb-2 grey--text">Адрес</div>
        <div style="font-size: 16px">
          {{ user.address }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  props: {
    user: {
      type: String,
      required: true,
    },
  },
  methods: {
    rightDate(date) {
      var time = moment(date).locale("ru");
      return time.format("D MMMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped></style>
