<template>
  <v-card
    class="mx-auto rounded-lg"
    elevation="0"
  >
    <v-list color="#E2E2E2" style="padding: 0">
      <v-list-item>

        <v-list-item-title>Мои команды</v-list-item-title>

        <v-list-item-icon v-if="isTeamAdmin">
          <v-icon
          @click="
            $router.push({
                name: 'admin-team-create',
                params: { userId },
            })
            "
            color="#000">mdi-plus</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-group
      style="background-color: #fff"
      v-for="team in teams"
      :key="team.teamId"
      no-action
      >
        <template  v-slot:activator>
          <v-list-item-title @click="changeTeam(team.teamId)">{{team.teamTitle}}</v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn v-if="team.roleId == userAdminRoleId" @click.stop="editTeam(team.teamId)" small icon>
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn v-if="team.roleId == userAdminRoleId" @click.stop="openWarningDialog(team.teamId)" small icon>
            <v-icon small>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>

        <v-card elevation="0" class="pl-4">
          <AppTeamVerticalSidebar :key="componentKey" :team="team" />
        </v-card>

      </v-list-group>
    </v-list>
    <v-dialog v-model="warning_dialog" max-width="600">
        <v-card class="grey lighten-5">
          <v-card-text class="text-h5 black--text pt-5">
            Вы действительно хотите удалить эту команду?
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn
              class="body-2"
              @click="warning_dialog = false"
              elevation="0"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="error"
              class="body-2"
              @click="deleteTeam"
            >
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import { mapState } from "vuex";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sidebar: null,
      teams: [],
      componentKey: "",
      isTeamAdmin: false,
      allUserRoles: [],
      userAdminRoleId: "",
      userTrainerRoleId: "",
      warning_dialog: false,
      teamId: "",
    };
  },
  created(){
    this.getAllAdminTeams()
    // this.definingUserRole()
    this.getUserRoles()
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    async getAllAdminTeams() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/team/titles`)
      .then( (res) => {
        this.teams = res.data.filter((x) => x.approved === "accepted")
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
      .then( (res) => {
        this.allUserRoles = res.data
        this.allUserRoles.forEach(item => {
          if(item.name === "TEAM_ADMIN"){
            this.isTeamAdmin = true
            this.userAdminRoleId = item.id
          }
          if(item.name === "TRAINER"){
            this.userAdminRoleId = item.id
          }
        });
      })
      .catch((error) => {
        console.error(error);
      })
    },

    changeTeam(id){
      this.componentKey = id
    },

    deleteTeam() {
      const payload = {
        userId: this.userId,
        teamId: this.teamId,
      };
      this.$store.dispatch("user/deleteTeam", payload).then(() => {
        this.$router.go();
      });
    },

    openWarningDialog(id){
      this.warning_dialog = true
      this.teamId = id
    },

    editTeam(id) {
      const teamId = id
      this.$router.push({
        name: "admin-team-edit",
        params: { teamId },
      });
    },
  }
};
</script>

<style>
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child){
  margin-left: 10px;
}

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
  min-width: 0;
}
</style>
