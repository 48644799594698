var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('yandex-map',{attrs:{"settings":_vm.settings,"coords":_vm.coords,"zoom":_vm.zoom,"options":{
    suppressMapOpenBlock: true,
    autoFitToViewport: 'always',
  },"controls":['geolocationControl', 'zoomControl', 'trafficControl'],"cluster-callbacks":{ '1': { click: _vm.getDataPoint } },"cluster-options":{
    1: {
      clusterDisableClickZoom: false,
      hasBalloon: false,
      clusterLayout: [
        '<div class=cluster-icon>{{ properties.geoObjects.length }}</div>' ].join(''),
    },
  }},on:{"map-was-initialized":_vm.initHandler}},_vm._l((_vm.surfaces),function(billboard,index){return _c('ymap-marker',{key:index,attrs:{"marker-id":index,"marker-type":"placemark","callbacks":{ click: _vm.getDataPoint },"cluster-name":"1","balloon-template":_vm.mapBalloon(billboard),"coords":billboard.coords.split(',')}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }