<template>
  <div>
    <div class="white">
      <v-container>
        <div class="d-flex">
          <div class="mb-4 text-h5 mr-2" style="font-weight: 500">Профессиональные навыки</div>
          <div v-if="isLK">
            <v-btn icon
            @click="$router.push({
              name: 'admin-user-role-edit',
              params: { userId, roleId },
            })">
              <v-icon size="22" color="#000">
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </div>
        
        <template v-if="role.name === 'PLAYER'">
          <v-row>
            <v-col cols="6" md="3" class="mb-0" v-show="role.position">
              <div class="body-1 mb-2 grey--text">Амплуа</div>
              <div class="">
                {{ role.position }}
              </div>
            </v-col>
            <v-col cols="6" md="3" class="mb-0" v-show="role.grip">
              <div class="body-1 mb-2 grey--text">Хват</div>
              <div class="">{{ role.grip }}</div>
            </v-col>
            <v-col cols="6" md="3" class="mb-0" v-show="role.height">
              <div class="body-1 mb-2 grey--text">Рост</div>
              <div class="">
                {{ role.height + " см" }}
              </div>
            </v-col>
            <v-col cols="6" md="3" class="mb-0" v-show="role.weight">
              <div class="body-1 mb-2 grey--text">Вес</div>
              <div class="">{{ role.weight + " кг" }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" class="mb-0" v-show="role.userAchievements.length != 0">
              <div class="body-1 mb-2 grey--text">Достижения</div>
              <div v-for="(item, i) in role.userAchievements" :key="i" class="">{{ item }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" class="mb-0" v-show="role.userStatistics">
              <div class="body-1 mb-2 grey--text">Статистика</div>
              <a :href="role.userStatistics" class="">{{ role.userStatistics }}</a>
            </v-col>
          </v-row>
        </template>
        <template v-if="role.name === 'TRAINER'">
          <v-row class="mb-4">
            <v-col cols="6" md="3" class="mb-2" v-show="role.status">
              <div class="body-1 mb-2 grey--text">Статус</div>
              <div class="">
                {{ role.status }}
              </div>
            </v-col>
            <v-col cols="6" md="3" class="mb-2" v-show="role.category">
              <div class="body-1 mb-2 grey--text">Возрастная категория</div>
              <div class="">{{ roleCategory }}</div>
            </v-col>
          </v-row>
        </template>
        <v-row >
          <v-col cols="12" v-show="role.biography">
            <div class="body-1 mb-2 grey--text">Биография</div>
            <div v-html="role.biography"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    roleId: {
      type: String,
      required: true,
    },
    isLK: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    //AdminForumCard,
  },
  created() {
    const userId = this.userId;
    this.breadcrumb_items = [
      {
        text: "Личный кабинет",
        disabled: false,
        exact: true,
        to: { name: "user-profile", params: { userId } },
      },
      {
        text: "роль пользователя",
        disabled: true,
        to: "",
      },
    ];
    this.$store.dispatch("user/getUser", userId).then(() => {
      this.getSocialMedia();
    });
    this.$store.dispatch("user/getForums", this.userId);
    this.$store.dispatch("user/getRole", this.roleId);
  },
  computed: {
    ...mapState("user", ["user", "forums", "role"]),
    roleCategory() {
      if (this.role.name === "TRAINER") {
        return this.role.category
          .map((cat) => {
            if (cat == "ADULT") return "Взрослый";
            if (cat == "KID") return "Детский";
            if (cat == "YOUTH") return "Юношеский";
            if (cat == "FEMALE") return "Женский";
          })
          .join(", ");
      } else return "";
    },
    displaySocialMedia() {
      return this.social_media.filter((element) => element.link);
    },
    fullName() {
      const { name, middleName, surname } = this.user;
      return `${name} ${middleName} ${surname}`;
    },
  },
  data() {
    return {
      breadcrumb_items: [],
      loading: false,
      social_media: [],
      createForumDialog: false,
      modifyForumDialog: false,
      nforum: {
        find: { value: "PLAYERTEAM", state: "Игрок ищет команду" },
        description: "",
        city: "Москва",
        position: "",
        grip: "",
      },
      mforum: {
        find: {},
        description: "",
        city: "",
        position: "",
        grip: "",
      },
      cities: ["Москва"],
      playerSearch: [
        { value: "PLAYERTEAM", state: "Игрок ищет команду" },
        { value: "TEAMPLAYER", state: "Команда ищет игроков" },
        { value: "TEAMTRAINER", state: "Команда ищет тренера" },
        { value: "TRAINERTEAM", state: "Тренер ищет команду" },
      ],
      positions: ["Защитник", "Нападающий", "Вратарь"],
      grips: ["левый", "правый"],
      forumId: "",
    };
  },
  methods: {
    getSocialMedia() {
      const { vk, whatsApp, instagram, website, facebook } = this.user;
      this.social_media = [
        {
          id: 1,
          name: "vk",
          link: vk,
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: whatsApp,
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: website,
          icon: "mdi-web",
        },
        {
          id: 4,
          name: "instagram",
          link: instagram,
          icon: "mdi-instagram",
        },
        {
          id: 5,
          name: "facebook",
          link: facebook,
          icon: "mdi-facebook",
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
