<template>
  <v-card class="mx-auto rounded-lg" elevation="0">
    <v-list color="#E2E2E2" style="padding: 0">
      <v-list-item>
        <v-list-item-title>Лагеря и сборы</v-list-item-title>

        <v-list-item-icon>
          <v-icon
            @click="
              $router.push({
                name: 'create-camp',
                params: { userId },
              })
            "
            color="#000"
            >mdi-plus</v-icon
          >
        </v-list-item-icon>
      </v-list-item>

      <v-list-group
        style="background-color: #fff"
        v-for="{ camp, id } in camps"
        :key="id"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>{{ camp.title }}</v-list-item-title>
          <v-spacer></v-spacer>
          <v-btn @click.stop="editCamp(camp.id)" small icon>
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn @click.stop="openWarningDialog(camp.id)" :key="id" small icon>
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-list-group>
      <v-dialog v-model="warning_dialog" max-width="600">
        <v-card class="grey lighten-5">
          <v-card-text class="text-h5 black--text pt-5">
            Вы действительно хотите удалить эту лигу?
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn class="body-2" @click="warning_dialog = false" elevation="0">
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="error"
              class="body-2"
              @click="deleteCamp"
            >
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-card>
</template>

<script>
import { GET_USER_BY_ID } from "@/api";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isTrainer: false,
      allUserRoles: [],
      trainerRoleId: "",
      warning_dialog: false,
      campId: "",
    };
  },
  async created() {
    await this.getUserRoles();
    this.getAllAdminCamps();
  },
  computed: {
    ...mapState("user", ["camps"]),
  },
  methods: {
    getAllAdminCamps() {
      this.getCamps([this.userId, this.trainerRoleId]);
    },

    async getUserRoles() {
      const res = await axios.get(`${GET_USER_BY_ID}${this.userId}/roles`);
      this.allUserRoles = res.data;
      this.allUserRoles.forEach(({ name, id }) => {
        if (name === "TRAINER") {
          this.isTrainer = true;
          this.trainerRoleId = id;
        }
      });
    },

    editCamp(id) {
      const campId = id;
      this.$router.push({
        name: "edit-admin-camp",
        params: { campId },
      });
    },

    openWarningDialog(id) {
      this.warning_dialog = true;
      this.campId = id;
    },

    deleteCamp() {
      this.$store.dispatch("user/deleteCamp", this.campId);
      this.warning_dialog = false;
    },

    ...mapActions("user", ["getCamps"]),
  },
};
</script>

<style>
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 10px;
}

.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0;
}
</style>
