<template>
  <v-card
    class="mx-auto d-flex rounded-card"
    max-width="600"
    max-height="300"
    :elevation="elevation"
    @mouseover="elevation = '2'"
    @mouseout="elevation = '0'"
    @click="goToArena"
  >
    <v-avatar size="200" tile
      ><img :src="this.pickAvatar(arena.editedProfilePicture)" alt="logo" />
    </v-avatar>
    <div class="d-flex flex-column justify-space-between">
      <div>
        <v-card-title class="mb-2"> {{ arena.title }} </v-card-title>
        <v-card-subtitle>
          <p class="ma-0">{{ `${arena.city}` }}</p>
          <p class="mt-1 mb-0">{{ `${arena.address}` }}</p>
        </v-card-subtitle>
      </div>
      <div class="mx-2 mb-4 d-flex justify-space-between">
        <v-btn
          @click.stop="openCardMap"
          class="rounded-lg white"
          small
          elevation="0"
        >
          На карте
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ArenaCard",
  props: {
    arena: Object,
  },
  mounted() {
    this.selected_arenas.forEach((x) => {
      if (x.id === this.arena.id) {
        this.selected = true;
      }
    });
  },
  data() {
    return {
      elevation: 0,
      selected: false,
    };
  },
  methods: {
    goToArena() {
      const arenaId = this.arena.id;
      this.$router.push({
        name: "arena-information",
        params: { arenaId },
      });
    },
    openCardMap() {
      this.$router.push({
        name: "arena-map-all",
        query: { arenaTitle: `${this.arena.title}` },
      });
    },
    selectArena(arena) {
      this.selected = !this.selected;
      if (this.selected) {
        this.$store.dispatch("arena/addToSelectedArenas", arena);
      } else {
        this.$store.dispatch("arena/removeFromSelectedArenas", arena);
      }
    },
    pickAvatar(croppedImg) {
      return croppedImg ? croppedImg : require("@/assets/team_room_1.jpg");
    },
  },
  filters: {
    addressDescription: (value) => {
      if (!value) return "";
      return value.slice(0, 45);
    },
    arenaTitle: (value) => {
      if (!value) return "";
      if (value.length <= 28) return value;
      return value.slice(0, 28) + "..";
    },
  },
  computed: {
    ...mapState("arena", ["selected_arenas"]),
    checkPlus() {
      return this.selected ? "mdi-check" : "mdi-plus";
    },
  },
};
</script>

<style></style>
