<template>
  <div>
    <v-card color="transparent" elevation="0" class="mb-5">
      <div class="d-flex flex-no-wrap">
        <div class="ma-3">
          <v-avatar
            class="rounded-lg"
            tile
            width="200px"
            height="150px"
            contain
          >
            <v-img
              contain
              :src="
                item.profilePicture != null
                  ? item.profilePicture
                  : require('@/assets/preview_arena_1.jpg')
              "
            ></v-img>
          </v-avatar>
        </div>
        <v-row>
          <v-col cols="12">
            <slot name="edit-delete"></slot>

            <v-card-text>
              <div class="text-h5 mb-4" style="color: #0681c8">
                {{ item.title }}
                <span class="body-1 ml-4" v-show="item.length * item.width">
                  {{ item.length * item.width }}
                </span>
              </div>
              <div class="body-1 black--text mb-3" style="white-space: pre-line" v-if="item.description.length < 200">
                {{ item.description }}
              </div>
              <div v-else >
                <div 
                class="body-1"
                v-if="!readMoreInfo"
                style="white-space: pre-line"
                v-html="item.description.slice(0, 200) + '...'">
                </div>
                <div 
                v-else
                style="white-space: pre-line"
                v-html="item.description"> 
                </div>

              </div>
              <div
              class="mt-2"
              style="color: #0681c8; cursor: pointer;"
              v-show="item.description.length > 200"
              @click="readMoreInfo = !readMoreInfo">
                {{ readMoreInfo ? "Скрыть" : "Показать полностью" }}
              </div>
              <!-- <v-btn
              small
              v-show="item.description.length > 200"
              elevation="0"
              @click="readMoreInfo = !readMoreInfo"
              >
                {{ readMoreInfo ? "Скрыть" : "Показать полностью" }}
              </v-btn> -->
            </v-card-text>
            <!-- <v-card-actions class="pl-4 bottom">
              <v-btn
                class="px-6"
                color="grey"
                large
                elevation="0"
                @click="
                  $router.push({
                    name: 'edit-admin-schedule-event',
                    params: { arenaId },
                  })
                "
              >
                Забронировать
              </v-btn>
            </v-card-actions> -->
          </v-col>
        </v-row>
      </div>
    </v-card>

    <slot name="price-list" :prices="priceList">
      <v-row v-if="item.price.length">
        <v-col
          cols="2"
          class="text-center border"
          v-for="(itm, indx) in item.price"
          :key="indx"
        >
          <div class="mb-3 grey--text">
            {{ itm.startTime + " - " + itm.endTime }}
          </div>
          <div class="right-border mr-n3">
            <p class="mb-0">{{ itm.weekdayPrice }}</p>
            <p class="primary--text">
              {{ itm.holidayPrice }}
            </p>
          </div>
        </v-col>
      </v-row>
      <div class="mt-n8" v-show="item.price.length">
        <span class="mr-5 font-weight-bold">
          <v-icon style="font-size: 70px" color="#000" class="">
            mdi-circle-small
          </v-icon>
          <span class="ml-n5">Будни</span>
        </span>
        <span class="font-weight-bold primary--text">
          <v-icon style="font-size: 70px" color="primary" class="">
            mdi-circle-small
          </v-icon>
          <span class="ml-n5"> Выходные </span>
        </span>
      </div>
    </slot>
    <slot name="modify-pricelist"> </slot>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ArenaPaymentCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    arenaId: {
      type: String,
      required: true,
    },
  },
  data(){
    return {
      readMoreInfo: null,
    }
  },
  computed: {
    priceList() {
      const now = moment();
      const prices = this.item.price;
      return prices.filter((x) => {
        const showDate = x.showDate ? `${x.showDate} 00:00:00` : "";
        const priceShowDate = moment(showDate);
        if (priceShowDate.diff(now, "days", true) < 0) {
          return x;
        }
      });
    },
  },
};
</script>

<style scoped></style>
