<template>
  <v-container>
    <div
      style="
        text-decoration: none;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 500;
      "
    >
      {{ title }}
    </div>
    <v-row class="mt-5" dense>
      <v-col class="d-flex" cols="12" md="2">
        <v-select
          :items="todoMode"
          v-model="currentTodoMode"
          solo
          flat
          outlined
          hide-details="auto"
          v-on:change="fetchData(true)"
        ></v-select>
      </v-col>
      <v-col cols="9" md="8" lg="9">
        <v-text-field
          label="Поиск по названию"
          single-line
          prepend-inner-icon="mdi-magnify"
          solo
          flat
          outlined
          hide-details="auto"
          class="rounded-lg"
          color="primary"
          v-model="state.search"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="2" lg="1">
        <v-btn
          class="rounded-lg"
          large
          depressed
          height="55px"
          width="100%"
          color="primary"
          v-on:click="fetchData()"
        >
          Найти
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-3" no-gutters>
      <v-col class="d-flex" cols="4" md="2">
        <v-select
          :items="cities"
          v-model="state.city"
          solo
          flat
          outlined
          hide-details="auto"
          v-on:change="fetchData(true)"
        ></v-select>
      </v-col>
      <v-col class="d-flex ml-3" cols="4" md="2">
        <v-select
          :items="sortBy"
          v-model="state.sort"
          solo
          flat
          outlined
          hide-details="auto"
          v-on:change="fetchData()"
        ></v-select>
      </v-col>
      <v-col class="d-flex ml-3" cols="4" md="2">
        <v-select
          v-model="state.pageSize"
          :items="showByList"
          solo
          flat
          return-object
          outlined
          hide-details="auto"
          v-on:change="fetchData()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mt-4" no-gutters>
      <v-col
        class="pa-4"
        cols="12"
        md="4"
        xl="3"
        v-for="({ id, logo, editedLogo, title, address }, i) in camps.content"
        :key="i"
      >
        <v-card class="mx-auto" max-width="400" hover>
          <router-link
            :to="{
              path: `/campInfo/${id}`,
              params: { id },
            }"
            class="undo-link-default"
          >
            <v-img
              class="white--text align-end"
              height="250px"
              :src="editedLogo ? editedLogo : logo"
            >
            </v-img>

            <v-card-title class="text--primary">
              <div>{{ title ? title : "Нет имени" }}</div>
            </v-card-title>

            <v-card-subtitle class="mt-2 grey--text">
              {{ address ? `${address.slice(0, 30)}...` : "Адрес не известен" }}
            </v-card-subtitle>
          </router-link>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="blue"
              text
              v-show="buttonToggle"
              v-on:click="isApproved({ isApproved: true, id: id })"
            >
              Одобрить
            </v-btn>
            <v-btn
              color="red"
              text
              v-show="!buttonToggle"
              v-on:click="isApproved({ isApproved: false, id: id })"
            >
              Отклонить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center py-10">
      <v-pagination
        color="grey"
        :length="camps.totalPages"
        :total-visible="7"
        v-model="state.currentPage"
        v-on:input="fetchData()"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    title: String,
  },
  data() {
    return {
      todoMode: ["Одобренные запросы", "Неодобренные запросы"],
      currentTodoMode: "Одобренные запросы",
      paginationLength: 0,
      cities: ["Все города", "Москва", "Казань"],
      numFound: 0,
      buttonToggle: false,
      sortBy: ["По дате", "По имени"],
      showByList: ["Показывать по 12", "Показывать по 8"],
      state: {
        city: "Все города",
        currentPage: 1,
        pageSize: "Показывать по 12",
        sort: "По дате",
        startAge: "",
        endAge: "",
        search: "",
        startDate: "",
        endDate: "",
        approved: true,
      },
    };
  },
  watch: {
    currentTodoMode() {
      this.buttonToggle = !this.buttonToggle;
    },
  },
  mounted() {
    const defState = {
      currentPage: 1,
      pageSize: 12,
      sort: true,
      approved: true,
    };
    this.fetchData(defState);
  },
  computed: {
    ...mapState("camps", ["camps", "camp"]),
  },
  methods: {
    fetchData() {
      const {
        city,
        currentPage,
        pageSize,
        sort,
        search,
        startDate,
        endDate,
        startAge,
        endAge,
      } = this.state;

      const newState = {
        ...(city === "Все города" ? {} : { city }),
        currentPage,
        ...(pageSize === "Показывать по 12"
          ? { pageSize: 12 }
          : { pageSize: 8 }),
        ...(sort === "По дате" ? { sort: true } : { sort: false }),
        ...(search === "" ? {} : { search }),
        ...(startDate === "" ? {} : { startDate }),
        ...(endDate === "" ? {} : { endDate }),
        ...(startAge === "" ? {} : { startAge }),
        ...(endAge === "" ? {} : { endAge }),
        ...(this.currentTodoMode === "Одобренные запросы"
          ? { approved: true }
          : { approved: false }),
      };

      this.fetchCamps(newState);

      this.state.startDate = "";
      this.state.endDate = "";
      this.state.starАge = "";
      this.state.endAge = "";
    },
    async isApproved({ isApproved, id }) {
      await this.getCamp(id);
      this.camp.approved = isApproved;
      await this.putCamp({ campId: id, camp: this.camp });
      this.fetchData();
    },
    ...mapActions("camps", ["fetchCamps", "getCamp", "putCamp"]),
  },
};
</script>

<style lang="scss" scoped></style>
