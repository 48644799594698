<template>
  <div class="white" style="border-radius: 12px;">
          <v-card-title>
            <div class="text-h5 black--text">Создать объявление</div>
          </v-card-title>
          <v-card-text class="mb-4">
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="playerSearch"
                  v-model="nforum.find"
                  placeholder="Тип объявления"
                  solo
                  background-color="#F5F5F5"
                  flat
                  item-text="state"
                  item-value="value"
                  return-object
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col v-if="nforum.find.value == 'TEAMPLAYER' || nforum.find.value == 'TEAMTRAINER'" cols="12">
                <v-select
                  :items="teams"
                  v-model="selectedTeam"
                  placeholder="Команда"
                  solo
                  background-color="#F5F5F5"
                  flat
                  item-text="teamTitle"
                  item-value="teamId"
                  return-object
                  hide-details="auto"
                ></v-select>
              </v-col>
              
              <v-col cols="12">
                <div class="mb-2 text-h6">Название объявления</div>
                <v-text-field
                label="Название объявления"
                v-model="nforum.title"
                background-color="#F5F5F5"
                solo
                flat
                hide-details="auto"
                class="rounded-lg"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="nforum.find.value == 'TEAMPLAYER'">
                <div class="mb-2 text-h6">Амплуа</div>
                <v-select
                :items="positions"
                label="Амплуа"
                v-model="nforum.position"
                background-color="#F5F5F5"
                solo
                flat
                hide-details="auto"
                class="rounded-lg"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="nforum.find.value == 'TEAMPLAYER'">
                <div class="mb-2 text-h6">Хват</div>
                <v-select
                :items="grips"
                label="Хват"
                v-model="nforum.grip"
                background-color="#F5F5F5"
                solo
                flat
                hide-details="auto"
                class="rounded-lg"
                ></v-select>
              </v-col>
              <v-col cols="12" class="">
                <div class="mb-2 text-h6">Описание объявления</div>
                <v-textarea
                  solo
                  v-model="nforum.description"
                  background-color="#F5F5F5"
                  min-height="130"
                  flat
                  elevation="0"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="cities"
                  v-model="nforum.city"
                  placeholder="Город"
                  background-color="#F5F5F5"
                  solo
                  flat
                  item-text="state"
                  item-value="value"
                  return-object
                  hide-details="auto"
                  class="rounded-lg"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-n6 mx-2">
            <v-btn
            class="rounded-lg"
            large
            color="grey lighten-2"
            elevation="0"
            @click="goBack"
            >
              Назад
            </v-btn>
            <v-btn
            large
            class="rounded-lg"
            color="primary"
            elevation="0"
            @click="saveForum"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
  </div>
</template>

<script>
import Axios from "axios";
import { GET_USER_BY_ID } from "@/api";
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getAllAdminTeams()
    const userId = this.userId;
    this.$store.dispatch("user/getUser", userId).then(() => {
      this.getSocialMedia();
    });
    this.$store.dispatch("user/getForums", this.userId);
    this.$store.dispatch("user/getRole", this.roleId);
  },
  computed: {
    ...mapState("user", ["user", "forums", "role"]),
    roleCategory() {
      if (this.role.name === "TRAINER") {
        return this.role.category
          .map((cat) => {
            if (cat == "ADULT") return "Взрослый";
            if (cat == "KID") return "Детский";
            if (cat == "YOUTH") return "Юношеский";
            if (cat == "FEMALE") return "Женский";
          })
          .join(", ");
      } else return "";
    },
    displaySocialMedia() {
      return this.social_media.filter((element) => element.link);
    },
    fullName() {
      const { name, middleName, surname } = this.user;
      return `${surname} ${name} ${middleName} `;
    },
  },
  data() {
    return {
      teams: [],
      selectedTeam: {},
      loading: false,
      social_media: [],
      modifyForumDialog: false,
      nforum: {
        find: {},
        description: "",
        city: "Москва",
        position: "",
        grip: "",
        title: "",
        organizer: "",
      },
      cities: ["Москва"],
      playerSearch: [],
      positions: ["Защитник", "Нападающий", "Вратарь"],
      grips: ["Левый", "Правый"],
      forumId: "",
      organizer: "",
      teamId: ""
    };
  },
  methods: {
    goBack(){
      this.$router.push({
        name: "admin-advertisements",
        params: { userId: this.userId },
      });
    },

    async getAllAdminTeams() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/team/titles`)
      .then( (res) => {
        this.teams = res.data.filter((x) => x.approved === "accepted")
        console.log(this.teams)
        this.getUserRoles()
      })
      .catch((error) => {
        console.error(error);
      })
    },

    async getUserRoles() {
      await Axios.get(`${GET_USER_BY_ID}${this.userId}/roles`)
      .then( (res) => {
        res.data.forEach(item => {
          if(item.name == "PLAYER"){
            const obj = { 
              value: "PLAYERTEAM", 
              state: "Игрок ищет команду" 
            }
            this.playerSearch.push(obj)
          } 
          if(item.name == "TRAINER"){
            const obj = { 
              value: "TRAINERTEAM", 
              state: "Тренер ищет команду" 
            }
            this.playerSearch.push(obj)
          } 
          if(item.name == "TEAM_ADMIN"){
            this.teams = this.teams.filter((x) => x.roleId === item.id)
            const arr = [{ 
              value: "TEAMPLAYER", 
              state: "Команда ищет игроков" 
            },
            { 
              value: "TEAMTRAINER", 
              state: "Команда ищет тренера" 
            },]
            this.playerSearch = this.playerSearch.concat(arr)
          } 
          
        });
        this.nforum.find = this.playerSearch[0]
      })
      .catch((error) => {
        console.error(error);
      })
    },

    isValid(input) {
      if (input) return true;
      return false;
    },
    dateFormat(date) {
      let newDate = new Date(date);
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formatter.format(newDate);
    },
    getSocialMedia() {
      const { vk, whatsApp, instagram, website, facebook } = this.user;
      this.social_media = [
        {
          id: 1,
          name: "vk",
          link: vk,
          icon: "mdi-alpha-k-box-outline",
        },
        {
          id: 2,
          name: "whatsapp",
          link: whatsApp,
          icon: "mdi-whatsapp",
        },
        {
          id: 3,
          name: "web",
          link: website,
          icon: "mdi-web",
        },
        {
          id: 4,
          name: "instagram",
          link: instagram,
          icon: "mdi-instagram",
        },
        {
          id: 5,
          name: "facebook",
          link: facebook,
          icon: "mdi-facebook",
        },
      ];
    },
    initForumDialog() {
      return {
        find: "",
        description: "",
        city: "",
        position: "",
        grip: "",
      };
    },
    saveForum() {
      const { description, position, city, grip, find, title } = this.nforum;
      if(this.nforum.find.value == 'TEAMPLAYER' || this.nforum.find.value == 'TEAMTRAINER'){
        this.organizer = this.selectedTeam.teamTitle
        this.teamId = this.selectedTeam.teamId
      } else {
        this.organizer = this.fullName
      }
      const postForum = {
        date: moment().format("YYYY-MM-DD"),
        description,
        grip,
        city,
        title,
        role: position,
        userId: this.userId,
        teamId: this.teamId,
        type: find.value,
        organizer: this.organizer,
      };
      this.$store.dispatch("user/postForum", postForum).then(() => {
        this.nforum = this.initForumDialog();
        this.$router.push({
          name: "admin-advertisements",
          params: { userId: this.userId },
        });
      });
    },
  },
};
</script>

<style scoped></style>
